import {startInterval, stopInterval} from 'intervalActions.js';
import _find from 'lodash/find';
import _size from 'lodash/size';

export const TOGGLE_ACCOUNT_MODAL = 'TOGGLE_ACCOUNT_MODAL';
export const UPDATE_SESSION_TIME = 'UPDATE_SESSION_TIME';
export const LOAD_VERYFICATION_STATUS = 'LOAD_VERYFICATION_STATUS';

const toggleAccountModal = (isOpen) => {
    return {
        type: TOGGLE_ACCOUNT_MODAL,
        payload: {isOpen}
    }
};

const startSessionTimeInterval = () => {
    return (dispatch, getState) => {

        const key = 'SESSION_TIME';
        const intervalExist = checkIntervalExist(key, getState);
        if(intervalExist) return;

        const config = {
            key,
            actionCreator: sessionTimer,
            timeout: 1000
        };
        dispatch(startInterval(config));
    }
};

const stopSessionTimeInterval = () => {
    return (dispatch, getState) => {
        const key = 'SESSION_TIME';
        const intervalExist = checkIntervalExist(key, getState);
        if (intervalExist) {
            dispatch(stopInterval(key));
        }
    }
};

const checkIntervalExist = (key, getState)=>{
    const {Intervals:{intervals}} = getState();
    const intervalExist = _find(intervals, {key});
    return _size(intervalExist) ? true: false;
};

const sessionTimer = () => {
    return (dispatch) => {
        dispatch({type: UPDATE_SESSION_TIME});
    }
};

const loadVeryficationStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {code, data} = await CustomerApi.getVerificationStatus();
            dispatch(loadVeryficationStatusSuccess(data.account));
        } catch (error) {
            dispatch(loadVeryficationStatusFailure(error));
            throw error;
        }
    }
};

const loadVeryficationStatusFailure = (error) => {
    return {
        type: `${LOAD_VERYFICATION_STATUS}_FAILURE`,
        payload: {error}
    }
};

const loadVeryficationStatusSuccess = (accountData) => {
    return {
        type: `${LOAD_VERYFICATION_STATUS}_SUCCESS`,
        payload: {accountData}
    }
};

export {toggleAccountModal, startSessionTimeInterval, stopSessionTimeInterval, loadVeryficationStatus}