import styled from 'styled-components';

const S = {};
 
S.AccountMainWrapper = styled.div``;

S.UserActivityLink = styled.div`
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: ${({theme:{$accountUserActivityLinkColor}})=> $accountUserActivityLinkColor};
`;

export default S;