import styled from 'styled-components';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.AccountEditProfile = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
`;

S.AccountEditProfileIframe = styled.iframe`
  width: 100%;
`;

S.ChangePasswordButton = styled.button`
  align-self: flex-start;
`;


export default S;