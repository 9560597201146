import styled from 'styled-components';
import {Card} from 'global/styles/styles.js';
const S = {};

const Header = ({ theme: { header } }) => header();
const CardBody = ({ theme: { cardBody } }) => cardBody();
const CardTab = ({ theme: { cardTab } }) => cardTab();
const CardLabel = ({ theme: { cardLabel } }) => cardLabel();
const Divider = ({ theme: { divider } }) => divider();
const CardWithTab = ({ theme: { cardWithTab } }) => cardWithTab();
const Link = ({ theme: { link } }) => link();

S.AccountHistoryBonus = styled.div`
    width: calc(100% - 2rem);
    padding: 0 1rem;
`;

S.HistoricalBonusRow = styled.div``;

S.BonusBox = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 0.45rem 0.75rem 0.45rem;
`;

S.BonusOffrImg = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
`;

S.BonusTitle = styled.div`${CardLabel}`;

S.BonusValue = styled.div`${CardLabel}
    font-weight: 700;
`;

S.Divider = styled.div`${Divider}`;

S.Link = styled.a`${Link}
    margin: 1rem 0 0.1rem;
`;

S.BonusHeader = styled.h4` ${Header} `;

S.BonusBody = styled.div`${CardBody}`;

S.BonusContent = styled(Card)` ${CardWithTab}
    justify-content: center;
`;
S.BonusImageWrapper = styled.div` ${CardTab}
    position: relative;
    padding-top: ${p => `calc(100% / ${p.ratio})`};
    min-height: unset;
    overflow: hidden;
`;

S.BonusList = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

S.NoResult = styled.div`
    height: 2.5rem;
    text-align: center;
    color: ${({theme:{$noResultColor}}) => $noResultColor};
`;

S.BonusFooter = styled.div`
  text-align: center;
  padding-top: 0.9375rem;
`;

S.BonusRegulationLink = styled.a`
  font-size: 0.75rem;
  color: ${({theme:{$accountProfileBonusRegulationLinkColor}})=> $accountProfileBonusRegulationLinkColor};
  text-decoration: underline;
`;
export default S;