export default {
    LIST_SETTINGS: {
        miniature: true,
        className: 'paymentList',
        paymentCurrency: true
    },
    PROVIDER: {

        19: {
            steps: 1,
            minatureComponent: {
                img: 'deposit.png',
            },
            callData: 'cmsPage',
            stepOne: [
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        20: {
            steps: 2,
            minatureComponent: {
                img: 'payout.png',
            },
            callData: ['callBankList', 'callAccountTypes', 'callCustomerBankDetail', 'cmsPage'],

            confirmUserData: [
                { bankId: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { accountType: { label: 'payment_accountTypes' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                bank: {
                    actionOnAccount: 1,
                    additionalInformationsJSON: {},
                    customerName: ''
                }

            },

            stepOne: [
                { ImageComponent: 'payout.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callBankList',
                        label: 'payment_bankLists',
                        name: 'bank->bankId',
                        value: 'bankId',
                        text: 'bankName',
                        first: 'payment_chooseSelected'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callAccountTypes',
                        label: 'payment_accountTypes',
                        name: 'bank->accountType',
                        value: 'accountType',
                        text: 'accountTypeName',
                        first: 'payment_chooseSelected'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_payout',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'bank.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                { ConfirmDataComponent: {} },

                {
                    InputComponent: {
                        label: 'payment_password',
                        name: 'security->securityToken',
                        required: 'required',
                        type: 'password'
                    }
                },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }
                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        27: {
            steps: 2,
            callData: 'cmsPage',

            minatureComponent: {
                img: 'deposit.png',
            },

            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        31: {
            steps: 1,
            minatureComponent: {
                img: 'deposit.png',
            },
            callData: 'cmsPage',
            stepOne: [
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        32: {
            steps: 2,
            minatureComponent: {
                img: 'payout.png',
            },
            callData: ['callBankList', 'callAccountTypes', 'callWonAmountToPayout', 'callCustomerBankDetail', 'cmsPage'],

            confirmUserData: [
                { bankId: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { accountType: { label: 'payment_accountTypes' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                customer: {
                    email: '',
                    firstName: '',
                    lastName: '',
                },
                bank: {
                    actionOnAccount: 1,
                    additionalInformationsJSON: {},
                    customerName: ''
                }
            },

            stepOne: [
                { ImageComponent: 'payout.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callBankList',
                        label: 'payment_bankLists',
                        name: 'bank->bankId',
                        value: 'bankId',
                        text: 'bankName',
                        first: 'payment_chooseSelected'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callAccountTypes',
                        label: 'payment_accountTypes',
                        name: 'bank->accountType',
                        value: 'accountType',
                        text: 'accountTypeName',
                        first: 'payment_chooseSelected'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required'
                    }
                },

                
                {
                    InfoWonAmountToPayoutComponent: {
                        labelLocked: 'payout_noAmountToPayout',
                        labelUnlocked: 'payment_wonAmountToPayout',
                        data: 'callWonAmountToPayout'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_payout',
                        action: 'submit',
                        type: 'submitting',
                        disabledCondition: 'callWonAmountToPayout',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'payout.png' },

                { ConfirmDataComponent: {} },

                {
                    InputComponent: {
                        label: 'payment_pinCode',
                        name: 'security->securityToken',
                        required: 'required',
                        type: 'password'
                    }
                },


                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        33: {
            steps: 1,
            minatureComponent: {
                img: 'deposit.png',
            },
            callData: 'cmsPage',
            stepOne: [
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },


        37: {
            steps: 2,
            callData: 'cmsPage',
            minatureComponent: {
                img: 'deposit.png',
            },
            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: 'USD',
                callbackUrl: '',
                remoteTransactionCurrency: 'USD'
            },

            stepOne: [

                { ImageComponent: 'deposit.png' },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [

                { ImageComponent: 'deposit.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        38: {
            steps: 2,
            callData: 'cmsPage',
            minatureComponent: {
                img: 'payout.png',
            },
            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: '',
                remoteTransactionCurrency: 'USD',
                remoteTransactionAmount: ''
            },

            stepOne: [

                { ImageComponent: 'payout.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_accountPerfectMoneyNumber',
                        name: 'walletId',
                        required: 'required'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_payout',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'payout.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_cancel',
                                onClick: 'resetProvider'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        39: {
            steps: 2,

            minatureComponent: {
                img: 'deposit.png',
            },

            callData: 'cmsPage',

            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        41: {
            steps: 1,
            minatureComponent: {
                img: 'deposit.png',
            },
            callData: 'cmsPage',
            stepOne: [
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        43: {
            steps: 1,
            minatureComponent: {
                img: 'deposit.png',
            },
            callData: 'cmsPage',
            stepOne: [
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        103: {
            steps: 2,
            callData: ['callCreditCardList', 'cmsPage'],
            observedSelectors: ['card->cardType'],
            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { cardType: { label: 'payment_selectedCard' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    SelectComponent: {
                        data: 'callCreditCardList',
                        label: 'payment_cardLists',
                        name: 'card->cardType',
                        value: 'cardType',
                        text: 'cardNumber',
                        first: 'payment_paymentNewCard',
                        removeOption: {
                            label: 'payment_removeCard',
                            onClick: 'removeCardConfirm',
                        }
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: false
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        104: {
            steps: 2,
            isBettingShopTransaction: true,
            callData: 'cmsPage',

            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { customerId: { label: 'payment_customerId' } },
                { loginName: { label: 'payment_loginName' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                isBettingShopTransaction: '',
                callbackUrl: ''
            },

            stepOne: [
                { ImageComponent: 'paystack.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'paystack.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },

        105: {
            steps: 2,
            isAgentConsoleTransaction: true,
            isBettingShopTransaction: false,
            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { customerId: { label: 'payment_customerId' } },
                { loginName: { label: 'payment_loginName' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                callbackUrl: '',
                isAgentConsoleTransaction: '',
                isBettingShopTransaction: ''
            },

            stepOne: [
                { ImageComponent: 'paystack.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'paystack.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                }
            ]
        },

        113: {
            steps: 2,
            callData: ['callPaystackBankList', 'cmsPage'],

            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { cbnCode: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { additionalData: { label: 'payment_additionalData' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                customer: {
                    email: '',
                    firstName: '',
                    lastName: ''
                }
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callPaystackBankList',
                        label: 'payment_bankLists',
                        name: 'bank->cbnCode',
                        value: 'cbnCode',
                        text: 'bankName',
                        first: 'payment_chooseSelected',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_additionalData',
                        name: 'additionalData',
                        type: 'date',
                        required: 'required',
                        className: 'without-floating-label'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }

            ],

            stepThree: [
                {ImageComponent: 'paystack.png'},

                {InputComponent: {
                  label: 'payment_verificationCode',
                  name: 'verificationCode',
                  required: 'required'
                }},

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },
            ]
        },

        114: {
            steps: 2,
            callData: ['callPaystackBankList', 'cmsPage'],
            isBettingShopTransaction: true,
            isShopUser: true,

            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { cbnCode: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { customerId: { label: 'payment_customerId' } },
                { loginName: { label: 'payment_loginName' } },
                { additionalData: { label: 'payment_additionalData' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                customer: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    isShopUser: true,
                },
                callbackUrl: '',
                isBettingShopTransaction: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    SelectComponent: {
                        data: 'callPaystackBankList',
                        label: 'payment_bankLists',
                        name: 'bank->cbnCode',
                        value: 'cbnCode',
                        text: 'bankName',
                        first: 'payment_chooseSelected',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_additionalData',
                        name: 'additionalData',
                        type: 'date',
                        required: 'required',
                        className: 'without-floating-label'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepThree: [
                {ImageComponent: 'paystack.png'},

                {InputComponent: {
                  label: 'payment_verificationCode',
                  name: 'verificationCode',
                  required: 'required'
                }},

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },
            ]
        },

        115: {
            steps: 2,
            callData: ['callPaystackBankList', 'cmsPage'],
            isBettingShopTransaction: false,
            isAgentConsoleTransaction: true,

            minatureComponent: {
                img: 'paystack.png',
            },

            confirmUserData: [
                { cbnCode: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { customerId: { label: 'payment_customerId' } },
                { loginName: { label: 'payment_loginName' } },
                { additionalData: { label: 'payment_additionalData' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                customer: {
                    email: '',
                    firstName: '',
                    lastName: '',
                },
                isBettingShopTransaction: '',
                isAgentConsoleTransaction: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    SelectComponent: {
                        data: 'callPaystackBankList',
                        label: 'payment_bankLists',
                        name: 'bank->cbnCode',
                        value: 'cbnCode',
                        text: 'bankName',
                        first: 'payment_chooseSelected',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_additionalData',
                        name: 'additionalData',
                        type: 'date',
                        required: 'required',
                        className: 'without-floating-label'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },
        170: {
            steps: 2,
            minatureComponent: {
                img: 'payout.png',
            },
            callData: 'callBillers',
            stepOne: [
                {
                    MenuComponent: {
                        data: 'callBillers'
                    }
                }
            ]
        },
        238: {
          steps: 2,
          insideIframe: true,

          minatureComponent: {
              img: 'paystack.png',
              shortDescription: 'payments_shortDescription_'
          },

          confirmUserData: [
              {transactionAmount: {label: 'payment_transactionAmount'}}
          ],

          initialize: {
              providerId: '',
              transactionCurrency: '',
              callbackUrl: ''
          },

          stepOne: [
              {ImageComponent: 'paystack.png'},

              {InputComponent: {
                  label: 'payment_transactionAmount',
                  name: 'transactionAmount',
                  required: 'required'
              }},

              {ButtonComponent: {
                  label: 'payment_deposit',
                  action: 'submit',
                  type: 'submitting',
                  disabled: true
              }}
          ],
          stepTwo: [
            { ImageComponent: 'paystack.png' },
            { ConfirmDataComponent: {} },
            { GroupComponents: [
               { ButtonComponent: {
                 label: 'common_back',
                 onClick: 'backStep'
               }},
               { ButtonComponent: {
                 label: 'common_accept',
                 action: 'submit'
               }},
               { ButtonComponent: {
                 label: 'common_cancel',
                 onClick: 'resetProvider'
               }}
             ]
           }]
        },
        255: {
            steps: 2,
            isBettingShopTransaction: true,
            renderAccount: true,
            autoInitialize: true,
            providerName: 'payment_providerName_',
            callData: 'cmsPage',

            minatureComponent: {
                img: 'deposit.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                {bankName: {label: 'payment_bankName'}},
                {accountNumber: {label: 'payment_bankAccountNumber'}},
                {accountName: {label: 'payment_bankAccountName'}},
                {cbnCode: {label: 'payment_cbnCode'}}
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
            },

            stepOne: [],
            stepTwo: [
              { ImageComponent: 'deposit.png' },
              { TextComponent: { label: 'payments_monnifyTransfer' } },
              { ConfirmDataComponent: {} },
              { CmsComponent: { data: 'cmsPage' } }
            ]
          },
          275: {
            steps: 2,
            insideIframe: false,
  
            minatureComponent: {
                img: 'gt-bank.png',
                shortDescription: 'payments_shortDescription_'
            },
  
            confirmUserData: [
                {transactionAmount: {label: 'payment_transactionAmount'}}
            ],
  
            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: '',
                customer: {
                    firstName: '',
                    lastName: ''
                },
            },
  
            stepOne: [
                {ImageComponent: 'gt-bank.png'},
  
                {InputComponent: {
                    label: 'payment_transactionAmount',
                    name: 'transactionAmount',
                    required: 'required'
                }},
  
                {ButtonComponent: {
                    label: 'payment_deposit',
                    action: 'submit',
                    type: 'submitting',
                    disabled: true
                }}
            ],
            stepTwo: [
              { ImageComponent: 'gt-bank.png' },
              { ConfirmDataComponent: {} },
              { GroupComponents: [
                 { ButtonComponent: {
                   label: 'common_back',
                   onClick: 'backStep'
                 }},
                 { ButtonComponent: {
                   label: 'common_accept',
                   action: 'submit'
                 }},
                 { ButtonComponent: {
                   label: 'common_cancel',
                   onClick: 'resetProvider'
                 }}
               ]
             }]
          },

          293: {
            steps: 2,
            renderAccount: true,
            isAgentConsoleTransaction: true,

            minatureComponent: {
                img: 'deposit.png'
            },

            confirmUserData: [
                { bankName: { label: 'payment_bankName' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { cbnCode: { label: 'payment_cbnCode' } }
            ],

            initialize: {
                providerId: '',
                transactionAmount: '1',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                isAgentConsoleTransaction: ''
            },

            stepOne: [
                { ImageComponent: 'deposit.png' },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'deposit.png' },
                { ConfirmDataComponent: {} }
            ]
        },

        299: {
            steps: 2,

            minatureComponent: {
                img: 'opay.png',
                shortDescription: 'payments_shortDescription_'
            },

            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [
                { ImageComponent: 'opay.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                }
            ],
            stepTwo: [
                { ImageComponent: 'opay.png' },
                { ConfirmDataComponent: {} },
                {
                    GroupComponents: [
                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },
                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }
                    ]
                }]
        },

        304: {
            steps: 2,
            minatureComponent: {
                img: 'opay.png',
            },
            confirmUserData: [
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: '',
                callbackUrl: ''
            },

            stepOne: [

                { ImageComponent: 'opay.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_payout',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'opay.png' },

                { ConfirmDataComponent: {} },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount'
                    }
                },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                }
            ]
        },
        310: {
            steps: 2,
            isAgentConsoleTransaction: true,
            isBettingShopTransaction: false,

            minatureComponent: {
                img: 'opay.png',
            },

            confirmUserData: [
                { customerId: { label: 'payment_customerId' } },
                { loginName: { label: 'payment_loginName' } },
                { transactionAmount: { label: 'payment_transactionAmount' } },
                { phoneNumber: {label: 'payment_phoneNumber'} }
            ],

            initialize: {
                providerId: '',
                transactionCurrency: process.env.MAIN_CURRENCY_CODE,
                callbackUrl: '',
                isAgentConsoleTransaction: '',
                isBettingShopTransaction: ''
            },

            stepOne: [
                { ImageComponent: 'opay.png' },

                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_agentId',
                        name: 'customerId',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_loginName',
                        name: 'customer->loginName',
                        required: 'required'
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required',
                        limitsProvider: true
                    }
                },

                {
                    InputComponent: {
                        label: 'payment_phoneNumber',
                        name: 'phoneNumber',
                        type: 'text',
                        required: 'required',
                    }
                },

                {
                    ButtonComponent: {
                        label: 'payment_deposit',
                        action: 'submit',
                        type: 'submitting',
                        disabled: true
                    }
                }
            ],

            stepTwo: [
                { ImageComponent: 'opay.png' },

                { ConfirmDataComponent: {} },

                {
                    GroupComponents: [

                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },

                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }

                    ]
                }
            ]
        },
        332: {
            steps: 2,
            minatureComponent: {
                img: 'payout.png',
            },
            callData: ['callBankList', 'callAccountTypes', 'callWonAmountToPayout', 'callCustomerBankDetail', 'cmsPage'],
            confirmUserData: [
                { bankId: { label: 'payment_bankLists' } },
                { accountNumber: { label: 'payment_bankAccountNumber' } },
                { accountType: { label: 'payment_accountTypes' } },
                { transactionAmount: { label: 'payment_transactionAmount' } }
            ],
            initialize: {
                providerId: '',
                transactionCurrency: '',
                customer: {
                    email: '',
                    firstName: '',
                    lastName: '',
                },
                bank: {
                    actionOnAccount: 1,
                    additionalInformationsJSON: {},
                    customerName: ''
                }
            },
            stepOne: [
                { ImageComponent: 'payout.png' },
                {
                    LimitsComponent: {
                        max: 'payment_maxTransactionAmount',
                        min: 'payment_minTransactionAmount',
                        fee: 'payment_minFee'
                    }
                },
                {
                    SelectComponent: {
                        data: 'callBankList',
                        label: 'payment_bankLists',
                        name: 'bank->bankId',
                        value: 'bankId',
                        text: 'bankName',
                        first: 'payment_chooseSelected'
                    }
                },
                {
                    InputComponent: {
                        label: 'payment_bankAccountNumber',
                        name: 'bank->accountNumber',
                        required: 'required'
                    }
                },
                {
                    SelectComponent: {
                        data: 'callAccountTypes',
                        label: 'payment_accountTypes',
                        name: 'bank->accountType',
                        value: 'accountType',
                        text: 'accountTypeName',
                        first: 'payment_chooseSelected'
                    }
                },
                {
                    InputComponent: {
                        label: 'payment_transactionAmount',
                        name: 'transactionAmount',
                        required: 'required'
                    }
                },
                {
                    InfoWonAmountToPayoutComponent: {
                        labelLocked: 'payout_noAmountToPayout',
                        labelUnlocked: 'payment_wonAmountToPayout',
                        data: 'callWonAmountToPayout'
                    }
                },
                {
                    ButtonComponent: {
                        label: 'payment_payout',
                        action: 'submit',
                        type: 'submitting',
                        disabledCondition: 'callWonAmountToPayout',
                        disabled: true
                    }
                },
                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ],
            stepTwo: [
                { ImageComponent: 'payout.png' },
                { ConfirmDataComponent: {} },
                {
                    InputComponent: {
                        label: 'payment_pinCode',
                        name: 'security->securityToken',
                        required: 'required',
                        type: 'password'
                    }
                },
                {
                    GroupComponents: [
                        {
                            ButtonComponent: {
                                label: 'common_back',
                                onClick: 'backStep'
                            }
                        },
                        {
                            ButtonComponent: {
                                label: 'common_accept',
                                action: 'submit'
                            }
                        }
                    ]
                },

                {
                    CmsComponent: {
                        data: 'cmsPage'
                    }
                }
            ]
        },
    }
};
