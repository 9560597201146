import React, {Component} from 'react';
import {connect} from 'react-redux';
import S from 'StyledAccountSessionTimer.js';
import {translation} from 'utilsHelper.js';
import _map from 'lodash/map';

class AccountSessionTimer extends Component {

    parseSessionTime = () => {
        const {sessionTime, sessionStart, prevSessionTime} = this.props;

        const sessionTimeDiff = Math.floor( (sessionTime - sessionStart) / 1000);
        const hoursSession = Math.floor(sessionTimeDiff / 3600);
        const minutesSession = Math.floor((sessionTimeDiff - (hoursSession * 3600)) / 60);
        const secondsSession = sessionTimeDiff - (hoursSession * 3600) - (minutesSession * 60);
        const timePartsSession = _map([hoursSession, minutesSession, secondsSession], (part) => ('0' + part).substr(-2));
        return timePartsSession
    };
    
    render() {
        const sessionTimeParts = this.parseSessionTime();
        return (
            <S.AccountSessionWrapper>
                <S.AccountSessionLabel>{translation('account_loggedSince')}</S.AccountSessionLabel>
                <S.AccountSessionTimer className="session-timer">
                    {[
                        <S.TimeParts key="time_part_hours">{sessionTimeParts[0]} <span>h</span></S.TimeParts>,
                        <S.TimeParts key="time_part_minutes">{sessionTimeParts[1]} <span>min</span></S.TimeParts>,
                        <S.TimeParts key="time_part_seconds">{sessionTimeParts[2]} <span>sek</span></S.TimeParts>
                    ]}
                </S.AccountSessionTimer>
            </S.AccountSessionWrapper>
        );
    }
}

const mapStateToProps = (state, props ) => {

    return {
        sessionTime: (state.Account && state.Account.sessionTime)? state.Account.sessionTime : 0,
        sessionStart: (state.Auth && state.Auth.user)? state.Auth.user.sessionStart : 0,
        prevSessionTime: (state?.Auth?.user?.sessionTime?.prevSessionTime ) ? state.Auth.user.sessionTime.prevSessionTime : 0,
    }
};

export default connect(mapStateToProps)(AccountSessionTimer);
