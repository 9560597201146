import styled,{css} from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js';
const S = {};
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;


S.BackButton = styled.div``;
S.HeaderTitle = styled.div``;

S.CashoutHeader = styled.div`
    display: flex;
    background: #fff;
    color: #061957;
    padding: 0;
    height: 2.5625rem;
    line-height: 2.5625rem;
    font-size: 0.875rem;
    font-family: "Oswald", sans-serif;
    font-weight: 500;

    & ${S.BackButton} {
        width: 2.625rem;
        text-align: center;
        background: #F5F7FC url(${imagePath}images/svg/arrow-right-navy-blue.svg) no-repeat;
        background-size: 0.5rem 0.875rem;
        background-position: center;
        transform: rotate(180deg);
    }

    & ${S.HeaderTitle} {
        font-size: 1rem;
        padding-left: .8rem;
    }
`;

S.CashoutForm = styled(Form)`
    padding: 1rem 0.5rem;
    position: relative;

    & .full-cashout {
        margin-top: 2rem;
    }
`;

S.CashoutFormInner = styled.div`
    background: #F5F7FB;
    border-radius: 5px;
    border: 1px solid #BCC1CE;
`;

S.CashoutIcon = styled.i`
    width: 35px;
    height: 35px;
    background: url(${imagePath}images/svg/icon_cashout.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1.5rem;
`;

S.Row = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

S.InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
    color: ${({theme:{$accountProfileBetHistoryCashoutHeader}})=> $accountProfileBetHistoryCashoutHeader};

    & .close {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        background: url(${imagePath}images/svg/ic_modal_close.svg) no-repeat;
        background-size: contain;
    }
`;

S.Label = styled.label`
    color: #112469!important;
    font-size: 1rem;
    font-weight: 600;
    margin: 0.3rem;
`;

S.InputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: -5px;
    font-family: "Oswald", sans-serif;
    font-size: 2rem;
    color: #098E09;
    font-weight: bold;
`;

S.Currency = styled.div`

`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #000;
    font-size: 0.94rem;
    display: block;
    width: 100%;
    text-align: center;
    &:disabled {
        border: none;
    }

    &.maximum-amount {
        font-family: "Oswald", sans-serif;
        font-size: 2rem;
        color: #098E09;
        font-weight: bold;
        background: transparent;
    }
`;

S.AcceptCheckbox = styled.div`
    width: 75%;
    margin: 2rem auto;
    label {
        margin-left: 10px;
        color: #112469;
        font-weight: 600;
        text-align: center;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: 25px;
    background-color: #E4202C;
    height: 2.13rem;
    line-height: 1;
    font-size: 0.88rem;
    font-weight: 600;
    padding: 0.42rem;
    width: 67%;
    color: #fff;
    min-width: 20%;
    cursor: pointer;
    
    ${({disabled})=> {
        return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
    }}
`;

S.Error = styled.div`
    left: 0;
    color: red;
    margin: 2px 0 0;
    position: absolute;
    font-size: 0.81rem;
`;

S.SubmissionError = styled.div`
    margin-bottom: 1rem;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 0.8rem;
    text-align: center;
`;

S.Stake = styled.div`
    font-weight: 600;
    color: #000;
    font-size: 0.94rem;
    text-align: right;
`;

S.PossibleReturn = styled(S.Stake)``;

S.Button = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4202C;
    border-radius: 20px;
    border: 0;
    color: #fff;
    font-size: 0.88rem;
    cursor: pointer;
    padding: 0.42rem;
    width: 44%;
`;

S.ConclusionMsg = styled.div`
    width: 100%;
    text-align: center;
    border-radius: 0;
    position: relative;
    border: none;
    font-weight: 600;
    color: #001A5B;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: 'Mulish', sans-serif;
`;

S.ConclusionTaxInfo = styled(S.ConclusionMsg)``;

S.Overlay = styled.div`
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
`;

S.TaxInfo = styled.div`
  color: #098E09;
   font-size: 16px;
      font-family: 'Mulish', sans-serif;
          font-weight: 600;
          width: 350px;
          text-align: center;
`;

export default S;