import React from 'react';
import { RotateSpinLoader  } from 'react-css-loaders';
import S from 'StyledLoader.js';

const Loader = (props) => {
    const {size=10, color='#123abc'} = props;
    return (
        <S.LoaderWrapper className="loader">
            <RotateSpinLoader size={size} color={color}/>
        </S.LoaderWrapper>
    );
};

export default React.memo(Loader);