import React, {useEffect, useReducer, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledResponsibleLimitsTable.js';
import {translation} from 'helpers/utilsHelper.js';
import {formatDateFromUnix, getPolishTimePlurality} from 'datesHelper';
import {loadCustomerLimits} from 'customerActions.js';
import { getKeyByLimitedId } from 'limitTypes.enum.js';
import Loader from 'Loader.js';
import arrowIconDown from 'arrow_down.svg';

const initialState = {["DAILY_STAKE_LIMIT"]: false, 
                      ["MONTHLY_STAKE_LIMIT"]: false, 
                      ["DAILY_SESSION_TIME_LIMIT"]: false, 
                      ["MONTHLY_SESSION_TIME_LIMIT"]: false};

const reducer = (state, type) => {
    switch(type) {
        case 'DAILY_STAKE_LIMIT':
            return { ...state, ["DAILY_STAKE_LIMIT"]: !state["DAILY_STAKE_LIMIT"]}
        case 'MONTHLY_STAKE_LIMIT':
            return { ...state, ["MONTHLY_STAKE_LIMIT"]: !state["MONTHLY_STAKE_LIMIT"]}
        case 'DAILY_SESSION_TIME_LIMIT':
            return { ...state, ["DAILY_SESSION_TIME_LIMIT"]: !state["DAILY_SESSION_TIME_LIMIT"]}
        case 'MONTHLY_SESSION_TIME_LIMIT':
            return { ...state, ["MONTHLY_SESSION_TIME_LIMIT"]: !state["MONTHLY_SESSION_TIME_LIMIT"]}

        default:
            return state  
    }
};


const ResponsibleLimitsTable = (props) => {
    
    const {customerLimits, loadCustomerLimits} = props;

    useEffect(() => {
        loadCustomerLimits();
    }, []);

    const[tabs, dispatch] = useReducer(reducer, initialState);

    let customerLimitsFiltered;

    if (customerLimits) {
        customerLimitsFiltered = customerLimits.filter(item => item.limitType == 1 ||item.limitType == 3 || item.limitType == 13 || item.limitType == 15);
    }

    const toggleLimitsTabs = (e, limit) => {
        dispatch(`${getKeyByLimitedId(limit)}`);
    };

    const currency = translation('account_responsibleGame_currency');
    const hoursName = (number) =>  translation(`account_responsibleGame_hours_${getPolishTimePlurality(number)}`);
    const minutesName = (number) =>  translation(`account_responsibleGame_minutes_${getPolishTimePlurality(number)}`);

    const renderCell = (usage, type) => usage == undefined ? translation('account_responsibleGame_emptyLimitData') 
        : type == 1 || type == 3 ? `${usage} ${currency}` 
        : usage < 60 ? `${formatDateFromUnix(usage * 60, 'm')} ${minutesName(usage)}` 
        : `${Math.floor(usage/60)} ${hoursName(Math.floor(usage/60))}`;
    
    return (
        !customerLimits ?

        <Loader/>

        :
        
        <S.LimitsTable>
            {customerLimitsFiltered.map(({ limitType, limitAmount, limitUsage, requestedLimitAmount, requestedLimitValidFrom }, index) => {

                return (
                    <S.LimitTypeWrapper key={limitType} onClick={(e) => toggleLimitsTabs(e, limitType)}>
                        <S.MainHeader>
                            {translation(`account_responsibleGame_${limitType}`)}
                            {<S.ArrowSwitchIcon className={tabs[getKeyByLimitedId(limitType)]? "open": "close"} dangerouslySetInnerHTML={{__html: arrowIconDown}}/>}
                        </S.MainHeader>
                        {tabs[getKeyByLimitedId(limitType)] &&
                            <>
                                <S.SingleLimitInfoText>{translation(`account_responsibleGame_limitTxt${getKeyByLimitedId(limitType)}`)}</S.SingleLimitInfoText>
                                <S.FlexRow key={index}>
{/*Na życzenie klienta              <S.Cell>
                                        <S.Header>{translation('account_responsibleGame_headerUsed')}</S.Header>
                                        {limitUsage == undefined ? null : renderLimitUsage(limitUsage, limitType)}
                                    </S.Cell> */}
                                    <S.Cell>
                                        <S.CellLabels>
                                            <S.Header>{translation('account_responsibleGame_headerUsageLeft')}</S.Header>
                                        </S.CellLabels>
                                        <S.CellDoubleDataBold>
                                            <div>{renderCell(limitAmount-limitUsage, limitType)}</div>
                                        </S.CellDoubleDataBold>
                                    </S.Cell>
                                    <S.Cell>
                                        <S.CellLabels>
                                            <S.Header>{translation('account_responsibleGame_headerCurrent')}</S.Header>
                                        </S.CellLabels>
                                        <S.CellDoubleData>
                                            <div>{renderCell(limitAmount, limitType)}</div>
                                        </S.CellDoubleData>
                                    </S.Cell>
                                    <S.Cell>
                                        <S.Header>{translation('account_responsibleGame_headerPlanned')}</S.Header>
                                        <div>{renderCell(requestedLimitAmount, limitType)}</div>
                                    </S.Cell>
                                    <S.Cell>
                                        <S.Header>{translation('account_responsibleGame_headerValidFrom')}</S.Header>
                                        {requestedLimitValidFrom == undefined ? null : formatDateFromUnix(requestedLimitValidFrom/1000, 'dd.MM.yyyy HH:mm')}</S.Cell>
                                </S.FlexRow>
                            </>
                        }
                    </S.LimitTypeWrapper>    
                );
            })}
        </S.LimitsTable>

    );
};

const mapStateToProps = (state, props) => {
    const {Customer:{customerLimits}} = state;

    return {
        customerLimits,
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionToBind = {loadCustomerLimits};
    return {
        ...bindActionCreators(actionToBind, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleLimitsTable);
