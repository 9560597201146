import React, {Component} from 'react';
import S from 'StyledCmsPage.js';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _each from 'lodash/each';
import {cmsPage} from 'cmsActions.js';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Loader from 'Loader.js';
// import MetaTags from 'MetaTags.js';

class CmsPage extends Component {

  constructor(props){
    super(props);
    this.cmsContainerRef = React.createRef();
  }

  componentDidMount = async()=> {
    const {cmsPage, name, isPlaces, alternativeContent} = this.props;
    try {
         await cmsPage(name, null, isPlaces, alternativeContent);
         this.processScripts(); 
    } catch (error){
      console.log('cmsPage: ', error);
    }
  };

  processScripts = () => {
    const cmsContainerSelector = this.cmsContainerRef.current;
    if (!_size(cmsContainerSelector)) return;

    const scripts = [...cmsContainerSelector.querySelectorAll('script')];

    if(_size(scripts)){
        _each(scripts, (dom)=>{
              const scriptContent = dom.innerText;
              const fn = new Function(scriptContent);
              fn.call();
        });
    }
  };

  render() {
    const {name, callCmsPage, isCmsPageExist, isPending, error, isPlaces = false} = this.props;
    const cmsPage = _.find(callCmsPage, {name});
    let html = '';
    let metaParams = null;

    if(_size(cmsPage)){
       html = cmsPage.data.html;
       let {title, metaDescriptions, tags} = cmsPage.data;
       metaParams = {title, metaDescriptions, tags, isPlaces};
    }
    
    return (
      <S.CmsPage className="cms-page">
     
      {((isPending && !isCmsPageExist) || !_size(cmsPage)) ?
          <Loader/>
          :
          (
              <>
              {/* <MetaTags {...metaParams}/> */}
              <S.CmsWrapper ref={this.cmsContainerRef} dangerouslySetInnerHTML={{__html: html}}></S.CmsWrapper>
              </>
          )

      }
    </S.CmsPage>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {Cms:{callCmsPage, isPending, error}} = state;
  const isCmsPageExist = _find(callCmsPage, {name: props.name}) ? true : false;
  return {
    callCmsPage,
    isPending,
    error,
    isCmsPageExist
  }
};

const mapDispatchToProps = (dispatch) => {

  const bindedActions = {
    cmsPage
  };

  return {
      ...bindActionCreators(bindedActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsPage);