import React,{useState} from 'react';
import S from 'StyledPayoutCancel.js';
import { translation } from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import ModalOpacity from 'ModalOpacity.js'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cancellPayout,fetchTransactionsByFilter } from 'accountWalletActions.js';

const PayoutCancel = (props) => {
    const {id,amount,date} = props
    const [showStatusModal,setStatusModal] = useState(false)
    const [statusOfPaymentCancell,setStatusOfPaymentCancell] = useState(null)
    const cancellPayoutAction = async ( transactionId ) => {
        const {fetchTransactionsByFilter, cancellPayout} = props;
        setStatusModal(false)
        try {
            const params ={forceCache:true}
            const config = {
                transactionId,
            };
            const payment =await cancellPayout(config);
            if(payment !=undefined){
                setStatusModal(true)
                setStatusOfPaymentCancell(true)
                fetchTransactionsByFilter(params)
            }else{
                throw "Something went wrong"
            }


        } catch (error) {
            console.log('cancellPayoutAction:' + error);
            setStatusModal(true)
            setStatusOfPaymentCancell(false)
        }

    };
    const redirectToTransferHistory = ()=>{
        app.router.redirect('/transfer-history')
        setStatusModal(false)
    }



  return (
    <>
    <S.PayoutCancelContainer>
        <div>{translation('account_wallet_payoutCancel_info')}</div>
        <div className="transaction-amount">{amount} PLN</div>
        <div className="transaction-text">{translation('account_wallet_payoutCancel_date')} {date&&formatDate(date, 'dd-MM-yyyy')} {`${date&&formatDate(date, 'HH:mm')}`}</div>
        <button onClick={()=>cancellPayoutAction(id)}>POTWIERDŹ</button>
    </S.PayoutCancelContainer> 
    <ModalOpacity 
                isOpen={showStatusModal}
                toggleOpen={()=>setshowStatusModal(!showStatusModal)} 
                showHeaderIcon={false}
                showHeader={false}
                showCloseIcon={false}
                padding={0} 
                width="355px"
                minHeightBody="311px">
                    {statusOfPaymentCancell ? (
                    <S.PayoutCancelContainer>
                        <div className="status-icon green"></div>
                        <div className="status-info">{translation('payment_cancel_success')}</div>
                        <div className="status-text">{translation('payment_cancel_success_text')}</div>
                        <button className="green" onClick={()=>redirectToTransferHistory()}>PRZEJDŹ DO KONTA</button>
                    </S.PayoutCancelContainer>
                    ):(
                        <S.PayoutCancelContainer>
                            <div className="status-icon red"></div>
                            <div className="status-info">{translation('payment_cancel_failure')}</div>
                            <div className="status-text">{translation('payment_cancel_failure_text')}</div>
                            <button className="blue" onClick={()=>cancellPayoutAction(id)}>SPRÓBUJ PONOWNIE</button>
                        </S.PayoutCancelContainer>
                    )}



                </ModalOpacity>
 
    </>
  )
};
const mapStateToProps = ({ AccountWallet: { isPending, error, startDate, endDate, activeTab, data, currentPage, limit } }, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        data,
        currentPage,
        limit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancellPayout: bindActionCreators(cancellPayout, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutCancel);