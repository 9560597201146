import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getVirutalGame} from 'integrationActions.js';
import Loader from 'Loader.js';
import {NotLogged} from 'global/styles/styles.js';
import {translation} from 'helpers/utilsHelper.js';
import S from 'StyledIntegrationGameIframe.js';


class IntegrationGameIframe extends Component {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    componentDidMount = async() => {
        this.getUrlGame();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isLogged !== this.props.isLogged) {
            this.getUrlGame();
        }
    }

    getUrlGame = async() => {
        const {dispatch, provider, gameId} = this.props;
        try {
            await dispatch(getVirutalGame(provider, gameId));
        } catch (error) {
            console.log(`getVirutalGame: ${error}`);
        }
    }

    getUrlGame = async() => {
        const {dispatch, provider, gameId} = this.props;
        try {
            switch(provider) {
                case 'elbet': 
                    this.elbetAddEventListenerResize();
                    await dispatch(getVirutalGame(provider, gameId));  
                    break;
                default:
                    console.log(`getVirutalGame: Integration for ${provider} not found!`);
            }

        } catch (error) {
            console.log(`getVirutalGame: ${error}`);
        }
    }

    elbetAddEventListenerResize = () => {
        window.addEventListener("message", (event) => {
            if (event.data.eventName == 'game_to_iframe' && this.iframeRef && this.iframeRef.current) {
                this.iframeRef.current.height = `${event.data.response}px`;
            }
        }, false);
    }

    login = async() => {
        $(".user-nl").click();
    }

    render() {

        const {isPending, error, gameUrl, isLogged} = this.props;

        if (error) {
            return <div>Error fallback component!</div>
        }
        
        if (isPending) {
            return <Loader/>
        }

        if (!isLogged && !isPending) {
            this.login();
            return <S.NotLogged >{translation('common_notLoggedIn')}</S.NotLogged >
        }

        return (
            <S.IntegrationGameIframe>
                <iframe 
                    ref={this.iframeRef}
                    src={gameUrl} 
                    frameBorder="0"
                    scrolling="no"
                ></iframe>
            </S.IntegrationGameIframe>

        )    
    }
}

const mapStateToProps = (state, props) => {

    let {IntegrationGameIframe:{isPending, error, gameUrl, iFrameHeight}, Auth:{isLogged}} = state;

    return {
        isPending,
        error,
        gameUrl,
        isLogged,
        iFrameHeight
    }
};

export default connect(mapStateToProps)(IntegrationGameIframe);
