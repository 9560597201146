import {translation} from 'utilsHelper.js';
import _filter from 'lodash/filter';
import _reject from 'lodash/reject';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import betSlipTypes from 'betSlipTypes.enum.js';

let types = [];
const factorial = [0, 1, 2, 6, 24, 120, 720, 5040, 40320, 362880, 3628800, 39916800, 479001600, 6227020800, 87178291200, 1307674368000, 20922789888000, 355687428096000, 6402373705728000, 121645100408832000, 2432902008176640000, 51090942171709440000];

const __addToTypes = (key, extendedName = key) => {

    if (_findIndex(types, {key}) == -1) {
        let betTypeByKey = _get(betSlipTypes, [key]);
        betTypeByKey = {...betTypeByKey, key, extendedName};
        types = [...types, betTypeByKey];
    }
};

const getBetslipTypes = (outcomes, blocks, currentTab, addAccumulator) => {
    types = [];

    const bankers = _filter(outcomes, {banker: true});
    const outcomesNotInBlock = _reject(outcomes, {inBlock: true});
    const betsCount = _size(outcomesNotInBlock) + _size(blocks) - _size(bankers);
    const combinationName = translation('betSlip_betType_combination_shortcut');

    const slipTypesPL = process.env.POLISH_BETSLIP_TYPES && JSON.parse(process.env.POLISH_BETSLIP_TYPES);
    if (slipTypesPL) {
        getPolishBetSlipTypes(betsCount, combinationName, blocks, bankers, outcomes, currentTab, addAccumulator);
    } else {
        getDefaultBetSlipTypes(betsCount, combinationName, blocks, bankers, outcomes, currentTab);
    }

    return types;
};

const getPolishBetSlipTypes = (betsCount, combinationName, blocks, bankers, outcomes, currentTab, addAccumulator) => {

    if (currentTab != 'ACCUMULATOR') {
        if (betsCount < 2 && _size(bankers) > 0) {

            if (_size(outcomes) === 1 && _size(blocks) === 0) {
                __addToTypes('SINGLE');
            } else {
                __addToTypes('ACCUMULATOR');
            }

        } else if (_size(outcomes) === 0 && _size(blocks) === 1) {
            __addToTypes('ACCUMULATOR');
        } else {

            const defaultSystemBetsCount = process.env.DEFAULT_SYSTEM_BET_COUNT && JSON.parse(process.env.DEFAULT_SYSTEM_BET_COUNT);

            if (betsCount == 1) {

                __addToTypes('SINGLE');

            } else if (betsCount > 0 && betsCount < defaultSystemBetsCount) {

                let betSlipTypeMessage = translation('betSlip_type_combination');
                for (let i = 1; i < betsCount; i++) {
                    const combinationsCount = calculateSlipCombinations(betsCount, i, i, addAccumulator);
                    let typeMsg = `${betSlipTypeMessage.replace('{0}', i).replace('{1}', betsCount)}  ( ${combinationsCount} ${combinationName} )`;
                    const bankersCount = _size(bankers);
                    if (bankersCount) {
                        typeMsg += ` + ${bankersCount} ${translation('betSlip_banker_symbol')}`;
                    }
                    __addToTypes(_get(betSlipTypes.getByType(i), ['name']).replace('betSlip_type_', ''), typeMsg);
                }
            } else {

                let typeMsg = `${translation('betSlip_type_combination').replace('{0}', 1).replace('{1}', betsCount)}  ( ${betsCount} ${combinationName} )`;
                const bankersCount = _size(bankers);
                if (bankersCount) {
                    typeMsg += ` + ${bankersCount} ${translation('betSlip_banker_symbol')}`;
                }
                __addToTypes('SINGLES', typeMsg);
            }
        }
    } else {
        __addToTypes('ACCUMULATOR');
    }
};

const getDefaultBetSlipTypes = (betsCount, combinationName, blocks, bankers, outcomes, currentTab) => {

    if (currentTab !== 'ACCUMULATOR') {

        if (betsCount < 2 && _size(bankers) > 0) {

            if (_size(outcomes) === 1 && _size(blocks) === 0) {
                __addToTypes('SINGLE');
            } else {
                __addToTypes('ACCUMULATOR');
            }

        } else if (_size(outcomes) === 0 && _size(blocks) === 1) {
            __addToTypes('ACCUMULATOR');
        } else {

            if (betsCount > 0) {

                __addToTypes('SINGLES', translation('betSlip_type_SINGLES') + ' ( ' + betsCount + ' ' + combinationName + ')');

                switch (betsCount) {
                    case 3:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (3 ' + combinationName + ')');
                        __addToTypes('TRIXIE', translation('betSlip_type_TRIXIE') + ' (4 ' + combinationName + ')');
                        __addToTypes('PATENT', translation('betSlip_type_PATENT') + ' (7 ' + combinationName + ')');
                        break;

                    case 4:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (6 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (4 ' + combinationName + ')');
                        __addToTypes('YANKEE', translation('betSlip_type_YANKEE') + ' (11 ' + combinationName + ')');
                        __addToTypes('LUCKY-15', translation('betSlip_type_LUCKY-15') + ' (15 ' + combinationName + ')');
                        break;

                    case 5:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (10 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (10 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (5 ' + combinationName + ')');
                        __addToTypes('CANADIAN', translation('betSlip_type_CANADIAN') + ' (26 ' + combinationName + ')');
                        __addToTypes('LUCKY-31', translation('betSlip_type_LUCKY-31') + ' (31 ' + combinationName + ')');
                        break;

                    case 6:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (15 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (20 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (15 ' + combinationName + ')');
                        __addToTypes('5-FOLD', translation('betSlip_type_5-FOLD') + ' (31 ' + combinationName + ')');
                        __addToTypes('HEINZ', translation('betSlip_type_HEINZ') + ' (57 ' + combinationName + ')');
                        __addToTypes('LUCKY-63', translation('betSlip_type_LUCKY-63') + ' (63 ' + combinationName + ')');
                        break;

                    case 7:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (21 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (35 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (35 ' + combinationName + ')');
                        __addToTypes('5-FOLD', translation('betSlip_type_5-FOLD') + ' (21 ' + combinationName + ')');
                        __addToTypes('6-FOLD', translation('betSlip_type_6-FOLD') + ' (7 ' + combinationName + ')');
                        __addToTypes('SUPER-HEINZ', translation('betSlip_type_SUPER-HEINZ') + ' (120 ' + combinationName + ')');
                        break;

                    case 8:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (28 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (56 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (70 ' + combinationName + ')');
                        __addToTypes('5-FOLD', translation('betSlip_type_5-FOLD') + ' (56 ' + combinationName + ')');
                        __addToTypes('6-FOLD', translation('betSlip_type_6-FOLD') + ' (28 ' + combinationName + ')');
                        __addToTypes('7-FOLD', translation('betSlip_type_7-FOLD') + ' (8 ' + combinationName + ')');
                        __addToTypes('GOLIATH', translation('betSlip_type_GOLIATH') + ' (247 ' + combinationName + ')');
                        break;

                    case 9:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (36 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (84 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (126 ' + combinationName + ')');
                        __addToTypes('5-FOLD', translation('betSlip_type_5-FOLD') + ' (126 ' + combinationName + ')');
                        __addToTypes('6-FOLD', translation('betSlip_type_6-FOLD') + ' (84 ' + combinationName + ')');
                        __addToTypes('7-FOLD', translation('betSlip_type_7-FOLD') + ' (36 ' + combinationName + ')');
                        __addToTypes('8-FOLD', translation('betSlip_type_8-FOLD') + ' (9 ' + combinationName + ')');
                        break;

                    case 10:
                        __addToTypes('DOUBLE', translation('betSlip_type_DOUBLES') + ' (45 ' + combinationName + ')');
                        __addToTypes('TRIPLE', translation('betSlip_type_TRIPLES') + ' (120 ' + combinationName + ')');
                        __addToTypes('4-FOLD', translation('betSlip_type_4-FOLD') + ' (210 ' + combinationName + ')');
                        __addToTypes('5-FOLD', translation('betSlip_type_5-FOLD') + ' (252 ' + combinationName + ')');
                        __addToTypes('6-FOLD', translation('betSlip_type_6-FOLD') + ' (210 ' + combinationName + ')');
                        __addToTypes('7-FOLD', translation('betSlip_type_7-FOLD') + ' (120 ' + combinationName + ')');
                        __addToTypes('8-FOLD', translation('betSlip_type_8-FOLD') + ' (45 ' + combinationName + ')');
                        __addToTypes('9-FOLD', translation('betSlip_type_9-FOLD') + ' (10 ' + combinationName + ')');
                        break;

                    default:
                        break;
                }
            }
        }
    } else {
        __addToTypes('ACCUMULATOR');
    }

};

const calculateSlipCombinations = (betCount, subsetSize, betType, addAccumulator) => {

    let combinations;
    if (betType > 1 && betType < 21) {
        combinations = factorial[betCount] / (factorial[subsetSize] * factorial[betCount - subsetSize]);
        if (addAccumulator) {
            combinations++;
        }
    } else if (betType === 1) {
        combinations = betCount;
    } else if (betType === 101) {
        combinations = 4;
    } else if (betType === 102) {
        combinations = 7;
    } else if (betType === 104) {
        combinations = 11;
    } else if (betType === 105) {
        combinations = 15;
    } else if (betType === 106) {
        combinations = 31;
    } else if (betType === 107) {
        combinations = 57;
    } else if (betType === 108) {
        combinations = 120;
    } else if (betType === 109) {
        combinations = 247;
    } else if (betType === 110) {
        combinations = 26;
    } else if (betType === 111) {
        combinations = 63;
    } else {
        combinations = 1;
    }
    return combinations;
};

const getSystemBetMultiplier = (oddsCount, type) => {

    switch (type) {
        case 0:
            return oddsCount; // Accumulator
        case 1:
            return oddsCount; // Singles
        case 2:
            return binomialTheorem(oddsCount, 2); // Doubles
        case 3:
            return binomialTheorem(oddsCount, 3); // Triples
        case 4:
            return binomialTheorem(oddsCount, 4); // 4-fold
        case 5:
            return binomialTheorem(oddsCount, 5); // 5-fold
        case 6:
            return binomialTheorem(oddsCount, 6); // 6-fold
        case 7:
            return binomialTheorem(oddsCount, 7); // 7-fold
        case 8:
            return binomialTheorem(oddsCount, 8); // 8-fold
        case 9:
            return binomialTheorem(oddsCount, 9); // 9-fold
        case 10:
            return binomialTheorem(oddsCount, 10); // 10-fold
        case 11:
            return binomialTheorem(oddsCount, 11); // 11-fold
        case 12:
            return binomialTheorem(oddsCount, 12); // 12-fold
        case 13:
            return binomialTheorem(oddsCount, 13); // 13-fold
        case 14:
            return binomialTheorem(oddsCount, 14); // 14-fold
        case 15:
            return binomialTheorem(oddsCount, 15); // 15-fold
        case 16:
            return binomialTheorem(oddsCount, 16); // 16-fold
        case 17:
            return binomialTheorem(oddsCount, 17); // 17-fold
        case 18:
            return binomialTheorem(oddsCount, 18); // 18-fold
        case 19:
            return binomialTheorem(oddsCount, 19); // 19-fold
        case 20:
            return binomialTheorem(oddsCount, 20); // 20-fold
        case 100:
            return 1; // Single
        case 101:
            return 4; // Trixie
        case 102:
            return 7; // Patent
        case 103:
            return 10; // Round-Robin
        case 104:
            return 11; // Yankee
        case 105:
            return 15; // Lucky_15
        case 110:
            return 26; // Canadian
        case 106:
            return 31; // Lucky_31
        case 107:
            return 57; // Heinz
        case 108:
            return 120; // Super-Heinz
        case 111:
            return 63; // Lucky_63
        case 109:
            return 247; // Goliath
        default:
            return 1;
    }
};

// kombinacja bez powtorzen
const binomialTheorem = (n, k) => {
    return theorial(n) / (theorial(n - k) * theorial(k));
};

// silnia
const theorial = (n) => {
    var result = 1;
    for (var i = 2; i <= n; i++) {
        result *= i;
    }
    return result;
};


export {getBetslipTypes, getSystemBetMultiplier}