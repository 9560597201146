import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from 'Loader.js';
import S from 'StyledIntegrationGameContent.js';


class IntegrationGameContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVirtualGame: null
        };
    }

    activeVirtualGames = () => {
        this.setState({selectedVirtualGame: true});
    }

    render() {

        const {isPending, error, name, image, gameId, provider} = this.props;

        if (error) {
            return <div>Error fallback component!</div>
        }
        
        if (isPending) {
            return <Loader/>
        }

        return (
            <div>
                <S.StyledIntegrationGameContent>
                    <img src={image}></img>
                </S.StyledIntegrationGameContent>
            </div>
        )    
    }
}

const mapStateToProps = (state, props) => {

    let {IntegrationGamesList:{isPending, error}} = state;

    return {
        isPending,
        error
    }
};

export default connect(mapStateToProps)(IntegrationGameContent);
