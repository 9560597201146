import styled from 'styled-components';

const S = {};

S.IntegrationGameIframe = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    & > iframe {
        width: 100%;
        min-height: 30rem;
    }
`;

S.NotLogged = styled.div`
    font-size: 1.5rem;
    color: #000;
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;
`;


export default S;
