import styled from 'styled-components';
import {Button, Card} from 'global/styles/styles.js';

const Header = ({ theme: { header } }) => header()
const CardTab = ({ theme: { cardTab } }) => cardTab()
const CardLabel = ({ theme: { cardLabel } }) => cardLabel()
const Divider = ({ theme: { divider } }) => divider()
const CardWithTab = ({ theme: { cardWithTab } }) => cardWithTab()
const Link = ({ theme: { link } }) => link();
const PrimaryBtn = ({ theme: { primaryBtn } }) => primaryBtn();

const CalendarContainer = ({ theme: { calendarContainer } }) => calendarContainer()
const DateLabel = ({ theme: { dateLabel } }) => dateLabel()
const DatePicker = ({ theme: { datePicker } }) => datePicker()

const FontFamilyOswald = ({theme: {$textOswaldRegular}}) => `${$textOswaldRegular}`;

const S = {};

S.AccountFreebet = styled.div`width: 100%;`;

S.FreebetList = styled.div`margin: 0 1rem;`;

S.FreebetActiveList= styled.div`margin-top: 1.5rem;`;

S.FreebetHistoryList= styled(S.FreebetActiveList)``;

S.NoResult = styled.div`
    display: flex;
    height: 2.5rem;
    font-size: 0.75rem;
    justify-content: center;
    align-items: center;
    color: ${({theme:{$noResultColor}}) => $noResultColor};
`;

S.FreebetRow = styled.div`
    margin-bottom: 0.625rem;
`;

S.FreebetBox = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 0.45rem 0.75rem 0.45rem;
    &.imageContainer {
        padding: 0.45rem 0rem 0.45rem;
    }
`;

S.BonusOffrImg = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
`;

S.FreebetTitle = styled.div`font-weight: 700;`;

S.FreebetHeader = styled.h4` ${Header} `;

S.FreebetBody = styled.div``;

S.FreebetImageWrapper = styled.div` ${CardTab}
    position: relative;
    padding-top: ${p => `calc(100% / ${p.ratio})`};
    min-height: unset;
    overflow: hidden;
`;

S.FreebetTitle = styled.div`${CardLabel}`;

S.FreebetValue = styled.div`${CardLabel}
font-weight: 700;
`;

S.FreebetContent = styled(Card)` ${CardWithTab}
    justify-content: center;
`;

S.FreebetFilters = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.625rem 1rem 0.5625rem 1rem;
`;

S.Divider = styled.div`${Divider}`

S.Link = styled.a`${Link}
    margin: 1rem 0 0.1rem
`;

S.FilterBtn = styled(Button)`${PrimaryBtn} 
padding: 0.85rem 0.5rem; margin: 0 0.2rem;
background-color: ${({ isActive }) => (isActive && '#2D375E80' )};
color: ${({ isActive }) => (isActive && '#fff' )};
`;

S.FreebetFiltersBtns = styled.div`
    display: flex;
    flex-grow: 0;
`;

S.FreebetFiltersDates = styled.div`${CalendarContainer}`

S.DateIconWrapper = styled.div`
  display: flex;
  font-family: ${FontFamilyOswald};
  justify-content: center;
  align-items: center;
  background-color: ${({theme:{$dateIconWrapperBgColor}}) => $dateIconWrapperBgColor};
  width: 2.125rem;
  border-radius: 4px 0 0 4px;
  flex-shrink: 0;
`;

S.DateLabel = styled.div`${DateLabel}`

S.DateIcon = styled.i`
    position: absolute;
    height: 1rem;
    width: 1rem;
    top: 6px;
    margin: auto 0;
    pointer-events: none;
    & svg{
      width: 100%;

      & path {
        fill: #fff;
      }
    }
`;

S.FreebetFooter = styled.div`
  text-align: center;
  padding-top: 0.9375rem;
`;

S.FreebetRegulationLink = styled.a`
  font-size: 0.75rem;
  color: ${({theme:{$accountProfileFreebetRegulationLinkColor}})=> $accountProfileFreebetRegulationLinkColor};
  text-decoration: underline;
`;

S.Datepicker = styled.div`${DatePicker}
    width: 48.5%;
    flex-wrap: wrap;
`;

export default S;