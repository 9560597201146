import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import S from 'StyledModal.js';
import classNames from 'classnames';
import {modalUserIconSVG} from 'global/svgIcons/svgIcons.js';

const Modal = ({showHeader, showHeaderIcon, showCloseIcon, showHeaderTitle, onClose, title = '', children, className, padding, width}) => {

    useEffect(()=>{
        window.addEventListener('scroll', onScrollHandler);
        return ()=> window.removeEventListener('scroll', onScrollHandler);
    }, []);

    const elementMouseDownRef = useRef(null);

    const onScrollHandler = (e) => {
        window.scrollTo(0, 0);
    };

    const onMouseUpHandler = (e) => {
        if (elementMouseDownRef.current == e.target && e.target.matches('.modal-background')) {
            if (onClose && typeof onClose == 'function') {
                onClose();
                e.stopPropagation();
            }
        } else {
            e.stopPropagation();
        }
    };

    const onMouseDownHandler = (e)=>{
        elementMouseDownRef.current = e.target;
    };

    const closeIcon = (showCloseIcon && <S.CloseIconContainer onClick={onClose}></S.CloseIconContainer>);

    const headerIcon = (showHeaderIcon && <S.UserIconContainer dangerouslySetInnerHTML={modalUserIconSVG()}></S.UserIconContainer>);

    const headerTitle = (showHeaderTitle && <S.ModalTitle>{title}</S.ModalTitle>);

    const modalMarkup = (
        <S.ModalBackground className={classNames(className, 'modal-background')}
                           onMouseDown={onMouseDownHandler}
                           onMouseUp={onMouseUpHandler}>

            <S.ModalWrapper className="modal-wrapper" padding={padding} width={width}>
                {showHeader &&
                (
                    <S.ModalHeader>
                        {closeIcon}
                        {headerIcon}
                        {headerTitle}
                    </S.ModalHeader>
                )}
                <S.ModalBody>
                    {children}
                </S.ModalBody>
            </S.ModalWrapper>

        </S.ModalBackground>
    );

    return ReactDOM.createPortal(modalMarkup, document.body);
};

export default Modal;