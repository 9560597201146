import React, {Component} from 'react';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect} from 'react-router-dom';
import S from 'StyledAccountMain.js';
import AccountRoute from 'AccountRoute.js';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadVeryficationStatus} from 'accountActions.js';
import {translation, formatMoney, redirect} from 'utilsHelper.js';

class AccountMain extends Component {

    componentDidMount = () => {
        const { accountTab } = this.props;

        if (!this.props.user) {
            redirect('/');
            return; 
        } else if (accountTab){
            this.props.history.push('/account/'+accountTab);
        }
    }

    render() {
        const {user: accountData, logOutUser} = this.props;
        return (
            <S.AccountMainWrapper>
                <AccountRoute accountData={accountData}/>
            </S.AccountMainWrapper>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Account:{ accountData}, Auth: {user} } = state;
    return {
        accountData,
        user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountMain));
