import styled from 'styled-components';

const S = {};

S.StyledIntegrationGameContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.5rem solid #fff;
    border-radius: 0.5rem;
    transition-duration: 1s;

    & > img {
        width: 75%;
    }

    & > span {
        font-size: 1rem;
        color: #ad1f21;
        font-weight: 600;
        margin: 1rem;
        white-space: nowrap;
    }
`;



export default S;
