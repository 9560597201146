import React from 'react';
import {Field} from 'redux-form';
import {createMarkup} from 'cmsActions.js';
import S from 'StyledProviderContent.js';
import {translation} from 'helpers/utilsHelper.js';

const MOBILE_URL = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;

const Item = ({item}) => {
  return (
      <S.Item>
          {item}
      </S.Item>
  )
}


const GroupComponents = ({items}) => {
  return (
      <S.GroupComponents>
          {items}
      </S.GroupComponents>
  );
}

const InputComponent = ({name, onChange, required, onKeyDown, onClick, value, placeholder, type, label, limits, className, isDeposit, balance}) => {

  placeholder ? placeholder = translation(placeholder) : '';
  label ? label =  translation(label) : '';
  let max = undefined;
  let min = undefined;

  if (limits) {
      const {maxTotalAmountOnAttempts, minAmount} = limits;
      'maxTotalAmountOnAttempts' in  limits ?  max = maxTotalAmountOnAttempts : undefined;
      'minAmount' in  limits ?  min = minAmount : min = 1;
  } else if (!isDeposit && name == 'transactionAmount') {
      min = 0.01;
      max = balance;
  } else if (name == 'transactionAmount') {
      min = 0.01;
      max = 1000000;
  }

  if (value) {
    disabled = true;
  }

  const minValue = min => value =>
      value && value < min && [translation('payment_valueTooSmall') + ' ' + min] || undefined;

  const maxValue = max => value =>
      value && value > max && [translation('payment_valueTooBig') + ' ' + max]  || undefined;

  return (
      <S.InputComponent>

          <Field
              name={name}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onClick={onClick}
              type={type}
              value={value}
              placeholder={placeholder}
              label={label}
              required={required}
              component={RenderField}
              className={className}
              validate={[maxValue(max), minValue(min)]}
          />
      </S.InputComponent>
  );

}

const RenderField = ({ input, required, label, disabled, type, className, meta: { touched, error, warning, initial} }) => {

   const isDisabled = (initial) => {
        return initial ? true : false;
   }


  return (
      <S.InputWrapper>
          <S.Input {...input} required={required} type={type} className={className} disabled={isDisabled(initial)}/>
          <S.Label>{label}</S.Label>
          {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
      </S.InputWrapper>
  )
}

const RenderSelectField = ({ input, label, required, removeOption, meta: { touched, error, warning, initial}, children}) => {

  const isDisabled = (initial) => {
    return initial ? true : false;
  }

  return (
    <S.RenderSelectField>
        <label>{label}</label>
        <select {...input} required={required} disabled={isDisabled(initial)}>
        {children}
        </select>
        {touched && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
        {removeOption && input.value && <button type="button" onClick={removeOption.onClick}>{translation(removeOption.label)}</button>}
    </S.RenderSelectField>
  )
}

const ButtonComponent = React.forwardRef(({onClick, action, label, type, disabled}, ref) => {
    return (
        <S.ButtonComponent>        
            <button ref={ref} onClick={onClick} type={type} action={action} disabled={disabled}>
                {translation(label)} 
            </button>
        </S.ButtonComponent>
    );
})

const ImageComponent = ({src ,alt, onClick}) => {

  if (Array.isArray(src)) {
      const keySrc = 0;
      var socure = MOBILE_URL + "images/payments/" + src[keySrc].src;
      alt ? alt = alt : alt = '';

  } else {
      var socure = MOBILE_URL + "images/payments/" + src;
      alt ? alt = alt : alt = '';
  }

  return (
      <S.ImageComponent onClick={onClick}>
          <img src={socure.toString()} alt={alt.toString()}></img>
      </S.ImageComponent>
  );
}

const SelectComponent = ({data, name, onChange, onClick, value, type, label, required, removeOption, disabled}) => {

  if (data != null || required) {

      label ? label =  translation(label) : '';
      return (
          <S.SelectComponent>
              <Field
                  name={name}
                  value={value}
                  onChange={onChange}
                  onClick={onClick}
                  type={type}
                  required={required}
                  disabled={disabled}
                  label={label}
                  removeOption={removeOption}
                  component={RenderSelectField}>

                  {data.map((option, key) => {

                      if (key == 0 && disabled) {

                          return <option disabled={disabled} key={key} value={option.value}>{option.name}</option>;

                      } else {

                          return <option key={key} value={option.value}>{option.name}</option>;

                      }

                  })}

              </Field>
          </S.SelectComponent>
      );
  }
}

const TextComponent = ({label}) => {

    return (
        <S.TextComponent>
            <label>{translation(label)}</label>
        </S.TextComponent>
    );
  }

const ImgAndTextComponent = ({imageComponent, label}) => {

  return (
      <S.ImgAndTextComponent>
          <ImageComponent src={imageComponent} />
          <label>{translation(label)}</label>
      </S.ImgAndTextComponent>
  );
}

const ConfirmRowComponent = ({label, value}) => {
  return (
      <S.ConfirmRowComponent>
          <span>{translation(label)}</span>
          <span>{value}</span>
      </S.ConfirmRowComponent>
  );
}

const ConfirmDataComponent = ({titleLabel, label, value, item}) => {

  return (
      <S.ConfirmDataComponent>
          {!!titleLabel && <span>{translation(titleLabel)}</span>}
            {(!!label || !!value) && (<div>
                {!!label && <span>{translation(label)}</span>}
                {!!value && <span>{translation(value)}</span>}
            </div>)}
            {item}
      </S.ConfirmDataComponent>
  )
}

const CmsComponent = ({item}) => {
  const {html} = item.data;
    return (
        <div dangerouslySetInnerHTML={createMarkup(html)}></div>
    )
}

const ExternalComponent = ({item}) => {
    return (
        <div>
            <span>{translation('payment_waitingForRedirect')}</span>
            <div id="externalForm" dangerouslySetInnerHTML={createMarkup(item)}></div>
        </div>
    )
}

const IframeComponent = ({url}) => {
  return (
      <S.IframeComponent>
          <iframe src={url} frameBorder="0" sandbox='allow-same-origin allow-scripts allow-popups allow-forms' allowFullScreen></iframe>
      </S.IframeComponent>
  )
}

const LimitsComponent = ({min, max, fee, minLabel, maxLabel, feeLabel, transactionCurrency}) => {

    return (
        <div>
            {min ? 
            <div>
            <span>{translation(minLabel)}</span>
            <span>{` ${min} ${transactionCurrency}`}</span>
            </div> : <div></div>}
            {max ?
            <div>
                <span>{translation(maxLabel)}</span>
                <span>{` ${max} ${transactionCurrency}`}</span>
            </div> : <div></div>}
            {fee ?
            <div>
                <span>{translation(feeLabel)}</span>
                <span>{` ${fee} ${transactionCurrency}`}</span>
            </div> : <div></div>}
        </div>
    )
}

const MenuComponent = ({items}) => {
  return (
      <div>

      </div>
  )
}


              
const InfoWonAmountToPayoutComponent = ({transactionAmount, transactionCurrency, labelLocked, labelUnlocked}) => {

    if (transactionAmount > 0) {
        return (
            <S.InfoWonAmountToPayoutComponentUnlocked>
                <div>{`${translation(labelUnlocked).replace('{{transactionAmount}}', transactionAmount).replace('{{transactionCurrency}}', transactionCurrency)}`}</div>
            </S.InfoWonAmountToPayoutComponentUnlocked>
        )
    } else {
        return (
            <S.InfoWonAmountToPayoutComponentLocked>
                <div>{translation(labelLocked)} </div>
            </S.InfoWonAmountToPayoutComponentLocked>
        ) 
    }
}

const RemoveCardCardComponent = ({cardType, cardNumber, onClickAccept, onClickCancel}) => {
    return (
       <S.RemoveCardCardComponent>
           <span>{`${translation('payment_confirmRemoveCard')} ${cardNumber}`}</span>
           <div>
               <button type="button" onClick ={() => onClickAccept(cardType)}>{translation('common_accept')}</button>
               <button type="button" onClick ={() => onClickCancel()}>{translation('common_cancel')}</button>
           </div>
       </S.RemoveCardCardComponent> 
    );
}

export {
  Item,
  GroupComponents,
  InputComponent,
  ButtonComponent,
  SelectComponent,
  ImgAndTextComponent,
  ImageComponent,
  ConfirmRowComponent,
  ConfirmDataComponent,
  CmsComponent,
  IframeComponent,
  ExternalComponent,
  LimitsComponent,
  MenuComponent,
  TextComponent,
  InfoWonAmountToPayoutComponent,
  RemoveCardCardComponent
};
