const modalUserIconSVG = () => {
    return {
        __html: `
          <svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                    <path d="M8.825,15.197c-2.156,0 -4.063,-1.104 -5.175,-2.777c0.026,-1.717 3.45,-2.656 5.175,-2.656c1.718,0 5.15,0.939 5.175,2.656c-1.112,1.673 -3.019,2.777 -5.175,2.777m0,-12.247c1.432,0 2.588,1.155 2.588,2.587c0,1.432 -1.156,2.588 -2.588,2.588c-1.432,0 -2.587,-1.156 -2.587,-2.588c0,-1.432 1.155,-2.587 2.587,-2.587m0,-2.588c-4.76,0 -8.625,3.864 -8.625,8.625c0,4.761 3.865,8.625 8.625,8.625c4.761,0 8.625,-3.864 8.625,-8.625c0,-4.761 -3.864,-8.625 -8.625,-8.625" style="fill:#3B3B3B;fill-rule:nonzero;"></path>
          </svg>`
    };
};

export {modalUserIconSVG};
