import styled from 'styled-components';
import {Card, Button} from 'global/styles/styles.js';
const S = {};

const Header = ({ theme: { header } }) => header();
const CardBody = ({ theme: { cardBody } }) => cardBody();
const CardTab = ({ theme: { cardTab } }) => cardTab();
const CardLabel = ({ theme: { cardLabel } }) => cardLabel();
const Divider = ({ theme: { divider } }) => divider();
const CardWithTab = ({ theme: { cardWithTab } }) => cardWithTab();

S.AccountQueueBonus = styled.div`
    padding: 0 0.65rem;
`;

S.QueueBonusRow = styled.div``;

S.BonusBody = styled.div`${CardBody}`;

S.BonusContent = styled(Card)` ${CardWithTab}
    justify-content: center;
`;
S.BonusImageWrapper = styled.div` ${CardTab}
    position: relative;
    padding-top: ${p => `calc(100% / ${p.ratio})`};
    min-height: unset;
    overflow: hidden;
`;

S.BonusBox = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 0.45rem 0.75rem 0.45rem;
`;

S.BonusOffrImg = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
`;

S.BonusTitle = styled.div`${CardLabel}`;

S.BonusValue = styled.div`${CardLabel}
    font-weight: 700;
`;

S.Divider = styled.div`${Divider}`;

S.BonusHeader = styled.h4` ${Header} `;

S.BonusList = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

S.NoResult = styled.div`
    height: 2.5rem;
    text-align: center;
    color: ${({theme:{$noResultColor}}) => $noResultColor};
`;

S.Button = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;
    height: 31px;
    color: #fff;
    border-radius: 6px;
    font-size: 11px;
    letter-spacing: -0.28px;
    &.reject {
        background-color: #bebebe;
    }
    &.accept {
        background-color: #EC3839;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -30px;
    color: #001a5b;
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    height: 2.625rem;
    border-radius: 25px;
    box-shadow: none;
    padding: 0 0.75rem;
    margin-right: 1rem;
    margin-top: 1rem;
    flex: 1;
    background: ${({theme:{$accountProfileBonusModalsCancelBttnBg}})=> $accountProfileBonusModalsCancelBttnBg};
   
`;
S.ButtonAccept = styled(S.ButtonCancel)`
    margin-right: 0;
    color: ${({theme:{$accountProfileBonusModalsAcceptBttnColor}})=> $accountProfileBonusModalsAcceptBttnColor};
    background: ${({theme:{$accountProfileBonusModalsSuccessBttnBg}})=> $accountProfileBonusModalsSuccessBttnBg};
   
`;
S.Title = styled.div`
    font-size: 1.2307692308rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;
S.Description = styled.div`
    text-align: center;
`;

S.ModalInfo = styled.div`
    width: 100%;
    margin-top: -44px;
`;

S.InfoTitle = styled.h4` 
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    text-align: center;
    margin-bottom: 2rem;
`;

S.InfoDescription = styled.div`
    text-align: center;  
    color: ${({theme:{$accountProfileBonusModalsTextColor}})=> $accountProfileBonusModalsTextColor};
`;

S.BonusFooter = styled.div`
  text-align: center;
  padding-top: 0.9375rem;
`;

S.BonusRegulationLink = styled.a`
  font-size: 0.75rem;
  color: ${({theme:{$accountProfileBonusRegulationLinkColor}})=> $accountProfileBonusRegulationLinkColor};
  text-decoration: underline;
`;

export default S;