import styled from 'styled-components';
import {Card} from 'global/styles/styles.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const S = {};

S.VirtualBetHistoryItemWrapper = styled.div`
    margin: 0.2rem 0;
    padding: 0 0.5rem;
    filter: drop-shadow(0px 4px 10px rgba(68, 85, 106, 0.08));
`;

S.VirtualBetHistoryItem = styled(Card)`
    width: 100%;
    font-family: Open Sans;
    font-size: 0.6rem;
    font-weight: 600;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;

    padding: 0.5rem 0;
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${FontFamily};
        font-size: 0.7rem;
        font-weight: bold;
        &.transaction-info {
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.6rem;
            padding-left: 0.7rem;
            color: ${({theme: {$accountProfileBetHistoryTransactionItemTypeColor}}) => $accountProfileBetHistoryTransactionItemTypeColor};

            .transaction-type {
                margin-top: 0.3rem;
            }
        }
        &.transaction-stake {
            color: ${({theme: {$accountProfileBetHistoryTransactionItemStakeColor}}) => $accountProfileBetHistoryTransactionItemStakeColor};
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            text-align: center;
        }
        &.transaction-confirmed-win {
            color: ${({theme: {$accountProfileBetHistoryTransactionItemWonAmountColor}}) => $accountProfileBetHistoryTransactionItemWonAmountColor};
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            text-align: center;
        }
        &.transaction-date {
            color: ${({theme: {$accountProfileBetHistoryTransactionItemDateColor}}) => $accountProfileBetHistoryTransactionItemDateColor};
            font-weight: normal;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            justify-content: space-between;
            .date-container {
                flex-direction: column;
                align-items: center;
                opacity: 0.5;
            }
            i {
                font-size: 1rem;
                margin-left: 0.5rem;
                color: ${({theme: {$accountProfileBetHistoryTransactionArrowColor}}) => $accountProfileBetHistoryTransactionArrowColor};
            }
        }
    }
`;

S.StatusIcon = styled.div`
    height: 14px;
    width: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    &.pending_acceptation {
        background-image: url(/images/icon_pending.png);
    }

    &.won, &.cashout {
        background-image: url(/images/icon_won.png);
    }

    /* &.cashout {
        background-image: url(/images/icon_cashout.png);
    } */

    &.cancel {
        background-image: url(/images/icon_cancel.png);
    }

    &.cashback {
        background-image: url(/images/icon_cashback.png);
    }

    &.lost {
        background-image: url(/images/icon_lost.png);
    }

    &.placed {
        background-image: url(/images/icon_pending.png);
    }
`;

export default S;