import {combineReducers} from 'redux';
import accountBetHistoryReducer from 'accountBetHistoryReducer.js';
import accountFreebetReducer from 'accountFreebetReducer.js';
import accountSessionsHistoryReducer from 'accountSessionsHistoryReducer.js';
import accountResponsibleGameSelfExclusionReducer from 'accountResponsibleGameSelfExclusionReducer.js';
import accountCashbackReducer from 'accountCashbackReducer.js';
import accountReducer from 'accountReducer.js';
import accountBonusReducer from 'accountBonusReducer.js';
import accountWalletReducer from 'accountWalletReducer.js';
import authReducer from 'authReducer.js';
import customerReducer from 'customerReducer.js';
import offersReducer from 'offersReducer.js';
import paymentProvidersReducer from 'paymentProvidersReducer.js';
import providerContentReducer from 'providerContentReducer.js';
import cmsReducer from 'cmsReducer.js';
import IntegrationGamesListReducer from 'IntegrationGamesListReducer.js';
import IntegrationGameIframeReducer from 'IntegrationGameIframeReducer.js';
import ToggleReducer from 'toggleReducer.js';
import {reducer as formReducer} from 'redux-form';
import intervalsReducer from 'intervalsReducer.js';
import favouritesReducer from 'favouritesReducer.js';
import topMenuReducer from 'topMenuReducer.js';
import customerDataByMyIdReducer from 'customerDataByMyIdReducer.js';
import liveEventReducer from 'liveEventReducer.js';
import marketReducer from 'marketReducer.js';

export default combineReducers({
    form: formReducer,
    Account: accountReducer,
    AccountBetHistory: accountBetHistoryReducer,
    AccountBonus: accountBonusReducer,
    AccountCashback: accountCashbackReducer,
    AccountDeposit: paymentProvidersReducer,
    DepositLoginPopup: paymentProvidersReducer,
    AccountFreebet: accountFreebetReducer,
    AccountSessionHistory: accountSessionsHistoryReducer,
    AccountResponsibleGameSelfExclusion: accountResponsibleGameSelfExclusionReducer,
    AccountWallet: accountWalletReducer,
    AccountWithdraw: paymentProvidersReducer,
    Auth: authReducer,
    Customer: customerReducer,
    CustomerDataByMyId: customerDataByMyIdReducer,
    Cms: cmsReducer,
    LiveEvent: liveEventReducer,
    MarketData: marketReducer,
    Offers: offersReducer,
    ProviderContent: providerContentReducer,
    IntegrationGamesList: IntegrationGamesListReducer,
    IntegrationGameIframe: IntegrationGameIframeReducer,
    Intervals: intervalsReducer,
    Toggle:ToggleReducer,
    TopMenu: topMenuReducer,
    Favourites: favouritesReducer,
});

