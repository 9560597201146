import React, {useState, useRef, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import S from 'StyledCashoutForm.js';
import {Field, reduxForm, SubmissionError}  from 'redux-form';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import Box from 'react-styled-box';
import useToggle from 'UseToggle.js';
import ModalOpacity from 'ModalOpacity.js';
import Loader from 'Loader.js';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import {withRouter} from 'react-router-dom';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    if (values.maximumAmount && values.maximumAmount.amount) {
        values.maximumAmount = values.maximumAmount.amount;
    }
    const errors = validation(values, validators.CASHOUT);
    return errors;
};


const renderCheckbox = ({
    input,
    label,
    touch,
    meta: {touched, error, warning, submitFailed}
}) => {
    return (
        <S.AcceptCheckbox as={Box} flexDirection="row" justifyContent="center" alignItems="flex-start">
            <input {...input} id="customerAcceptedAmountChange" value={input.value} checked={input.value} type="checkbox" 
                onChange={event => {
                    input.onChange(event.target.checked);
                    touch(input.name);
                }}/>
            <label htmlFor="customerAcceptedAmountChange">{label}</label>
        </S.AcceptCheckbox>
    )
}

const renderField = ({
    input,
    className,
    currencyCode,
    extraData,
    label,
    type,
    disabled,
    customError,
    meta: {touched, error, warning}
}) => {

    const inputRef = useRef(null);

    useEffect(() => {
        let inputWidth = inputRef.current.value.length;
        inputRef.current.style.width = `${inputWidth}ch`;
    }, []);

    if (input.name == 'maximumAmount') {
        input.value = extraData.amount ? extraData.amount : extraData;
    }
    return (
        <S.Row>

            <S.Label>{label}</S.Label>

            <S.InputWrapper>
                <S.Input {...input} ref={inputRef} className={className} type={type} disabled={disabled} required="required"/>
                <S.Currency>{currencyCode}</S.Currency>
                {touched ?
                    ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))
                    :
                    (customError && <S.Error>{customError}</S.Error>)
                }
            </S.InputWrapper>

        </S.Row>
    )
};

let CashoutForm = ({history: { goBack }, location: { state: { toggleLiveCurtainForTxId, refreshBetHistoryList, prepareCashout, cachoutOnSubmit, stake, possibleReturn, currencyCode, isFullCashoutDisabled, transactionOdds, transactionId, goBackToTransactionList }}, maxAmount, curtainActive, dispatch, valid, error, pristine, submitting, handleSubmit, touch}) => {

    const [showInfoModal, toggleInfoModal] = useToggle(false);
    // const [showAmountChangeChbox, toggleAmountChangeChbox] =  useState(false);
    const [infoModalSuccessMsg, setInfoModalSuccessMsg] = useState('');
    const abortController = useRef(new AbortController());
    useEffect(() => {
        // spike change header text
        app.controller.BetSlipHistory.selector.contentHeader().hide();

        // poprawic, tylko dla curtainActive = true
        return () => {
                abortController.current.abort();
                toggleLiveCurtainForTxId(transactionId, false);
            }
    }, []);

    const showCashoutTaxInfo = useMemo(()=>{
        const cashoutAmount = (maxAmount?.amount??maxAmount);
        const tax = process.env.CASHOUT_TAX;
        return (cashoutAmount>tax);
    }, [maxAmount]);

    const onFormSubmit = async(type, values) => {

        const config = {
            slipId: transactionId,
            requestedAmount: ((type == 'full') ? maxAmount.amount ? maxAmount.amount : maxAmount : _get(values, 'partialAmount')),
            countDownRequired: maxAmount.countDownRequired || false,
            type: `cashout${_capitalize(type)}Amount`
        };

        const customerAcceptedAmountChange = _get(values, 'customerAcceptedAmountChange');
        if (customerAcceptedAmountChange) {
            config['customerAcceptedAmountChange'] = customerAcceptedAmountChange;
        } else {
            config['customerAcceptedAmountChange'] = false;
        }

        try {
            const cashoutResult = await cachoutOnSubmit(config, abortController.current.signal);
            const successMsg = `${translation(`account_cashout_cashoutSuccess_${type}`)} ${cashoutResult.currentCashoutAmount} ${currencyCode}`;

            setInfoModalSuccessMsg(successMsg);
            toggleInfoModal();

        } catch ({message, oddsHasChanged = null}) {
            // if (oddsHasChanged) {
            //     toggleAmountChangeChbox(true);
            // } else {
            //     toggleAmountChangeChbox(false)
            // }

            toggleLiveCurtainForTxId(transactionId, false);
            throw new SubmissionError({_error: message});
        }

    };

    return (
        <>
            <S.CashoutHeader>
                    <S.BackButton onClick={goBack}></S.BackButton>
                    <S.HeaderTitle>{translation('account_betHistory_cashout')}</S.HeaderTitle>
            </S.CashoutHeader>

            <S.CashoutForm method="post">

                {curtainActive && (
                    <S.Overlay>
                        <Loader size="5"/>
                    </S.Overlay>
                )}

                <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false} showCloseIcon={false}
                            toggleOpen={goBackToTransactionList} padding="8px" width="92%">

                    <Box flexDirection="column" width="100%" margin="-15px 0 0">
                        <Box flexDirection="column" justifyContent="center" margin="0 0 20px">
                            <S.ConclusionMsg>{infoModalSuccessMsg}</S.ConclusionMsg>
                            {showCashoutTaxInfo && <S.ConclusionTaxInfo>{translation('account_cashout_conclusionTaxInfo')}</S.ConclusionTaxInfo>}
                        </Box>
                        <Box justifyContent="center">
                            <S.Button onClick={goBackToTransactionList}>{translation('common_ok')}</S.Button>
                        </Box>
                    </Box>

                </ModalOpacity>

                <S.CashoutFormInner as={Box} flexDirection="column" padding="2rem" alignItems="center">

                    <S.CashoutIcon></S.CashoutIcon>

                    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                        <S.Label>
                            <span>{translation('account_cashout_odds')} {transactionOdds}</span>
                        </S.Label>
                    </Box>

                    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                        <S.Label>
                            <span>{translation('account_cashout_amountTitle')} {formatMoney(stake)}</span>
                        </S.Label>
                    </Box>

                    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                        <S.Label>
                            <span>{translation('account_cashout_possibleWinTitle')} {formatMoney(possibleReturn)}</span>&nbsp;
                        </S.Label>
                    </Box>

                    <Box flexDirection="row" margin='0 0 10px 0' className="full-cashout">

                        <Box >
                            <Field
                                name="maximumAmount"
                                type="text"
                                className="maximum-amount"
                                component={renderField}
                                extraData={maxAmount}
                                label={translation('account_cashout_fullTitle')}
                                currencyCode={currencyCode}
                                disabled={true}
                                customError={isFullCashoutDisabled && translation('account_cashout_cashoutBelowStake')}
                            />
                        </Box>
                    </Box>

                    {showCashoutTaxInfo && (
                        <Box flexDirection="row" margin='0 0 20px 0'>
                            <S.TaxInfo>{translation('account_cashout_taxInfo')}</S.TaxInfo>
                        </Box>
                    )}

                    {error && <S.SubmissionError className="submission-error">{translation(`account_${error}`)}</S.SubmissionError>}

                </S.CashoutFormInner>

                <Field
                    name="customerAcceptedAmountChange"
                    component={renderCheckbox}
                    label={translation('account_cashout_acceptAmountChange')}
                    required={false}
                    touch={touch}
                />

                <Box justifyContent="center">
                    <S.SubmitBtn type="button" onClick={handleSubmit(onFormSubmit.bind(null, 'full'))}
                                disabled={submitting || isFullCashoutDisabled}>{translation('account_cashout_full')}</S.SubmitBtn>
                </Box>

            </S.CashoutForm>
        </>
    )
};

CashoutForm = reduxForm({
    form: 'cashoutForm',
    validate
})(CashoutForm);

const mapStateToProps = ({AccountBetHistory: {transactionsList}}, { location: { state: { stake, transactionId }}}) => {
    const properTransaction = transactionsList.find(transaction => transaction.transactionId == transactionId);
    return {
        isFullCashoutDisabled: (stake > properTransaction?.maxAmount?.amount),
        maxAmount: properTransaction?.maxAmount,
        curtainActive: properTransaction?.curtainActive
    }
};

export default withRouter(connect(mapStateToProps)(CashoutForm));

