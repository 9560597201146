import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledModal from 'StyledModal.js';
import {List, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = '12px';
const S = {};

S.AccountBetHistory = styled.div`
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 0 auto;
    font-family: ${FontFamily};
    padding-bottom: 2.33rem;
`;

export default S;