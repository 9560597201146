/* global app */
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { SBPayments } from 'sb-payments-etoto'
import * as styled from 'StyledAccountDeposit'

const { Payments, useSession } = SBPayments

const AccountDeposit = ({ isLogged }) => {
  const session = useSession()

  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_2`.toLowerCase(), [app.config.lang, app.config.mainCountryCode])

  return (
    <styled.Wrapper>
      {isLogged && (
        <Payments lang={lang} session={session} />
      )}
    </styled.Wrapper>
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => {
  return {
    isLogged
  }
}

export default connect(mapStateToProps)(AccountDeposit)
