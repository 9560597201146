import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {TOGGLE_ACCOUNT_MODAL, UPDATE_SESSION_TIME, LOAD_VERYFICATION_STATUS} from 'accountActions.js';

let initialState = {
    isOpen: false,
    sessionTime: null,
    accountData: null,
};

export default typeToReducer({
    [TOGGLE_ACCOUNT_MODAL]: (state, {payload: {isOpen}}) => {
        return {...state, isOpen};
    },
    ['USER_SIGNIN_SUCCESS']: (state, {payload: {user}}) => {
        let newState = state;
        const {sessionTime} = user;
        if(!state.sessionTime){
             newState = update(newState, {sessionTime: {$set: sessionTime}});
        }
        return {...newState};
    },
    [UPDATE_SESSION_TIME]: (state) => {
        return {...state, sessionTime: state.sessionTime + 1000};
    },
    ['USER_LOGOUT']:  (state) => {
        return update(state, {sessionTime: {$set: null}});
    },
    [LOAD_VERYFICATION_STATUS]: {
        SUCCESS: (state, {payload: {accountData}}) => {
            return {...state, accountData};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
}, initialState);
