import styled from 'styled-components'
import { Card, Button } from 'global/styles/styles.js'

const CardLabel = ({ theme: { cardLabel } }) => cardLabel()
const Divider = ({ theme: { divider } }) => divider()
const CardWithTab = ({ theme: { cardWithTab } }) => cardWithTab()
const PrimaryBtn = ({ theme: { primaryBtn } }) => primaryBtn()
const CalendarContainer = ({ theme: { calendarContainer } }) => calendarContainer()
const DateLabel = ({ theme: { dateLabel } }) => dateLabel()
const DatePicker = ({ theme: { datePicker } }) => datePicker()

const S = {}
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL

S.AccountWallet = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: #F2F5F9;
`
S.PayoutCancelButton = styled.button`
  width:100%;
  padding: 8px 10px;
  font-size:12px;
  color:#fff;
  border-radius:4px;
  border-color:transparent;
  overflow:hidden;
  &.available{
      background-color:#02B253;
  }
  &.not-available{
      background-color:#BAC1D0;
  }

`
S.PayoutCancelContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
flex-wrap:wrap;
width:100%;
padding:35px;
font-weight:700;
    & .transaction-amount{
        margin:20px 0 ;
        font-size:30px;

    }
    & .transaction-date{
        font-weight:400;
    }
    & button{
            width:100px;
            margin-top:20px;
            padding: 8px 10px;
            font-size:12px;
            color:#fff;
            border-radius:4px;
            border-color:transparent;
            overflow:hidden;
            background-color:#E4202C;
          }
`


S.WalletTransactionsList = styled.div`
    margin-top: 0.625rem;
    width: 100%;
`

S.NoResult = styled.div`
    height: 2.5rem;
    display: flex;
    color: ${({ theme: { $noResultColor } }) => $noResultColor};
    justify-content: center;
    align-items: center;
`

S.WalletTransactionsListItem = styled(Card)`
  display: flex;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  padding: 0.85rem 0.75rem 0.85rem;

  &>div {
    &.wallet-id {
      display:flex;
      color: #141C4E;
      font-size: 1rem;
      font-weight: 600
    }

    &.wallet-date {
      color: #2D375E80;
      font-size: 0.7rem;
      font-weight: 400
    }
  }
`

S.WalletTransactionsListItemDetails = styled(Card)`
${CardWithTab}

padding-top:0;
      
  & .wallet-status {

    & .status-icon {
      width: 0.8125rem;
      height: 0.8125rem;
      background-size: contain;
      background-repeat: no-repeat;
    }
		&.red {
		    & .status-icon{
		        background-image: url(${imagePath}images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(${imagePath}images/svg/status_green.svg);
		    }
		}
	    &.gray{
		    & .status-icon {
		      background-image: url(${imagePath}images/svg/status_gray.svg);
         
		    }
    }

      & > div {
        display: flex;
      }
    
}
`

S.ItemDetailsBox = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 0.45rem 0.75rem 0.45rem;
`

S.Divider = styled.div`${Divider}`

S.WalletTransactions = styled.div`
  margin: 0 0.625rem;
`

S.WalletTransactionsListItemWrapper = styled.div` padding-bottom: 0.5rem `

S.WalletFilters = styled.div`
  display: flex;
  padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
  flex-direction: column;
`

S.WalletFiltersTop = styled.div`display: flex `

S.WalletFiltersDates = styled.div`${CalendarContainer}`

S.DateLabel = styled.div`${DateLabel}
`

S.Datepicker = styled.div`${DatePicker}`

S.FreebetFiltersBtns = styled.div`
    display: flex;
    flex-grow: 0;
`

S.FilterBtn = styled(Button)`${PrimaryBtn} 
padding: 0.85rem 0.5rem; margin: 0 0.2rem;
background-color: ${({ isActive }) => (isActive && '#2D375E80')};
color: ${({ isActive }) => (isActive && '#fff')};
`

S.FakeTableBody = styled.div``

S.ItemDetailsTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  font-size: 0.8125rem;
`

S.ItemDetailsHeader = styled.div`${CardLabel}`

S.ItemDetailsBody = styled.div`${CardLabel}
font-weight: 700;
  &.red{
  color: red;
  }
  &.green{
  color: green;
  }
`

export default S