import styled, {css} from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme:{$fwBold}}) => `${$fwBold}`;

const baseFont = `16px`;

const S = {};

S.ResponsibleGamingForm = styled(Form)`
    width: auto !important;
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    margin: ${rem('3.5px', baseFont)} ${rem('10px', baseFont)} 0 !important;
    background: ${({theme:{$accountResponsibleGameTabBgColor}})=> $accountResponsibleGameTabBgColor};
    box-shadow: 0 4px 13px 0 #44556a47;
    border-radius: 7px;
    ${({modal})=>{
        if(modal==true){
            return css`
                ${rem('17px', baseFont)} 0 ${rem('20px', baseFont)} 0 !important;
            `;
        }    
    }}
`;

S.CloseModalButton = styled(Button)`
    position: absolute;
    right: -40px;
    top: -40px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
    transform: none;
    line-height: 1;
    margin-right: -3rem;
    margin-top: -3rem;
    border-radius: 50%;
    bottom: 0;
    margin: .83rem 0;
    background: #fff url(/images/svg/header_close_black.svg) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

S.LoaderWrapper = styled.div`
    width: auto;
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.Label = styled.label`
    padding: 6px;
    font-size: ${rem('10px', baseFont)};
    font-weight: ${FwSemibold};
    color: #2D375E80;
    text-overflow: ellipsis;
    overflow: hidden;
`;

S.Input = styled(Input)`
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    font-size:  ${rem('14px', baseFont)};   
    color: ${({theme:{$accountResponsibleGameFormInputText}})=> $accountResponsibleGameFormInputText};
    border: none;
    display: block;
    text-align: left;
    background: inherit;
    border-radius: 7px;
    padding: ${rem('17px', baseFont)} 0 ${rem('17px', baseFont)} ${rem('14px', baseFont)};

    &:focus {
        outline: 0;
        outline-color: transparent;
        outline-style: none;
        border-bottom-color: #0E62C4;
    }
     
    &[type="number"]{
      -moz-appearance: textfield;
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
   
`;

S.SubmitBtn = styled(Button)`
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    width: 100%;
    background-color: ${({theme:{$accountResponsibleGameFormSubmitBtn}})=> $accountResponsibleGameFormSubmitBtn};
    font-size: ${rem('12px', baseFont)};
    color: #fff;
    border-radius: 7px;
    padding: ${rem('14px', baseFont)};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    &:disabled {
        background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnDisabled}})=> $accountResponsibleGameSelfExclusionBtnDisabled};
    }
`;

S.ClearFormBtn = styled(Button)`
    font-family: ${FontFamily};
    font-weight: ${FwBold};
    height: ${rem('40px', baseFont)};
    width: ${rem('220px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameFormClearBtn}})=> $accountResponsibleGameFormClearBtn};
    font-size: ${rem('13px', baseFont)};
    color: #fff;
    border-radius: 20px;
    padding: 0 ${rem('10px', baseFont)};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    padding: 2px 4px;
    font-size: ${rem('10px', baseFont)};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    color: red;
    font-size: ${rem('11px', baseFont)};
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.Currency = styled.div`
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    font-size:  ${rem('15px', baseFont)};
    color: ${({theme:{$accountResponsibleGameFormInputText}})=> $accountResponsibleGameFormInputText};
`;

S.Hour = styled(S.Currency)`
    text-transform: lowercase;
`;

S.InputWrapper = styled.div`
    margin-bottom: ${rem('9px', baseFont)};
    display: flex;
    flex-direction: column;
   
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
                flex: 1;
                font-size: 1.17rem;
                font-weight: 400;
                padding-top: 0;
                color: #787d83;
                display: flex;
                position: relative;
                left: 0;
                cursor: pointer;
                top: 0;
                
                & a{
                    color: #CE0006;
                }
            }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 1px;
            height: 14px;
            width: auto;
         }
    }
`;

S.ValueWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    border: 1px solid ${({theme:{$accountResponsibleGameFormBorderColor}})=> $accountResponsibleGameFormBorderColor};;
    border-radius: 7px;
    box-shadow: 0 4px 13px 0 #44556a14;

    &.has-error{
        border: 1px solid red;
    }
    &.is-valid{
        border: 1px solid #BAC1D0;
    }
    &.inputFocused {
        border: 1px solid ${({theme:{$accountResponsibleGameFormInputFocusedBorder}})=> $accountResponsibleGameFormInputFocusedBorder};
    }
`;

S.Body = styled.div`
    padding: 10px;
    border-radius: 7px;
`;

S.BodyInner = styled.div`
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    > div {
        flex-basis: 100vw;
    }
    ${({modal})=>{
        if(modal==true){
            return css`
                padding-left: ${rem('53px', baseFont)};
            `;
        }    
    }}
`;

S.Footer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    ${({modal})=>{
        if(modal==true){
            return css`
                align-items: center;
                padding-top: ${rem('40px', baseFont)};
            `;
            }    
    }}
`;

S.Info = styled.div`
    margin: 20px 0;
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    font-size: ${rem('12px', baseFont)};
    color: ${({theme:{$accountResponsibleGameInfoColor}})=> $accountResponsibleGameInfoColor};
`;

S.FormTitle = styled.h2`
    margin: 0;
    padding: 16px 12px;
    color: ${({theme:{$accountResponsibleGameTitleColor}})=> $accountResponsibleGameTitleColor};
    font-size: ${rem('15px', baseFont)};
    font-weight: ${FwSemibold};
    color: #2D375E;
    ${({modal})=>{
        if(modal==true){
            return css`
                text-align: center;
            `;
        }    
    }}
`;

S.LimitUpdateResponse = styled(S.SubmissionError)`
    background-color: transparent;
    border-color: transparent;
`;

S.Limit = styled.div`
  font-size: ${rem('11px', baseFont)};
  color: ${({status})=>(status?'green':'red')};
  margin-bottom: ${rem('10px', baseFont)};
  &:last-of-type{
    margin-bottom: 0;
  }
`;

S.LimitName = styled.div`
  font-weight: ${FwSemibold};
`;

S.LimitText = styled.div`
    color: ${({theme:{$accountResponsibleGameFormLabel}})=> $accountResponsibleGameFormLabel};
    line-height: 1.4;
`;

S.DialogBtnOk = styled(Button)`
    margin-bottom: ${rem('10px', baseFont)}; 
    border-radius: ${rem('10px', baseFont)};
    width: 100%;
    padding: ${rem('18px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelBgColor}})=> $accountResponsibleGameSelfExclusionBtnCancelBgColor};
    font-size: ${rem('14px', baseFont)};
    font-family: ${FontFamily};
    font-weight: ${FwBold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelColor}})=> $accountResponsibleGameSelfExclusionBtnCancelColor};
    
    &:focus {
            outline: 0;
            outline-color: transparent;
            outline-style: none;
            border-bottom-color: #0E62C4;
        }
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
    background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
    font-weight: ${FwBold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnColor}})=> $accountResponsibleGameSelfExclusionBtnColor};
`;

S.DialogText =styled.div`
    font-size: ${rem('17px', baseFont)};
    font-weight:  ${FwSemibold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionDialogText}})=> $accountResponsibleGameSelfExclusionDialogText};;
    text-align: center;
`;

export default S;