import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import S from 'StyledAccountHistoricalBonus.js';

import {
    setCurrentPage,
    fetchCreditBonusListByFilter,
    fetchCreditBonusListPending,
    fetchCreditBonusListFulfilled,
    fetchCreditBonusListFailure
} from 'accountBonusActions.js';

class AccountHistoricalBonus extends Component {

    componentDidMount = async() => {
        const {fetchCreditBonusListPending, fetchCreditBonusListByFilter, fetchCreditBonusListFailure, fetchCreditBonusListFulfilled, endDate, startDate} = this.props;

        fetchCreditBonusListPending();

        try {
            const data = await fetchCreditBonusListByFilter({status: 'HISTORY', endDate, startDate});
            fetchCreditBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountHistoricalBonus:', error);
            fetchCreditBonusListFailure(error)
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchCreditBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            status,
            currentPage: offset
        };

        try {
            await fetchCreditBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    checkStatus = (status_txt, wager_percentage) => {
        if(status_txt === "OFFER_COMPLETED_AND_CLOSED" && wager_percentage === 100) {
            return  <span className="positive">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs></defs><circle className="a" cx="10" cy="10" r="10"/><path className="b" d="M4.444,10h0L0,5.191,1.246,3.845l3.2,3.462L11.2,0l1.243,1.346L4.445,10Z" transform="translate(3.777 5.001)"/></svg>
                    </span>;
        } else {
            return  <span className="negative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><path className="a" d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"/><path className="b" d="M0,0H24V24H0Z"/></svg>
                    </span>
        }
    };


    render() {

        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const historyBonus = _get(bonus, 'history');
        const historyBonusData = _get(historyBonus, 'data');

        return (
            <S.AccountHistoryBonus className="account-history-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <S.BonusList>
                           

                                    {_size(historyBonusData) ?
                                        (
                                            <>

                                            {_map(historyBonusData, ({offerName, nameKey, offerId, validTo, offerPlayerInfo, activationTime, assignedBonusAmount}) => {
                                                offerPlayerInfo = offerPlayerInfo ?? {};
                                                const {imageUrl = null, rulesPageUrl = null} = offerPlayerInfo;
                                               return (
                                                    <S.HistoricalBonusRow key={offerId}>
                                                         <S.BonusHeader data-translation={nameKey} className="bonusName">{translation(nameKey)}</S.BonusHeader>
                                                         <S.BonusBody>
                                                            <S.BonusImageWrapper ratio={2}>
                                                                {imageUrl && <S.BonusOffrImg src={imageUrl}></S.BonusOffrImg>}
                                                            </S.BonusImageWrapper>
                                                            <S.BonusContent>
                                                                <S.BonusBox>
                                                                    <S.BonusTitle>{translation('account_bonus_activationTime')}</S.BonusTitle>
                                                                    <S.BonusValue>{formatDate(activationTime, 'yyyy-MM-dd HH:mm')}</S.BonusValue>
                                                                </S.BonusBox>
                                                                <S.Divider />
                                                                <S.BonusBox>
                                                                    <S.BonusTitle>{translation('account_bonus_validTo')}</S.BonusTitle>
                                                                    <S.BonusValue>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</S.BonusValue>
                                                                </S.BonusBox>
                                                                <S.Divider />
                                                                <S.BonusBox>
                                                                    <S.BonusTitle>{translation('account_bonus_amount')}</S.BonusTitle>
                                                                    <S.BonusValue> 
                                                                        <span className="amount">{formatMoney(assignedBonusAmount)}</span>&nbsp;
                                                                        <span className="currency">{currencyCode}</span></S.BonusValue>
                                                                </S.BonusBox>
                                                            </S.BonusContent>
                                                        </S.BonusBody>
                                                       
                                                       {rulesPageUrl &&
                                                           <S.BonusFooter>
                                                               <S.BonusRegulationLink href={rulesPageUrl}>
                                                                   {translation('account_bonus_regulationLink')}
                                                               </S.BonusRegulationLink>
                                                           </S.BonusFooter>
                                                       }
                                                        
                                                    </S.HistoricalBonusRow>
                                                )
                                            })}

                                            {_size(historyBonusData) && (
                                                        <Pagination currentPage={_get(historyBonus, 'currentPage')}
                                                                    limit={limit} data={historyBonusData}
                                                                    cb={this.goPage.bind(this, 'history')}/>
                                                    
                                            )}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <div className="noResults">{translation('account_bonus_notFound')}</div>
                                        </S.NoResult>
                                    }
                              
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountHistoryBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        fetchCreditBonusListPending: bindActionCreators(fetchCreditBonusListPending, dispatch),
        fetchCreditBonusListFulfilled: bindActionCreators(fetchCreditBonusListFulfilled, dispatch),
        fetchCreditBonusListFailure: bindActionCreators(fetchCreditBonusListFailure, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistoricalBonus);