import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import S from 'StyledResponsibleGamingForm.js';
import {Field, Fields, reduxForm, SubmissionError, formValueSelector, destroy} from 'redux-form';
import {translation, redirect} from 'helpers/utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {loadCustomerLimits, updateCustomerLimits} from 'customerActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import Loader from 'Loader.js';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _each from 'lodash/each';
import _compact from 'lodash/compact';
import _sortBy from 'lodash/sortBy';
import _omit from 'lodash/omit';
import {getLimitIdByKey} from 'limitTypes.enum.js';
import ModalOpacity from 'ModalOpacity.js';

const renderField = ({input, meta, disabled}, fieldName, onFocus) => {
    const {touched, error, warning, valid, asyncValidating, submitFailed, active, dirty} = meta;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (error),
        'is-valid': ((touched || dirty ||submitFailed) && valid),
        'inputFocused': active,
    });

    const inputRef = useRef(null);

    useEffect(() => {
        let valueLength = inputRef.current.value.length;
        let inputWidth = valueLength + 1;
        inputRef.current.style.width = `${inputWidth}ch`;
      });

    return (
        <Box key={fieldName} flexDirection="column" flexBasis="50%">
            <Box flexGrow={1}>
                <S.Label>{translation(`account_responsibleGame_${fieldName}`)}</S.Label>
            </Box>
            <Box>
                <S.Row>
                    <S.InputWrapper>
                        <S.ValueWrapper className={inputClass}>
                            <S.Input ref={inputRef} {...input} type="number" required="required" 
                                    disabled={disabled}/>

                            {(['limitType13', 'limitType15'].indexOf(fieldName) != -1)
                                ?
                            <S.Hour>godzin</S.Hour>
                                :
                            <S.Currency>zł</S.Currency>
                            }
                        </S.ValueWrapper>
                        
                        {(error && <S.Error>{error}</S.Error>) || (warning &&
                        <S.Error>{warning}</S.Error>)}

                    </S.InputWrapper>
                </S.Row>
            </Box>
        </Box>
    )
};

// validation rules
const required = value => (value || typeof value === 'number' ? undefined : `${translation('common_fieldRequired')}`);
const minLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let minLimitValue = _get(limitById, ['minLimitValue']);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            minLimitValue = Math.floor(minLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value < minLimitValue) ? `${translation('limitUpdateInfo_lowerThanMinimum')} ${minLimitValue}` : undefined;
    }
};
const maxLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let defaultMaxLimitValue = _get(limitById, ['defaultMaxLimitValue']);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            defaultMaxLimitValue = Math.floor(defaultMaxLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value > defaultMaxLimitValue) ? `${translation('limitUpdateInfo_error502')} ${defaultMaxLimitValue}` : undefined;
    }
};

const monthlyLimitLowerThenDaily = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const stakeLimits = _map(['DAILY_STAKE_LIMIT', 'MONTHLY_STAKE_LIMIT'], (key) => getLimitIdByKey(key));
        if (stakeLimits.indexOf(id) != -1) {
            if(parseInt(allValues['limitType1']) > parseInt(allValues['limitType3'])){
                return translation('limitUpdateInfo_error505');
            } 
        }

        const sessionLimits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (sessionLimits.indexOf(id) != -1) {
            if(parseInt(allValues['limitType13']) > parseInt(allValues['limitType15'])){
                return translation('limitUpdateInfo_error505');
            } 
        }
    }
}

const renderFields = (fields) => {
    const fieldNames = _get(fields, ['names']);
    const disabled = _get(fields, ['disabled']);
    let pickedFields = _pick(fields, fieldNames);
    pickedFields = _reduce(pickedFields, (initialObject, nextObj) => {
        const {input:{name}} = nextObj;
        _set(initialObject, [name], {...nextObj, disabled});
        return initialObject;
    }, {});
    return _map(pickedFields, renderField);
};

let ResponsibleGamingForm = (props) => {

    const {
        toggleConfirmationModal,
        updateCustomerLimits,
        loadCustomerLimits,
        isPending,
        invalid,
        toggleOpen,
        handleSubmit,
        submitting,
        error,
        reset,
        customerLimits,
        submitSucceeded,
        submitFailed,
        modal
    } = props;

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

    useEffect(() => {
        loadCustomerLimits();
    }, []);

    const onFormSubmit = async(values) => {
        setIsConfirmationModalOpen(false)

        try {
            let limitData = _pick(values, fieldsMap);
            const isMonthlyLimitLowerThanDaily = parseInt(limitData["limitType1"]) > parseInt(limitData["limitType3"]) || parseInt(limitData["limitType13"]) > parseInt(limitData["limitType15"]);
            if (isMonthlyLimitLowerThanDaily) {
                throw ({
                    _error: translation('account_responsibleGame_monthlyLimitLowerThanDaily')
                });
            } else {
                limitData = _map(customerLimits, (limit) => {
                    const {limitType} = limit;
                    const limitAmount = _get(limitData, [`limitType${limitType}`]);
                    if (limitAmount) {
                        return {...limit, limitAmount}
                    }
                    return null;
                });
    
                limitData = _compact(limitData);
                await updateCustomerLimits(limitData);
                if(modal){
                    redirect('/');
                } else {
                    toggleOpen();
                    toggleConfirmationModal();
                }
            }

        } catch (errors) {
            throw new SubmissionError(errors);
        }
    };

    const limitKeysToIdMap = useMemo(() => {
        const limitKeys = [
            'DAILY_STAKE_LIMIT',
            'MONTHLY_STAKE_LIMIT',
            'DAILY_SESSION_TIME_LIMIT',
            'MONTHLY_SESSION_TIME_LIMIT'
        ];
        return _map(limitKeys, (key) => {
            return getLimitIdByKey(key);
        });
    }, []);

    const fieldsMap = useMemo(() => {
        return _reduce(limitKeysToIdMap, (initialArray, id) => {
            return initialArray.concat(`limitType${id}`);
        }, []);
    }, []);

    const fieldsValidation = useMemo(() => {
        let validationRules = _reduce(limitKeysToIdMap, (initObject, id) => {
            const fieldName = `limitType${id}`;
            const maxLimit = maxLimitByTypeId(id);
            const minLimit = minLimitByTypeId(id);
            const monthlyLimit = monthlyLimitLowerThenDaily(id);
            return _set(initObject, [fieldName], [required, minLimit, maxLimit, monthlyLimit]);
        }, {});
        return validationRules;
    }, []);

    const fieldsParse = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToParse = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToParse.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value * 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const fieldsFormat = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToFormat = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToFormat.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value / 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const showLimitUpdateResponse = (customerLimits) => {

        const todayUnix = new Date().getTime();
        const filteredCustomerLimits = _filter(customerLimits, ({limitType})=>limitKeysToIdMap.includes(limitType));
        const sortOrder = {
            13: 1,
            15: 2,
            1: 3,
            3: 4
        };
        const sortedCustomerLimits = _sortBy(filteredCustomerLimits, ({limitType})=>sortOrder[limitType]);
        const result = _reduce(sortedCustomerLimits, (initalArray, {limitType, limitAmount, oldLimitAmount, exceptionCode, requestedLimitValidFrom = todayUnix}) => {
            const limitName = translation(`account_responsibleGaming_limitType_${limitType}`);
            const requestedLimitValidFromDate = formatDate(new Date(requestedLimitValidFrom), 'yyyy-MM-dd HH:mm');
            const typeOfChange = limitAmount < oldLimitAmount ? 'decrease' : 'increase';

            let descriptionKey = exceptionCode && getLimitIdByKey(exceptionCode);
            descriptionKey = descriptionKey ?? exceptionCode;

            let message = translation(`account_responsibleGaming_limitUpdateInfo_${typeOfChange}Success`);
            if (typeOfChange == 'increase') {
                if (exceptionCode) {
                    message = translation(`account_responsibleGaming_limitUpdateInfo_error_${descriptionKey}`);
                    if (exceptionCode != 500) {
                        message += (' ' + translation(`account_responsibleGaming_limits_newLimitsStartsFrom`, [requestedLimitValidFromDate, requestedLimitValidFromDate]));
                    }
                } else {
                    message += (' ' + translation(`account_responsibleGaming_limits_newLimitsStartsFrom`, [requestedLimitValidFromDate, requestedLimitValidFromDate]));
                }
            }
            const status = !exceptionCode ? true : false;
            const limit = (
                <S.Limit key={limitType} status={status}>
                    <S.LimitName>{limitName}</S.LimitName>
                    <S.LimitText dangerouslySetInnerHTML={{__html: message}}/>
                </S.Limit>
            )
            return (exceptionCode!=500) ? [...initalArray, limit] : initalArray;

        }, []);
        return result;
    };

    if (isPending) {
        return <S.LoaderWrapper as={Loader} color="#F05A22"/>;
    }

    const toggleActionInfoAfterSubmit = () => {
        if(modal !== true && (submitSucceeded||submitFailed) && !error) {
            return (<S.LimitUpdateResponse>{showLimitUpdateResponse(customerLimits)}</S.LimitUpdateResponse>)
        }
    }

    return (
        <S.ResponsibleGamingForm modal={modal} className="responsible-gaming" autocomplete="off">
            <ModalOpacity 
                isOpen={isConfirmationModalOpen} 
                showHeader={false}
                showCloseIcon={false}>
                <Box flexDirection="column" padding="0.25rem 1.25rem" width="100%" overflow="hidden">
                    <Box margin="0.75rem 0" alignSelf="center">
                        <S.DialogText dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_limitsChange_confirmMessage')}}/>
                    </Box>
                    <Box flexDirection="column" justifyContent="space-between" margin="1.25rem 0 0">
                        <S.DialogBtnOk onClick={handleSubmit(onFormSubmit)}>{translation('account_responsibleGaming_limitsChange_confirm')}</S.DialogBtnOk>
                        <S.DialogBtnCancel onClick={() => setIsConfirmationModalOpen(false)}>{translation('account_responsibleGaming_cancel')}</S.DialogBtnCancel>
                    </Box>
                </Box>
            </ModalOpacity>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            {toggleActionInfoAfterSubmit()}

            <S.Body modal={modal}>

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <S.FormTitle modal={modal}>{translation('account_responsibleGame_formTitle')}</S.FormTitle>

                <Box className="form-wrapper">

                    <S.BodyInner modal={modal} className="body-inner">

                        <Fields names={fieldsMap} validate={fieldsValidation} parse={fieldsParse} format={fieldsFormat} component={renderFields}/>

                    </S.BodyInner>

                </Box>

            </S.Body>


            <S.Footer modal={modal}>

                <S.SubmitBtn type="button" onClick={() => setIsConfirmationModalOpen(true)} disabled={submitting || invalid}>{translation('account_responsibleGame_setLimits')}</S.SubmitBtn>
                {/* {!modal && <S.ClearFormBtn type="button" disabled={submitting} onClick={reset}>{translation('account_responsibleGame_resetToDefault')}</S.ClearFormBtn>} */}
   
            </S.Footer>

        </S.ResponsibleGamingForm>
    );
};

const prepareInitialValues = (values, modal) => {

    if (values) {
        values = _reduce(values, (initialObject, {limitType, defaultLimitValue, limitAmount}) => {
            const key = `limitType${limitType}`;
            const limitValue = defaultLimitValue;
            _set(initialObject, [key], limitValue);
            return initialObject;
        }, {});
    }
    return values;
};

const mapStateToProps = (state, {modal}) => {
    const {Customer:{customerLimits, isCustomerLimitsPending:isPending}} = state;
    const initialValues = prepareInitialValues(customerLimits, modal);

    _set(initialValues, ['acceptStandardLimits'], true);
    _set(initialValues, ['limitSetting'], 'standard');
    return {
        customerLimits,
        isPending,
        initialValues
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionsToBind = {updateCustomerLimits, loadCustomerLimits};
    return {
        ...bindActionCreators(actionsToBind, dispatch),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'responsibleGamingForm'
    })
)(ResponsibleGamingForm);
