import {format, fromUnixTime, subYears, subMonths, subDays, compareAsc} from 'date-fns';

const formatDate = (date, pattern) => {
    return format(date, pattern);
};

const formatDateFromUnix = (timestamp, pattern) => {
    return format(fromUnixTime(timestamp), pattern);
};

const substractYears = (date, years)=>{
    return subYears(date, years);
};

const substractMonths = (date, months) => {
    return subMonths(date, months)
};

const getDaysDifference = (fromDate, toDate) => {
    let diffTime = Math.abs(fromDate.getTime() - toDate.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

const substractDays = (date, days)=>{
    return subDays(date, days);
};

const getTimeFilterRange = (filterType) => {
    let dateObject = {};
    let startDate = new Date();
    dateObject['endDate'] = new Date();

    switch(filterType) {
        case 'today':
            dateObject['startDate'] = startDate;
            break;
        case 'yesterday':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 1);
            break;
        case 'week':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 6);
            break;
        case 'month':
            dateObject['startDate'] = startDate.setDate(startDate.getDate() - 29);
            break;
        default: 
            return;
    }
    return dateObject;
}

const getPolishTimePlurality = (number) => {
    const stringNumber = number.toString();
    const stringLength = stringNumber.length;
    let plurality;

    if (stringNumber == '1') {
        plurality = 'singular'
    } else if ((stringNumber.endsWith(2) || stringNumber.endsWith(3) || stringNumber.endsWith(4)) && (stringNumber.charAt(stringLength - 2) !== '1' || stringLength === 1)) {
        plurality = 'from2To4';
    } else {
        plurality = 'plural';
    }
    
    return plurality;
}

  const formatDateCustom = (timestamp, format, allowNumsWithoutZero) => {
    return formatDateAllowNumWithoutZero(new Date(timestamp), format, allowNumsWithoutZero);
  }

//   /**
//    * Produces date string in given format
//    *
//    * Formats:
//    *
//    *  yyyy-MM-dd
//    *  yyyy-MM-dd HH:mm:ss
//    *  yyyy-MM-dd HH:mm
//    *
//    *  dd-MM-yyyy
//    *  dd-MM-yyyy HH:mm:ss
//    *  dd-MM-yyyy HH:mm
//    *
//    *  MM-dd HH:mm
//    *
//    *  HH:mm:ss
//    *  HH:mm
//    *
//    * @param date
//    * @param format
//    */
  const formatDateAllowNumWithoutZero = (date, format, allowNumsWithoutZero) => {

    var minutes = (date.getMinutes() < 10 && !allowNumsWithoutZero ? '0' : '') + date.getMinutes();
    var seconds = (date.getSeconds() < 10 && !allowNumsWithoutZero ? '0' : '') + date.getSeconds();
    var hours = (date.getHours() < 10 && !allowNumsWithoutZero ? '0' : '') + date.getHours();
    var day = (date.getDate() < 10 && !allowNumsWithoutZero ? '0' : '') + date.getDate();
    var month = (date.getMonth() < 9 && !allowNumsWithoutZero ? '0' : '') + (date.getMonth() + 1);
    var year = (1900 + date.getYear());

    var shortDay = app.service.Dict.getShortDays()[date.getDay()];
    var shortMonth = app.service.Dict.getShortMonths()[date.getMonth()];
    var fullDay = app.service.Dict.getDaysNames()[date.getDay()];
    var fullMonth = app.service.Dict.getMonths()[date.getMonth()];

    shortDay = shortDay ? shortDay.name : '';
    shortMonth = shortMonth ? shortMonth.name : '';
    fullDay = fullDay ? fullDay.name : '';
    fullMonth = fullMonth ? fullMonth.name : '';

    format = format.replace('ss', seconds);
    format = format.replace('HH', hours);
    format = format.replace('mm', minutes);
    format = format.replace('dd', day);
    format = format.replace('MM', month);
    format = format.replace('yyyy', year);
    format = format.replace('yy', year.toString().substring(2,4));
    format = format.replace('sd', shortDay);
    format = format.replace('sm', shortMonth);
    format = format.replace('fd', fullDay);
    format = format.replace('fm', fullMonth);

    return format;

  }

export {formatDate, formatDateFromUnix, substractYears, substractMonths, substractDays, getDaysDifference, compareAsc, getTimeFilterRange, getPolishTimePlurality, formatDateCustom}