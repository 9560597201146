import typeToReducer from 'type-to-reducer';
import {LOAD_CMS_PAGE} from 'cmsActions.js';

let initialState = {
    callCmsPage: [],
    isPending: false,
    error: null
};

export default typeToReducer({
    [LOAD_CMS_PAGE]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callCmsPage}}) => {
            const cmsDataTemp = [...state.callCmsPage];
            const isExist = cmsDataTemp.find(cmsPage => cmsPage.name == callCmsPage.name);
            if(!isExist) {
                cmsDataTemp.push(callCmsPage);
            }
            return {...state, isPending: false, callCmsPage: cmsDataTemp};
        }
    },
}, initialState);
