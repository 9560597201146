import styled from 'styled-components';
import {Card, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const PrimaryBtn = ({ theme: { primaryBtn } }) => primaryBtn()
const CalendarContainer = ({ theme: { calendarContainer } }) => calendarContainer()
const DateLabel = ({ theme: { dateLabel } }) => dateLabel()
const DatePicker = ({ theme: { datePicker } }) => datePicker()

const S = {};

S.AccountBetHistoryListView = styled.div`
    background-color: #f2f5f9;
    `;

S.TabsWrapper = styled.div`
    margin: 1rem 0.625rem 0.7rem;
    display: flex;
    padding: 4px 4px;
    background-color: #E0E6ED;
    border-radius: 8px;
    color: #2D375E80;
    text-transform: uppercase;

    & > div:nth-child(1) {
            margin-right: 0.3rem;
    }
    & > div:nth-child(2) {
            margin-left: 0.3rem;
    }

`;

S.Tab = styled(Card)`
    display: flex;
    justify-content: center;
    align-content:center;
    background-color: none;
    padding: 0.4rem;
    color:#2D375E80;
    border-radius: 6px;
    font-size: 0.7rem;
    font-weight: 700;
    cursor: pointer;
    background-color: inherit;
    
        &.active-tab {
        color: #141C4E;
        background-color: #FFF;
        box-shadow: 0px 4px 4px rgba(181, 193, 209, 0.47)
    }
`;

S.BetHistoryFilters = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0.625rem ;

`;
S.BetHistoryFiltersRow = styled.div``;

S.BetHistoryFiltersBtns = styled.div`
    display: flex;
    flex-grow: 0;
`;
S.FilterBtn = styled(Button)`
    ${PrimaryBtn} 
    padding: 0.65rem 0.4rem; margin: 0 0.2rem;
    background-color: ${({ isActive }) => (isActive && '#2D375E80')};
    color: ${({ isActive }) => (isActive && '#fff')};
`;

S.BetHistoryFiltersDates = styled.div`${CalendarContainer}
    padding: 1.3rem 0.75rem 0.3125rem ;
`

S.DateLabel = styled.div`${DateLabel}`

S.Datepicker = styled.div`${DatePicker}
    flex-wrap: wrap;
`

S.BetHistoryTransactionsList = styled.div``;

S.NoResult = styled.div`
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.25rem 0;
    font-size: 1rem;
    color: ${({theme:{$accountProfileBetHistoryTransactionHeaderTxtColor}})=> $accountProfileBetHistoryTransactionHeaderTxtColor};
`;

S.transactionDataHeader = styled.div`
    display: flex;
    flex-direction: column;
    & > div {
       &:last-of-type {
        margin-bottom: 14px;
       }
    }
`;

S.HeaderInfoRegData = styled.div`
    display: flex;
    margin-right: auto;
    flex: 1 1 0;
    padding-bottom: 0.44rem;
    font-weight: 700;
    border-bottom: 2px solid ${({theme:{$accountProfileBetHistoryTransactionHeaderBorder}})=> $accountProfileBetHistoryTransactionHeaderBorder};
`;

S.HeaderInfoDataLabel = styled.div`
    width:100%;
    padding: 1rem 0.5rem 0.2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    font-size: 0.7rem;
    color: #2D375E80;

        & > div:nth-child(1) {
            padding-left: 0.5rem;
    }
        & > div:nth-child(4) {
            padding-left: 10px;
    }
`;

S.transactionDataHeaderInfo = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    align-items: center;
    padding: 0 0.25rem;
    font-family: Open Sans;
    font-weight: 400;
    color: #2D375E80;
`;


S.BetHistoryTransactionsListItem = styled.div`
display: flex;
& > div {
	padding: 1.2rem .67rem;
	border: none;
	font-size: 1rem;
	color: #fff;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	color: #000;
	font-size: 1rem;
	padding: 1.2rem .67rem;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	&.wallet-status {
		width: 13.2995706264%;
		padding-left: 2.7523945833%;
		display: flex;
		align-items: center;
		&.red{
		    & .status-icon{
		        background-image: url(/images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/svg/status_green.svg);
		    }
		}
		
		& .status-icon{
		    margin-left: 1.17rem;
            width: 1.3819275738rem;
            height: 1.3819275738rem;
            background-color: transparent;
            background-position: 0 0;
            background-size: cover;
		}
	}
	&.wallet-type {
		width: 20.1805570847%;
	}
	&.wallet-info {
		width: 20.1805570847%;
	}
	&.wallet-amount {
		width: 15.6005724981%;
		&.green{}
		&.red{}
	}
	&.wallet-date {
		width: 16.9767697897%;
		display: flex;
		justify-content: space-between;
	}
	&.wallet-id {
		flex: 1;
	}
}
`;

S.BetHistoryTransactions = styled.div`
`;

S.ChooseDateRange = styled.div`
    font-size: 0.95rem;
    width: calc(100% - 17.5rem);
    line-height: 1rem;
    font-weight: bold;
    color: ${({ theme: { $accountProfileBetHistoryDatepickerLabelColor } }) => $accountProfileBetHistoryDatepickerLabelColor};
`;


export default S;