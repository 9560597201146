import { translation } from 'utilsHelper.js';

export default {
    REGISTER: {

    },
    EDIT_ACCOUNT: {

    },
    PARAGONEX_AUTHORIZATION: {
        password: {
            length: {
                minimum: 5,
                maximum: 20,
                tooShort: () => translation('paragonex_passwordTooShort'),
                tooLong: () => translation('paragonex_passwordTooLong'),
            },
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('paragonex_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('paragonex_passwordsNotEqual'),
            }
        },
    },
    PARAGONEX_TRANSFER: {
        payoutAmount: {
            presence: {
                message: () => translation('paragonex_payoutAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        },
        depositAmount: {
            presence: {
                message: () => translation('paragonex_depositAmountEmpty'),
            },
            format: {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: () => translation('paragonex_wrongAmountPattern'),
            },
        }
    }
};

