import _size from 'lodash/size';
import {translation} from 'utilsHelper.js';
import {loadCustomerData} from 'customerActions.js';

export const LOAD_OFFERS_CANCELED_ON_WITHDRWAL = 'LOAD_OFFERS_CANCELED_ON_WITHDRWAL';
export const LOAD_OFFERS_BALANCES = 'LOAD_OFFERS_BALANCES';
export const WITHDRAW_OFFER_BALANCE = 'WITHDRAW_OFFER_BALANCE';

// action creators
const offersCanceledOnWithdrawal = () => {
    
    return async(dispatch, getState, {OffersApi}) => {

        dispatch(loadOffersCanceledOnWithdrawalPending());
        try {
            const {code, data} = await OffersApi.getOffersCanceledOnWithdrawal();
            if(code!=200){
                throw {message: translation(`offers_error${code}`)};
            }
            dispatch(loadOffersCanceledOnWithdrawalSuccess(data));

            return data;
        } catch ({message}) {
            dispatch(loadOffersCanceledOnWithdrawalFailure(message));
            throw message;
        }
    }
};

const loadOffersCanceledOnWithdrawalFailure = (error) => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_FAILURE`,
        payload: {error}
    }
};

const loadOffersCanceledOnWithdrawalSuccess = (callOffersCanceledOnWithdrawal) => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_SUCCESS`,
        payload: {callOffersCanceledOnWithdrawal}
    }
};

const loadOffersCanceledOnWithdrawalPending = () => {
    return {
        type: `${LOAD_OFFERS_CANCELED_ON_WITHDRWAL}_PENDING`
    }
};

const loadCustomerOffersBalances = () => {

    return async(dispatch, getState, {OffersApi}) => {
        dispatch(loadOffersBalancesPending());

        try {

            const {code, data} = await OffersApi.getCustomerOfferBalancesWithDetails();
            if(code!=200){
                throw {message: translation(`offers_error${code}`)};
            }
            dispatch(loadOffersBalancesSuccess(data));
            return data;
        }catch({message}){
            dispatch(loadOffersBalancesFailure(message));
            throw message;
        }
    }
};

const loadOffersBalancesSuccess = (offersBalances) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_SUCCESS`,
        payload: {offersBalances}
    }
};

const loadOffersBalancesPending = (data) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_PENDING`,
        payload: {data}
    }
};

const loadOffersBalancesFailure = (data) => {
    return {
        type: `${LOAD_OFFERS_BALANCES}_FAILURE`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalance = (config) => {
    return async(dispatch, getState, {OffersApi}) => {
        dispatch(withdrawCustomerOfferBalancePending());

        try{
            const {code, data} = await OffersApi.withdrawCustomerOfferBalance(config);
            if (code != 200 || (Array.isArray(data) && data.length == 0)) {
                throw {message: translation(`offers_error${code}`)};
            } else {
                dispatch(withdrawCustomerOfferBalanceSuccess(data));
                dispatch(loadCustomerOffersBalances());
               
                // loadCustomerData nie jest obslugiwany na mobile w tej chwili - jak zostanie dodany to odkomentować
                // dispatch(loadCustomerData());

            }
            return data;
        }catch(error){
            dispatch(withdrawCustomerOfferBalanceFailure(error));
            throw error;
        }
    }
};

const withdrawCustomerOfferBalanceSuccess = (data) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_SUCCESS`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalancePending = (data) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_PENDING`,
        payload: {data}
    }
};

const withdrawCustomerOfferBalanceFailure = (error) => {
    return {
        type: `${WITHDRAW_OFFER_BALANCE}_FAILURE`,
        payload: {error}
    }
};

export {offersCanceledOnWithdrawal, loadCustomerOffersBalances, withdrawCustomerOfferBalance};