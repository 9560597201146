import styled from 'styled-components'


const S = {}
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL



S.PayoutCancelContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
flex-wrap:wrap;
width:100%;
padding:35px 0;
font-weight:700;
    & .transaction-amount{
        margin:20px 0 ;
        font-size:30px;

    }
    & .transaction-text{
        text-align:center;
        font-weight:400;
        font-size:13px;
    }
    & .status-info{
        font-size:17px;
        font-weight:700;
        padding:10px ;
    }
    & .status-text{
        font-size:15px;
        font-weight:400;
        padding:10px;
    }
    & .status-icon {
      width: 61px;
      height: 61px;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius:100%;
      
        &.red {
		    background-image: url(${imagePath}images/icon-transation-failure.png);
	    }
	    &.green{
		    background-image: url(${imagePath}images/icon-transation-success.png);
		}
    }
    & button{
            width:80%;
            margin-top:20px;
            padding: 8px 10px;
            font-size:12px;
            color:#fff;
            border-radius:4px;
            border-color:transparent;
            overflow:hidden;
            background-color:#E4202C;
            &.green{
                background-color:#02B253;
                padding:17px;
                border-radius:10px;
            }
            &.blue{
                background-color:#0E62C4;
                padding:17px;
                border-radius:10px;
            }
          }
`

export default S