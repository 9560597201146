import React, {Component} from 'react';
import {connect} from 'react-redux';
import S from 'StyledAccountEditProfile.js';
import _find from 'lodash/find';
import _get from 'lodash/get';

class AccountEditProfile extends Component {

    showChangePasswordModal = () => {
        app.system.render(app.modal.ChangePassword, {onClose: this.hideChangePasswordModal});
    };

    hideChangePasswordModal = () => {
        this.props.dispatch({type: 'TOGGLE_BY_KEY', payload: {key: 'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL', isOpen: false}});
    };

    componentDidUpdate = (prevProps) => {
        if ((this.props.isChangePasswordModalOpen != prevProps.isChangePasswordModalOpen) && this.props.isChangePasswordModalOpen) {
            this.showChangePasswordModal();
        }
    };

    render() {

        return (
            <S.AccountEditProfile>

                <S.AccountEditProfileIframe src={`${process.env.REGISTRATION_URL}/profil`} frameBorder="0"/>

            </S.AccountEditProfile>
        );
    }

};

const mapStateToProps = (state, props) => {
    const {Customer:{error, isPending}, Auth:{user:{userId}}, Toggle:{toggleMap}} = state;
    const toggleChangePasswordModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL'});
    const isChangePasswordModalOpen = _get(toggleChangePasswordModalObject, ['isOpen']);
    return {
        isChangePasswordModalOpen,
        error,
        isPending,
        userId
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditProfile);