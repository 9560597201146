import React, {useEffect, useState} from 'react';
import S from 'StyledAccountFreebet.js';
import Loader from 'Loader.js';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation} from 'utilsHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Pagination from 'Pagination.js';
import {formatDate} from 'datesHelper.js';
import {connect} from 'react-redux';
import {
    changeDateByType,
    fetchFreebetsByFilter,
    setCurrentPage,
    fetchAllFreebets,
    changeActiveTab,
    resetToInitialState
} from 'freebetActions.js';

const AccountFreebet = (props) => {
    const {isPending, activeTab, startDate, endDate, freebets, limit, currencyCode} = props;
    const activeFreebets = _get(freebets, 'active');
    const historyFreebets = _get(freebets, 'history');
    const activeFreebetsData = _get(activeFreebets, 'data');
    const historyFreebetsData = _get(historyFreebets, 'data');

    const [calendarStartDate, setCalendarStartDate] = useState(formatDate(new Date, 'yyyy-MM-dd'));
    const [calendarEndDate, setCalendarEndDate] = useState(formatDate(new Date, 'yyyy-MM-dd'));

    const dispatch = useDispatch();

    useEffect(() => {
        const datePickers = document.getElementsByClassName("react-datepicker__input-container");
        Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

        getAllFreebets();

        return () => {
            const {resetToInitialState} = props;
            resetToInitialState();
        }
    }, []);

    const getAllFreebets = async() => {
        try {
            await dispatch(fetchAllFreebets());
    
        } catch (error) {
            console.log('fetchFreebetByFilter:' + error);
        }
    };

    const onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchFreebetsByFilter, startDate, endDate, limit} = props;
 
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = activeTab.toUpperCase();

        changeActiveTab(activeTab);
        fetchFreebetsByFilter(params);
    };

    const handleChangeStart = (date) => {
 
        const {changeDateByType, endDate, limit, fetchFreebetsByFilter} = props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = 'HISTORY';

        setCalendarStartDate(date);
        changeDateByType('startDate', startDate);
        fetchFreebetsByFilter(params);
    };

    const handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchFreebetsByFilter} = props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = 'HISTORY';

        setCalendarEndDate(date);
        changeDateByType('endDate', endDate);
        fetchFreebetsByFilter(params);
    };

    const goPage = async (status, currentPage) => {
        const {fetchFreebetsByFilter, startDate, endDate, limit, setCurrentPage} = props;
        const offset = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            status,
            currentPage: offset
        };

        try {
            await fetchFreebetsByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };
    
    return (
        <S.AccountFreebet>
            <S.FreebetFilters className="freebet-filters">

                <S.FreebetFiltersBtns>
                    <S.FilterBtn
                        isActive={(activeTab == 'active')}
                        onClick={onChangeFilter.bind(this, 'active')}>
                        {translation('account_freebets_activeFreebets')}
                    </S.FilterBtn>

                    <S.FilterBtn
                        isActive={(activeTab == 'history')}
                        onClick={onChangeFilter.bind(this, 'history')}>
                        {translation('account_freebets_historyFreebets')}
                    </S.FilterBtn>
                </S.FreebetFiltersBtns>
                {activeTab == 'history' && (
                    <S.FreebetFiltersDates className="freebet-filters-dates">
                        <S.Datepicker>
                        <div className="select-date"> {translation('account_wallet_selectDateFrom')}</div>
                            <div className="icon"></div>
                            <input type="date"
                                value={calendarStartDate}
                                onChange={(event) => handleChangeStart(event.target.value)}
                            />
                        </S.Datepicker>
                        <S.Datepicker>
                        <div className="select-date"> {translation('account_wallet_selectDateTo')}</div>
                            <div className="icon"></div>
                            <input type="date"
                                value={calendarEndDate}
                                onChange={(event) => handleChangeEnd(event.target.value)}
                            />
                        </S.Datepicker>
                    </S.FreebetFiltersDates>
                )
                }

            </S.FreebetFilters>

        <S.FreebetList>

            {isPending ?
                <Loader/>
                : (
                    <>
                             {activeTab === 'active' && (
                                    <S.FreebetActiveList className="freebet-active-list">
                                       
                                                {activeFreebets ?
                                                    (<>
                                                        {_map(activeFreebetsData, ({validTo, offerName, offerPlayerInfo, offerId, freebetStake, nameKey}) => {
                                                            offerPlayerInfo = offerPlayerInfo ?? {};
                                                            const {imageUrl = null, rulesPageUrl = null} = offerPlayerInfo;
                                                           return (
                                                               <S.FreebetRow key={offerId}>
                                                                   <S.FreebetHeader data-translation={nameKey} className="freebetName">{translation(nameKey)}</S.FreebetHeader>
                                                                   <S.FreebetBody>
                                                                       <S.FreebetImageWrapper ratio={2}>
                                                                            {imageUrl && <S.BonusOffrImg src={imageUrl}></S.BonusOffrImg>}
                                                                        </S.FreebetImageWrapper>
                                                                        <S.FreebetContent>
                                                                           <S.FreebetBox>
                                                                               <S.FreebetTitle>{translation('account_freebet_offerId')}</S.FreebetTitle>
                                                                               <S.FreebetValue>{offerId}</S.FreebetValue>
                                                                           </S.FreebetBox>
                                                                           <S.Divider />
                                                                           <S.FreebetBox>
                                                                               <S.FreebetTitle>{translation('account_freebet_freebetAmount')}</S.FreebetTitle>
                                                                               <S.FreebetValue>
                                                                                   <span className="amount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                                                   <span className="currency">{currencyCode}</span>
                                                                               </S.FreebetValue>
                                                                           </S.FreebetBox>
                                                                           <S.Divider />
                                                                           <S.FreebetBox>
                                                                               <S.FreebetTitle>{translation('account_freebet_expirationDate')}</S.FreebetTitle>
                                                                               <S.FreebetValue>{formatDate(validTo, 'yyyy.MM.dd HH:mm')}</S.FreebetValue>
                                                                           </S.FreebetBox>
                                                                       </S.FreebetContent>
                                                                   </S.FreebetBody>

                                                                   {rulesPageUrl &&
                                                                       <S.FreebetFooter>
                                                                           <S.FreebetRegulationLink href={rulesPageUrl}>
                                                                               {translation('account_freebet_regulationLink')}
                                                                           </S.FreebetRegulationLink>
                                                                       </S.FreebetFooter>
                                                                   }

                                                               </S.FreebetRow>
                                                            )
                                                        })}

                                                        {_size(activeFreebetsData) > 0 && (
                                                            <Pagination
                                                                currentPage={_get(activeFreebets, 'currentPage')}
                                                                limit={limit} data={activeFreebetsData}
                                                                cb={goPage.bind(this, 'active')}
                                                            />
                                                        )}
                                                    </>)
                                                    :
                                                    <S.NoResult>{translation('account_freebets_noActiveFreebets')}</S.NoResult>
                                                }
                                          

                                    </S.FreebetActiveList>
                                )}
                                  {activeTab === 'history' && (
                                    <S.FreebetHistoryList className="freebet-history-list">
                
                                                {_size(historyFreebetsData) ?
                                                    (<>
                                                        {_map(historyFreebetsData, ({validTo,  offerPlayerInfo, offerName, offerId, freebetStake, nameKey}) => {
                                                             offerPlayerInfo = offerPlayerInfo ?? {};
                                                             const {imageUrl = null, rulesPageUrl = null} = offerPlayerInfo;
                                                            return (
                                                                <S.FreebetRow key={offerId}>
                                                                <S.FreebetHeader data-translation={nameKey} className="freebetName">{translation(nameKey)}</S.FreebetHeader>
                                                                <S.FreebetBody>
                                                                    <S.FreebetImageWrapper ratio={2}>
                                                                        {imageUrl && <S.BonusOffrImg src={imageUrl}></S.BonusOffrImg>}
                                                                    </S.FreebetImageWrapper>
                                                                <S.FreebetContent>
                                                                <S.FreebetBox>
                                                                       <S.FreebetTitle>{translation('account_freebet_offerId')}</S.FreebetTitle>
                                                                       <S.FreebetValue>{offerId}</S.FreebetValue>
                                                                   </S.FreebetBox>
                                                                        <S.Divider />
                                                                   <S.FreebetBox>
                                                                       <S.FreebetTitle>{translation('account_freebet_freebetAmount')}</S.FreebetTitle>
                                                                       <S.FreebetValue>
                                                                           <span className="amount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                                           <span className="currency">{currencyCode}</span>
                                                                       </S.FreebetValue>
                                                                   </S.FreebetBox>
                                                                        <S.Divider />
                                                                   <S.FreebetBox>
                                                                       <S.FreebetTitle>{translation('account_freebet_expirationDate')}</S.FreebetTitle>
                                                                       <S.FreebetValue>{formatDate(validTo, 'yyyy.MM.dd HH:mm')}</S.FreebetValue>
                                                                   </S.FreebetBox>
                                                                </S.FreebetContent>
                                                               </S.FreebetBody>
                                                              
                                                                    {rulesPageUrl &&
                                                                        <S.FreebetFooter>
                                                                            <S.FreebetRegulationLink href={rulesPageUrl}>
                                                                                {translation('account_freebet_regulationLink')}
                                                                            </S.FreebetRegulationLink>
                                                                        </S.FreebetFooter>
                                                                    }
                                                       
                                                            </S.FreebetRow>
                                                            )
                                                        })}

                                                        {_size(historyFreebetsData) > 0 && (
                                                            <Pagination
                                                                currentPage={_get(historyFreebets, 'currentPage')}
                                                                limit={limit} data={historyFreebetsData}
                                                                cb={goPage.bind(this, 'history')}/>)}
                                                    </>)

                                                    :
                                                    <S.NoResult>{translation('account_freebets_noHistoryFreebets')}</S.NoResult>
                                                }

                        
                                    </S.FreebetHistoryList>
                                )}
                        
                    </>
                )
            }

        </S.FreebetList>

    </S.AccountFreebet>
    );
};

const mapStateToProps = ({AccountFreebet: {isPending, error, startDate, endDate, freebets, limit, activeTab}, Auth: {user: {currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        freebets,
        limit,
        currencyCode,
        activeTab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchFreebetsByFilter: bindActionCreators(fetchFreebetsByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchAllFreebets: bindActionCreators(fetchAllFreebets, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        resetToInitialState: bindActionCreators(resetToInitialState, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFreebet);
