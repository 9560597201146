import {formatDate, formatDateFromUnix} from 'helpers/datesHelper.js';
import {translation} from 'utilsHelper.js'
import betSlipConstants from 'betSlipConstants.enum.js';
import betSlipTypes from 'betSlipTypes.enum.js';
import _reject from 'lodash/reject';
import _find from "lodash/find";
import _flatMap from "lodash/flatMap";

const createBlocksForTransactions = (transactions = []) => {
    
    for (let transaction of transactions) {

        var blockLetter = 0;
        var blocks = {};

        if (transaction.transactionDetails) {

            for (const [index, transactionDetail] of transaction.transactionDetails.entries()) {


                if (transactionDetail.blockId == null) {
                    if (transaction.slipType != 0 && transaction.slipType != 100) {
                      if(transactionDetail.banker && transactionDetail.outcomeId != -1){
                        // bankers should be treated as separate block, so we need to put all bankers with the same blockId
                        transactionDetail.blockId = betSlipConstants.BANKER_BLOCK_ID;
                      } else{
                        transactionDetail.blockId = transactionDetail.outcomeId;
                      }
                    } else {
                        transactionDetail.blockId = 'AccumulatorBlock';
                    }
                }

                transactionDetail.__index = index;

                if (!blocks[transactionDetail.blockId]) {
                    blocks[transactionDetail.blockId] = [];
                    if (transactionDetail.blockId != 0 && (transactionDetail.eventId || transactionDetail.remoteId) != -1) {
                        if (transactionDetail.banker) {
                            blocks[transactionDetail.blockId].blockName = 'P';
                        } else {
                            blockLetter++;
                            blocks[transactionDetail.blockId].blockName = String.fromCharCode(blockLetter + 64);
                        }
                    }
                }

                blocks[transactionDetail.blockId].push(transactionDetail);

            }

        }

        transaction.blocks = blocks;

        if (Object.keys(transaction.blocks).length > 1) {
            createCombinationsForBlocks(transaction);
        }
    }
    return transactions;
};



const createCombinationsForBlocks = (transaction) => {
    var letterBlocksMap = {};
    for (var i in transaction.blocks) {
      letterBlocksMap[i] = transaction.blocks[i].blockName;
    }

    if (transaction.slipCombinations) {
        for (let combination of transaction.slipCombinations.combinations) {
            combination.combinationLetters = {};
            combination.combinationLettersText = '';
            combination.combinationLetterStatus = {};
            for (let combinationOutcomeId of combination.outcomeIds) {
                const flatMap = _flatMap(transaction.blocks, b => b);
                const block = _find(flatMap, (o) => {
                  return o.blockId == combinationOutcomeId || o.outcomeId == combinationOutcomeId;
                });

                combination.combinationLetters[combinationOutcomeId] = letterBlocksMap[block.blockId];
                combination.combinationLettersText += letterBlocksMap[block.blockId];

                let transactionStatusCode = transaction.transactionDetails.find(transaction => transaction.outcomeId == combinationOutcomeId)?.statusCode;
                // means it is a block outcome
                if(!transactionStatusCode) {
                    const outcomesForBlock = transaction.transactionDetails.filter(transaction => transaction.blockId == combinationOutcomeId);
                    
                    for(let outcome of outcomesForBlock) {
                        // means it is lost at least one outcome in block, so the whole block is lost
                        if(outcome.statusCode == 3) {
                            transactionStatusCode = outcome.statusCode;
                        }
                    }
                }

                combination.combinationLetterStatus[letterBlocksMap[block.blockId]] = transactionStatusCode;
            }
        }
    } 
};

const groupTransactionsByData = (array, key) => {

    return array.reduce((result, currentValue) => {

        if(currentValue.transactionType === 3943 || currentValue.transactionType === 3948 ){
            return result;
        }

        let keySelection = currentValue[key];

        (result[formatDate(keySelection,'dd-MM-yyyy')] = result[formatDate(keySelection,'dd-MM-yyyy')] || []).push(
          currentValue
        );

        return result;
    }, {}); 
};

const getPolishTransactionType = ({slipType, transactionType, cashBackReturn, cashBackId, transactionDetails}) => {

    if (slipType == null) {
        return translation('betSlip_integrationType_' + Number(transactionType));
    }

    if (cashBackReturn && cashBackId) {
        return translation('betSlip_cashBackType_' + Number(cashBackId));
    }

    if (slipType == 0) {
        return translation('betSlip_type_ACCUMULATOR');
    } else if (slipType == 100) {
        return translation('betSlip_type_SINGLE');
    }

    const allValidTransactions = _reject(transactionDetails, {outcomeId: -1});
    let bankerCount = 0;
    let allCount = allValidTransactions.length;
    const betSlipType = translation('betSlip_type_combination');
    const blocksCount = [];

    if ([0, 100].indexOf(slipType) == -1) {
        for (let i = 0; i < allValidTransactions.length; i++) {
            if (allValidTransactions[i].blockId != 0 && allValidTransactions[i].blockId != null 
              && allValidTransactions[i].blockId != betSlipConstants.BANKER_BLOCK_ID) {
                allCount--;
                if (blocksCount.indexOf(allValidTransactions[i].blockId) == -1) {
                    blocksCount.push(allValidTransactions[i].blockId);
                }
            }
            if (allValidTransactions[i].banker && allValidTransactions[i].eventId != -1) {
                bankerCount++;
            }
        }
    }
    
    let systemAccumulator = '';
    let typeName = slipType;

    if(typeName >= betSlipTypes.SYSTEM_BET_PLUS_ACCUMULATOR.type && typeName < betSlipTypes.SYSTEM_BET_AND_UP_OFFSET.type){
      typeName = typeName == betSlipTypes.SYSTEM_BET_PLUS_ACCUMULATOR.type ? 1 : typeName - betSlipTypes.SYSTEM_BET_PLUS_ACCUMULATOR.type;
      systemAccumulator = ` + ${translation('betSlip_system_accumulator_symbol')}`;
    } else if(typeName >= betSlipTypes.SYSTEM_BET_AND_UP_OFFSET.type){
      typeName = typeName - betSlipTypes.SYSTEM_BET_AND_UP_OFFSET.type;
    }

    let slipTypeName = betSlipType.replace('{0}', typeName).replace('{1}', allCount + blocksCount.length - bankerCount) + systemAccumulator;

    if (bankerCount > 0) {
      slipTypeName += " +" + bankerCount + translation('betSlip_banker_symbol');
    }

    return slipTypeName;
};

/**
 * @params betIsWonIfConfirmedReturnIsGtZero - config for partially cashed betslip when confirmedReturn > 0 = won
 * @params stake - how much stake customer bet
 * @params confirmedReturn - returned cash
 */
 const getTransactionResultStatusText = (betIsWonIfConfirmedReturnIsGtZero, stake, confirmedReturn) => {
    let statusText = ''
    if(betIsWonIfConfirmedReturnIsGtZero) {
      if (confirmedReturn > 0 ) {
        statusText = 'won';
      } else {
        statusText = 'lost';
      }
  } else {
      if (confirmedReturn >= stake) {
        statusText = 'won';
      } else {
        statusText = 'lost';
      }
    }
    return statusText
}

export {createBlocksForTransactions, groupTransactionsByData, getPolishTransactionType, getTransactionResultStatusText}