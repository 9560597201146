
const encode = encodeURIComponent;

export default (requests) => {
    return {
        getPaymentProviders: (currencyCode) => {
            if (currencyCode) {
                return requests.get('/payment/providers/active/' + currencyCode);
            } else {
                return requests.get('/payment/providers/active');
            }
        },

        getCustomerCreditCardList: (providerId) => {
            return requests.get('/payment/customerCreditCardList/' + providerId);
        },

        getPaystackBankList: (providerId) => {
            return requests.get('/payment/payStack/bankList/' + providerId);
        },

        getBankList: () => {
            return requests.get('/payment/banks');
        },

        getAccountTypes: () => {
            return requests.get('/payment/bank/accountTypes');
        },

        getCustomerBankDetail: () => {
            return requests.get('/payment/customerBankDetail');
        },

        getCustomerBanksDetailList: () => {
            return requests.get(`/payment/customerBanksDetail`);
        },

        getBillers: () => {
            const config = {};
            config.baseURL =  process.env.INTEGRATION_URL;
            return requests.get('/payment-api/rest/payment/public/quickteller/bill/v1/api/billers', config);
        },

        sendPaymentData: (data, step) => {
            return requests.post(`/payment/payment/` + step, data);
        },
        
        changePinCode: (data) => {
            return requests.put(`/payment/user/security/setNewPinCode`, data);
        },

        getWonAmountToPayout: (data) => {
            return requests.post(`/payment/user/limit/wonAmountToPayout` , data);
        },

        customerCreditCardUpdate: (card) => {
            return requests.post(`payment/creditCard/update`, card);
        },  
    }
};
