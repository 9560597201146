import typeToReducer from 'type-to-reducer';

import {USER_LOGOUT, USER_SIGNIN, BRANDS_DATA, TOGGLE_PROLONG_SESSION_MODAL, UPDATE_USER_BALANCE} from 'authActions.js';

let initialState = {
    isPending: false,
    isLogged: false,
    error: null,
    user: null,
    brandData: null,
    isOpenProlongSessionModal: null
};

export default typeToReducer({
    [USER_LOGOUT]: (state, action) => {
        return {...state, isLogged: false, user: null, isOpenProlongSessionModal: null}
    },

    [USER_SIGNIN]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {user}}) => {
            return {...state, isLogged: true, isPending: false, user};
        }
    },

    [BRANDS_DATA] : {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {brandData}}) => {
            return {...state, isPending: false, brandData};
        }
    },

    [TOGGLE_PROLONG_SESSION_MODAL]: (state, {payload: {isOpenProlongSessionModal}}) => {
        return {...state, isOpenProlongSessionModal};
    },

    [UPDATE_USER_BALANCE]: (state, {payload:{data:{newBalance, bonusBalanceList = [], creditBonusBalance}}}) => {
        let user = update(state.user, {balance: {$set: newBalance}});

        if (_size(bonusBalanceList) && ('bonusBalanceList' in user) && _size(user['bonusBalanceList'])) {
            _each(bonusBalanceList, ({wagerBonusId, amount}) => {
                wagerBonusId = Number(wagerBonusId);
                const indexOfItem = _findIndex(user.bonusBalanceList, {wagerBonusId});
                if (indexOfItem != -1) {
                    user = update(user, {bonusBalanceList: {[indexOfItem]: {amount: {$set: amount}}}})
                }
            });

        }

        if(creditBonusBalance){
          user = update(user, {creditBonusBalance: {$set: creditBonusBalance}});
        }
        return {...state, user}
    },

}, initialState)