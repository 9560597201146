import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import S from 'StyledAccountMenu.js';
import _map from 'lodash/map';
import {translation, redirect} from 'helpers/utilsHelper';
import classNames from 'classnames';


const AccountMenu = ({logOut}) => {

    const logOutUserBtnAction = () => {
        redirect('/');
        logOut();
        app.component.TopMenu.updateUserStatus();
    };


    const onUserDataLinkClick = (event, url) => {
        event.preventDefault();
        redirect(url);
    };

   
    return (
        <S.AccountMenu className="account-menu">
            
        </S.AccountMenu>
    );

};

export default withRouter(AccountMenu);
