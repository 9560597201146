import React, {useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import S from 'StyledVirtualBetHistoryItemDetails.js';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {translation, decimal} from 'helpers/utilsHelper.js'; 
import {formatDateFromUnix} from 'helpers/datesHelper.js';
import { getPathTransaction } from 'transactionsSelectors.js';
import betSlipTypes from 'betSlipTypes.enum.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addOutcome, clearBetslip, changeSlipStake } from 'betSlipActions.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";
import _find from "lodash/find";

const VirtualBetHistoryItemDetails = ({transaction, history, source = "history", location: {}}) => {

    const currencyCode = process.env.MAIN_CURRENCY_CODE;
    const { transactionId, stake, possibleReturn, regDate, transactionDetails, tax, confirmedReturn, slipCombinations, statusCode, slipType, totalOdds, salesTaxOdds} = transaction;

    const [sortedBlocks, setSortedBlocks] = useState({});
    const [taxFactor, setTaxFactor] = useState(0);

    useEffect(() => {

        let blockInfo = prepareBlocks();
        setSortedBlocks(blockInfo);
        app.controller.BetSlipHistory.selector.contentHeader().hide();
    }, []);


    const getTransactionDetailsStatus = (statusCode) => {
        let resultText = '';

        switch (statusCode) {
            case 1:
                resultText = 'lost';
                break;
            case 2:
                resultText = 'cancel';
                break;
            case 3:
            case 4:
                resultText = 'won';
                break;
        }

        return resultText;
    };

    const getTransactionStatus = (statusCode) => {
        let resultText = '';
        
        switch (statusCode) {
            case 1:
                resultText = 'placed';
                break;
            case 2:
                resultText = 'lost';
                break;
            case 3:
            case 4:
                resultText = 'won';
                break;
            case 5:
                resultText = 'cancel';
                break;
            case 6:
                resultText = 'pending_acceptation';
                break;
            case 7:
                resultText = 'rejected';
                break;
          }

        return resultText;
    };

    const getTransactionType = useMemo(() => {

        let slipTypeValue = slipType;
        if (slipTypeValue == null) {
            slipTypeValue = translation('betSlip_integrationType_' + Number(transactionType));
            return slipTypeValue;
        }

        let bankerCount = 0;
        let allCount = transactionDetails.length;
        const blocksCount = [];

        if ([0, 100].indexOf(slipType) == -1) {
            for (let i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].blockId != 0 && transactionDetails[i].blockId != null) {
                    allCount--;
                    if (blocksCount.indexOf(transactionDetails[i].blockId)== -1) {
                        blocksCount.push(transactionDetails[i].blockId);
                    }
                }
                if (transactionDetails[i].banker && transactionDetails[i].remoteId != -1) {
                    bankerCount++;
                }
            }
        }

        const betSlipType = translation('betSlip_type_combination');
        let typeName = slipType;
        let multi = '';
        if (typeName == 1) {
            typeName = translation('betSlip_type_SIMPLE');
        } else if (typeName == 2) {
            typeName = translation('betSlip_type_SYSTEM');
        } else {
            multi = `${translation('betSlip_type_MULTI')} `;
        }

        slipTypeValue = multi + betSlipType.replace('{0}', typeName).replace('{1}', allCount + blocksCount.length - bankerCount);

        return slipTypeValue;
    }, []);

    const checkFactor = (block) => {
        const taxFactorOutcome = _find(block, {remoteId: -1});
        taxFactorOutcome ? setTaxFactor(taxFactorOutcome.odds) : setTaxFactor(0.88);
    }

    const prepareBlocks = () => {
        let pureBlocksObject = {}
        let blocks = _get(transaction, 'blocks')
        for (const id in blocks) {    
            checkFactor(blocks[id]);

            if(id != -1){
                pureBlocksObject[blocks[id].blockName] = blocks[id]
            }
        }
        const sortedData = {};
        Object.keys(pureBlocksObject).sort().forEach(function(key) {
            sortedData[key] = pureBlocksObject[key];
        });
        return sortedData;
    };

    const {goBack} = history;

    const getTransactionResult = useMemo(() => {

        const transactionStatus = getTransactionStatus(statusCode);
        let translationKey = null;

        if (transactionStatus == 'won') {
            translationKey = translation('transactionDetails_betSlipWon');
        } else if (transactionStatus == 'cashout') {
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            translationKey = translation('transactionDetails_betSlipCancel');
        } else  {
            translationKey = translation('transactionDetails_betSlipPlaced');
        }

        return translationKey;

    }, [statusCode]);

    return (
        <>
            <S.BetHistoryItemHeader>
                <div className="back" onClick={goBack}></div>
                <div className="header-title">{translation('account_betHistoryTransactionItem_headerTitle')}</div>

            </S.BetHistoryItemHeader>
            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">
                <S.BetHistoryDetailsHeader className="bet-history-item-details-header">
                    <S.BetHistoryDetailsHeaderInfoId>{`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transactionId}`}</S.BetHistoryDetailsHeaderInfoId>
                </S.BetHistoryDetailsHeader>
                {_map(sortedBlocks, (blocks) => {
                    return (!_isEmpty(blocks) && (
                        <React.Fragment key={blocks[0].remoteId}>
                            {Object.keys(transaction.blocks).length !== 1 && (
                                <S.BetHistoryBlockHeader className={blocks.blockName == "P"? "banker" : "block"}>{blocks.blockName == "P"? translation('account_betHistoryTransactionItem_detailsBanker') : `${translation('account_betHistoryTransactionItem_detailsBlock')} ${blocks.blockName}`}</S.BetHistoryBlockHeader>
                            )}
                            <S.BetHistoryItemDetails className="bet-history-item-details">
    
                                {_map(blocks, ({ remoteId, eventStart, eventName, gameName, outcomeName, outcomeResult, odds, statusCode }) => {
                                    const statusResult = getTransactionDetailsStatus(statusCode);
                                    return (remoteId != -1) && (
                                         
                                        <S.BetHistoryDetailsBody key={remoteId}>
                                            <S.BetHistorySingleItemHeaderInfo>
                                                <div className="event-name-info">
                                                    <div>{eventName}</div>
                                                </div>
                                                <div className="event-time-info">
                                                    <div>{formatDateFromUnix(eventStart, 'dd-MM-yyyy')}</div>
                                                    <div>{formatDateFromUnix(eventStart, 'HH:mm')}</div>
                                                </div>
                                            </S.BetHistorySingleItemHeaderInfo>
                                            <S.BetHistorySingleItemBetInfo>
                                                <div className="event-infos">
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_betType')}</div>
                                                        <div>{`${outcomeName} [${gameName}]`}</div>
                                                    </div>
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_outcomeOdds')}</div>
                                                        <div>{odds}</div>
                                                    </div>
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_result')}</div>
                                                        <div>{outcomeResult}</div>
                                                    </div>
                                                </div>
                                                <div className="event-status">
                                                    <S.StatusIcon className={statusResult}></S.StatusIcon>
                                                </div>
                                            </S.BetHistorySingleItemBetInfo>
    
                                        </S.BetHistoryDetailsBody>
                                        
                                    )     
                                })}
                            </S.BetHistoryItemDetails>
                        </React.Fragment>
                    ));
    
                })}
                <S.BetHistoryItemsFooter>
                    <S.BetHistoryItemsFooterDetailsContainer>
                        <S.BetHistoryItemsFooterLabels>
                            <div>{translation('account_betHistoryTransactionItem_status')}</div>
                            <div>{translation('account_betHistoryTransactionItem_betSlipType')}</div>
                            <div>{translation('account_betHistoryTransactionItem_betSlipDate')}</div>
                            <div>{translation('account_betHistoryTransactionItem_taxFactor')}</div>
                            <div>{translation('account_betHistoryTransactionItem_totalOdds')}</div>
                            <div>{translation('account_betHistoryTransactionItem_stake')}</div>
                            <div>{translation('account_betHistoryTransactionItem_taxValue')}</div>
                        </S.BetHistoryItemsFooterLabels>
                        <S.BetHistoryItemsFooterValues>
                            <div>{getTransactionResult}</div>
                            <div>{getTransactionType}</div>
                            <div>{`${formatDateFromUnix(regDate, 'dd-MM-yyyy')} ${formatDateFromUnix(regDate, 'HH:mm')}`}</div>
                            <div>{salesTaxOdds}</div>
                            <div>{totalOdds}</div>
                            <div>{`${stake.toFixed(2)} ${currencyCode}`}</div>
                            <div>{`${tax.toFixed(2)} ${currencyCode}`}</div>
                        </S.BetHistoryItemsFooterValues>
                    </S.BetHistoryItemsFooterDetailsContainer>
                </S.BetHistoryItemsFooter>
                <S.BetHistoryDetailsSummary>
                    <div className="footer-logo"></div>
                        <S.PossibleWinWrapper>
                        {
                            statusCode == 1 ? (
                                <>
                                    <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWinLabel')}</S.PossibleWinLabel>
                                    <S.PossibleWinValue>{`${possibleReturn.max} ${currencyCode}`}</S.PossibleWinValue>
                                </>
                                
                            ) : (
                                <>
                                    <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_wonAmount')}</S.PossibleWinLabel>
                                    <S.PossibleWinValue>{`${confirmedReturn.toFixed(2)} ${currencyCode}`}</S.PossibleWinValue>
                                </>
                            )
                        }
                        </S.PossibleWinWrapper>
                </S.BetHistoryDetailsSummary>
            </S.BetHistoryDetailsWrapper>
        </>
    );
};

const mapStateToProps = ({AccountBetHistory:{virtualBetHistory, cashouts}, AccountFreebet:{freebets:{history:{data: historyFreebets}}}}, {match: {params}}) => {
    return {
        transaction: getPathTransaction(virtualBetHistory, params),
        cashout: cashouts[params.transactionId],
        historyFreebets
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearBetslip: bindActionCreators(clearBetslip, dispatch),
        addOutcome: bindActionCreators(addOutcome, dispatch),
        changeSlipStake: bindActionCreators(changeSlipStake, dispatch),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VirtualBetHistoryItemDetails));

