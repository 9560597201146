import typeToReducer from "type-to-reducer";

import {
    LOAD_VIRTUAL_GAME
} from "integrationActions.js";

let initialState = {
    gameUrl: null,
    error: null,
    isPending: true,
    iFrameHeight: null
};


export default typeToReducer({
    [LOAD_VIRTUAL_GAME]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {gameUrl}}) => {
            return {...state, isPending: false, gameUrl};
        }

    },
}, initialState);

