import typeToReducer from 'type-to-reducer';
import { CHANGE_DATE_BY_TYPE, CHANGE_ACTIVE_TAB, FETCH_TRANSACTIONS_LIST, SET_CURRENT_PAGE, RESET_TO_INITIAL_STATE } from 'accountWalletActions.js';

let initialState = {
  activeTab: 'all',
  startDate: new Date(),
  endDate: new Date(),
  currentPage: 0,
  limit: 10,
  isPending: true,
  error: null,
  prevData: [],
  data: [],
  nextData: [],
};

const accountWalletInitialState = { ...initialState };

export default typeToReducer(
  {
    [CHANGE_DATE_BY_TYPE]: (state, { payload: { dateType, date } }) => {
      return { ...state, currentPage: 0, [dateType]: date };
    },
    [CHANGE_ACTIVE_TAB]: (state, { payload: { activeTab } }) => {
      return { ...state, currentPage: 0, activeTab };
    },
    [SET_CURRENT_PAGE]: (state, { payload: { currentPage } }) => {
      return { ...state, currentPage };
    },
    [FETCH_TRANSACTIONS_LIST]: {
      PENDING: (state, action) => {
        return { ...state, isPending: true };
      },
      FAILURE: (state, { payload: { error } }) => {
        return { ...state, isPending: false, error };
      },
      SUCCESS: (state, { payload: { prevData, data, nextData } }) => {
        return { ...state, isPending: false, prevData, data, nextData };
      },
    },
    [RESET_TO_INITIAL_STATE]: (state) => {
      return accountWalletInitialState;
    },
  },
  initialState
);
