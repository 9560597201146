import typeToReducer from "type-to-reducer";

import {
    LOAD_VIRTUAL_GAMES_LIST
} from "integrationActions.js";

let initialState = {
    gamesList: [],
    error: null,
    isPending: true
};


export default typeToReducer({
    [LOAD_VIRTUAL_GAMES_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {gamesList}}) => {
            return {...state, isPending: false, gamesList};
        }
    },
}, initialState);

