import typeToReducer from "type-to-reducer";
import update from 'immutability-helper';

import {
    LOAD_EVENT_MULTI_CATEGORIES,
    LOAD_CATEGORIES,
	LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID,
	LOAD_GAME_TYPE_GROUPS_FOR_SPORT,
	
} from 'marketActions';

let initialState = {
    isPendingEventMultiCategories: false,
    errorOnEventMultiCategories: null, 
    eventMultiCategories: [],
    isPendingCategories: false,
    errorOnCategories: null,
    categories: [],
	categoriesByMarketGroupTypeId: {},
	marketGroupTypes: []
};

export default typeToReducer({

    [LOAD_EVENT_MULTI_CATEGORIES] : {
        PENDING: (state, action) => {
            return {...state, isPendingEventMultiCategories: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, errorOnEventMultiCategories: error, isPendingEventMultiCategories: false};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPendingEventMultiCategories: false, eventMultiCategories: data};
        }
    },

    [LOAD_CATEGORIES] : {
        PENDING: (state, actions) => {
            return {...state, isPendingCategories: true }
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, errorOnCategories: error, isPendingCategories: false};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPendingCategories: false, categories: data};
        }
    },
	
	[LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {groupTypeId, categories}}) => {
            return update(state, {
                categoriesByMarketGroupTypeId: {
                    [groupTypeId]: { $set: categories}
                },
                isPending: {$set: false}
            });
        }
    },
	
	[LOAD_GAME_TYPE_GROUPS_FOR_SPORT]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {marketGroupTypes}}) => {
            return {...state, isPending: false, marketGroupTypes};
        }
    }

}, initialState); 

