import React, {useEffect, useState} from 'react';
import S from 'StyledAccountBonus.js';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {NavLink, withRouter} from 'react-router-dom';
import {translation} from 'helpers/utilsHelper.js';
import AccountBonusRoute from 'AccountBonusRoute.js';
import {formatDate} from 'datesHelper.js';
import {changeDateByType, changeActiveTab, fetchCreditBonusListByFilter} from 'accountBonusActions.js';


const AccountBonus = (props) => {
    const {match, startDate, endDate, changeActiveTab, activeTab} = props;
    const [calendarStartDate, setCalendarStartDate] = useState(formatDate(new Date, 'yyyy-MM-dd'));
    const [calendarEndDate, setCalendarEndDate] = useState(formatDate(new Date, 'yyyy-MM-dd'));

    useEffect(() => {
        const datePickers = document.getElementsByClassName("react-datepicker__input-container");
        Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    }, []);

    const handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, fetchCreditBonusListByFilter, activeTab} = props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        setCalendarStartDate(date);
        changeDateByType('startDate', startDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else if(activeTab == 'current') {
            params['status'] = 'ACTIVE';
        } else{
            params['status'] = 'QUEUE';
        }

        fetchCreditBonusListByFilter(params);
    };

    const handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchCreditBonusListByFilter, activeTab} = props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        setCalendarEndDate(date);
        changeDateByType('endDate', endDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else if(activeTab == 'current') {
            params['status'] = 'ACTIVE';
        } else{
            params['status'] = 'QUEUE';
        }

        fetchCreditBonusListByFilter(params);
    };

    return (
        <S.AccountBonus>
        <S.BonusFilters className="bonus-filters">
            { activeTab !== "active" && (
                <S.BonusFiltersDates className="bonus-filters-dates">
                           <S.Datepicker>
                           <div className="icon"></div>
                            <S.DateLabel>{translation('account_wallet_selectDate')}</S.DateLabel>
                            <input type="date"
                                value={calendarStartDate}
                                onChange={(event) => handleChangeStart(event.target.value)}
                            />
                        </S.Datepicker>
                        <S.Datepicker>
                            <div className="icon"></div>
                            <S.DateLabel>{translation('account_wallet_selectDate')}</S.DateLabel>
                            <input type="date"
                                value={calendarEndDate}
                                onChange={(event) => handleChangeEnd(event.target.value)}
                            />
                        </S.Datepicker>

                </S.BonusFiltersDates>
            )}
                    <S.BonusFiltersBtns className="bonus-btns-wrapper">
                        <S.FilterBtn as={NavLink} to={`${match.path}/active-bonus`} exact activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'active')}>{translation('account_bonus_active')}</S.FilterBtn>

                        <S.FilterBtn as={NavLink} to={`${match.path}/queue-bonus`} exact activeClassName="active"
                                    onClick={changeActiveTab.bind(null, 'queue')}>{translation('account_bonus_queue')}</S.FilterBtn>

                        <S.FilterBtn as={NavLink} to={`${match.path}/historical-bonus`} exact activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'historical')}>{translation('account_bonus_history')}</S.FilterBtn>   
                    </S.BonusFiltersBtns>
        
        </S.BonusFilters>

        <AccountBonusRoute {...props}/>

    </S.AccountBonus>
    );
};

const mapStateToProps = ({AccountBonus:{startDate, endDate, activeTab, limit}}, props) => {
    return {
        startDate,
        endDate,
        activeTab,
        limit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountBonus));