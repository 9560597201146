import React, { useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import S from 'StyledBetHistoryItemDetails.js';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {translation, decimal, redirect} from 'helpers/utilsHelper.js'; 
import {formatDate} from 'helpers/datesHelper.js';
import { getPathTransaction } from 'transactionsSelectors.js';
import useToggle from 'UseToggle.js';
import betSlipTypes from 'betSlipTypes.enum.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addOutcome, clearBetslip, changeSlipStake } from 'betSlipActions.js';
import ModalOpacity from 'ModalOpacity.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";
import _find from "lodash/find";
import FreebetIcon from 'freebet_icon.svg';
import AccountShareBetIcon from 'AccountShareBetIcon.js';
import _size from 'lodash/size';
import {getPolishTransactionType} from 'helpers/transactionsHelper.js';

const BetHistoryItemDetails = ({transaction, cashout, historyFreebets, history, source = "history", location: {cashoutFormProps}}) => {

    const [sortedBlocks, setSortedBlocks] = useState({});
    const [taxFactor, setTaxFactor] = useState(0);
    const [showCopyInfo, toggleCopyInfo] = useToggle(false);
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');
    const [showPlaceBetModal,togglePlaceBetModal] = useToggle(false)
    const isActiveTabOccupied = app.service.BetSlip.betSlipData.itemsOrderTab[`s${app.component.BetSlipTabs.getActiveTab()}`].length ? true:false
    useEffect(() => {
        
        //prepare transaction data
        let blockInfo = prepareBlocks();
        setSortedBlocks(blockInfo);
        // spike change header text
        if(source !='share'){
            app.controller.BetSlipHistory.selector.contentHeader().hide();
        }
    }, []);

    const hasCancelledCombination = () => {
        let isCancelledComb = false;
        const transactionDetails = transaction.transactionDetails;
        isCancelledComb = transactionDetails.every(detail => detail.statusCode === 4);

        return isCancelledComb;
    };


    const getTransactionDetailsStatus = (statusCode) => {
        let resultText = 'placed';

        switch (statusCode) {
            case 0:
                resultText = 'pending_acceptation';
                break;
            case 1:
                resultText = 'placed';
                break;
            case 2 :
                resultText = 'won';
                break;
            case 3 :
                resultText = 'lost';
                break;
            case 4 :
            case 10:
                resultText = 'cancel';
                break;
            case 5 :
                resultText = 'cashout';
                break;
            default:
                resultText = 'default';
                break;
        }

        return resultText;
    };

    const getTransactionType = useMemo(() => {
      return getPolishTransactionType(transaction);
    }, []);



    const checkFactor = (block) => {
        const taxFactorOutcome = _find(block, {eventId: -1});
        // temporary solution (0.88) for old slips migrated from etoto.pl
        taxFactorOutcome ? setTaxFactor(taxFactorOutcome.odds) : setTaxFactor(0.88);
    }

    const prepareBlocks = () => {
        let pureBlocksObject = {}
        let blocks = _get(transaction, 'blocks')
        for (const id in blocks) {    
            //get and set factor from data #wspolczynnik 
            checkFactor(blocks[id]);

            if(id != -1){
                pureBlocksObject[blocks[id].blockName] = blocks[id]
            }
        }
        const sortedData = {};
        Object.keys(pureBlocksObject).sort().forEach(function(key) {
            sortedData[key] = pureBlocksObject[key];
        });
        return sortedData;
    };

    const showCopyInfoAction = () => {
        toggleCopyInfo();
    };

    const isRebetPossible = () => {
        let possibleRebet = false;
        const transactionDetails = transaction.transactionDetails;
        const now = new Date().getTime();

        for (let i = 0; i < transactionDetails.length; i++) {
            if (transactionDetails[i].eventStart >= now && transactionDetails[i].eventId !== -1) {
                possibleRebet = true;
                break;
            }
        }
        return possibleRebet;
    };

    const addRebet = (isActiveTabOccupied) => {

        try {
            //hidden actions, waiting for betslip rewrite to react in future 
            //clearBetslip();
            clearBetslipSpike();
            const transactionDetails = transaction.transactionDetails;
            const now = new Date().getTime();
            
            for (let i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].eventStart >= now) {
                    const outcome = transactionDetails[i];
                    if(outcome.eventId === -1) {
                        continue;
                    } else {

                        addOutcomeToSlip(outcome);
                    }
                }
            }
            redirect('/bet-slip');
            //changeSlipStake(transaction.stake);
            changeStakeSpikeMethod(transaction.stake);
            isActiveTabOccupied? togglePlaceBetModal() : null
        } catch (error) {
            console.log({error});
        }
    };

    const addOutcomeToSlip = (singleOutcome) => {
        const {eventId, outcomeId, eventType, gameId, sportId, outcomeName, eventName, gameName, odds} = singleOutcome;
        
        const outcome = {
            outcomeId: outcomeId,
            outcomeLive: eventType === 2,
            gameId: gameId,
            eventType: eventType,
            eventId: eventId,
            sportId: sportId,
            combinationTypes: [1],
            outcomeName: outcomeName,
            eventName: eventName,
            gameName: gameName,
            outcomeOdds: odds
        };
        app.service.BetSlip.addBet(outcome)
        // addOutcome, usable only when we rewrite betslip to react on mobile
        //addOutcome(outcome, true)
    };

    const changeStakeSpikeMethod = (stake) => {
        app.component.BetSlip.updateSimpleStake(stake)
        app.service.BetSlip.setBetSlipStake(stake)
    };

    const clearBetslipSpike = () => {
        app.service.BetSlip.removeAllBets();
    }

    // const currencyCode = process.env.MAIN_CURRENCY_CODE;
    const currencyCode = app.config.mainCurrencyCodeSuffix;
    const { transactionId, stake, possibleReturn, regDate, transactionDetails, tax, taxConfirmed, confirmedReturn, bonus = 0, cashBackReturn, slipCombinations = null, statusCode, slipType, totalOddsWithoutSalesTaxFactor = 1, isFreebet} = transaction;
    const {goBack} = history;
    const scrollDown = ()=>{
        const target = document.getElementById('shareActions');
        target.scrollIntoView({ behavior: 'smooth' });
    }
    const getCombinationApplicableBonusAmount = useMemo(() => {
        let combinationApplicableBonus = 0;
        if (slipCombinations?.combinations) {
            for (let combination of slipCombinations.combinations) {
                if (combination.confirmedReturn) {
                    combinationApplicableBonus = combinationApplicableBonus + combination.bonus;
                }
            }
        }
        return combinationApplicableBonus
    },[]);

    const redirectToCashout = async (e, transactionId) => {
        e.stopPropagation();
        try {
            const maxAmount = await cashoutFormProps.prepareCashout(transactionId);
            history.push({
                pathname: '/cashout',
                state: cashoutFormProps
            });
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    const checkFreebet = (slipId) => {
        const historyFreebetList = _reduce(historyFreebets, (initialArray, {betSlipId}) => {
            return [...initialArray, betSlipId];
        }, []);
        return (historyFreebetList && historyFreebetList.indexOf(slipId) !== -1);
    };


    const renderCashoutButton = () => {
        return (cashout?.currentCashoutAmount &&
            <S.BetHistoryDetailsCashoutButton onClick={(e) => redirectToCashout(e, transactionId)}>
                <div className="cashout-text">{translation('account_betHistoryTransactionItem_performCashout')}</div>
                <div className="cashout-value">{`${cashout.currentCashoutAmount.toFixed(2)} ${currencyCode}`}</div>
            </S.BetHistoryDetailsCashoutButton>
        );
    };

    const getTransactionResult = useMemo(() => {

        if (cashBackReturn) {
            return translation('transactionDetails_betSlipCashback');
        }

        const transactionStatus = getTransactionDetailsStatus(statusCode);
        const hasCancelledComb = hasCancelledCombination();
        let translationKey = null;
        let translationValue = null;

        if (transactionStatus == 'won') {



            if ([0, 100].indexOf(slipType) != -1) {
                if ((confirmedReturn + bonus) >= stake || (isFreebet && confirmedReturn > 0) || (isFreebet && hasCancelledComb)) {
                    translationKey = translation('transactionDetails_betSlipWon');
                    translationValue = 'won'
                } else {
                    translationKey = translation('transactionDetails_betSlipLost');
                }
            }else{
                if (confirmedReturn > 0 || isFreebet) {
                    translationKey = translation('transactionDetails_betSlipWon');
                    translationValue = 'won'
                } else {
                    translationKey = translation('transactionDetails_betSlipLost');
                }
            }
            
            // return 'transactionStatus'
        } else if (transactionStatus == 'cashout') {
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            translationKey = translation('transactionDetails_betSlipCancel');
        } else  {
            translationKey = translation('transactionDetails_betSlipPlaced');
        }

        return {translationKey, translationValue};

    }, [statusCode]);

    const customBetDetails = (outcomeName, gameName) => {
      const outcomeNameSplitted = outcomeName.split(' / ');
      const gameNameSplitted = gameName.split(' / ');
      const fullDetails = [];
      for(let i in outcomeNameSplitted){
        const singleDetail = (
          <div key={i}>
            <span className='bold'>{`${outcomeNameSplitted[i]}` }</span>
            <span> {`[${gameNameSplitted[i]}]`}</span>
          </div>
        )
        fullDetails.push(singleDetail)
      }
      return fullDetails;
    }

    const blockCombinations = (transactionItem) => {
        if (transactionItem.slipCombinations) {
            var combinations = [];
            for (var i = 0; i < transactionItem.slipCombinations.combinations.length; i++) {
                var combination = transactionItem.slipCombinations.combinations[i];
                var returnedCombination = (
                    <S.BetHistoryItemDetailsBlockCombinationsItem key={`bet-history-item-block-combinations_${i}`}>
                        <div className="combinations_combination">
                            {_map(combination.combinationLettersText.split(''), letter => {
                                const status = getTransactionDetailsStatus(combination.combinationLetterStatus[letter]);
                                return <S.Letter key={`${combination.combinationLettersText}_${letter}`} className={status}>{letter}</S.Letter>
                            })}
                        </div>
                        <div className="combinations_odds">{combination.oddsNoGain}</div>
                        <div className="combinations_possibleWin">{combination.possibleReturn > 0 ?  (combination.possibleReturn + combination.bonus).toFixed(2) : combination.possibleReturn}&nbsp;{currencyCode}</div>
                        <div className={classNames({'combinations_win': true, 'won': combination.confirmedReturn > 0})}>{combination.confirmedReturn > 0 ? (combination.confirmedReturn + combination.bonus).toFixed(2) : combination.confirmedReturn}&nbsp;{currencyCode}</div>
                    </S.BetHistoryItemDetailsBlockCombinationsItem>
                );
                combinations.push(returnedCombination);
            }
            return combinations;
        }
    };

    return (
        <>
        {source !='share' ? (
            <S.BetHistoryItemHeader>
                <div className="back" onClick={goBack}></div>
                <div className="header-title">{translation('account_betHistoryTransactionItem_headerTitle')}</div>
                {(process.env.ENABLE_FREEBET && isFreebet) && <S.FreebetIcon dangerouslySetInnerHTML={{__html: FreebetIcon}}/>}

            </S.BetHistoryItemHeader>
        ):null}
            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">
                <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={toggleCashoutErrorModal} showHeaderIcon={false}
                            showHeaderTitle={false} width='75%'>
                    <S.ErrorModal>
                        <S.ErrorModalTitle>{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                        <S.ErrorModalMessage>{cashoutErrorCode ? translation(`account_betHistoryTransactionItem_cashout_error_${cashoutErrorCode}`) : translation(`account_betHistoryTransactionItem_cashout_error_default`)}</S.ErrorModalMessage>
                    </S.ErrorModal>
                </ModalOpacity>
                {source ==='share' ? (
                <ModalOpacity isOpen={showPlaceBetModal} toggleOpen={togglePlaceBetModal} showHeaderIcon={false}
                            showHeaderTitle={false} width='75%' showHeader={false} showCloseIcon={false}>
                    <S.PlaceBetModal>
                        <S.PlaceBetModalTitle>{translation('placeSameBetModa_placeSameBetTitle')}</S.PlaceBetModalTitle>
                        <S.PlaceBetModalMsg>{ translation(`placeSameBetModa_placeSameBetText`)}</S.PlaceBetModalMsg>
                        <S.PlaceBetModalButtonsContainer>
                            <S.PlaceSameBetButton confirm onClick={addRebet}>{translation('placeSameBetModal_Yes')}</S.PlaceSameBetButton>
                            <S.PlaceSameBetButton onClick={togglePlaceBetModal}>{translation('placeSameBetModal_No')}</S.PlaceSameBetButton>
                        </S.PlaceBetModalButtonsContainer>
                    </S.PlaceBetModal>
                </ModalOpacity>
                ):null}
                <S.BetHistoryDetailsHeader className="bet-history-item-details-header">
                    <S.BetHistoryDetailsHeaderInfoId>{`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transactionId}`}</S.BetHistoryDetailsHeaderInfoId>
                    <S.ShareAnchorButton onClick={scrollDown}>
                        {translation('account_betHistoryTransactionItem_shareTitle')}
                        <S.Icon/>
                    </S.ShareAnchorButton>
                </S.BetHistoryDetailsHeader>
                {process.env.REBET_ALLOWED && source === "share" && (
                    <>
                        {(() => {
                            const hasRebet = isRebetPossible();
                                if (hasRebet) {
                                    return <S.BetHistoryDetailsHeaderRebet onClick={() => isActiveTabOccupied ? togglePlaceBetModal():addRebet(isActiveTabOccupied)}>{translation('shareCoupon_rebet')}<S.CouponIcon/></S.BetHistoryDetailsHeaderRebet>
                                }
                            })()}
                    </>
                )}
                {_map(sortedBlocks, (blocks) => {
                    return (!_isEmpty(blocks) && (
                        <React.Fragment key={blocks[0].eventId}>
                            {Object.keys(transaction.blocks).length !== 1 && (
                                <S.BetHistoryBlockHeader className={blocks.blockName == "P"? "banker" : "block"}>{blocks.blockName == "P"? translation('account_betHistoryTransactionItem_detailsBanker') : `${translation('account_betHistoryTransactionItem_detailsBlock')} ${blocks.blockName}`}</S.BetHistoryBlockHeader>
                            )}
                            <S.BetHistoryItemDetails className="bet-history-item-details">
    
                                {_map(blocks, ({ eventId, eventStart, eventName, gameName, outcomeName, outcomeResult, odds, score, statusCode, sportId, categoryName1, categoryName2, categoryName3, gameTypeId }) => {
                                    const statusResult = getTransactionDetailsStatus(statusCode);
                                    return (eventId != -1) && (
                                         
                                        <S.BetHistoryDetailsBody key={eventId}>
                                            <S.BetHistorySingleItemHeaderInfo>
                                                <div className="event-name-info">
                                                    <h4>{eventName}</h4>
                                                    <p>{`${categoryName1} > ${categoryName2} - ${categoryName3}`}</p>
                                                </div>
                                                <div className="event-time-info">
                                                    <p>{formatDate(eventStart, 'dd-MM-yyyy')}   {formatDate(eventStart, 'HH:mm')}
                                                    </p>
                                                </div>
                                            </S.BetHistorySingleItemHeaderInfo>
                                            <S.BetHistorySingleItemBetInfo>
                                                <div className="event-infos">
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_betType')}</div>
                                                        {(gameTypeId === -9600 || gameTypeId === -9601)  
                                                        ?
                                                          (
                                                            <div className="info-wrapper">
                                                              {customBetDetails(outcomeName, gameName)}
                                                            </div>
                                                          )
                                                        :
                                                          <div>{`${outcomeName} [${gameName}]`}</div>
                                                        }
                                                    </div>
                                                    <S.Divider />
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_outcomeOdds')}</div>
                                                        <div>    
                                                        <div>{odds}</div>
                                                        <div className="event-status">
                                                            <S.StatusIcon className={statusResult}></S.StatusIcon>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <S.Divider />
                                                    <div className="info-row">
                                                        <div>{translation('account_betHistoryTransactionItem_result')}</div>
                                                        <div>{outcomeResult}</div>
                                                    </div>
                                                </div>
                                            </S.BetHistorySingleItemBetInfo>
    
                                        </S.BetHistoryDetailsBody>
                                        
                                    )     
                                })}
                            </S.BetHistoryItemDetails>
                        </React.Fragment>
                    ));
    
                })}

                {transaction.slipCombinations && !_isEmpty(transaction.slipCombinations.combinations) && (
                    <S.BetHistoryItemDetailsBlockCombinations>
                        <S.BetHistoryItemDetailsBlockCombinationsCaption>
                            {translation('account_betHistoryTransactionItem_combinations')}
                        </S.BetHistoryItemDetailsBlockCombinationsCaption>
                        <S.BetHistoryItemDetailsBlockCombinationsTable>
                            <S.BetHistoryItemDetailsBlockCombinationsHeader>
                                <div className="combinations_combination">{translation('account_betHistoryTransactionItem_combination')}</div>
                                <div className="combinations_odds">{translation('account_betHistoryTransactionItem_combinationOdds')}</div>
                                <div className="combinations_possibleWin">{translation('account_betHistoryTransactionItem_combinationPossibleWin')}</div>
                                <div className="combinations_win">{translation('account_betHistoryTransactionItem_combinationWin')}</div>
                            </S.BetHistoryItemDetailsBlockCombinationsHeader>
                            <S.BetHistoryItemDetailsBlockCombinationsBody>
                                {blockCombinations(transaction)}
                            </S.BetHistoryItemDetailsBlockCombinationsBody>
                        </S.BetHistoryItemDetailsBlockCombinationsTable>
                    </S.BetHistoryItemDetailsBlockCombinations>
                )}

                <S.BetHistoryItemsFooter>
                    <S.BetHistoryItemsFooterDetailsContainer>
                        <S.BetHistoryItemsFooterLabels>
                            <div>{translation('account_betHistoryTransactionItem_status')}</div>
                            <div>{translation('account_betHistoryTransactionItem_betSlipType')}</div>
                            <div>{translation('account_betHistoryTransactionItem_betSlipDate')}</div>
                            <div>{translation('account_betHistoryTransactionItem_taxFactor')}</div>
                            <div>{translation('account_betHistoryTransactionItem_totalOdds')}</div>
                            <div>{translation('account_betHistoryTransactionItem_stake')}</div>
                            <div>{translation('account_betHistoryTransactionItem_taxValue')}</div>
                        </S.BetHistoryItemsFooterLabels>
                        <S.BetHistoryItemsFooterValues>
                            <div className={getTransactionResult.translationValue === 'won' ? 'win' : ''}>{getTransactionResult.translationKey}</div>
                            <div>{getTransactionType??''}</div>
                            <div>{`${formatDate(regDate, 'dd-MM-yyyy')} ${formatDate(regDate, 'HH:mm')}`}</div>
                            <div>{taxFactor??""}</div>
                            <div>{decimal(totalOddsWithoutSalesTaxFactor)??''}</div>
                            <div>{`${stake.toFixed(2)} ${currencyCode}`}</div>
                            {(taxConfirmed > 0 && statusCode === 1)  
                            ?
                            <div>{`${taxConfirmed.toFixed(2)} ${currencyCode}`}</div>
                            :
                            <div>{`${tax.toFixed(2)} ${currencyCode}`}</div>
                            }
                        </S.BetHistoryItemsFooterValues>
                    </S.BetHistoryItemsFooterDetailsContainer>
                    {cashout?.currentCashoutAmount && renderCashoutButton()}
                </S.BetHistoryItemsFooter>
                <S.BetHistoryDetailsSummary>
                    <div className="logo-container">
                        <div className="footer-logo"></div>
                    </div>
                        <S.PossibleWinWrapper>
                        {
                            statusCode == 1 ? (
                                <>
                                    {([0, 100].indexOf(slipType) != -1) ?
                                        <>
                                            <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWinLabel')}</S.PossibleWinLabel>
                                            <S.PossibleWinValue>{`${(possibleReturn + bonus).toFixed(2)} ${currencyCode}`}</S.PossibleWinValue>
                                        </>
                                        :
                                        <S.PossibleConfirmedWin>
                                            <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWinLabel')}</S.PossibleWinLabel>
                                            <S.PossibleWinValue>{`${(possibleReturn + bonus - getCombinationApplicableBonusAmount).toFixed(2)} ${currencyCode}`}</S.PossibleWinValue>
                                            <S.ConfirmedWinLabel>{translation('account_betHistoryTransactionItem_wonAmount')}</S.ConfirmedWinLabel>
                                            <S.ConfirmedWinValue>{`${(confirmedReturn + getCombinationApplicableBonusAmount).toFixed(2)} ${currencyCode}`}</S.ConfirmedWinValue>
                                        </S.PossibleConfirmedWin>
                                    }
                                </>
                            ) : (
                                <>
                                    <S.ConfirmedWinLabel>{translation('account_betHistoryTransactionItem_wonAmount')}</S.ConfirmedWinLabel>
                                    <S.ConfirmedWinValue>{(isFreebet && hasCancelledCombination()) ? `${(0).toFixed(2)} ${currencyCode}` : `${(confirmedReturn + bonus).toFixed(2)} ${currencyCode}`}</S.ConfirmedWinValue>
                                </>
                            )
                        }
                        </S.PossibleWinWrapper>
                </S.BetHistoryDetailsSummary>
                <S.BetHistoryDetailsHeaderShareActions id='shareActions'>
                    <S.ShareButtonText>{translation('account_betHistory_shareButton')}</S.ShareButtonText>
                    <S.ShareButtonsContainer>
                                <AccountShareBetIcon type="facebook" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon type="twitter" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon showCopyInfo={showCopyInfoAction} type="link" transaction={transaction}></AccountShareBetIcon>
                    </S.ShareButtonsContainer>
                    {showCopyInfo ? <S.BetHistoryDetailsCopyText>{translation('betSlipPlaceSameSlip_link_copied')}</S.BetHistoryDetailsCopyText>:null}
                </S.BetHistoryDetailsHeaderShareActions>
            </S.BetHistoryDetailsWrapper>
        </>
    );
};

const mapStateToProps = ({AccountBetHistory:{transactionsList, cashouts}, AccountFreebet:{freebets:{history:{data: historyFreebets}}}}, {match: {params}}) => {
    return {
        transaction: getPathTransaction(transactionsList, params),
        cashout: cashouts[params.transactionId],
        historyFreebets
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearBetslip: bindActionCreators(clearBetslip, dispatch),
        addOutcome: bindActionCreators(addOutcome, dispatch),
        changeSlipStake: bindActionCreators(changeSlipStake, dispatch),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetHistoryItemDetails));

