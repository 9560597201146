export const LOAD_EVENT_MULTI_CATEGORIES = 'LOAD_EVENT_MULTI_CATEGORIES';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID = 'LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID';
export const LOAD_GAME_TYPE_GROUPS_FOR_SPORT = 'LOAD_GAME_TYPE_GROUPS_FOR_SPORT';

// actions creators

const loadEventMultiCategories = (categoriesIds) => {
    return async(dispatch, getState, {MarketApi}) => {

        dispatch(loadEventMultiCategoriesPending());

        try {
            const {code, data} = await MarketApi.getCategoryMultiEvents(categoriesIds);

            dispatch(loadEventMultiCategoriesSuccess(data));
        } catch (error) {
            dispatch(loadEventMultiCategoriesFailure(error));
            throw error;
        }
    }
};

const loadEventMultiCategoriesFailure = (error) => {
    return {
        type: `${LOAD_EVENT_MULTI_CATEGORIES}_FAILURE`,
        payload: {error}
    }
};

const loadEventMultiCategoriesSuccess = (data) => {
    return {
        type: `${LOAD_EVENT_MULTI_CATEGORIES}_SUCCESS`,
        payload: {data}
    }
};

const loadEventMultiCategoriesPending = () => {
    return {
        type: `${LOAD_EVENT_MULTI_CATEGORIES}_PENDING`
    }
};

const loadCategories = () => {
    return async(dispatch, getState, {MarketApi}) => {
        try {
            const {code, data} = await MarketApi.getCategories();

            dispatch(loadCategoriesSuccess(data));
        } catch (error) {
            dispatch(loadCategoriesFailure(error));
            throw error;
        }
    }
};

const loadCategoriesFailure = (error) => {
    return {
        type: `${LOAD_CATEGORIES}_FAILURE`,
        payload: {error}
    }
};

const loadCategoriesSuccess = (data) => {
    return {
        type: `${LOAD_CATEGORIES}_SUCCESS`,
        payload: {data}
    }
};

const getCategoriesForMarketGroupTypeId = (marketGroupTypeId) => {

    return async(dispatch, getState, {MarketApi}) => {

        dispatch(loadCategoriesForMarketGroupTypeIdPending());
        try {
            const {code, data} = await MarketApi.getCategoriesForMarketGroupTypeId(marketGroupTypeId);

            if(code != 200){
                throw {message:`data error`};
            }
            dispatch(loadCategoriesForMarketGroupTypeIdSuccess(marketGroupTypeId, data));

            return data;
        } catch(error){
            dispatch(loadCategoriesForMarketGroupTypeIdFailure(data));
            throw error;
        }
    }

};

const loadCategoriesForMarketGroupTypeIdPending = () => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_PENDING`
    }
};

const loadCategoriesForMarketGroupTypeIdSuccess = (groupTypeId, categories) => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_SUCCESS`,
        payload: {groupTypeId, categories}
    }
};

const loadCategoriesForMarketGroupTypeIdFailure = () => {
    return {
        type: `${LOAD_CATEGORIES_FOR_MARKET_GROUP_TYPE_ID}_FAILURE`
    }
};

const getGameTypeGroupsForSport = (sportIds) => {

    return async(dispatch, getState, {MarketApi}) => {
        dispatch(loadGameTypeGroupsForSportPending());
        try {
            const {code, data} = await MarketApi.getGameTypeGroupsForSport(sportIds);
            if(code != 200){
                throw {message:`data error`};
            }
            
            dispatch(loadGameTypeGroupsForSportSuccess(data));

            return data;
        } catch(error){
            dispatch(loadGameTypeGroupsForSportFailure(data));
            throw error;
        }
    }

};

const loadGameTypeGroupsForSportPending = () => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_PENDING`
    }
};

const loadGameTypeGroupsForSportSuccess = (marketGroupTypes) => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_SUCCESS`,
        payload: {marketGroupTypes}
    }
};

const loadGameTypeGroupsForSportFailure = () => {
    return {
        type: `${LOAD_GAME_TYPE_GROUPS_FOR_SPORT}_FAILURE`
    }
};

export {
    loadEventMultiCategories,
    loadEventMultiCategoriesFailure,
    loadEventMultiCategoriesSuccess,
    loadEventMultiCategoriesPending,
    loadCategories,
    loadCategoriesFailure,
    loadCategoriesSuccess,
	getCategoriesForMarketGroupTypeId,
	getGameTypeGroupsForSport
}