export const CHANGE_DATE_BY_TYPE = 'CHANGE_WALLET_DATE_BY_TYPE';
export const CHANGE_ACTIVE_TAB = 'CHANGE_WALLET_ACTIVE_TAB';
export const FETCH_TRANSACTIONS_LIST = 'FETCH_WALLET_TRANSACTIONS_LIST';
export const SET_CURRENT_PAGE = 'SET_WALLET_CURRENT_PAGE';
export const RESET_TO_INITIAL_STATE = 'RESET_WALLET_TO_INITIAL_STATE';

const setCurrentPage = (currentPage) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage}
    }
}; 

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const changeActiveTab = (activeTab) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: {
            activeTab
        }
    }
};

const fetchTransactionsByFilter = (config) => {
    return async(dispatch, getState, { AccountWalletApi }) => {
        dispatch(fetchTransactionListPending());

        try {
            const { AccountWallet: { activeTab, currentPage, limit, startDate, endDate, prevData: currentPrevData, data: currentData, nextData: currentNextData } } = getState();
            const initialConfig = { activeTab, currentPage, limit, startDate, endDate };
            const { nextPage } = config ?? {};
            let prevData = [], data = [], nextData = [];
            
            if (!nextPage) {
                const { data: newCurrentData } = await AccountWalletApi.getTransactionsByFilter({...initialConfig, ...config, currentPage: 0})
                const { data: newNextData } = await AccountWalletApi.getTransactionsByFilter({
                    ...initialConfig,
                    ...config,
                    currentPage: 10
                })
                data = newCurrentData;
                nextData = newNextData;
                dispatch(setCurrentPage(0));
            }
            else {
                if (nextPage > currentPage) {
                    prevData = [...currentData];
                    data = [...currentNextData];
                    const { data: newNextData } = await AccountWalletApi.getTransactionsByFilter({
                        ...initialConfig,
                        ...config,
                        currentPage: (nextPage + 1) * limit
                    })
                    nextData = newNextData;
                } 
                else {
                    nextData = [...currentData]
                    data = [...currentPrevData]
                    const { data: newPrevData } = await AccountWalletApi.getTransactionsByFilter({
                        ...initialConfig,
                        ...config,
                        currentPage: nextPage * limit
                    })
                    prevData = newPrevData;
                }
            }
            dispatch(fetchTransactionListSuccess({ prevData, data, nextData }));
        } catch(error) {
            dispatch(fetchTransactionListFailure(error));
            throw error;
        }
    }
};

const fetchFirstDepositInfo = () => {
    let startDate = new Date('2018-01-01');
    let endDate = new Date();

    const config = {
      activeTab: 'deposit',
      endDate,
      startDate,
      currentPage: 0,
      limit: 1
      };

    return async(dispatch, getState, {AccountWalletApi}) => {
        dispatch(fetchTransactionListPending());

        try{
            const {code, data} = await AccountWalletApi.getTransactionsByFilter(config);
            dispatch(fetchTransactionListSuccess(data));
            return data;
        }catch(error){
            dispatch(fetchTransactionListFailure(error));
            throw error;
        }
    }
};

const fetchTransactionListSuccess = ({ prevData, data, nextData }) => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_SUCCESS`,
        payload: { prevData, data, nextData }
    }
};

const fetchTransactionListPending = () => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_PENDING`
    }
};

const fetchTransactionListFailure = (error) => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_FAILURE`,
        payload: {error}
    }
};

const resetToInitialState = ()=>{
    return {
        type: RESET_TO_INITIAL_STATE
    }
};

const cancellPayout = (config) => {

    try {
        return async(dispatch, getState, {AccountWalletApi}) => {
           const {data} = await AccountWalletApi.cancellPayout(config);
           return data;
        }
    } catch(error) {
        console.log(error);
        throw error;
    }

};


export {changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage, fetchFirstDepositInfo, resetToInitialState, cancellPayout}