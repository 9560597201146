export default (requests) => {
    return {
        getCategoryMultiEvents: (categoryIds) => {
            return requests.get(`/market/categories/multi/${categoryIds}/events`);
        },
        getCategories: () => {
            return requests.get(`/market/categories`);
        },
		getGameTypeGroupsForSport: (sportIds) => {
            return requests.get('/market/game/group/sport/'+sportIds);
        },
		getCategoriesForMarketGroupTypeId: (marketGroupTypeId) => {
            return requests.get('/market/categories/multi/market-types/'+marketGroupTypeId);
        },
    }
}