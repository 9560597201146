import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwMedium = ({theme:{$fwMedium}}) => `${$fwMedium}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme:{$fwBold}}) => `${$fwBold}`;

const baseFont = `16px`;
const S = {};
const imagePath = process.env.MOBILE_URL;

S.SelfExclusion = styled.div`
    font-family: ${FontFamily};
    width: 100%;
    padding: 0;
    font-weight: ${FwRegular};
`;

S.SelfExclusionBoxArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 ${rem('10px', baseFont)};
`;

S.SelfExclusionBox = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${rem('10px', baseFont)};
    margin: ${rem('10px', baseFont)} 0 0;
    box-shadow: 0 4px 13px 0 ${({theme:{$accountResponsibleGameLimitTabShadowColor}})=> $accountResponsibleGameLimitTabShadowColor};
    padding: ${rem('14px', baseFont)};
    overflow: hidden;
    background: ${({theme:{$accountResponsibleGameSelfExclusionBoxBg}})=> $accountResponsibleGameSelfExclusionBoxBg};
    `;

S.SelfExclusionValidTo = styled(S.SelfExclusionBox)`
    font-size: ${rem('12px', baseFont)};
    font-weight: ${FwSemibold};
    color: #EC3839;
  `;

S.Title = styled.div`
    font-size: ${rem('17px', baseFont)};
    font-weight:  ${FwBold};
    margin: ${rem('20px', baseFont)} ${rem('15px', baseFont)} ${rem('5px', baseFont)};
    color: ${({theme:{$accountResponsibleGameSelfExclusionHeaderColor}})=> $accountResponsibleGameSelfExclusionHeaderColor};
`;

S.RowWrapper = styled.div`
`;

S.Button = styled(Button)`
  width: 100%;
  font-weight: ${FwBold};
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
  font-family: ${FontFamily};
  font-size: ${rem('12px', baseFont)};
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnColor}})=> $accountResponsibleGameSelfExclusionBtnColor};
  border-radius: 7px;
  padding: ${rem('13px', baseFont)} ${rem('18px', baseFont)};
  text-align: center;
  margin-top:  ${rem('17px', baseFont)};

  &:disabled {
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnTxtDisabled}})=> $accountResponsibleGameSelfExclusionBtnTxtDisabled};
  }
  ${({disabled})=>{
    if(disabled){
      return `
        color: ${({theme:{$accountResponsibleGameSelfExclusionBtnTxtDisabled}})=> $accountResponsibleGameSelfExclusionBtnTxtDisabled};
      `;
    }
  }}
`;

S.Error = styled.span`
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    color: #EC3839;
    font-size: ${rem('12px', baseFont)};
    margin: ${rem('10px', baseFont)} 0 0;
    text-align: center;
`;

S.Description = styled.div`
  font-size: ${rem('13px', baseFont)};
  color: ${({theme:{$accountResponsibleGameSelfExclusionDescColor}})=> $accountResponsibleGameSelfExclusionDescColor};
  font-weight: ${FwRegular};
`;

S.DialogBtnOk = styled(Button)`
  border-radius: ${rem('10px', baseFont)};
  width: 100%;
  padding: ${rem('18px', baseFont)};
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
  font-size: ${rem('14px', baseFont)};
  font-family: ${FontFamily};
  font-weight: ${FwBold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnOkColor}})=> $accountResponsibleGameSelfExclusionBtnOkColor};
  
  &:focus {
          outline: 0;
          outline-color: transparent;
          outline-style: none;
          border-bottom-color: #0E62C4;
      }
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
  margin-bottom: ${rem('10px', baseFont)}; 
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelBgColor}})=> $accountResponsibleGameSelfExclusionBtnCancelBgColor};
  font-weight: ${FwBold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelColor}})=> $accountResponsibleGameSelfExclusionBtnCancelColor};
`;

S.DialogText =styled.div`
  font-size: ${rem('17px', baseFont)};
  font-weight:  ${FwSemibold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionDialogText}})=> $accountResponsibleGameSelfExclusionDialogText};
  text-align: center;
`;

export default S;