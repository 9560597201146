import styled from 'styled-components';
import StyledResponsibleGamingForm from 'StyledResponsibleGamingForm.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyOswald = ({theme: {$textOswaldRegular}}) => `${$textOswaldRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `16px`;

const S = {};

S.AccountResponsibleGame = styled.div`
  width: 100%;
  font-family: ${FontFamilyOswald};
  ${StyledResponsibleGamingForm.ResponsibleGamingForm}{
    width: ${rem('600px', baseFont)};
    margin: ${rem('50px', baseFont)} auto 0;
  }
  
  ${StyledResponsibleGamingForm.Body}{
      padding: 0;
  }
  
    ${StyledResponsibleGamingForm.ButtonsWrapper}{
    padding-left: 0;
    padding-right: 0;
    }
    
    /* ${StyledResponsibleGamingForm.SubmitBtn}{
      width: ${rem('220px', baseFont)}
    } */

`;

S.AccountResponsibleGameTabs = styled.div``;
S.TabsWrapper = styled.ul`
  display: flex;
  margin: ${rem('20px', baseFont)} ${rem('10px', baseFont)} 0;
  padding: 0;
  list-style-type: none;
`;
S.Tab = styled.li`
  flex-grow: 1;
  margin-right: 8px;
  padding: 0 5px;
  height: 42px;
  background: ${({theme:{$accountResponsibleGameTabBgColor}})=> $accountResponsibleGameTabBgColor};
  font-size: ${rem('12px', baseFont)};
  color: ${({theme:{$accountResponsibleGameTabTextColor}})=> $accountResponsibleGameTabTextColor};
  border-radius: 7px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({theme:{$fwSemibold}})=> $fwSemibold};
  white-space: normal;
  text-align: center;
  box-shadow: 0 4px 4px 0 ${({theme:{$accountResponsibleGameTabShadowColor}})=> $accountResponsibleGameTabShadowColor};
  &.active-tab {
    background: ${({theme:{$accountResponsibleGameTabActiveBgColor}})=> $accountResponsibleGameTabActiveBgColor};
    color: ${({theme:{$accountResponsibleGameTabActiveTextColor}})=> $accountResponsibleGameTabActiveTextColor};
  }
  &:last-child {
    margin-right: 0;
  }
`;

export default S;