import React from 'react';
import ReactDOM from 'react-dom';
import {MemoryRouter as Router} from 'react-router-dom';
import {Provider, connect} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import themeDefault from 'theme.js'
import themeLight from 'themeLight.js';
import {destroy} from 'redux-form';
import * as ComponentsLibrary from 'components.js';
import store from './store.js';
import * as AuthActions from 'authActions.js';
import * as AccountActions from 'accountActions.js';
import * as CustomerActions from 'customerActions.js';
import * as FavouritesActions from 'favouritesActions.js';


let ThemeWrapperProvider = ({children, currentTheme}) => {

    const theme = Cookies.get('themeClass') ? (Cookies.get('themeClass') == 'theme-light' ? themeLight : themeDefault) :  themeDefault;
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};


const initComponent = (componentName, rootId, params = {}) => {
    const Component = ComponentsLibrary[componentName];
    const WrappedComponent = (
        <Provider store={store}>
            <ThemeWrapperProvider className="theme-wrapper-provider">
                <Router initialEntries={['/edit-profile']} initialIndex={0}>
                    <Component {...params}/>
                </Router>
            </ThemeWrapperProvider>
        </Provider>
    );
    ReactDOM.render(WrappedComponent, rootId);
};


const unmountComponentAtNode = (selectorsArray) => {
    const reactComponents = document.querySelectorAll([].concat(selectorsArray).join(','));
    if (reactComponents.length) {
        for(let component of reactComponents) {
            ReactDOM.unmountComponentAtNode(component);
        }
    }
};

const actions = {
    AuthActions,
    AccountActions,
    CustomerActions,
    FavouritesActions
}

export {initComponent, unmountComponentAtNode, store, actions, CustomerActions, destroy}