import React,{ useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getTransactionById } from 'accountBetHistoryActions.js';
import Loader from 'Loader.js';
import BetHistoryItemDetails from 'BetHistoryItemDetails.js';

const SharedCouponViewer = ({isPending, transactionId, getTransactionById}) => {
    useEffect(() => {
        getTransactionById(transactionId);
    },[]);
    return (
        <>
            {isPending ?
                <Loader size="5"/>
                :
                <BetHistoryItemDetails source="share"/>
            }
        </>
    )
};

const mapStateToProps = ({AccountBetHistory:{isPending}}, props) => {
    return {
        isPending
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getTransactionById: bindActionCreators(getTransactionById, dispatch)

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedCouponViewer);
