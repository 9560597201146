import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {substractDays} from 'datesHelper.js';
import {CHANGE_DATE_BY_TYPE, FETCH_FREEBET_LIST, SET_CURRENT_PAGE, CHANGE_ACTIVE_TAB, RESET_TO_INITIAL_STATE} from 'freebetActions.js';

let initialState = {
    isPending: true,
    error: null,
    startDate: substractDays(new Date(), 1),
    endDate: new Date(),
    freebets: {
        active: {
            currentPage: 0,
            data: []

        },
        history: {
            currentPage: 0,
            data: []
        }
    },
    limit: 10,
    activeTab: 'active'
};

const accountFreebetsInitialState = {...initialState};

export default typeToReducer({
    [RESET_TO_INITIAL_STATE]: ()=>{
        return accountFreebetsInitialState;
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {activeTab}}) => {
        return update(state, {
            activeTab: {$set: activeTab},
            freebets: {
                active: {
                    currentPage: {$set: 0}
                },
                history: {
                    currentPage: {$set: 0}
                }
            }
        });
    },
    [CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return update(state, {
            [dateType]: {$set: date},
            freebets: {
                active: {
                    currentPage: {$set: 0}
                },
                history: {
                    currentPage: {$set: 0}
                }
            }
        });
    },
    [SET_CURRENT_PAGE]: (state, {payload: {currentPage, status}}) => {
        return {...state, freebets: {...state.freebets, [status]: {...state.freebets[status], currentPage}}}
    },
    [FETCH_FREEBET_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data, status}}) => {
            return {...state, freebets: {...state.freebets, [status]: {...state.freebets[status], data}}};
        },
        FULFILLED: (state, action) => {
            return {...state, isPending: false}
        }
    }
}, initialState);
