import React from 'react';
import {Route, Switch, Router, Redirect, useParams} from 'react-router-dom';
import AccountSessionHistory from 'AccountSessionHistory.js';
import Account from 'Account.js';

const AccountRoute = (props) => {

    return (
        <Switch>
            <Route path="/account/session-history" component={AccountSessionHistory}/>
            <Route path="/account" component={Account}/>
            <Redirect to="/account/edit-profile"/>
        </Switch> 
    )
};

export default AccountRoute;