import React, {Component, useEffect} from 'react';
import AccountBetHistoryRoute from 'AccountBetHistoryRoute.js';
import {MemoryRouter as Router} from 'react-router-dom';
import S from 'StyledAccountBetHistory.js';

class AccountBetHistory extends Component {

    render() {

        return (
            <S.AccountBetHistory className="account-bet-history">
                <Router>
                    <AccountBetHistoryRoute/>
                </Router>
            </S.AccountBetHistory>
        );
    }
}

export default AccountBetHistory;
