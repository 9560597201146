import {formatDate} from 'helpers/datesHelper.js';
const encode = encodeURIComponent;
const today = new Date();

export default (requests, axios) => {
    return {
        getFreebetByFilter: ({status='ACTIVE', endDate = today, startDate = today, limit = 10, currentPage = 0, forceEncoded = false}={}) => {
            const dateFrom = formatDate(startDate, 'yyyy-MM-dd');
            const dateTo = formatDate(endDate, 'yyyy-MM-dd');
            const sessionCookie = Cookies.get('X-ODDS-SESSION');
            const config = {};
            status = status.toUpperCase();

            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.get(`/public/offers/freebet/${dateFrom}/${dateTo}/${limit}/${currentPage}/?status=${encode(status)}${(forceEncoded?'&encoded=true':'')}`, config);
        }
    }
};
