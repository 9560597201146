import {applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from 'reducers.js';
import * as clientApi from 'api.js';
import authMiddleware from 'authMiddleware.js';
import intervalMiddleware from 'intervalMiddleware.js';

// const middlewares = [authMiddleware, thunk.withExtraArgument(clientApi)];
const middlewares = [thunk.withExtraArgument(clientApi), authMiddleware, intervalMiddleware];
if (process.env.NODE_ENV === 'development') {
    const {createLogger} = require('redux-logger');
    const logger = createLogger({
        diff: false,
        collapsed: true,
        predicate: (getState, action) => {
            if(action.type.includes('@@redux-form') || ['UPDATE_SESSION_TIME','UPDATE_CURRENT_TIME'].indexOf(action.type) != -1){
                return false;
            }
            return true;
        }
    });
    middlewares.push(logger);
}

let store = createStore(reducers, {}, compose(applyMiddleware(...middlewares), (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
export default store;


