import styled from 'styled-components';

const S = {};


S.IntegrationGamesList = styled.div`
    display: flex;  
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > div:first-child {
        font-size: 3rem;
        font-weight: 600;
        color: #FFF200;
    }

    & > div:last-child {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        & > div {
            & > .loader {
                & > div {
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    & > div {
        & > div {
            width: 50%;
        }
    }

    .loader {
        & > div {
            width: 27%;
        }
    }
`;


export default S;
