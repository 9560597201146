// action creators

export const LOAD_CMS_PAGE = 'LOAD_CMS_PAGE'; 

const cmsPage = (page, errorPage = null, isPlaces = false, alternativeContent = null) => {
    return async(dispatch, getState, {CmsApi}) => {

        const {Cms: {isPending}} = getState();
        if(!isPending) {
            dispatch(loadCmsPagePending());
        }
        dispatch(loadCmsPagePending());
        
        try {
            const {code, data = {}} = await CmsApi.getCmsPage(errorPage || page);
            if (code != 200) {
                if(code == 404){
                    if(alternativeContent) {
                        return dispatch(cmsPage(page, alternativeContent, isPlaces));
                    } else if (!isPlaces) {
                        return dispatch(cmsPage(page, 'error404'));
                    }
                } else {
                    throw {message: translation(`cmsPage_error${code}`)};
                }
            }

            const cmsPageData = {name: page, data};
            dispatch(loadCmsPageSuccess(cmsPageData));

            return cmsPageData;

        } catch ({error}) {
            dispatch(loadCmsPageFailure(error));
            throw error;
        }
    }
};

const createMarkup = (html) => {
    return {__html: html};
}

const loadCmsPageFailure = (error) => {
    return {
        type: `${LOAD_CMS_PAGE}_FAILURE`,
        payload: {error}
    }
};

const loadCmsPageSuccess = (callCmsPage) => {
    return {
        type: `${LOAD_CMS_PAGE}_SUCCESS`,
        payload: {callCmsPage}
    }
};

const loadCmsPagePending = () => {
    return {
        type: `${LOAD_CMS_PAGE}_PENDING`
    }
};

export {cmsPage, createMarkup};