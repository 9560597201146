import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {getTimeFilterRange, formatDate, substractDays, compareAsc} from 'helpers/datesHelper.js';
import {groupTransactionsByData} from 'helpers/transactionsHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _some from 'lodash/some';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import classNames from 'classnames';
import S from 'StyledAccountBetHistoryListView.js';
import {
    changeDateByType,
    changeDateByTypeOnVirtualTab,
    changeActiveTab,
    changeActiveTabOnVirtualTab,
    changeActiveFilter,
    setCurrentPage,
    fetchTransactionsByFilter,
    fetchCashoutsMaximumAmount,
    resetBetHistoryList,
    refreshBetHistoryList,
    processCashoutAmount,
    processRebet,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    fetchExternalBetHistory,
    filterExternalBetHistory
} from 'accountBetHistoryActions.js';

import BetHistoryTransactionItem from 'BetHistoryTransactionItem.js';
import VirtualBetHistoryItem from 'VirtualBetHistoryItem.js';
import { toggleAccountModal} from 'accountActions.js';

const tabs = [
    { label: 'accountBetHistory_bettingHistoryTab', value: 1 },
    { label: 'accountBetHistory_virtualsHistoryTab', value: 2 },
]

const headerInfoLabel = ['account_betHistory_status', 'account_betHistory_stake', 'account_betHistory_win', 'account_betHistory_date']

class AccountBetHistoryListView extends Component {

    state = {
        isCountDownRequired: false,
        bettingActiveTab: 1,
        calendarStartDate: formatDate(new Date, 'yyyy-MM-dd'),
        calendarEndDate: formatDate(new Date, 'yyyy-MM-dd'),
        virtualStartDate: formatDate(new Date, 'yyyy-MM-dd'),
        virtualEndDate: formatDate(new Date, 'yyyy-MM-dd'),
    }

    componentDidMount = async() => {

        //show spike header 
        app.controller.BetSlipHistory.selector.contentHeader().show();

        const {fetchTransactionsByFilter, fetchExternalBetHistory, startDate, endDate, limit, activeTab, activeFilter, currentPage, virtualBetHistory} = this.props;
        
        let transactionPageLimitations;
        currentPage == 0? (transactionPageLimitations = currentPage) : (transactionPageLimitations = currentPage * limit)
        
        const params = {
            endDate,
            startDate,
            limit,
            activeTab,
            activeFilter,
            currentPage: transactionPageLimitations
        };

        try {
            const data = await fetchTransactionsByFilter(params);
        } catch (error) {
            console.log('fetchTransactionsByFilter:' + error);
        }
    };

    handleClickOnTab = async(tabId) => {

        const {virtualStartDate, virtualEndDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory} = this.props;
        const virtualBetHistoryParams =  {
            virtualStartDate,
            virtualEndDate,
            virtualLimit,
            virtualActiveTab
        }

        try {
            if(tabId === 2) {
                await fetchExternalBetHistory(virtualBetHistoryParams);
            }
        } catch (error) {
            console.log('fetchExternalBetHistory:' + error);
        }

        this.setState({
            bettingActiveTab: tabId,
        })
    }

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        this.setState({calendarStartDate: date});
        changeDateByType('startDate', startDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        this.setState({calendarEndDate: date});
        changeDateByType('endDate', endDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeVirtualStartDate = (date) => {
        const {changeDateByTypeOnVirtualTab, virtualEndDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory, virtualBetHistory} = this.props;
        const virtualStartDate = new Date(date);
        const params = {
            virtualEndDate,
            virtualStartDate,
            virtualLimit,
            virtualActiveTab
        };

        this.setState({virtualStartDate: date});
        changeDateByTypeOnVirtualTab('virtualStartDate', virtualStartDate);
        fetchExternalBetHistory(params);
    };

    handleChangeVirtualEndDate = (date) => {
        const {changeDateByTypeOnVirtualTab, virtualStartDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory} = this.props;
        const virtualEndDate = new Date(date);
        const params = {
            virtualEndDate,
            virtualStartDate,
            virtualLimit,
            virtualActiveTab
        };

        this.setState({virtualEndDate: date});
        changeDateByTypeOnVirtualTab('virtualEndDate', virtualEndDate);
        fetchExternalBetHistory(params);
    };


    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchTransactionsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit
        };

        changeActiveTab(activeTab);
        fetchTransactionsByFilter(params);
    };

    onChangeVirtualTabFilter = (virtualActiveTab) => {
        const {changeActiveTabOnVirtualTab, virtualBetHistory, filterExternalBetHistory} = this.props;

        changeActiveTabOnVirtualTab(virtualActiveTab);
        filterExternalBetHistory(virtualBetHistory, virtualActiveTab);
    };


    onChangeRange = (activeFilter) => {
        const {changeActiveFilter, changeDateByType, fetchTransactionsByFilter, limit, activeTab} = this.props;

        changeActiveFilter(activeFilter);
        const dataRangeObj = getTimeFilterRange(activeFilter);

        changeDateByType('startDate', dataRangeObj['startDate']);
        changeDateByType('endDate', dataRangeObj['endDate']);

        const params = {
            activeTab,
            startDate: dataRangeObj['startDate'],
            endDate: dataRangeObj['endDate'],
            limit
        };

        fetchTransactionsByFilter(params);
    };

    setStartDate = (days) => {
        const {changeDateByType, fetchTransactionsByFilter, activeTab, currentPage, limit} = this.props;
        const today = new Date();
        const startDate = substractDays(today, days);
        const endDate = days == 1 ? startDate : today;
        const params = {
            startDate,
            activeTab,
            endDate,
            currentPage,
            limit
        };

        fetchTransactionsByFilter(params);
        changeDateByType('startDate', startDate);
        changeDateByType('endDate', endDate);
    };

    compareDates = (dateLeft, dateRight) => {
        dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
        dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
        const result = compareAsc(dateLeft, dateRight);
        if (!result) {
            return true;
        }
        return false;
    };

    prepareCashout = async(slipId) => {
        const {fetchCashoutsMaximumAmount} = this.props;

        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };
    
    processCashout = async(config, abortControllerSignal) => {
        const curtainDelay = async(delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({message: translation('account_cashoutForm_aborted')});
                });
            });
            return p;
        };
        
        const {processCashoutAmount, processLiveCurtainForTxId} = this.props;
        try {
            let slipId = _get(config, ['slipId']);
            let countDownRequired = _get(config, ['countDownRequired']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);

            if (hasCashoutLiveCurtain && countDownRequired) {
                const curtainData = _omit({...config}, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            }else{
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    processRebet = async(eventId) => {
        const {processRebet} = this.props;
        try {
            const result = await processRebet(eventId);
            return result;
        } catch (error) {
            throw error;
        }
    };

    renderTransactions = () => {
        const {                            
            index,
            currencyCode,
            currentPage,
            transaction,
            transactionsList,
            cashouts,
            historyFreebets,
            refreshBetHistoryList,
            toggleLiveCurtainForTxId
        } = this.props;

        // let transactionsGroupedByData = groupTransactionsByData(transactionsList, "regDate");
        
        let temporaryJscContainer = [];

        // for (let property in transactionsGroupedByData) {

            temporaryJscContainer.push(
                <S.transactionDataHeader key={currentPage}>

                    <S.transactionDataHeaderInfo>
                    <S.HeaderInfoDataLabel >
                        {headerInfoLabel.map(el => (
                            <div key={el}>
                                {translation(el)}
                            </div>
                        ))}
                        {/* <S.HeaderInfoRegData>{property}</S.HeaderInfoRegData> */}
                    </S.HeaderInfoDataLabel >

                    </S.transactionDataHeaderInfo>

                    {_map(transactionsList, (transaction, index) => {
                        const transactionItemProps = {
                            index,
                            currencyCode,
                            transaction,
                            transactionsList,
                            cashouts,
                            historyFreebets,
                            refreshBetHistoryList,
                            toggleLiveCurtainForTxId
                        };                        
                        return (
                            <BetHistoryTransactionItem key={transaction.transactionId}
                                                    className="transaction-list-item" {...transactionItemProps}
                                                    prepareCashout={this.prepareCashout}
                                                    processCashout={this.processCashout}
                                                    processRebet={this.processRebet}
                                                    toggleAccountModal={toggleAccountModal}
                                    >
                            </BetHistoryTransactionItem>
                        )
                    })
                }

            </S.transactionDataHeader>
            )
// }

        return temporaryJscContainer;
    }

    renderVirtualTransactions = () => {
        const { filteredVirtualBetHistory, currentPage} = this.props;
        const { bettingActiveTab } = this.state;
        let virtualItemsContainer = [];

        virtualItemsContainer.push(            
            <S.transactionDataHeader key={currentPage}>
                <S.transactionDataHeaderInfo>
                    <S.HeaderInfoDataLabel>
                        <div>{translation('account_betHistory_status')}</div>
                        <div>{translation('account_betHistory_stake')}</div>
                        <div>{translation('account_betHistory_win')}</div>
                        <div>{translation('account_betHistory_date')}</div>
                    </S.HeaderInfoDataLabel>
                    {/* <S.HeaderInfoRegData>{property}</S.HeaderInfoRegData> */}
                </S.transactionDataHeaderInfo>
                {_map(filteredVirtualBetHistory, (transaction, index) => {
                    const transactionItemProps = {
                        index,
                        transaction
                    };
                    return (
                        <VirtualBetHistoryItem key={transaction.transactionId}
                                                className="transaction-list-item" {...transactionItemProps}
                                                activeTab={bettingActiveTab}
                                                // processCashout={this.processCashout}
                                                processRebet={this.processRebet}
                                                toggleAccountModal={toggleAccountModal}
                                >
                        </VirtualBetHistoryItem>
                    )
                })
            }
        </S.transactionDataHeader>
        )

        return virtualItemsContainer;
    }

    goPage = async(currentPage) => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit,
            currentPage: offset
        };
        try {
            await fetchTransactionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {

        const { isPending, isPendingVirtualHistory, activeTab, limit, transactionsList, currentPage, virtualActiveTab, virtualEndDate, virtualStartDate, filteredVirtualBetHistory } = this.props;
        const { bettingActiveTab } = this.state;
        let transactionsJsx = [];
        let virtualItemsJsx = [];
        
        if(transactionsList.length > 0) { 
            transactionsJsx = this.renderTransactions();
        }
        if(filteredVirtualBetHistory.length > 0) { 
            virtualItemsJsx = this.renderVirtualTransactions();
        }

        return (
            <S.AccountBetHistoryListView className="account-bet-history">

                <S.TabsWrapper>
                    {tabs.map(({ label, value }, index) => {
                        return (
                            <S.Tab 
                                key={index} 
                                className={`tab ${value === bettingActiveTab ? 'active-tab' : ''}`} 
                                onClick={() => {this.handleClickOnTab(value)}}>{translation(label)}
                            </S.Tab>
                        )
                    })}
                </S.TabsWrapper>

                {
                    bettingActiveTab == 1 ?
                        <>
                            <S.BetHistoryFilters className="bet-history-filters">
                                <S.BetHistoryFiltersRow className="byStatus">
                                    <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                                        <S.FilterBtn
                                            onClick={this.onChangeFilter.bind(this, 'all')}
                                            className={classNames({active: (activeTab == 'all')})}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeFilter.bind(this, 'lost')}
                                            className={classNames({active: (activeTab == 'lost')})}>{translation('account_betHistory_types_lost')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeFilter.bind(this, 'won')}
                                            className={classNames({active: (activeTab == 'won')})}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeFilter.bind(this, 'undecided')}
                                            className={classNames({active: (activeTab == 'undecided')})}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                                    </S.BetHistoryFiltersBtns>
                                </S.BetHistoryFiltersRow>
                            </S.BetHistoryFilters>

                            <S.BetHistoryFiltersDates className="bet-history-filters-dates">
                    
                                <S.Datepicker>
                                    <S.DateLabel>
                                        <div> {translation('account_betHistory_chooseDateRangeFrom')}</div>
                                    </S.DateLabel>
                                    <div className="icon"></div>
                                    <input type="date"
                                        value={this.state.calendarStartDate}
                                        onChange={(event) => this.handleChangeStart(event.target.value)}
                                    />
                                </S.Datepicker>
                                <S.Datepicker>
                                    <S.DateLabel>
                                        <div> {translation('account_betHistory_chooseDateRangeTo')}</div>
                                    </S.DateLabel>
                                    <div className="icon"></div>
                                    <input type="date"
                                        value={this.state.calendarEndDate}
                                        onChange={(event) => this.handleChangeEnd(event.target.value)}
                                    />
                                </S.Datepicker>

                            </S.BetHistoryFiltersDates>

                            <S.BetHistoryTransactions className="bet-history-transaction-list">
                                {isPending ?
                                    <Loader size="5"/>
                                    :
                                    (
                                        <>
                                        <S.BetHistoryTransactionsList className="transaction-list">
                                            {_size(transactionsList) > 0 && (transactionsJsx)}

                                            {!_size(transactionsList) && (
                                                <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>)}

                                        </S.BetHistoryTransactionsList>

                                        {(_size(transactionsList) > 0 || (_size(transactionsList) == 0 && currentPage != 0)) &&  (
                                            <Pagination currentPage={currentPage} limit={limit} data={transactionsList}
                                                        cb={this.goPage}/>)}

                                        </>
                                    )
                                }
                            </S.BetHistoryTransactions>
                        </>

                        :

                    bettingActiveTab == 2 ?

                        <>
                            <S.BetHistoryFilters className="bet-history-filters">
                                <S.BetHistoryFiltersRow className="byStatus">
                                    <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                                        <S.FilterBtn
                                            onClick={this.onChangeVirtualTabFilter.bind(this, 'all')}
                                            className={classNames({active: (virtualActiveTab == 'all')})}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeVirtualTabFilter.bind(this, 'lost')}
                                            className={classNames({active: (virtualActiveTab == 'lost')})}>{translation('account_betHistory_types_lost')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeVirtualTabFilter.bind(this, 'won')}
                                            className={classNames({active: (virtualActiveTab == 'won')})}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                                        <S.FilterBtn
                                            onClick={this.onChangeVirtualTabFilter.bind(this, 'undecided')}
                                            className={classNames({active: (virtualActiveTab == 'undecided')})}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                                    </S.BetHistoryFiltersBtns>
                                </S.BetHistoryFiltersRow>
                            </S.BetHistoryFilters>

                            <S.BetHistoryFiltersDates className="bet-history-filters-dates">
                                <S.Datepicker>
                                    <S.DateLabel>
                                        <div> {translation('account_betHistory_chooseDateRangeFrom')}</div>
                                    </S.DateLabel>
                                    <input type="date"
                                        value={formatDate(virtualStartDate, 'yyyy-MM-dd')}
                                        onChange={(event) => this.handleChangeVirtualStartDate(event.target.value)}
                                    />
                                </S.Datepicker>
                                <S.Datepicker>
                                    <S.DateLabel>
                                        <div> {translation('account_betHistory_chooseDateRangeFrom')}</div>
                                    </S.DateLabel>
                                    <input type="date"
                                        value={formatDate(virtualEndDate, 'yyyy-MM-dd')}
                                        onChange={(event) => this.handleChangeVirtualEndDate(event.target.value)}
                                    />
                                </S.Datepicker>

                            </S.BetHistoryFiltersDates>

                            <S.BetHistoryTransactions className="bet-history-transaction-list">
                                {isPendingVirtualHistory ?
                                    <Loader size="5"/>
                                    :
                                    (
                                        <>
                                        <S.BetHistoryTransactionsList className="transaction-list">
                                            {_size(filteredVirtualBetHistory) > 0 && (virtualItemsJsx)}

                                            {!_size(filteredVirtualBetHistory) && (
                                                <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>)}

                                        </S.BetHistoryTransactionsList>

                                        </>
                                    )
                                }
                            </S.BetHistoryTransactions>
                        </>
                        
                        : null
                }

            </S.AccountBetHistoryListView>
        );
    }
}

const mapStateToProps = ({AccountBetHistory:{isPending, isPendingVirtualHistory, error, startDate, endDate, limit, transactionsList, currentPage, activeTab, virtualActiveTab, activeFilter, cashouts, virtualBetHistory, virtualStartDate, virtualEndDate, virtualLimit, virtualTabCurrentPage, filteredVirtualBetHistory}, AccountFreebet:{freebets:{history:{data: historyFreebets}}}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        limit,
        currencyCode,
        activeTab,
        activeFilter,
        transactionsList,
        cashouts,
        historyFreebets,
        currentPage,
        isPendingVirtualHistory,
        virtualBetHistory,
        virtualActiveTab,
        virtualStartDate,
        virtualEndDate,
        virtualLimit,
        virtualTabCurrentPage,
        filteredVirtualBetHistory
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeActiveFilter: bindActionCreators(changeActiveFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        fetchCashoutsMaximumAmount: bindActionCreators(fetchCashoutsMaximumAmount, dispatch),
        resetBetHistoryList: bindActionCreators(resetBetHistoryList, dispatch),
        refreshBetHistoryList: bindActionCreators(refreshBetHistoryList, dispatch),
        processCashoutAmount: bindActionCreators(processCashoutAmount, dispatch),
        processRebet: bindActionCreators(processRebet, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        processLiveCurtainForTxId: bindActionCreators(processLiveCurtainForTxId, dispatch),
        toggleLiveCurtainForTxId: bindActionCreators(toggleLiveCurtainForTxId, dispatch),
        changeActiveTabOnVirtualTab: bindActionCreators(changeActiveTabOnVirtualTab, dispatch),
        changeDateByTypeOnVirtualTab: bindActionCreators(changeDateByTypeOnVirtualTab, dispatch),
        fetchExternalBetHistory: bindActionCreators(fetchExternalBetHistory, dispatch),
        filterExternalBetHistory: bindActionCreators(filterExternalBetHistory, dispatch),
        dispatch
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountBetHistoryListView));
