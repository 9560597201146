import React, {useMemo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import S from 'StyledAccountResponsibleGameSelfExclusion.js';
import {translation} from 'utilsHelper.js';
import {excludeAccount} from 'responsibleGameActions.js';
import {loadCustomerLimits} from 'customerActions.js';
import _get from 'lodash/get';
import _find from 'lodash/find';
import limits from 'limitTypes.enum.js';
import {formatDate} from 'datesHelper';
import Box from "react-styled-box";
import ModalOpacity from 'ModalOpacity.js';
import CmsPage from 'CmsPage.js';

const AccountResponsibleGameSelfExclusion = ({excludeAccount, exclusion, suspension, customerLimits, loadCustomerLimits}) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [exclusionType, setExclusionType] = useState(undefined);
    const [disablePeriod, setDisablePeriod] = useState(undefined);

    const suspensionError = _get(suspension, ['error']);
    const exclusionError = _get(exclusion, ['error']);
    const suspensionPending = _get(suspension, ['isPending']);
    const exclusionPending = _get(exclusion, ['isPending']);

    const {userExcludedValidToDate, userSuspendedValidToDate} = useMemo(() => {
        let validToDates = {
            userExcludedValidToDate: false, 
            userSuspendedValidToDate: false
        }
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount, limitValidTo} = bettingExclusionLimitExist;
            const todayUnix = +(new Date());
            if (limitValidTo && todayUnix < limitValidTo && limitAmount > 0) {
                const exclusionType = limitAmount === 1 ? 'userSuspendedValidToDate' : 'userExcludedValidToDate';
                validToDates[exclusionType] = formatDate(limitValidTo, 'dd.MM.yyyy HH:mm');
            }
        }
        return validToDates;
    }, [customerLimits]);

    const [handleExclusionSubmit, handleSuspensionSubmit] = ['exclusion','suspension'].map(
        (type) => 
            (disablePeriod = 1) => {
                setIsConfirmationModalOpen(true);
                setExclusionType(type);
                setDisablePeriod(type === 'exclusion' ? _get(exclusion, ['period']) : disablePeriod);
            }
        )

    const confirmExclusion = (isConfirmed) => {
        setIsConfirmationModalOpen(false);
        if (isConfirmed) excludeAccount(exclusionType, disablePeriod);
    }

    useEffect(() => {
        loadCustomerLimits();
    }, []);

    return (
        <S.SelfExclusion>

            <ModalOpacity 
                isOpen={isConfirmationModalOpen} 
                showHeader={false}>
                <Box flexDirection="column" padding="0.25rem 1.25rem" width="100%" overflow="hidden">
                    <Box margin="0.75rem 0" alignSelf="center">
                        <S.DialogText dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_selfExclution_confirmMessage')}}/>
                    </Box>
                    <Box flexDirection="column" justifyContent="space-between" margin="1.25rem 0 0">
                        <S.DialogBtnCancel onClick={() => confirmExclusion(false)}>
                            {translation('account_responsibleGaming_cancel')}
                        </S.DialogBtnCancel>
                        <S.DialogBtnOk onClick={() => confirmExclusion(true)}>
                            {translation('account_responsibleGaming_selfExclusion_confirm')}
                        </S.DialogBtnOk>
                    </Box>
                </Box>
            </ModalOpacity>

            <S.SelfExclusionBoxArea>

                {userSuspendedValidToDate && <S.SelfExclusionValidTo dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_dateInfo_mobile', [userSuspendedValidToDate])}}/>}
                {userExcludedValidToDate && <S.SelfExclusionValidTo dangerouslySetInnerHTML={{__html: translation('account_responsibleGaming_dateInfo_mobile', [userExcludedValidToDate])}}/>}
                
                <S.SelfExclusionBox><CmsPage name="graj-odpowiedzialnie-mobile" isPlaces={true}/></S.SelfExclusionBox>
                
                <S.Title>{translation('account_responsibleGame_selfExclusion_transactionHistoryTitle')}</S.Title>
                <S.SelfExclusionBox><CmsPage name="historia-transakcji-i-zakladow" isPlaces={true}/></S.SelfExclusionBox>
                
                <S.Title>{translation('account_responsibleGame_selfExclusion_loggedInClockTitle')}</S.Title>
                <S.SelfExclusionBox><CmsPage name="zegar-czasu-zalogowania" isPlaces={true}/></S.SelfExclusionBox>
                
                <S.Title>{translation('account_responsibleGame_selfExclusion_closeAccountTitle')}</S.Title>
                <S.SelfExclusionBox><CmsPage name="zamkniecie-konta-mobile" isPlaces={true}/></S.SelfExclusionBox>

                <S.Title>{translation('account_responsibleGame_selfExclusion_suspensionTitle')}</S.Title>
                <S.SelfExclusionBox>
                    <S.Description>{translation('account_responsibleGame_selfExclusion_suspensionDescription')}</S.Description>
                    <S.RowWrapper>
                        <S.Button onClick={()=>handleSuspensionSubmit(1)} disabled={suspensionPending||userSuspendedValidToDate || userExcludedValidToDate}>
                            {translation("account_responsibleGame_selfExclusion_1")}
                        </S.Button>
                        <S.Button onClick={()=>handleSuspensionSubmit(7)} disabled={suspensionPending||userSuspendedValidToDate || userExcludedValidToDate}>
                            {translation("account_responsibleGame_selfExclusion_7")}
                        </S.Button>
                        <S.Button onClick={()=>handleSuspensionSubmit(14)} disabled={suspensionPending||userSuspendedValidToDate || userExcludedValidToDate}>
                            {translation("account_responsibleGame_selfExclusion_14")}
                        </S.Button>
                        <S.Button onClick={()=>handleSuspensionSubmit(30)} disabled={suspensionPending||userSuspendedValidToDate || userExcludedValidToDate}>
                            {translation("account_responsibleGame_selfExclusion_30")}
                        </S.Button>
                    </S.RowWrapper>
                    {suspensionError && <S.Error>{suspensionError}</S.Error>}
                </S.SelfExclusionBox>

                <S.Title>{translation('account_responsibleGame_selfExclusion_selfExclusionTitle')}</S.Title>
                <S.SelfExclusionBox>
                    <S.Description>{translation('account_responsibleGame_selfExclusion_selfExclusionDescription')}</S.Description>
                    <S.RowWrapper>
                        <S.Button onClick={handleExclusionSubmit} disabled={exclusionPending||userExcludedValidToDate || userSuspendedValidToDate}>
                            {translation("account_responsibleGame_selfExclusion_selfExclusionConfirm")}
                        </S.Button>
                    </S.RowWrapper>
                    {exclusionError && <S.Error>{exclusionError}</S.Error>}
                </S.SelfExclusionBox>

                <S.Title>{translation('account_responsibleGame_selfExclusion_addictionHelpTitle')}</S.Title>
                <S.SelfExclusionBox><CmsPage name="pomoc-z-problemami-hazardowymi" isPlaces={true}/></S.SelfExclusionBox>

            </S.SelfExclusionBoxArea>
        </S.SelfExclusion>
    );
}

const mapStateToProps = ({AccountResponsibleGameSelfExclusion: {suspension, exclusion}, Customer: {customerLimits}}) => {
    return {
        customerLimits,
        suspension,
        exclusion
    }
};

const mapDispatchToProps = {excludeAccount, loadCustomerLimits};

export default connect(mapStateToProps, mapDispatchToProps)(AccountResponsibleGameSelfExclusion);

