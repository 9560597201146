import styled from 'styled-components';
import {rem} from 'polished';

const S = {};
const baseFont = `16px`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

S.AccountSessionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem('34px', baseFont)} 0 ${rem('20px', baseFont)};
  color: ${({theme: {$sessionTimerColor}}) => $sessionTimerColor};
  font-weight: ${FwSemibold};
`;

S.AccountSessionTimer = styled.div`
  display: flex;
  padding: 0;
`;

S.TimeParts = styled.span`
  display: flex;
  margin-right: 0.2rem;
  font-size: 0.8rem;
`;

S.AccountSessionLabel = styled.span`
  display: flex;
  font-size: ${rem('12.8px', baseFont)};
  padding-right: ${rem('5px', baseFont)};
`;

export default S;