import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {
    CLEAR_PERSONAL_DATA,
    SET_CUSTOMER_REGULATIONS,
    LOAD_PERSONAL_DATA, 
    LOAD_CUSTOMER_LIMITS,
    UPDATE_CUSTOMER_LIMITS,
    LOAD_GIIF_DATA,
    LOAD_DOCUMENT_TYPES,
    LOAD_DOCUMENT_SCANS,
    LOAD_VERIFICATION_STATUS,
    UPLOAD_CUSTOMER_AVATAR,
    GET_CUSTOMER_AVATAR,
    LOAD_CUSTOMER_LIMITS_OPTIONS,
    GET_CUSTOMER_PROMOTIONS,
    UPDATE_CUSTOMER_PROMOTIONS,
    GET_CUSTOMER_DAYS_FROM_REGISTRATION,
    UPDATE_MYID_STATUS
} from 'customerActions.js';

let initialState = {
    userData: [],
    isPending: true,
    error: null,
    customerRegulations: null,
    customerLimits: null,
    isCustomerLimitsPending: true,
    giifData: null,
    documentTypes: null,
    documentScans: null,
    verificationStatus: null,
    customerAvatarUrl: null,
    limitOptions: null,
    constensPromotion: null,
    shouldVipCTABeNotDisplayed: false ,
    isAccountAboveDaysLimitAndTemporary:false,
    isMyIdActive: false
};

export default typeToReducer({
    [ CLEAR_PERSONAL_DATA]: (state) => {
        return initialState;
    },
    [LOAD_PERSONAL_DATA]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {userData}}) => {
            return {...state, isPending: false, userData};
        }
    },
    [LOAD_CUSTOMER_LIMITS]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isCustomerLimitsPending: false, error};
        },
        SUCCESS: (state, {payload: {customerLimits}}) => {
            return update(state, {
                isCustomerLimitsPending: {$set: false},
                customerLimits: {$set: customerLimits}
            })
        }
    },
    [LOAD_CUSTOMER_LIMITS_OPTIONS]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isCustomerLimitsPending: false, error};
        },
        SUCCESS: (state, {payload: {limitOptions}}) => {
            return update(state, {
                isCustomerLimitsPending: {$set: false},
                limitOptions: {$set: limitOptions}
            })
        }
    },
    [UPDATE_CUSTOMER_LIMITS]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state};
        },
        SUCCESS: (state, {payload: {customerLimits}}) => {
            return update(state, {
                customerLimits: {$set: customerLimits}
            })
        }
    },
    [SET_CUSTOMER_REGULATIONS]: (state, {payload: {customerRegulations}}) => {
        return update(state, {customerRegulations: {$set: customerRegulations}})
    },
    [LOAD_GIIF_DATA]:{
        SUCCESS: (state, {payload: {giifData}}) => {
            return {...state, giifData};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
    [LOAD_DOCUMENT_TYPES]:{
        SUCCESS: (state, {payload: {documentTypes}}) => {
            return {...state, documentTypes};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
    [LOAD_DOCUMENT_SCANS]: {
        SUCCESS: (state, {payload: {documentScans}}) => {
            return {...state, documentScans};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
    [LOAD_VERIFICATION_STATUS]: {
        SUCCESS: (state, {payload: {verificationStatus}}) => {
            return {...state, verificationStatus};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
    [UPLOAD_CUSTOMER_AVATAR]:{ 
        SUCCESS: (state, {payload: {customerAvatarUrl}}) => {
            return update(state, {
                customerAvatarUrl: {$set: customerAvatarUrl}
            })
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state};
        },
    },
    [GET_CUSTOMER_PROMOTIONS]:{
        SUCCESS:(state,{payload:{data}})=>{
            return update(state,{
                constensPromotion:{$set:data}
            })
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state};
        },
    },
    [GET_CUSTOMER_DAYS_FROM_REGISTRATION]:{
        SUCCESS:(state,{payload:{data}})=>{
            return update(state,{
                isAccountAboveDaysLimitAndTemporary:{$set:data}
            })
        },
        FAILURE: (state, {payload: {error}}) => {
            return update(state,{
                isAccountAboveDaysLimitAndTemporary:{$set:data}
            })
        },
    },
    [UPDATE_CUSTOMER_PROMOTIONS]:{
        SUCCESS:(state,{payload})=>{
            return update(state,{
                shouldVipCTABeNotDisplayed:{$set:true}
            })
        },
        FAILURE: (state, {payload}) => {
            return update(state,{
                shouldVipCTABeNotDisplayed:{$set:false}
            })
        },
    },
    [GET_CUSTOMER_AVATAR]: {
        SUCCESS: (state, {payload: {customerAvatarUrl}}) => {
            return {...state, customerAvatarUrl};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
    },
    [UPDATE_MYID_STATUS]: {
        SUCCESS:(state,{payload:{status}})=>{
            return update(state,{
                isMyIdActive:{$set:status}
            })
        },
    },
}, initialState);
