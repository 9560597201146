import styled from 'styled-components';
import {Card} from 'global/styles/styles.js';
const S = {};
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL

const Divider = ({ theme: { divider } }) => divider()
const Header = ({ theme: { header } }) => header()

S.BetHistoryItemDetails = styled.div``;
S.PlaceBetModal= styled.div`
width:100%;`
S.PlaceBetModalTitle=styled.h4`
    font-size: 1.2rem;
    font-weight:700;
    text-align: center;
    margin-bottom: 1.42rem;
`
S.PlaceBetModalMsg = styled.p`
    text-align: center;
    color: #061957;
    margin: 0;
    font-size: 1.2rem;
`;
S.BetHistoryDetailsCopyText= styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-top:10px;
color:rgba(45, 55, 94,0.8);
`
S.PlaceBetModalButtonsContainer=styled.div`
display:flex;
justify-content:space-between;
`
S.PlaceSameBetButton=styled.div`
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin: 0.625rem;
    padding:10px;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: ${props=>props.confirm? "#0E62C4" : "rgba(45, 55, 94,0.1)"}
`
S.ErrorModal = styled.div`width: 100%;`;
S.CouponIcon = styled.div`
margin-left:7px;
  background: url(/images/svg/ikonka-kupon.svg) no-repeat;
  height:19px;
  width:16px ;
`
S.ShareAnchorButton = styled.div`
display:flex;
align-items:center;
padding:8px 20px;
color:white;
font-weight:700 ;
font-size:.8rem;
text-transform:uppercase;
border-radius:5px;
background-color:rgba(45, 55, 94,0.85);
`
S.BetHistoryDetailsHeaderRebet = styled.div`
    display: flex;
    justify-content:center ;
    align-items:center;
    padding:10px 0;
    border-radius:15px;
    color:#fff;
    font-size:.8rem;
    background-color: #0E62C4;
    font-weight:700;
    text-transform:uppercase;
    cursor: pointer;
`;
S.Icon = styled.div`
    margin-left:7px;
      background: url(/images/svg/shareIcon.svg) no-repeat;
      height:14px;
      width:14px ;
`
S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.BetHistoryItemHeader = styled.div`
    display: flex;
    background: #fff;
    color: #061957;
    padding: 0;
    height: 2.5625rem;
    line-height: 2.5625rem;
    font-size: 0.875rem;
    font-family: "Oswald", sans-serif;
    font-weight: 500;

    .back {
        margin-left: 5px;
        width: 2.3125rem;
        height: 2.3125rem;
        text-align: center;
        background: #E6EAF0 url(${imagePath}images/svg/arrow-right-navy-blue.svg) no-repeat center center;
        background-size: 0.5rem 0.875rem;
        transform: rotate(180deg);
    }

    .header-title {
        font-size: 1rem;
        padding-left: .8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
    }
`;

S.BetHistoryBlockHeader = styled.div`
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterBackground}})=> $accountProfileBetHistoryTransactionDetailsFooterBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterColor}})=> $accountProfileBetHistoryTransactionDetailsFooterColor};
    height: 1.5rem;
    padding: 0 0.75rem;
`;

S.BetHistoryDetailsWrapper = styled.div`
    margin: 1rem ;
`;

S.BetHistoryDetailsHeader = styled.div`${Header}
display:flex;
justify-content: space-between;
`;
S.ShareButtonText=styled.div`
display:flex;
justify-content:center ;
width:100%;
font-weight:700;
font-size:1rem;
`
S.ShareButtonsContainer=styled.div`
display:flex;
justify-content:space-around ;
flex:1;
margin-top:25px;
`
S.BetHistoryDetailsHeaderShareActions = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    padding: 15px 25px;
    & .shareIcon {
        display: flex;
        width: 32px;
        height: 32px;
        margin-right: 0.67rem;
        background-size: contain;
    }
`;
S.BetHistoryDetailsHeaderInfoId = styled.div`
`;

S.BetHistoryDetailsHeaderGoBack = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 1.33rem;
    background: url(/images/svg/ic_expand_close.svg) no-repeat;
    background-size: contain;
`;

S.BetHistoryItemDetails = styled.div``;

S.BetHistoryDetailsBody = styled(Card)`
    justify-content:space-between;
    font-size: 0.75rem;
    border-radius: 10px;
    flex-wrap: wrap;
    padding: 0.75rem;
    width: inherit;
    margin: 0.5rem 0 1rem;
`;

S.BetHistorySingleItemHeaderInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .event-name-info {
        width: 63%;

        h4 {
            ${Header}
            margin: 0;
            font-size: 3.5vw
        }
        p {
            color: ${({ theme: { $secondColor } }) => $secondColor};
        }
    }
    .event-time-info {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        font-size: 0.7rem;

        p { 
            display: flex;
            flex-wrap: nowrap;
            color: ${({ theme: { $secondColor } }) => $secondColor};
        }

    }
`;

S.BetHistorySingleItemBetInfo = styled.div`
    display: flex;
    width: 100%;
        .event-infos {
         flex-direction: column;
         width: 100%;
         .info-row {
             display: flex;
             justify-content: flex-start;
             margin: 0.5rem 0;
             & > div:first-of-type {
                color: ${({ theme: { $secondColor } }) => $secondColor};
                margin-right: 2rem;
                width: 10%;
             }
             & > div:nth-of-type(2) {
                display: flex;
                color: ${({ theme: { $fontColor1 } }) => $fontColor1};
             }
         }
     }
     .event-status {
        padding-left: 0.5rem;
        align-items: center;
        display: flex;
     }
     .info-wrapper{
        display: flex;
        flex-direction: column;
        & .bold{
          font-weight: 700;
        }
     }
`;

S.Divider = styled.div`${Divider}`

S.BetHistoryItemsFooter = styled(Card)`
    justify-content:space-between;
    font-size: 0.75rem;
    border-radius: 10px 10px 0 0;
    flex-wrap: wrap;
    padding: 0.75rem;
    width: inherit;
    margin: 0.5rem 0 0;
    background-color: #E3E8EF;
`;

S.BetHistoryItemsFooterDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content:space-between;
    color: ${({ theme: { $fontColor1 } }) => $fontColor1};
`;

S.BetHistoryItemsFooterLabels = styled.div`
    div {
        padding: 0.4rem 0;
    }
`;

S.BetHistoryItemsFooterValues = styled.div`
    text-align: right;
    font-weight: 600;
    div {
        padding: 0.4rem 0;
    }
    .win{
        color: #02B253;
    }
`;

S.BetHistoryDetailsCashoutButton = styled.div`
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsCashoutBackground}})=> $accountProfileBetHistoryTransactionDetailsCashoutBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsCashoutColor}})=> $accountProfileBetHistoryTransactionDetailsCashoutColor};
    border-radius: 30px;
    margin-top: 1rem;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    .cashout-text {
        font-size: 0.9rem;
        padding-right: 0.5rem;
    }
    .cashout-value {
        font-size: 1rem;
        font-weight: bold;
        padding-left: 0.5rem;
    }
`;

S.BetHistoryDetailsSummary = styled.div`
    display: flex;
    border-radius: 0 0 10px 10px; 
    background-color: ${({ theme: { $primaryColor } }) => $primaryColor};
    & > div {
        width: 50%;
    }
    .logo-container {
        padding: 1rem;
        .footer-logo {
            height: 100%;
            background: url(${imagePath}images/logo.png) no-repeat center;
            border-bottom-left-radius: 10px;
            background-size: contain;
        }
    }
`;

S.PossibleWinWrapper = styled.div`
    flex-direction: column;
    color: ${({ theme: { $white } }) => $white};
    text-align: right;
    padding: 1rem;
    border-bottom-right-radius: 10px;
`;

S.ConfirmedWinLabel = styled.div`
    font-size: 0.9rem;
`;

S.ConfirmedWinValue = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

S.PossibleWinLabel = styled(S.ConfirmedWinLabel)`
`;

S.PossibleWinValue = styled(S.ConfirmedWinValue)`
`;

S.PossibleConfirmedWin = styled.div`
  ${S.PossibleWinLabel} {
    font-size: 0.75rem;
  }

  ${S.PossibleWinValue} {
    font-size: 0.85rem;
  }
`;


S.StatusIcon = styled.div`
    height: 14px;
    width: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    &.pending_acceptation {
        background-image: url(/images/icon_pending.png);
    }

    &.won {
        background-image: url(/images/icon_won.png);
    }

    &.cashout {
        background-image: url(/images/icon_cashout.png);
    }

    &.cancel {
        background-image: url(/images/icon_cancel.png);
    }

    &.cashback {
        background-image: url(/images/icon_cashback.png);
    }

    &.lost {
        background-image: url(/images/icon_lost.png);
    }

    &.placed {
        background-image: url(/images/icon_pending.png);
    }
`;

S.BetHistoryItemsFooterInfo = styled(S.BetHistoryItemsFooterLabels)`
    align-items: flex-end;
`;

S.FreebetIcon = styled.div`
 width:  25px;
     margin-left: auto;
         margin-top: 7px;
         margin-right: 15px;
         flex-shrink: 0;
`;

S.BetHistoryItemDetailsBlockCombinations = styled.div`
    padding: 1rem 0;
    background: #fff;
    border-radius: 10px;
`;

S.BetHistoryItemDetailsBlockCombinationsCaption = styled.div`
    height: 1.5rem;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 0.85rem;
    text-transform: uppercase;
    background: #fff;
    padding: 0 0.75rem;
    border-radius: 10px 10px 0 0;
    color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsCaption}}) => $accountBetHistoryItemDetailsBlockCombinationsCaption};  
`;

S.BetHistoryDetailsHeaderCaption = styled(S.BetHistoryItemDetailsBlockCombinationsCaption)``;

S.BetHistoryItemDetailsBlockCombinationsTable = styled.div``;

S.BetHistoryItemDetailsBlockCombinationsHeader = styled.div`
  background-color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor}}) => $accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor};  
  height: 2rem;
  display: flex;
  align-items: center;
  & > div{
      font-size: 0.7rem;
      font-weight: 500;
      padding-right: 0.9rem;
      color: #6C738E;
      &.combinations_combination{
      padding-left: 0.75rem;
       flex: 1 1 28%;
      }
      &.combinations_odds{
         flex: 1 1 24%;
      }
      &.combinations_possibleWin{
          flex: 1 1 24%;
      }
      &.combinations_win{
        flex: 1 1 24%;
      }
  }
`;

S.BetHistoryItemDetailsBlockCombinationsBody = styled.div``;

S.BetHistoryItemDetailsBlockCombinationsItem = styled.div`
  background-color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemBgColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemBgColor}; 
  color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemColor}; 
  /* border-bottom: 1px solid  ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemBorderColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemBorderColor};  */
  min-height: 2rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  
  &:last-of-type{
    border-bottom: none;
  }

  &:nth-child(odd) {
    background-color: #E3E8EF;
  }
  
  & > div{
    font-size: 0.75rem;
    padding-right: 0.9rem;
    font-weight: 600;
    &.combinations_combination{
      padding-left: 0.75rem;
      flex: 1 1 28%;
      display: flex;
      line-height: 1;
      flex-wrap: wrap;
    }
    &.combinations_odds{
      flex: 1 1 24%;
    }
    &.combinations_possibleWin{
      flex: 1 1 24%;
    }
    &.combinations_win{
      flex: 1 1 24%;
      color: #68708D;

      &.won {
          color: #02B253;
      }
    }
  }
`;

S.Letter = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: #02B253;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.55rem;
  border-radius: 50%;
  margin: 0.25rem 0.15rem 0.25rem 0;
  font-weight: 700;
  &:last-of-type{
    margin-right: 0;
  }
  &.lost {
      background-color: #EC3839;
  }
`;

export default S;