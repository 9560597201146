import styled from 'styled-components';
import {Input} from 'global/styles/styles.js';

const S = {};

S.Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;


S.ProviderContent = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > ${S.Item}{
        margin: 1rem 0;
    }

    &.provider-transition-enter,
    &.provider-transition-appear {
        top: 0;
        width: 100%;
        color: transparent;
        background-color: red;
    }

    &.provider-transition-enter-active,
    &.provider-transition-appear-active  {
        transition: all 1000ms;
        background-color: #848484;
    }

    &.provider-transition-exit {
        background-color: #9e8949;
        transition: all 1000ms;
    }

    &.provider-transition-exit-active {
        top: 0;
        width: 100%;
        color: transparent;
        background-color: #5a564c;
        transition: all 1000ms;
    }

    div {
        &.loader {
            position: unset;
            padding-top: unset;

            & > div {
                min-width: unset;
                max-width: unset;
                padding: unset;
                border: 1.1em solid rgb(51, 51, 51);
                border-left: 1.1em solid #f8941e;
            }
        }
    }
`;

S.ImgAndTextComponent = styled.div`
    display: flex;
    padding: 2rem 0 0 0;

    ${S.ImageComponent} {
        min-width: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > label {
        font-family: Oswald, sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

`;

S.TextComponent = styled.div`

    & > label {
        font-size: 0.8rem;
    }
`;

S.InputComponent = styled.div`
    width: 80%;
`;

S.RenderSelectField = styled.div`
    & > button {
        float: right;
        background: none;
        color: #FF0000;
        border: none;
    }
`;

S.SelectComponent = styled.div`
    width: 80%;

    label {
        font-weight: normal;
        font-size: 0.8rem;
        color: #fff;
        margin: 0;
        padding: 0 0.5rem 0.2rem;
        font-family: sans-serif;
    }

   ${S.RenderSelectField} {
        & > select {
            width: 100%;
            height: 34px;
            font-size: 1rem;
            color: #000;
            border-radius: .42rem;
            background-color: #f6f6f6;

            &:disabled {
                background-color: #D4D9DB;
            }
        }
    }
`;

S.InputWrapper = styled.div`
    position: relative;
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid #e1e1e1;
    color: #000;
    font-size: 1rem;
    padding: 0.3rem 0.3rem;
    background-color: #f6f6f6;
    display: block;
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    &:valid, &:disabled {
        & + ${S.Label}{
            margin-top: -1.62rem;
            font-size: 0.8rem;
            color: #fff;
        }
    }

    &:disabled {
        background-color: #D4D9DB;
    }


    &.without-floating-label {
        & + ${S.Label}{
            margin-top: -1.62rem;
            font-size: 0.8rem;
            color: #fff;
        }
    }
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.ButtonComponent = styled.div`

    margin: 2rem 1rem 0 1rem;

    & > button {
        background: #fba126;
        line-height: 2.33rem;
        border-radius: .33rem;
        color: #000;
        font-size: 1rem;
        font-style: italic;
        font-weight: 700;
        width: 8rem;
        letter-spacing: 1px;
    }

    & > button:disabled {
        background-color: #666;
        opacity: 0.5;
        transition-duration: 1s;
    }

`;

S.ImageComponent = styled.div`

    & > img {
        width: 6rem;
    }
`;

S.GroupComponents = styled.div`
    display: flex;
`;

S.LockComponent = styled.div`
    display: flex;
    flex-direction: column;
`;

S.ConfirmRowComponent = styled.div`
    display: flex;
    padding: 0.2rem 0 0 0;
    justify-content: space-between;
    font-size: 0.8rem;
    width: 100%;

    & > span {
        & > div {
            display: block;
            text-align: left;
        }
    }

    & > span:last-child {
        & > div {
            display: block;
            text-align: right;
        }
    }
`;

S.ConfirmDataComponent = styled.div`
    width: 90%;
`;

S.IframeComponent = styled.div`
    height: 100%;

    & > iframe {
        height: 75vh;
    }
`;

S.InfoWonAmountToPayoutComponentUnlocked = styled.div`
    background-color: #1f9c25;
    width: 75%;
    padding: 1.5rem;
`;

S.InfoWonAmountToPayoutComponentLocked = styled.div`
    background-color: #FF0000;
    width: 75%;
    padding: 1.5rem;
`;

S.RemoveCardCardComponent = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eab000;
    border-left: 8px solid #ce9c09;
    box-shadow: 1px 3px 4px rgba(0,0,0,.2);
    padding: 0.5rem;
    cursor: auto;
    border-radius: 5px;
    text-align: center;
    
    & > div {
        margin: 0.5rem;
        
        & > button {
            margin: 0 2rem;
            border: none;
            background: none;
            pointer-events: auto;
            font-weight: 600;
            color: #fff;
        }

        & > button:first-of-type {
            color: #09A83B;
        }
    }
`;



export default S;
