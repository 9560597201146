import styled from 'styled-components';

const S = {};
const baseFont = `12px`;
 
S.AccountWrapper = styled.div``;

S.AccountTopPanel = styled.div` 
    padding: 20px;
    background-color: ${({theme:{$accountTopPanelBgColor}})=> $accountTopPanelBgColor};
`;

S.UserDataContainer = styled.div` 
    display: flex;
`;

S.UserAvatarContainer = styled.div`
    flex: 1;
`;

S.UserAvatar = styled.div`
    margin: 1.5rem 0;
    height: 100px;
    border-radius: 50%;
`;

S.UserNameDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
`;

S.UserName = styled.div`
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: right;
`;

S.UserDataButtonContainer = styled.div`
    width: 95px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: ${({theme:{$accountTopPanelBtnBgColor}})=> $accountTopPanelBtnBgColor};
`;

S.UserDataButton = styled.div`
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${({theme:{$accountTopPanelBtnTextColor}})=> $accountTopPanelBtnTextColor};
`;

S.Separator = styled.div`
    margin: 10px 0;
    width: 100%;
    height: 2px;
    background-color: ${({theme:{$accountTopPanelSeparatorColor}})=> $accountTopPanelSeparatorColor};
`;

S.UserBalanceContainer = styled.div`
    display: flex;
`;

S.UserMainBalance = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

S.UserMainBalanceLabel = styled.div`
    margin-bottom: 0.2rem;
    display: inline-flex;
    font-size: 0.7rem;

`;

S.UserMainBalanceContainer = styled.div`
    display: inline-flex;
    font-weight: 600;
`;

S.UserMainBalanceValue = styled.div``;

S.FreebetsBalanceValue = styled.div``;

S.UserMainBalanceCurrency = styled.div``;

S.UserBonusBalanceWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

S.UserBonusBalance = styled(S.UserMainBalance)``;

S.UserBonusBalanceLabel = styled(S.UserMainBalanceLabel)``;

S.UserBonusBalanceAmount = styled.div`
    font-weight: 600;
`;

S.UserFreebets = styled(S.UserMainBalance)``;

S.FreebetLabel = styled(S.UserMainBalanceLabel)``;

S.FreebetsBalanceValue = styled.div`
    font-weight: 600;
`;

S.UserDataSessionTimerContainer = styled.div` 
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
`;

S.AccountSessionTimerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

S.AccountSessionLabel = styled.div`
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
`;

S.UserActivityLink = styled.div`
    text-align: center;
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: ${({theme:{$accountUserActivityLinkColor}})=> $accountUserActivityLinkColor};
`;

S.AccountMainMenuContainer = styled.div` 
    margin: 20px;
`;

S.AccountBody = styled.div``;

S.AccountBottom = styled.div`
    margin: 20px;
`;

S.BalanceSelect = styled.div``;


export default S;