import typeToReducer from 'type-to-reducer';
import {LOAD_CUSTOMER_CARD_LIST} from 'paymentActions.js';
import {LOAD_PAYSTACK_BANK_LIST} from 'paymentActions.js';
import {LOAD_BANK_LIST} from 'paymentActions.js';
import {LOAD_ACCOUNT_TYPES} from 'paymentActions.js';
import {LOAD_CUSTOMER_BANK_DETAIL} from 'paymentActions.js';
import {LOAD_WON_AMOUNT_TO_PAYOUT} from 'paymentActions.js';
import {LOAD_BILLERS} from 'paymentActions.js';
import {CLEAR_PAYMENT_DATA} from 'paymentActions.js';

let initialState = {
    isPending: false,
    error: null
};


export default typeToReducer({
    [LOAD_CUSTOMER_CARD_LIST]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callCreditCardList}}) => {
            return {...state, isPending: false, callCreditCardList};
        }
    },
    [LOAD_PAYSTACK_BANK_LIST]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callPaystackBankList}}) => {
            return {...state, isPending: false, callPaystackBankList};
        }
    },
    [LOAD_BANK_LIST]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callBankList}}) => {
            return {...state, isPending: false, callBankList};
        }
    },
    [LOAD_ACCOUNT_TYPES]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callAccountTypes}}) => {
            return {...state, isPending: false, callAccountTypes};
        }
    },
    [LOAD_WON_AMOUNT_TO_PAYOUT]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callWonAmountToPayout}}) => {
            return {...state, isPending: false, callWonAmountToPayout};
        }
    },
    [LOAD_CUSTOMER_BANK_DETAIL]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callCustomerBankDetail}}) => {
            return {...state, isPending: false, callCustomerBankDetail};
        }
    },
    [LOAD_BILLERS]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {callBillers}}) => {
            return {...state, isPending: false, callBillers};
        }
    },
    [CLEAR_PAYMENT_DATA]: (state, action) => {
        return {...state, callCustomerBankDetail: null, callWonAmountToPayout: null, callCreditCardLis: null};
    },

}, initialState);


