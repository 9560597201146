export const LOAD_LIVE_MAJOR_GAMES = 'LOAD_LIVE_MAJOR_GAMES';



// action creators

const loadLiveMajorGames = () => {
    return async(dispatch, getState, {LiveApi}) => {
        try {
            const {code, data} = await LiveApi.getLiveMajorGames();

            dispatch(loadLiveMajorGamesSuccess(data));
        } catch (error) {
            dispatch(loadLiveMajorGamesFailure(error));
            throw error;
        }
    }
};

const loadLiveMajorGamesFailure = (error) => {
    return {
        type: `${LOAD_LIVE_MAJOR_GAMES}_FAILURE`,
        payload: {error}
    }
};

const loadLiveMajorGamesSuccess = (data) => {
    return {
        type: `${LOAD_LIVE_MAJOR_GAMES}_SUCCESS`,
        payload: {data}
    }
};

export {
    loadLiveMajorGames,
    loadLiveMajorGamesFailure,
    loadLiveMajorGamesSuccess
}