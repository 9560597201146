import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import AccountCurrentBonus from 'AccountCurrentBonus.js';
import AccountQueueBonus from 'AccountQueueBonus.js';
import AccountHistoricalBonus from 'AccountHistoricalBonus.js';

const AccountBonusRoute = (props) => {
    /* if not selected redirect to current bonuses tab */
    return (
        <Switch>
            <Route path={`${props.match.path}/active-bonus`} component={AccountCurrentBonus}/>
            <Route path={`${props.match.path}/queue-bonus`} component={AccountQueueBonus}/>
            <Route path={`${props.match.path}/historical-bonus`} component={AccountHistoricalBonus}/>
            <Redirect to={`${props.match.path}/active-bonus`}/>
        </Switch>
    )
};

export default AccountBonusRoute;