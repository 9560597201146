import _size from 'lodash/size';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import { batch } from "react-redux";
import { translation } from 'utilsHelper.js';
import { setSegmentationCookie } from 'helpers/cookiesHelper.js';
import { updateUserBalance, toggleProlongSessionModal, logOutUser } from 'authActions.js'
import {startInterval, stopInterval} from 'intervalActions.js';

export const CLEAR_PERSONAL_DATA = 'CLEAR_PERSONAL_DATA';
export const LOAD_CUSTOMER_LIMITS = 'LOAD_CUSTOMER_LIMITS';
export const LOAD_CUSTOMER_LIMITS_OPTIONS = 'LOAD_CUSTOMER_LIMITS_OPTIONS';
export const UPDATE_CUSTOMER_LIMITS = 'UPDATE_CUSTOMER_LIMITS';
export const SET_CUSTOMER_REGULATIONS = 'SET_CUSTOMER_REGULATIONS';
export const LOAD_PERSONAL_DATA = 'LOAD_PERSONAL_DATA';
export const LOAD_GIIF_DATA = 'LOAD_GIIF_DATA';
export const LOAD_DOCUMENT_TYPES = 'LOAD_DOCUMENT_TYPES';
export const LOAD_DOCUMENT_SCANS = 'LOAD_DOCUMENT_SCANS';
export const LOAD_VERIFICATION_STATUS = 'LOAD_VERIFICATION_STATUS';
export const UPLOAD_CUSTOMER_AVATAR = 'UPLOAD_CUSTOMER_AVATAR';
export const GET_CUSTOMER_AVATAR = 'GET_CUSTOMER_AVATAR';
export const LOAD_PUBLIC_REG_CONSENTS = 'LOAD_PUBLIC_REG_CONSENTS';
export const GET_CUSTOMER_PROMOTIONS = 'GET_CUSTOMER_PROMOTIONS';
export const UPDATE_CUSTOMER_PROMOTIONS = 'UPDATE_CUSTOMER_PROMOTIONS';
export const GET_CUSTOMER_DAYS_FROM_REGISTRATION = 'GET_CUSTOMER_DAYS_FROM_REGISTRATION'
export const UPDATE_MYID_STATUS = 'UPDATE_MYID_STATUS'
export const CLOSE_CUSTOMER_ACCOUNT = 'CLOSE_CUSTOMER_ACCOUNT'

const startLoadCustomerDataInterval = () => {
    return (dispatch) => {
        const config = {
            key: 'LOAD_CUSTOMER_DATA',
            actionCreator: loadCustomerData,
            timeout: 20000
        };
        dispatch(startInterval(config));
    }
};

const stopLoadCustomerDataInterval = () => {
    return (dispatch, getState) => {
        const key = 'LOAD_CUSTOMER_DATA';
        const {Intervals} = getState();
        const intervalExist = _find(Intervals?.intervals, {key});
        if (_size(intervalExist)) {
            dispatch(stopInterval(key));
        }
    }
};

// action creators
const clearPersonalData = () => {
    return {
        type: `${CLEAR_PERSONAL_DATA}`
    }
}

const loadGiifData = () => {
    return async (dispatch, getState, { CustomerApi }) => {
      dispatch(loadPersonalDataPending());
  
      try {
        const { code, data } = await CustomerApi.getGiifTypes();
        dispatch(getGiifTypesSuccess(data));
  
        return data;
      } catch (error) {
        dispatch(getGiifTypesFailure(error));
        throw error;
      }
    };
};

const getGiifTypesFailure = (error) => {
    return {
        type: `${LOAD_GIIF_DATA}_FAILURE`,
        payload: {error}
    }
};

const getGiifTypesSuccess = (giifData) => {
    return {
        type: `${LOAD_GIIF_DATA}_SUCCESS`,
        payload: {giifData}
    }
};

const setCustomerRegulations = (customerRegulations) => {
    return {
        type: SET_CUSTOMER_REGULATIONS,
        payload: {customerRegulations}
    }
};

const isValueUnique = (fieldName, fieldValue) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            let fName = (fieldName == 'loginName') ? 'login' : fieldName;
            let fValue = fieldValue;
            const {code, data, description} = await CustomerApi.isValueUnique(fName, fValue);
            if (!data) {
                throw `This ${fName} is already used`;
            }
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const acceptRegulations = ()=>{
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.acceptRegulations();
            if (code == 200) {
                dispatch(getCustomerBettingStatus());
                return data;
            }
        } catch (error) {
            throw error;
        }
    }
};

const getCustomerBettingStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.getCustomerBettingStatus();
            if (code == 200 && _size(data)) {
                dispatch(setCustomerRegulations(data));
                return data;
            }
        } catch (error) {
            throw error;
        }
    }
};

const getCustomerSegmentStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} =  await CustomerApi.getCustomerSegmentStatus();

            if (code == 200) {
                if (_size(data)) setSegmentationCookie(data.filter(i => i > 0).join(','));
                else setSegmentationCookie('') // set empty cookie if data is empty
            }
        } catch (error) {
            throw error;
        }
    }
};
const getMyIdStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {data} =  await CustomerApi.getMyIdStatus();
            dispatch(updateMyIdStatus(data));
        } catch (error) {
            throw error;
        }
    }
};

const updateMyIdStatus = (status) => {
    return {
        type: `${UPDATE_MYID_STATUS}_SUCCESS`,
        payload: { status }
    }
};

const updateCustomerLimitsPending = () => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_PENDING`
    }
};

const updateCustomerLimitsFailure = (error) => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_FAILURE`,
        payload: {error}
    }
};

const updateCustomerLimitsSuccess = (customerLimits) => {
    return {
        type: `${UPDATE_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {customerLimits}
    }
};

const loadCustomerLimitsPending = () => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_PENDING`
    }
};

const loadCustomerLimitsFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerLimitsSuccess = (customerLimits) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {customerLimits}
    }
};

const loadCustomerLimitsOptionsPending = () => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_PENDING`
    }
};

const loadCustomerLimitsOptionsFailure = (error) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_FAILURE`,
        payload: {error}
    }
};

const loadCustomerLimitsOptionsSuccess = (limitOptions) => {
    return {
        type: `${LOAD_CUSTOMER_LIMITS_OPTIONS}_SUCCESS`,
        payload: {limitOptions}
    }
};

const loadPersonalDataFailure = (error) => {
    return {
        type: `${LOAD_PERSONAL_DATA}_FAILURE`,
        payload: {error}
    }
};

const loadDocumentTypes = () => {
    return async (dispatch, getState, { CustomerApi }) => {
      dispatch(loadPersonalDataPending());
      try {
        const { code, data } = await CustomerApi.getDocumentTypes();
        dispatch(getDocumentTypesSuccess(data));
        return data;
      } catch (error) {
        dispatch(getDocumentTypesFailure(error));
        throw error;
      }
    };
};

const loadDocumentScans = () => {
    return async (dispatch, getState, { CustomerApi }) => {
        dispatch(loadPersonalDataPending());
        try {
            const { code, data } = await CustomerApi.getDocumentScans();
            dispatch(loadDocumentScansSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadDocumentScansFailure(error));
            throw error;
        }
    }
};

const loadDocumentScansFailure = (error) => {
    return {
        type: `${LOAD_DOCUMENT_SCANS}_FAILURE`,
        payload: {error}
    }
};

const loadDocumentScansSuccess = (documentScans) => {
    return {
        type: `${LOAD_DOCUMENT_SCANS}_SUCCESS`,
        payload: {documentScans}
    }
};

const loadPersonalDataSuccess = (userData) => {
    return {
        type: `${LOAD_PERSONAL_DATA}_SUCCESS`,
        payload: {userData}
    }
};

const loadPersonalDataPending = () => {
    return {
        type: `${LOAD_PERSONAL_DATA}_PENDING`
    }
};

const loadPersonalData = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        dispatch(loadPersonalDataPending());
        try {
            const {code, data} = await CustomerApi.getPersonalData();
            dispatch(loadPersonalDataSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadPersonalDataFailure(error));
            throw error;
        }
    }
};

const cashierRegisterCustomer = (user) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data, description} = await CustomerApi.cashierRegisterCustomer(user);

            if (code == 500 || code == 418) {
                throw {message: translation(`login_api_error_${code}`)};
            } else if (code != 200) {
                throw {message: translation(`login_api_error_${description}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const loadVerificationStatus = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.getVerificationStatus();
            dispatch(loadVerificationStatusSuccess(data.account.verificationStatus));
        } catch (error) {
            dispatch(loadVerificationStatusFailure(error));
            throw error;
        }
    }
};

const loadVerificationStatusSuccess = (verificationStatus) => {
    return {
        type: `${LOAD_VERIFICATION_STATUS}_SUCCESS`,
        payload: {verificationStatus}
    }
};

const loadVerificationStatusFailure = (error) => {
    return {
        type: `${LOAD_VERIFICATION_STATUS}_FAILURE`,
        payload: {error}
    }
};

const getDocumentTypesSuccess = (documentTypes) => {
    return {
        type: `${LOAD_DOCUMENT_TYPES}_SUCCESS`,
        payload: {documentTypes}
    }
};

const getDocumentTypesFailure = (error) => {
    return {
        type: `${LOAD_DOCUMENT_TYPES}_FAILURE`,
        payload: {error}
    }
};

const sendMultipartForm = (formData) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const response = await CustomerApi.uploadDocumentScan(formData);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

const updatePersonalData = (userData) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const response = await CustomerApi.updatePersonalData(userData);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

const uploadDocumentData = (formData) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const response = await CustomerApi.uploadDocumentScan(formData);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

const loadCustomerLimits = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        dispatch(loadCustomerLimitsPending());

        try {
            const {code, data} = await CustomerApi.getCustomerLimits();
            dispatch(loadCustomerLimitsSuccess(data));

            return data;
        } catch (error) {
            dispatch(loadCustomerLimitsFailure(error));
            throw error;
        }
    }
};

const loadCustomerLimitsOptions = () => {
    return async(dispatch, getState, {OtherApi}) => {
        dispatch(loadCustomerLimitsOptionsPending());

        try {
            const {code, data} = await OtherApi.getStaticConf();
            if(data.limitOptions){
              dispatch(loadCustomerLimitsOptionsSuccess(data.limitOptions));
            }

            return data.limitOptions;
        } catch (error) {
            dispatch(loadCustomerLimitsOptionsFailure(error));
            throw error;
        }
    }
};

const updateCustomerLimits = (limits) => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            const {code, data} = await CustomerApi.updateCustomerLimits(limits);
            const {Customer:{customerRegulations}} = getState();
            const {bettingAllowed, bettingDisabledReasons} = customerRegulations||{};
            if (code == 200 && _size(data)) {
                const fieldsWithError = _filter(data, 'exceptionCode');
                const errors =_reduce(fieldsWithError, (initialObject, {limitType, exceptionCode, exceptionExtraMsg})=>{
                    const errorMsg = translation(`limits_limitUpdateInfo_error_${exceptionCode}`, (exceptionExtraMsg?[exceptionExtraMsg]:null));
                    const fiieldName =`limitType${limitType}`;
                    return {...initialObject, [fiieldName]: errorMsg}
                }, {});
                if(!bettingAllowed && [].concat(bettingDisabledReasons).indexOf('REGULATIONS_NOT_ACCEPTED')!=-1){
                    await dispatch(acceptRegulations());
                }
                dispatch(updateCustomerLimitsSuccess(data));

                if(_size(errors)){
                    // throw errors;
                }

                return data;
            }else{
                throw {_error: translation(`limitUpdateInfo_error_${code}`)};
            }
        } catch (error) {
            throw error;
        }
    }
};

const loadCustomerData = (requestProperties) => {
    return async (dispatch, getState, { CustomerApi }) => {
        try {
            const { code, data } = await CustomerApi.getCustomerData(requestProperties)

            if (code !== 200) throw { message: "Customer data error" }

            app.service.Auth.customerData = data;

            const config = {
                newBalance: data.balance,
                bonusBalanceList: data.bonusBalanceList,
                creditBonusBalance: data.creditBonusBalance
            };

            if (CLIENT === "editec" && data?.login?.startsWith("session_user_")) {
                const { Auth: { isOpenProlongSessionModal } } = getState();
                if (data?.sessionExpires == true && !isOpenProlongSessionModal) {
                    dispatch(toggleProlongSessionModal(true));
                }
            }

            dispatch(updateUserBalance(config));
        } catch ({ message }) {
            console.log(message)
        }
    }
};

const uploadCustomerAvatar = (avatar) => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {code, data} = await CustomerApi.uploadCustomerAvatar(avatar);
            if (code == 200 && _size(data)) {
                dispatch(uploadCustomerAvatarSuccess(data));
                return data;
            }
        } catch (error) {
            throw error;
        }
    }

};

const uploadCustomerAvatarSuccess = (customerAvatarUrl) => {

    return {
        type: `${UPLOAD_CUSTOMER_AVATAR}_SUCCESS`,
        payload: {customerAvatarUrl}
    }

};

const updateCustomerPromotion = (definitionId, booleanValue = true)=>{
    return async (dispatch,getState,{CustomerApi}) =>{
        try{
            const VipMarketingObj = {
                "definitionId" : definitionId,
                "accepted": booleanValue,
            }
            const {code,data} = await CustomerApi.updateCustomerConstans(VipMarketingObj)
            if (code===200){
                dispatch(updateCustomerPromotionSuccess(data))
            }else{
                dispatch(updateCustomerPromotionFailure({})) 
            }
            return data
            
        } catch(err){
            dispatch(updateCustomerPromotionFailure(err))
            throw err
        }
    }
}
const updateCustomerPromotionSuccess = (data)=>{
    return{
        type:`${UPDATE_CUSTOMER_PROMOTIONS}_SUCCESS`,
        payload:{data}
    }
}
const updateCustomerPromotionFailure = (data)=>{
    return{
        type:`${UPDATE_CUSTOMER_PROMOTIONS}_FAILURE`,
        payload:{data}
    }
}
const getCustomerDaysFromRegistration = ()=>{
    return async (dispatch, getState, { CustomerApi }) => {
        try {
            const {data}= await CustomerApi.getVerificationStatus()
            if(_size(data)){
                let daysFromRegistration = (new Date() - new Date(data.account.registeredOn)) / (1000 * 60 * 60 * 24);
                daysFromRegistration > 30 && data.account.accountStatus ==='TEMPORARY_ACCOUNT'  ? daysFromRegistration= true: daysFromRegistration=false;
                dispatch(getCustomerDaysFromRegistrationSuccess(daysFromRegistration))
            }
        } catch (error) {
            dispatch(getCustomerDaysFromRegistrationFailure(false))
          throw error;
        }
      };
}
const getCustomerDaysFromRegistrationSuccess=(data)=>{
    return{
        type:`${GET_CUSTOMER_DAYS_FROM_REGISTRATION}_SUCCESS`,
        payload:{data}
    }
}
const getCustomerDaysFromRegistrationFailure=(data)=>{
    return{
        type:`${GET_CUSTOMER_DAYS_FROM_REGISTRATION}_FAILURE`,
        payload:{data}
    }
}
const getCustomerPromotions=(booleanValue, param=false)=>{
    return async (dispatch, getState, { CustomerApi }) => {
        try {
            
          const { code, data } = await CustomerApi.getCustomerPromotions();
          if(param){
            dispatch(getCustomerPromotionsSuccess(data.PROMOTIONS[0]))
            return
          }
          if(code==200 && _size(data)){
            dispatch(getCustomerPromotionsSuccess(data.PROMOTIONS))
            dispatch(updateCustomerPromotion(data.PROMOTIONS[0].definitionId, booleanValue))
            return data;
          }
        } catch (error) {
            dispatch(getCustomerPromotionsFailure(error))
            dispatch(updateCustomerPromotionFailure(error))
          throw error;
        }
      };
}
const getCustomerPromotionsFailure=(data)=>{
    return{
        type:`${GET_CUSTOMER_PROMOTIONS}_FAILURE`,
        payload:{data}
    }
}
const getCustomerPromotionsSuccess = (data)=>{
    return{
        type:`${GET_CUSTOMER_PROMOTIONS}_SUCCESS`,
        payload:{data}
    }
}
const getCustomerAvatar = () => {

    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {code, data} = await CustomerApi.getCustomerAvatar();
            if (code == 200 && _size(data)) {
                dispatch(getCustomerAvatarSucess(data));
                return data;
            } else if (code == 404) {
                dispatch(getCustomerAvatarSucess(null));
            }
        } catch (error) {
            throw error;
        }
    }
    
};

const getCustomerAvatarSucess = (customerAvatarUrl) => {

    return {
        type: `${GET_CUSTOMER_AVATAR}_SUCCESS`,
        payload: {customerAvatarUrl}
    }

};

const loadPublicRegConsents = (containersNames) => {
 
    return async(dispatch, getState, {CustomerApi}) => {

        try {
            const {data} = await CustomerApi.getPublicRegConsents(containersNames);
            dispatch(loadPublicRegConsentsSuccess(data));
            return data;
        } catch (error) {
            dispatch(loadPublicRegConsentsFailure(error));
            throw error;
        }
    }
 
};

const loadPublicRegConsentsSuccess = (containers) => {
    return {
        type: `${LOAD_PUBLIC_REG_CONSENTS}_SUCCESS`,
        payload: {containers}
    }
};

const loadPublicRegConsentsFailure = (error) => {
    return {
        type: `${LOAD_PUBLIC_REG_CONSENTS}_FAILURE`,
        payload: {error}
    }
};

const closeCustomerAccount = () => {
    return async(dispatch, getState, {CustomerApi}) => {
        try {
            await CustomerApi.closeCustomerAccount()
            batch(() => {
                dispatch(logOutUser())
                dispatch(closeCustomerAccountSuccess())
            })
        } catch (error) {
            dispatch(closeCustomerAccountFailure(error))
            throw error;
        }
    }
}

const closeCustomerAccountSuccess = () => {
    return {
        type: `${CLOSE_CUSTOMER_ACCOUNT}_SUCCESS`,
    }
};

const closeCustomerAccountFailure = (error) => {
    return {
        type: `${CLOSE_CUSTOMER_ACCOUNT}_FAILURE`,
        payload: {error}
    }
};

export {
    cashierRegisterCustomer,
    clearPersonalData,
    isValueUnique,
    getCustomerBettingStatus,
    getCustomerSegmentStatus,
    loadCustomerLimits,
    loadGiifData,
    loadDocumentScans,
    loadDocumentTypes,
    loadPersonalDataFailure,
    loadPersonalDataSuccess,
    loadPersonalDataPending,
    loadPersonalData,
    loadVerificationStatus,
    sendMultipartForm,
    setCustomerRegulations,
    updateCustomerLimits,
    updatePersonalData,
    uploadDocumentData,
    loadCustomerData,
    uploadCustomerAvatar,
    getCustomerAvatar,
    loadCustomerLimitsOptions,
    acceptRegulations,
    getCustomerPromotions,
    getCustomerPromotionsSuccess,
    getCustomerPromotionsFailure,
    updateCustomerPromotionSuccess,
    updateCustomerPromotionFailure,
    updateCustomerPromotion,
    loadPublicRegConsents,
    getCustomerDaysFromRegistration,
    getMyIdStatus,
    closeCustomerAccount,
    startLoadCustomerDataInterval,
    stopLoadCustomerDataInterval
}