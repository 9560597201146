import _find from 'lodash/find';

export default {

    getByType: function (type) {
        const collection = this;
        const object = _find(collection, (o) => {
            return (o.type == type)
        });
        return object ? object : {type: undefined, name: ''};
    },

    'ACCUMULATOR': {type: 0, name: 'betSlip_type_ACCUMULATOR'},
    'SINGLES': {type: 1, name: 'betSlip_type_SINGLES'},
    'DOUBLE': {type: 2, name: 'betSlip_type_DOUBLE'},
    'TRIPLE': {type: 3, name: 'betSlip_type_TRIPLE'},

    '4-FOLD': {type: 4, name: 'betSlip_type_4-FOLD'},
    '5-FOLD': {type: 5, name: 'betSlip_type_5-FOLD'},
    '6-FOLD': {type: 6, name: 'betSlip_type_6-FOLD'},
    '7-FOLD': {type: 7, name: 'betSlip_type_7-FOLD'},
    '8-FOLD': {type: 8, name: 'betSlip_type_8-FOLD'},
    '9-FOLD': {type: 9, name: 'betSlip_type_9-FOLD'},

    'SYSTEM_BET_10': {type: 10, name: 'betSlip_type_SYSTEM_BET_10'},
    'SYSTEM_BET_11': {type: 11, name: 'betSlip_type_SYSTEM_BET_11'},
    'SYSTEM_BET_12': {type: 12, name: 'betSlip_type_SYSTEM_BET_12'},
    'SYSTEM_BET_13': {type: 13, name: 'betSlip_type_SYSTEM_BET_13'},
    'SYSTEM_BET_14': {type: 14, name: 'betSlip_type_SYSTEM_BET_14'},
    'SYSTEM_BET_15': {type: 15, name: 'betSlip_type_SYSTEM_BET_15'},
    'SYSTEM_BET_16': {type: 16, name: 'betSlip_type_SYSTEM_BET_16'},
    'SYSTEM_BET_17': {type: 17, name: 'betSlip_type_SYSTEM_BET_17'},
    'SYSTEM_BET_18': {type: 18, name: 'betSlip_type_SYSTEM_BET_18'},
    'SYSTEM_BET_19': {type: 19, name: 'betSlip_type_SYSTEM_BET_19'},
    'SYSTEM_BET_20': {type: 20, name: 'betSlip_type_SYSTEM_BET_20'},
    'SYSTEM_BET_21': {type: 21, name: 'betSlip_type_SYSTEM_BET_21'},
    'SYSTEM_BET_22': {type: 22, name: 'betSlip_type_SYSTEM_BET_22'},
    'SYSTEM_BET_23': {type: 23, name: 'betSlip_type_SYSTEM_BET_23'},
    'SYSTEM_BET_24': {type: 24, name: 'betSlip_type_SYSTEM_BET_24'},
    'SYSTEM_BET_25': {type: 25, name: 'betSlip_type_SYSTEM_BET_25'},
    'SYSTEM_BET_26': {type: 26, name: 'betSlip_type_SYSTEM_BET_26'},
    'SYSTEM_BET_27': {type: 27, name: 'betSlip_type_SYSTEM_BET_27'},
    'SYSTEM_BET_28': {type: 28, name: 'betSlip_type_SYSTEM_BET_28'},
    'SYSTEM_BET_29': {type: 29, name: 'betSlip_type_SYSTEM_BET_29'},
    'SYSTEM_BET_30': {type: 30, name: 'betSlip_type_SYSTEM_BET_30'},
    'SYSTEM_BET_31': {type: 31, name: 'betSlip_type_SYSTEM_BET_31'},
    'SYSTEM_BET_32': {type: 32, name: 'betSlip_type_SYSTEM_BET_32'},
    'SYSTEM_BET_33': {type: 33, name: 'betSlip_type_SYSTEM_BET_33'},
    'SYSTEM_BET_34': {type: 34, name: 'betSlip_type_SYSTEM_BET_34'},
    'SYSTEM_BET_35': {type: 35, name: 'betSlip_type_SYSTEM_BET_35'},
    'SYSTEM_BET_36': {type: 36, name: 'betSlip_type_SYSTEM_BET_36'},
    'SYSTEM_BET_37': {type: 37, name: 'betSlip_type_SYSTEM_BET_37'},
    'SYSTEM_BET_38': {type: 38, name: 'betSlip_type_SYSTEM_BET_38'},
    'SYSTEM_BET_39': {type: 39, name: 'betSlip_type_SYSTEM_BET_39'},
    'SYSTEM_BET_40': {type: 40, name: 'betSlip_type_SYSTEM_BET_40'},

    'SYSTEM_BET_PLUS_ACCUMULATOR': {type: 200, name: 'betSlip_type_SYSTEM_BET_PLUS_ACCUMULATOR'},
    'SINGLES_PLUS_ACCUMULATOR': {type: 201, name: 'betSlip_type_SINGLES_PLUS_ACCUMULATOR'},
    'DOUBLES_PLUS_ACCUMULATOR': {type: 202, name: 'betSlip_type_DOUBLES_PLUS_ACCUMULATOR'},
    'TRIPLES_PLUS_ACCUMULATOR': {type: 203, name: 'betSlip_type_TRIPLES_PLUS_ACCUMULATOR'},
    'FOUR_FOLD_PLUS_ACCUMULATOR': {type: 204, name: 'betSlip_type_FOUR_FOLD_PLUS_ACCUMULATOR'},
    'FIVE_FOLD_PLUS_ACCUMULATOR': {type: 205, name: 'betSlip_type_FIVE_FOLD_PLUS_ACCUMULATOR'},
    'SIX_FOLD_PLUS_ACCUMULATOR': {type: 206, name: 'betSlip_type_SIX_FOLD_PLUS_ACCUMULATOR'},
    'SEVEN_FOLD_PLUS_ACCUMULATOR': {type: 207, name: 'betSlip_type_SEVEN_FOLD_PLUS_ACCUMULATOR'},
    'EIGHT_FOLD_PLUS_ACCUMULATOR': {type: 208, name: 'betSlip_type_EIGHT_FOLD_PLUS_ACCUMULATOR'},
    'NINE_FOLD_PLUS_ACCUMULATOR': {type: 209, name: 'betSlip_type_NINE_FOLD_PLUS_ACCUMULATOR'},

    'SYSTEM_BET_10_PLUS_ACCUMULATOR': {type: 210, name: 'betSlip_type_SYSTEM_BET_10_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_11_PLUS_ACCUMULATOR': {type: 211, name: 'betSlip_type_SYSTEM_BET_11_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_12_PLUS_ACCUMULATOR': {type: 212, name: 'betSlip_type_SYSTEM_BET_12_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_13_PLUS_ACCUMULATOR': {type: 213, name: 'betSlip_type_SYSTEM_BET_13_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_14_PLUS_ACCUMULATOR': {type: 214, name: 'betSlip_type_SYSTEM_BET_14_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_15_PLUS_ACCUMULATOR': {type: 215, name: 'betSlip_type_SYSTEM_BET_15_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_16_PLUS_ACCUMULATOR': {type: 216, name: 'betSlip_type_SYSTEM_BET_16_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_17_PLUS_ACCUMULATOR': {type: 217, name: 'betSlip_type_SYSTEM_BET_17_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_18_PLUS_ACCUMULATOR': {type: 218, name: 'betSlip_type_SYSTEM_BET_18_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_19_PLUS_ACCUMULATOR': {type: 219, name: 'betSlip_type_SYSTEM_BET_19_PLUS_ACCUMULATOR'},
    'SYSTEM_BET_20_PLUS_ACCUMULATOR': {type: 220, name: 'betSlip_type_SYSTEM_BET_20_PLUS_ACCUMULATOR'},

    'SYSTEM_BET_AND_UP_OFFSET': {type: 300, name: 'betSlip_type_SYSTEM_BET_AND_UP_OFFSET'},
    'SINGLES_AND_UP': {type: 301, name: 'betSlip_type_SINGLES_AND_UP'},
    'DOUBLES_AND_UP': {type: 302, name: 'betSlip_type_DOUBLES_AND_UP'},
    'TRIPLES_AND_UP': {type: 303, name: 'betSlip_type_TRIPLES_AND_UP'},
    'FOUR_FOLD_AND_UP': {type: 304, name: 'betSlip_type_FOUR_FOLD_AND_UP'},
    'FIVE_FOLD_AND_UP': {type: 305, name: 'betSlip_type_FIVE_FOLD_AND_UP'},
    'SIX_FOLD_AND_UP': {type: 306, name: 'betSlip_type_SIX_FOLD_AND_UP'},
    'SEVEN_FOLD_AND_UP': {type: 307, name: 'betSlip_type_SEVEN_FOLD_AND_UP'},
    'EIGHT_FOLD_AND_UP': {type: 308, name: 'betSlip_type_EIGHT_FOLD_AND_UP'},
    'NINE_FOLD_AND_UP': {type: 309, name: 'betSlip_type_NINE_FOLD_AND_UP'},
    'SYSTEM_BET_10_AND_UP': {type: 310, name: 'betSlip_type_SYSTEM_BET_10_AND_UP'},
    'SYSTEM_BET_11_AND_UP': {type: 311, name: 'betSlip_type_SYSTEM_BET_11_AND_UP'},
    'SYSTEM_BET_12_AND_UP': {type: 312, name: 'betSlip_type_SYSTEM_BET_12_AND_UP'},
    'SYSTEM_BET_13_AND_UP': {type: 313, name: 'betSlip_type_SYSTEM_BET_13_AND_UP'},
    'SYSTEM_BET_14_AND_UP': {type: 314, name: 'betSlip_type_SYSTEM_BET_14_AND_UP'},
    'SYSTEM_BET_15_AND_UP': {type: 315, name: 'betSlip_type_SYSTEM_BET_15_AND_UP'},
    'SYSTEM_BET_16_AND_UP': {type: 316, name: 'betSlip_type_SYSTEM_BET_16_AND_UP'},
    'SYSTEM_BET_17_AND_UP': {type: 317, name: 'betSlip_type_SYSTEM_BET_17_AND_UP'},
    'SYSTEM_BET_18_AND_UP': {type: 318, name: 'betSlip_type_SYSTEM_BET_18_AND_UP'},
    'SYSTEM_BET_19_AND_UP': {type: 319, name: 'betSlip_type_SYSTEM_BET_19_AND_UP'},
    'SYSTEM_BET_20_AND_UP': {type: 320, name: 'betSlip_type_SYSTEM_BET_20_AND_UP'},
    'SYSTEM_BET_AND_UP_ID_LIMIT': {type: 350, name: 'betSlip_type_SYSTEM_BET_AND_UP_ID_LIMIT'},

    'TRIXIE': {type: 101, name: 'betSlip_type_TRIXIE'},
    'PATENT': {type: 102, name: 'betSlip_type_PATENT'},
    'YANKEE': {type: 104, name: 'betSlip_type_YANKEE'},
    'LUCKY-15': {type: 105, name: 'betSlip_type_LUCKY-15'},
    'LUCKY-31': {type: 106, name: 'betSlip_type_LUCKY-31'},
    'CANADIAN': {type: 110, name: 'betSlip_type_CANADIAN'},
    'HEINZ': {type: 107, name: 'betSlip_type_HEINZ'},
    'GOLIATH': {type: 109, name: 'betSlip_type_GOLIATH'},
    'LUCKY-63': {type: 111, name: 'betSlip_type_LUCKY-63'},
    'SUPER-HEINZ': {type: 108, name: 'betSlip_type_SUPER-HEINZ'},
    'SINGLE': {type: 100, name: 'betSlip_type_SINGLE'},

    'REDTIGER_SPEND': {type: 1983, name: 'betSlip_type_REDTIGER_SPEND'},
    'REDTIGER_PAYOUT': {type: 1984, name: 'betSlip_type_REDTIGER_PAYOUT'},
    'REDTIGER_CANCEL': {type: 1985, name: 'betSlip_type_REDTIGER_CANCEL'},
    'GOLDEN_RACE_SPEND': {type: 1310, name: 'betSlip_type_GOLDENRACE_SPEND'},
    'GOLDEN_RACE_PAYOUT': {type: 1311, name: 'betSlip_type_GOLDENRACE_PAYOUT'},
    'GOLDEN_RACE_WITHDRAW': {type: 1313, name: 'betSlip_type_GOLDENRACE_WITHDRAW'},
    'GOLDEN_RACE_CANCEL': {type: 1314, name: 'betSlip_type_GOLDENRACE_CANCEL'},

    'GLOBALBET_SPEND': {type: 1480, name: 'betSlip_type_GLOBALBET_SPEND'},
    'GLOBALBET_PAYOUT': {type: 1481, name: 'betSlip_type_GLOBALBET_PAYOUT'},
    'GLOBALBET_CANCEL': {type: 1482, name: 'betSlip_type_GLOBALBET_CANCEL'},
    'GLOBALBET_REVERSAL': {type: 1483, name: 'betSlip_type_GLOBALBET_REVERSAL'},
};
