import React from 'react';
import Modal from 'Modal.js';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';

const duration = 300;
const ModalWithOpacityStyles = styled(Modal)`
  &.modal-opacity-enter {
    opacity: 0.01;
  }

  &.modal-opacity-enter-active {
   transition: opacity ${duration}ms;
   opacity: 1;
  }

  &.modal-opacity-exit {
    opacity: 1;
  }

  &.modal-opacity-exit-active {
   opacity: 0.01;
   transition: opacity ${duration}ms ease-in;
  }
 
  &.modal-opacity-transparent {
    background: transparent!important;
  }
`; 

const ModalOpacity = ({isOpen, toggleOpen, children, title = '', showHeader = true, showCloseIcon = true, showHeaderIcon = true, showHeaderTitle = true, padding=null, width=null, className=null}) => {

    return (
        <>
        <CSSTransition in={isOpen}
                       className={`modal-opacity ${className}`}
                       classNames="modal-opacity"
                       unmountOnExit
                       timeout={duration}>

            <ModalWithOpacityStyles
                showHeader={showHeader}
                showHeaderIcon={showHeaderIcon}
                showCloseIcon={showCloseIcon}
                showHeaderTitle={showHeaderTitle}
                title={title}
                open={isOpen}
                onClose={toggleOpen}
                padding={padding}
                width={width}>
                {children}
            </ModalWithOpacityStyles>

        </CSSTransition>
        </>
    );
};

export default ModalOpacity;