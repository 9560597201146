import _get from 'lodash/get';
import _map from "lodash/map";
import _size from "lodash/size";
import _isObject from "lodash/isObject";

import {fetchFreebetsByFilter} from 'accountFreebetActions.js';
import {createBlocksForTransactions} from 'helpers/transactionsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import { result } from 'lodash';

export const CHANGE_DATE_BY_TYPE = 'CHANGE_BET_HISTORY_DATE_BY_TYPE';
export const CHANGE_ACTIVE_TAB = 'CHANGE_BET_HISTORY_ACTIVE_TAB';
export const FETCH_TRANSACTIONS_LIST = 'FETCH_BET_HISTORY_TRANSACTIONS_LIST';
export const FETCH_TRANSACTION_BY_ID = 'FETCH_TRANSACTION_BY_ID';
export const SET_CURRENT_PAGE = 'SET_BET_HISTORY_CURRENT_PAGE';
export const FETCH_CASHOUTS_LIST = 'FETCH_CASHOUTS_LIST';
export const SET_MAXIMUM_CASHOUT_AMOUNT = 'SET_MAXIMUM_CASHOUT_AMOUNT';
export const RESET_BET_HISTORY_LIST = 'RESET_BET_HISTORY_LIST';
export const CHANGE_ACTIVE_FILTER = 'CHANGE_ACTIVE_FILTER';
export const TOGGLE_LIVE_CURTAIN_FOR_TX_ID = 'TOGGLE_LIVE_CURTAIN_FOR_TX_ID';
export const RESET_CASHOUT_AMOUNT_FOR_TX_ID = 'RESET_CASHOUT_AMOUNT_FOR_TX_ID';
export const VIRTUAL_TAB_CHANGE_DATE_BY_TYPE = 'VIRTUAL_TAB_CHANGE_BET_HISTORY_DATE_BY_TYPE';
export const VIRTUAL_TAB_CHANGE_ACTIVE_TAB = 'CHANGE_BET_HISTORY_VIRTUAL_ACTIVE_TAB';
export const FETCH_EXTERNAL_BET_HISTORY = 'FETCH_EXTERNAL_BET_HISTORY';
export const FILTERED_EXTERNAL_BET_HISTORY = 'FILTERED_EXTERNAL_BET_HISTORY';
export const VIRTUAL_TAB_SET_CURRENT_PAGE  = 'VIRTUAL_TAB_SET_BET_HISTORY_CURRENT_PAGE';
export const CASHOUT_NOW_CURRENT_AMOUNT = 'CASHOUT_NOW_CURRENT_AMOUNT';

const toggleLiveCurtainForTxId = (transactionId, curtainActive) => {
    return {
        type: TOGGLE_LIVE_CURTAIN_FOR_TX_ID,
        payload: {transactionId, curtainActive}
    }
};

const setCurrentPage = (currentPage) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage}
    }
};

const setCurrentPageOnVirtualTab = (currentPage) => {
    return {
        type: VIRTUAL_TAB_SET_CURRENT_PAGE ,
        payload: {currentPage}
    }
};


const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const changeDateByTypeOnVirtualTab = (dateType, date) => {
    return {
        type: VIRTUAL_TAB_CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const changeActiveTab = (activeTab) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: {
            activeTab
        }
    }
};

const changeActiveTabOnVirtualTab = (virtualActiveTab) => {
    return {
        type: VIRTUAL_TAB_CHANGE_ACTIVE_TAB,
        payload: {
            virtualActiveTab
        }
    }
};

const changeActiveFilter = (activeFilter) => {
    return {
        type: CHANGE_ACTIVE_FILTER,
        payload: {
            activeFilter
        }
    }
};

const getTransactionByIdPending = () => {
    return {
        type: `${FETCH_TRANSACTION_BY_ID}_PENDING`
    };
};

const getTransactionByIdSuccess = (data) => {
    return {
        type: `${FETCH_TRANSACTION_BY_ID}_SUCCESS`,
        payload: {
            data
        }
    };
};

const fetchTransactionsByFilter = (config = {}) => {

    return async(dispatch, getState, {AccountBetHistoryApi}) => {

        dispatch(fetchTransactionListPending());

        try {
            const betHistoryResponse = await AccountBetHistoryApi.getTransactionsByFilter(config);
            let {code, data} = betHistoryResponse;
            data = createBlocksForTransactions(data);

            const multiCashoutAvailable = process.env.MULTI_CASHOUT_AVAILABLE && JSON.parse(process.env.MULTI_CASHOUT_AVAILABLE);
            const multiCashoutIds = multiCashoutAvailable ? _map(data, ({transactionId}) => transactionId): false;

 
            const cashoutsPromise = dispatch(fetchCashouts(config, multiCashoutIds));
            await Promise.all([cashoutsPromise]);

            dispatch(fetchTransactionListSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchTransactionListFailure(error));
            throw error;
        }
    }
};

const fetchExternalBetHistorySuccess = (data) => {
    return {
        type: `${FETCH_EXTERNAL_BET_HISTORY}_SUCCESS`,
        payload: {
            data
        }
    };
};

const filteredExternalBets = (data) => {
    return {
        type: `${FILTERED_EXTERNAL_BET_HISTORY}`,
        payload: {
            data
        }
    };
};

const fetchExternalBetHistoryFailure = (error) => {
    return {
        type: `${FETCH_EXTERNAL_BET_HISTORY}_FAILURE`,
        payload: {error}
    };
};

const fetchExternalBetHistoryPending = () => {
    return {
        type: `${FETCH_EXTERNAL_BET_HISTORY}_PENDING`,
    };
};

const fetchExternalBetHistory = (params) => {
    return async (dispatch, getState, {AccountVirtualBetHistoryApi}) => {

        dispatch(fetchExternalBetHistoryPending());
        await dispatch(changeActiveTabOnVirtualTab('all'));

        try {
            const { virtualStartDate, virtualEndDate } = params;
            const data = {
                date_from: formatDate(virtualStartDate, 'yyyy-MM-dd'),
                date_to: formatDate(virtualEndDate, 'yyyy-MM-dd'),
            }

            const { body } = await AccountVirtualBetHistoryApi.getExternalBetHistory(data);
            let result = Object.values(body);
            result = createBlocksForTransactions(result);

            dispatch(fetchExternalBetHistorySuccess(result));
            dispatch(filteredExternalBets(result));
        } catch (error) {
            dispatch(fetchExternalBetHistoryFailure(error));
            throw error;
        }
    }
};

const filterExternalBetHistory = (data, virtualActiveTab) => {

    return async (dispatch, getState) => {
        let selectedTabCode;
        let result;
    
        let filteredData = createBlocksForTransactions(data);
        switch (virtualActiveTab) {
            case 'undecided':
                selectedTabCode = 1;
                result = filteredData.filter(item => item.statusCode == selectedTabCode);
                break;
            case 'lost':
                selectedTabCode = 2;
                result = filteredData.filter(item => item.statusCode == selectedTabCode);
                break;
            case 'won':
                selectedTabCode = 4; // in RGS status won is translated to cashedout
                result = filteredData.filter(item => item.statusCode == selectedTabCode);
                break;
            default:
                result = filteredData;
                break;
        }
    
        dispatch(filteredExternalBets(result));
    }
};

const processLiveCurtainForTxId = (slipId, curtainData) => {
    return async (dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            const {code, data} = await AccountBetHistoryApi.cashoutPrepareLiveCountDown(slipId, curtainData);
            if(code == 200){
                dispatch(toggleLiveCurtainForTxId(slipId, true));
            }else{
                throw {message: 'processLiveCurtainForTxId_error'}
            }
            return _get(data, ['value'])
        } catch (error) {
            throw error;
        }
    }
};

const processRebet = (eventId) => {
    return async(dispatch, getState, {EventsApi}) => {
        try {
            const {code, data} = await EventsApi.getById(eventId);
        } catch (error) {
            throw error;
        }
    }
};

const getTransactionById = (transactionId) => {
    return async(dispatch, getState, {TransactionApi}) => {
        dispatch(getTransactionByIdPending());
        try {
            const {code, data} = await TransactionApi.getBetSlipTransactions(transactionId);
            let dataWithBlocks = createBlocksForTransactions([data]);
            dispatch(getTransactionByIdSuccess(dataWithBlocks));
        } catch (error) {
            throw error;
        }
    }
};

const processCashoutAmount = (config) => {
    return async(dispatch, getState, {AccountBetHistoryApi}) => {

        const {slipId, type, ...model} = config;
        const cashoutType = AccountBetHistoryApi[type];

        try {
            const {code, data} = await cashoutType(slipId, model);
            if (_get(data, 'success')) {
                const hasCachoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
                if (hasCachoutLiveCurtain) {
                    dispatch(toggleLiveCurtainForTxId(slipId, false));
                }
                const {AccountBetHistory:{cashouts}} = getState();
                if(cashouts && _isObject(cashouts[0])){
                  dispatch(resetCashoutAmountForTxId(slipId));
                }
                return data;
            } else {
                const currentCashoutAmount = _get(data, 'currentCashoutAmount');
                if (currentCashoutAmount) {
                    dispatch(setMaxCashoutForTxId({amount: currentCashoutAmount}, slipId));
                    throw {message: 'cashoutInfo_oddsHasChanged', oddsHasChanged: true};
                }

                const messageKey = _get(data, 'messageKey');
                if (messageKey) {
                    throw {message: messageKey};
                }

                throw {message: `error_${code}`}
            }
        } catch (error) {
            throw error;
        }
    }
};

const fetchCashouts = (config, multiCashoutIds = false) => {
    return async (dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            if (multiCashoutIds && Array.isArray(multiCashoutIds) && !_size(multiCashoutIds)) {
                 return Promise.resolve();
            }

            let actionType = null;
            if(multiCashoutIds){
                actionType = AccountBetHistoryApi.getMultiCashouts.bind(null, multiCashoutIds)
            } else {
                actionType = AccountBetHistoryApi.getCashouts.bind(null, config);
            }

            const {code, data} = await actionType();
            dispatch(fetchCashoutsSuccess(data));

            return data;
        } catch (error) {
            throw error;
        }
    }
};

const fetchCashoutsMaximumAmount = (transactionId) => {
    return async(dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            const {code, data} = await AccountBetHistoryApi.cashoutMaximumAmount(transactionId);
            switch(code) {
                case 421: // session is expired
                case 620:
                case 1001: // cashout validation error || cashout not available
                case 1002: // cashout validation error || cashout not available
                throw code;
            }

            dispatch(setMaxCashoutForTxId(data, transactionId));
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const refreshBetHistoryList = () => {
    return async(dispatch, getState) => {

        const {AccountBetHistory:{startDate, endDate, limit, activeTab, currentPage}} = getState();
        const params = {
            endDate,
            startDate,
            limit,
            activeTab,
            currentPage
        };

        try {
            await dispatch(fetchTransactionsByFilter(params));
        } catch (error) {
            throw error;
        }
    }
};

const resetBetHistoryList = () => {
    return async(dispatch) => {

        dispatch({type: `${RESET_BET_HISTORY_LIST}`});

        try {
            await dispatch(refreshBetHistoryList());
        } catch (error) {
            throw error;
        }
    }
};

const fetchCashoutsSuccess = (cashouts) => {
    return {
        type: `${FETCH_CASHOUTS_LIST}_SUCCESS`,
        payload: {cashouts}
    }
};

const setMaxCashoutForTxId = (maxAmount, transactionId) => {
    return {
        type: `${SET_MAXIMUM_CASHOUT_AMOUNT}`,
        payload: {maxAmount, transactionId}
    }
};

const resetCashoutAmountForTxId = (transactionId) => {
    return {
        type: `${RESET_CASHOUT_AMOUNT_FOR_TX_ID}`,
        payload: {transactionId}
    }
}

const fetchTransactionListSuccess = (data) => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_SUCCESS`,
        payload: {data}
    }
};

const fetchTransactionListPending = () => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_PENDING`
    }
};

const fetchTransactionListFailure = (error) => {
    return {
        type: `${FETCH_TRANSACTIONS_LIST}_FAILURE`,
        payload: {error}
    }
};

const fetchCashoutNowAmount = (slipId) => {
    return async(dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            const {code, data} = await AccountBetHistoryApi.getCashoutNowAmount(slipId);
            if (code == 200){
                return data || 0;
            }

            return 0;

        } catch (error) {
            throw error;
        }
    }
};

const cashoutFullMarkForWithdraw = (slipId, cashoutCode, payload) => {
    return async(dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            const response = await AccountBetHistoryApi.cashoutFullMarkForWithdraw(slipId, cashoutCode, payload);
            const {data, code} = response;
            if (code != 200){
                throw response;
            }
            return data || [];
        } catch (error) {
            throw error;
        }
    }
};

const cashoutPartialMarkForWithdraw = (slipId, cashoutCode, payload) => {
    return async(dispatch, getState, {AccountBetHistoryApi}) => {
        try {
            const response = await AccountBetHistoryApi.cashoutPartialMarkForWithdraw(slipId, cashoutCode, payload);
            const {data, code} = response;
            if (code != 200){
                throw response;
            }
            return data || [];
        } catch (error) {
            throw error;
        }
    }
}; 


export {
    changeDateByType,
    changeActiveTab,
    fetchTransactionsByFilter,
    setCurrentPage,
    fetchCashoutsMaximumAmount,
    resetBetHistoryList,
    refreshBetHistoryList,
    processCashoutAmount,
    processRebet,
    changeActiveFilter,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    changeDateByTypeOnVirtualTab,
    changeActiveTabOnVirtualTab,
    setCurrentPageOnVirtualTab,
    getTransactionById,
    fetchExternalBetHistory,
    filterExternalBetHistory,
    filteredExternalBets,
    fetchCashoutNowAmount,
    cashoutFullMarkForWithdraw,
    cashoutPartialMarkForWithdraw,
}