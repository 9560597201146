import _find from 'lodash/find';

export default {

    getByType: function (type) {
        const collection = this;
        const object = _find(collection, (o) => {
            return (o.type == type)
        });
        return object ? object : {type: undefined, name: ''};
    },

    'SIMPLE': {type: 1, name: 'virtualBetSlip_type_SIMPLE'},
    'SYSTEM': {type: 2, name: 'virtualBetSlip_type_SYSTEM'},
    'MULTISYSTEM': {type: 3, name: 'virtualBetSlip_type_MULTISYSTEM'},

};
