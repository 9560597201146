import React, { Component } from 'react';
import { connect } from 'react-redux';
import S from 'StyledProviderContent.js';
import { translation, pushCustomEventToGoogleAnalytics, device, browser } from 'helpers/utilsHelper.js';
import { cmsPage } from 'cmsActions.js';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import Loader from 'Loader.js';
import { NotLogged } from 'global/styles/styles.js';
import ReactNotification from "react-notifications-component";
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import "react-notifications-component/dist/theme.css";
import {
    creditCardList,
    paystackBankList,
    bankList,
    accountTypes,
    sendPaymentData,
    wonAmountToPayout,
    customerBankDetail,
    changePinCode,
    billers,
    customerCreditCardUpdate } from 'paymentActions.js';

// Micro Components
import {
    Item,
    GroupComponents,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    ImgAndTextComponent,
    ImageComponent,
    ConfirmRowComponent,
    ConfirmDataComponent,
    CmsComponent,
    IframeComponent,
    ExternalComponent,
    LimitsComponent,
    MenuComponent,
    TextComponent,
    InfoWonAmountToPayoutComponent,
    RemoveCardCardComponent
} from 'ProviderMicroComponents.js';


// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validatorsPayments.enum.js';


class ProviderContent extends Component {

    constructor(props) {
        super(props);
        this.addNotification = this.addNotification.bind(this);
        this.removeCard = this.removeCard.bind(this)
        this.toggleFormValid = React.createRef();
        this.notificationDOMRef = React.createRef();
        this.state = this.getInitialState();
    }

    componentDidMount = () => {
        const {dispatch, providerForm:{autoInitialize}} = this.props;
        try {
            this.drawComponents();

            if (autoInitialize) {
                dispatch(this.props.handleSubmit(this.sendData));
            }
        } catch (error) {
            console.log('DrawComponent error:' + error);
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.toggleFormValid.current && (this.props.invalid !== prevProps.invalid || !this.props.invalid)) {
            this.toggleFormValid.current.disabled = this.props.invalid;
        }
    }

    componentWillUnmount = () => {
        this.setState(this.getInitialState());
    }

    getInitialState = () => {
        let initialize = null;

        if (this.props.providerForm && 'initialize' in this.props.providerForm) {
            initialize = this.props.providerForm.initialize;
        }

        const initialState = {
            step: 1,
            drawing: [],
            insideDrawing: [],
            confrimData: {},
            active: false,
            sendAction: null,
            postData: JSON.parse(JSON.stringify(initialize)),
            callData: {}
        }

        return initialState;
    }

    updatePostData = async(formData) => {
        let { postData, confrimData } = this.state;

        for (let item in formData) {
            if (formData[item] == '') {
                continue;
            } else if (item.includes('->')) {
                let pathName = this.splitPath(item);
                let pathValue = this.splitPath(formData[item]);

                if (pathName && pathName.size == 2 && pathValue && pathValue.size == 2) {

                    if (!(pathName.part[0] in postData)) {
                        await this.setState(state => (postData[pathName.part[0]] = {}, state));
                    }

                    this.setState(state => (postData[pathName.part[0]][pathName.part[1]] = pathValue.part[0], state));
                    this.setState(state => (confrimData[pathName.part[1]] = pathValue.part[1], state));

                } else if (pathName && pathName.size == 2 && pathValue == undefined) {

                    if (!(pathName.part[0] in postData)) {
                        await this.setState(state => (postData[pathName.part[0]] = {}, state));
                    }

                    this.setState(state => (postData[pathName.part[0]][pathName.part[1]] = formData[item], state));
                    this.setState(state => (confrimData[pathName.part[1]] = formData[item], state));
                }
            } else {
                this.setState(state => (postData[item] = formData[item], state));
                this.setState(state => (confrimData[item] = formData[item], state));
            }
        }
    }

    splitPath = (name) => {
        let path = name.split('->');
        if (path.length > 1) {
            return {
                part: path,
                size: path.length
            };
        }
    }

    combineData = (data) => {
        const { name, value } = data;
        this.setState(state => (state.postData[name] = value, state));
    }

    combinePathData = (data) => {
        const { path, name, value } = data;
        this.setState(state => (state.postData[path][name] = value, state));
    }

    sendAction = async(postData, formData) => {
        let {sendAction} = this.state;
        const {dispatch} = this.props;

        switch(sendAction) {
            case 'setNewPinCode':
                const pinData = {
                    oldSecurityToken: formData.oldSecurityToken,
                    newSecurityToken: formData.oldSecurityToken
                }

                const result = await dispatch(changePinCode(pinData));
                if ('success' in result && result.success) {
                    this.addNotification('success', translation('payments_success'), translation('payments_successChangePinCode'));
                } else {
                    this.addNotification('danger', translation('payments_error'), translation('payments_errorChangePinCode'));
                }

                return result;
            default:
        }       return await dispatch(sendPaymentData((
            { 'process': {
                ...postData,
                iframeDomain: app.config.domainUrl
            } }[this.convertStep()] ||
            postData
        ), this.convertStep()));
    }

    sendData = async (formData) => {

        let { postData, step } = this.state;
        const { paymentData: { isDeposit, providerId }, providerForm: { insideIframe }, userData: {userId, balance}, dispatch } = this.props;
        let { providerForm: { steps }} = this.props;
        const {facebookPixel} = paymentPrepareTransaction.LIST_SETTINGS;

        await this.updatePostData(formData);
        formData = this.findPostData(formData);
        this.setState({ drawing: [<Loader />] })

        try {
            this.removeNotification();

            const result = await this.sendAction(postData, formData);
            const checkResult = this.checkResponse(result);

            if (checkResult && 'steps' in checkResult) {
                steps = checkResult.steps;
            }
               
            if (checkResult.goToNextStep) {
                const { success, externalRequestUrl, externalRequestForm, responseCode, remoteResponseCode, transactionCurrency, transactionAmount } = result;

                if (success && step != steps) {
                    this.setState({ step: step + 1, drawing: [], insideDrawing: [] })
                    this.setState(this.drawComponents());

                } else if (success && step == steps) {

                    if (isDeposit && facebookPixel) {
                        fbq('track', 'Purchase', {
                                currency: transactionCurrency ? transactionCurrency: postData.transactionCurrency, 
                                value: transactionAmount ? transactionAmount : postData.transactionAmount,
                            }    
                        );
                    }
                    if (isDeposit && app.config.dataLayerEvents.indexOf('deposit-success') > -1) {
                        pushCustomEventToGoogleAnalytics({
                            event: 'deposit-success',
                            transactionAmount: transactionAmount ? transactionAmount.toFixed(2) : formData.transactionAmount,
                            customerId: userId,
                            customerBalance: balance,
                            device: device(),
                            browser: browser(),
                            providerId: providerId,
                        });
                    }
                    if (externalRequestUrl) {
                      insideIframe ? this.renderIframe(externalRequestUrl) : window.location = externalRequestUrl
                    } else if (externalRequestForm) {
                        this.goToExternalForm(externalRequestForm);
                    } else {
                        this.addNotification('success', translation('payments_success'), translation('payments_success_' + this.paymentType(isDeposit)));
                        this.resetProvider();
                    }
                } else {
                    if (remoteResponseCode) {
                        this.addNotification('danger', translation('payments_error'), translation('payments_error_' + remoteResponseCode));
                    } else if (responseCode) {
                        this.addNotification('danger', translation('payments_error'), translation('payments_error_' + responseCode));
                    }
                    if (isDeposit && app.config.dataLayerEvents.indexOf('deposit-failed') > -1) {
                        pushCustomEventToGoogleAnalytics({
                            event: 'deposit-failed',
                            transactionAmount: formData.transactionAmount,
                            customerId: userId,
                            customerBalance: balance,
                            device: device(),
                            browser: browser(),
                            providerId: providerId,
                            error: remoteResponseCode ? translation(`payments_error_${remoteResponseCode}`) : translation(`payments_error_${responseCode}`),
                        });
                    }
                    this.resetProvider();
                }
            }

        } catch (error) {
            if (isDeposit && app.config.dataLayerEvents.indexOf('deposit-failed') > -1) {
                pushCustomEventToGoogleAnalytics({
                    event: 'deposit-failed',
                    transactionAmount: formData.transactionAmount,
                    customerId: userId,
                    customerBalance: balance,
                    device: device(),
                    browser: browser(),
                    providerId: providerId,
                    error: error.message,
                });
            }
            this.resetProvider();
            throw this.addNotification('danger', translation('payments_error'), translation('payments_generalError'));;
        }
    }

    paymentType = (isDeposti) => {
        return isDeposti ? 'deposit' : 'payout';
    }

    checkResponse = (result) => {
        this.setState({responseData: result});
        let {step} = this.state;

        const {userData, providerForm:{steps, renderAccount}} = this.props;

        if (steps == 3 && 'transactionId' in result) {
            this.setState(state => (state.postData['transactionId'] = result.transactionId, state));
        }

        if (step == 2 && 'responseCode' in result && result.responseCode == 28) {
            this.setNewPinCode();
            this.setState({sendAction: 'setNewPinCode'});
            return {
                goToNextStep: false
            }
        }

        if ('bank' in result && renderAccount) {
            const {bank} = result;

            for (const key in bank) {
                this.setState(state => (state.confrimData[key] = bank[key], state));
            }

            if (userData) {
                const {firstName, lastName} = userData;
                this.setState(state => (state.confrimData['accountName'] = `${firstName} ${lastName}` , state));
            }
        }

        if ('remoteResponseCode' in result && result.remoteResponseCode == '1023') {
            this.setState(state => (state.postData['transactionId'] = result.transactionId, state));
            return {
                steps: 3,
                goToNextStep: true
            }
        }

        return {
            goToNextStep: true
        }
    }

    goToExternalForm = (form) => {
        this.setState({ drawing: [<ExternalComponent item={form} />] });
        const externalForm = document.getElementById('externalForm');
        externalForm.children[0].submit();
    }

    renderIframe = (url) => {
        this.setState({drawing: [<IframeComponent url={url} />]});
    }

    addNotification = (type, title, message, content, container = 'top-center', insert = 'top') => {
        this.removeNotification();

        this.notificationDOMRef.current.addNotification({
            title: title,
            message: message,
            type: type,
            insert: insert,
            container: container,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismissable: { click: true },
            content: content
        });
    }

    removeNotification = () => {
        const { notifications } = this.notificationDOMRef.current.state;

        if (Array.isArray(notifications) && notifications.length) {
            this.notificationDOMRef.current.removeNotification(notifications[0].id);
        }
    }

    convertStep = () => {
        let { step } = this.state;

        if (step == 1) {
            return 'initialize';
        } else if (step == 2) {
            return 'process';
        } else {
            return 'verify';
        }
    }

    setNewPinCode = () => {

        const conditionalDrawing = [];

        this.setState({drawing: conditionalDrawing});

        conditionalDrawing.push(<TextComponent
            label={'payment_setNewPinCodeInfo'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'oldSecurityToken'}
            type={'password'}
            label={'payment_oldPinCode'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'newSecurityToken'}
            type={'password'}
            label={'payment_newPinCode'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'confirmNewSecurityToken'}
            type={'password'}
            label={'payment_confirmNewPinCode'}
        />);

        conditionalDrawing.push(<ButtonComponent
            label={'payment_changePinCode'}
            onClick={'submit'}
        />);

        this.setState({ drawing: conditionalDrawing, step: 2})
    }

    changePinCode = async () => {
        const { dispatch } = this.props;

        const pinData = {
            oldSecurityToken: this.state.postData.oldSecurityToken,
            newSecurityToken: this.state.postData.oldSecurityToken
        }
        const result = await dispatch(changePinCode(pinData));
    }

    drawComponents = async (itemsRecursive) => {
        const { providerForm } = this.props;

        if (providerForm) {

            const { providerForm: { stepOne, stepTwo, stepThree, callData }, paymentData: { providerId } } = this.props;
            const { step, drawing, insideDrawing } = this.state;

            if ('callData' in providerForm && itemsRecursive == undefined) {
                if (Array.isArray(callData)) {
                    for (let item of callData) {
                        await this.findAssignedFunction(item, providerId);
                    }
                } else {
                    await this.findAssignedFunction(callData, providerId);
                }
            }

            if (step == 1 && itemsRecursive == undefined) {
                for (let item of stepOne) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }

            } else if (step == 2 && itemsRecursive == undefined) {
                for (let item of stepTwo) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }
            } else if (step == 3 && itemsRecursive == undefined) {
                for (let item of stepThree) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }
            } else {
                for (let item of itemsRecursive) {
                    this.setState({ insideDrawing: this.findRequiredComponent(item, insideDrawing) });
                }

            }
        }
    }

    findRequiredComponent = (item, drawing) => {
        const keyIndex = 0;
        const valueIndex = 1;

        switch (Object.keys(item)[keyIndex]) {
            case 'ImageComponent': {
                drawing.push(<ImageComponent src={item.ImageComponent} />);
                break;
            }
            case 'TextComponent': {
                const {label} = item.TextComponent;
                drawing.push(<TextComponent
                    label={label}
                />);
                break;
            }
            case 'ImgAndTextComponent': {
                const { label, imageComponent } = item.ImgAndTextComponent;

                drawing.push(<ImgAndTextComponent
                    imageComponent={imageComponent}
                    label={label}
                />);
                break;
            }
            case 'InputComponent': {
                let { placeholder, required, onChange, type, name, label, limitsProvider, className } = item.InputComponent;
                onChange = this.findAssignedFunction(onChange);
                const { paymentData: { limits, isDeposit }, userData} = this.props;

                if (name == 'transactionAmount') {
                    limitsProvider = this.setLimitsProvider(limitsProvider, limits);
                }

                drawing.push(<InputComponent
                    placeholder={placeholder}
                    onChange={onChange}
                    name={name}
                    type={type}
                    label={label}
                    limits={limitsProvider}
                    required={required}
                    className={className}
                    isDeposit={isDeposit}
                    balance={userData ? userData.balance : null}
                />);
                break;
            }
            case 'SelectComponent': {
                let { data, name, text, first, onChange, value, label, required, disabled, removeOption } = item.SelectComponent;
                try {
                    data = this.extractDataForSelect(this.state.callData[data], value, text);
                } catch (error) {
                    this.resetProvider();
                    throw this.addNotification('danger', translation('payments_error'), translation('payments_generalError'));;
                }

                if (first) {
                    data.unshift({ name: translation(first), value: '' });
                }

                if (removeOption && typeof removeOption.onClick == 'string') {
                    removeOption.onClick = this.findAssignedFunction(removeOption.onClick);
                }

                if (onChange) {
                    onChange = this.findAssignedFunction(onChange);
                }

                drawing.push(<SelectComponent
                    data={data}
                    onChange={onChange}
                    name={name}
                    label={label}
                    required={required}
                    disabled={disabled}
                    removeOption={removeOption}
                />);
                break;
            }
            case 'ButtonComponent': {
                let {label, onClick, disabled, action, type, disabledCondition} = item.ButtonComponent;
                onClick = this.findAssignedFunction(onClick);
                action = this.findAssignedFunction(action);
                disabled = disabledCondition ? this.findDisabledCondition(disabledCondition) : disabled;

                
                drawing.push(<ButtonComponent
                    ref={type == 'submitting' ? this.toggleFormValid : null}
                    disabled={disabled}
                    label={label}
                    onClick={onClick}
                    type={type}
                />);
                

                break;
            }
            case 'GroupComponents': {
                this.drawComponents(item.GroupComponents);

                drawing.push(
                    <GroupComponents items={this.state.insideDrawing.map((item, index) => <Item item={item} key={index}></Item>)}></GroupComponents>
                );
                break;
            }
            case 'ConfirmDataComponent': {
                const data = this.findConfirmData();
                let {titleLabel, label, value} = item.ConfirmDataComponent;

                drawing.push(
                    <ConfirmDataComponent
                        titleLabel={titleLabel}
                        label={label}
                        value={value}

                        item={data.map((item, index) => <ConfirmRowComponent
                            label={item.label}
                            value={item.value}
                            key={index}
                        />)}
                    />
                );
                break;
            }
            case 'CmsComponent': {
                const { data } = item.CmsComponent;
                const { providerId } = this.props.paymentData;
                drawing.push(<CmsComponent item={this.state.callData[data + providerId]} />);
                break;
            }
            case 'LimitsComponent': {
                const { min, max, fee } = item.LimitsComponent;
                const { limits, currencies: [transactionCurrency] } = this.props.paymentData;

                if (limits) {
                    drawing.push(<LimitsComponent
                        min={limits.minAmount ? limits.minAmount : 1}
                        max={limits.maxTotalAmountOnAttempts ? limits.maxTotalAmountOnAttempts : undefined}
                        fee={limits.minFeeAmount ? limits.minFeeAmount : undefined}
                        feeLabel={fee}
                        minLabel={min}
                        maxLabel={max}
                        transactionCurrency={transactionCurrency}

                    />);
                }
                break;
            }
            case 'MenuComponent': {
                const { data } = item.MenuComponent;
                drawing.push(<MenuComponent items={this.state.callData[data]} />);
                break;
            }

            case 'InfoWonAmountToPayoutComponent': {
                let { data, labelLocked, labelUnlocked } = item.InfoWonAmountToPayoutComponent;
                const { transactionAmount, transactionCurrency } = this.state.callData[data];

                drawing.push(
                    <InfoWonAmountToPayoutComponent
                        transactionAmount={transactionAmount}
                        transactionCurrency={transactionCurrency}
                        labelLocked={labelLocked}
                        labelUnlocked={labelUnlocked}
                    />
                );
            }

            default:
                console.log('Payments, component not found!');
        }

        return drawing;
    }

    findDisabledCondition = (data) => {
        switch (data) {
            case 'callWonAmountToPayout': {
                return !(this.state.callData[data].transactionAmount > 0);
            }
            default: {
                return false;
            }

        }

    }

    findConfirmData = () => {
        let { confrimData } = this.state;
        const { confirmUserData } = this.props.providerForm;
        let data = [];

        for (let item of confirmUserData) {
            if (Object.keys(item) in confrimData) {
                let temp = {};
                temp['label'] = item[Object.keys(item)].label;
                temp['value'] = confrimData[Object.keys(item)];

                data.push(temp);
            }
        }

        return data;
    }

    findPostData = (formData) => {

        let { providerForm: { isBettingShopTransaction, isShopUser, isAgentConsoleTransaction, forceFullTransferLimitsCheck, skipCountingFee}, paymentData, userData: { currencyCode, email, firstName, lastName } } = this.props;
        let { postData } = this.state;

        if ('providerId' in postData && postData.providerId == '') {
            this.combineData({ name: 'providerId', value: paymentData['providerId'] });
        }

        if ('transactionCurrency' in postData && postData.transactionCurrency == '') {
            this.combineData({ name: 'transactionCurrency', value: currencyCode ? currencyCode : paymentData.currencies[0]});
        }

        if ('callbackUrl' in postData) {
            let { protocol, host } = window.location;

            const MOBILE_URL = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;
            const callbackUrl = protocol + "//" + host + MOBILE_URL;
            if (postData.callbackUrl == '') {
                this.combineData({ name: 'callbackUrl', value: callbackUrl });
            } else if (!postData.callbackUrl.includes(window.location.protocol)) {
                this.combineData({ name: 'callbackUrl', value: callbackUrl + postData.callbackUrl });
            }
        }

        if ('isBettingShopTransaction' in postData && postData.isBettingShopTransaction == '') {
            this.combineData({ name: 'isBettingShopTransaction', value: isBettingShopTransaction });
        }

        if ('isAgentConsoleTransaction' in postData && postData.isAgentConsoleTransaction == '') {
            this.combineData({ name: 'isAgentConsoleTransaction', value: isAgentConsoleTransaction });
        }

        if ('forceFullTransferLimitsCheck' in postData && postData.forceFullTransferLimitsCheck == '') {
            this.combineData({ name: 'forceFullTransferLimitsCheck', value: forceFullTransferLimitsCheck });
        }

        if ('skipCountingFee' in postData && postData.skipCountingFee == '') {
            this.combineData({ name: 'skipCountingFee', value: skipCountingFee });
        }

        if ('customer' in postData) {
            if ('email' in postData.customer && postData.customer.email == '') {
                this.combinePathData({ path: 'customer', name: 'email', value: email });
            }
            if ('firstName' in postData.customer && postData.customer.firstName == '') {
                this.combinePathData({ path: 'customer', name: 'firstName', value: firstName });
            }
            if ('lastName' in postData.customer && postData.customer.lastName == '') {
                this.combinePathData({ path: 'customer', name: 'lastName', value: lastName });
            }
            if ('isShopUser' in postData.customer && postData.customer.isShopUser == '') {
                this.combinePathData({ path: 'customer', name: 'isShopUser', value: isShopUser });
            }
        }

        if ('bank' in postData) {
            if ('customerName' in postData.bank && postData.bank.customerName == '') {
                this.combinePathData({ path: 'bank', name: 'customerName', value: firstName + ' ' + lastName });
            }
            if ('additionalInformationsJSON' in postData.bank) {
                let customerName = firstName + ' ' + lastName;
                this.combinePathData({ path: 'bank', name: 'additionalInformationsJSON', value: { customerName } });
            }
        }

        if ('remoteTransactionAmount' in postData && postData.remoteTransactionAmount == '') {
            this.combineData({ name: 'remoteTransactionAmount', value: formData.transactionAmount });
        }

        return formData;
    }

    setLimitsProvider = (limitsProvider, limits) => {

        if (limitsProvider && limits) {
            limitsProvider = limits;
        } else if (limits && 'onlyWinningsCanBePaidOut' in limits) {
            const {callWonAmountToPayout, callWonAmountToPayout: {success}} = this.state.callData;

            if (success && 'transactionAmount' in callWonAmountToPayout) {
                limitsProvider = {
                    maxTotalAmountOnAttempts: callWonAmountToPayout.transactionAmount
                }
            } else {
                limitsProvider = undefined;
            }

        } else {
            limitsProvider = undefined;
        }

        return limitsProvider;
    }

    findAssignedFunction = (name, providerId) => {
        switch (name) {
            case 'activeProvider':
                return this.activeProvider();
            case 'callCreditCardList':
                return this.getCreditCardList(name, providerId);
            case 'callPaystackBankList':
                return this.getPaystackBankList(name, providerId);
            case 'callBankList':
                return this.getBankList(name);
            case 'callAccountTypes':
                return this.getAccountTypes(name);
            case 'updatePostData':
                return this.updatePostData;
            case 'submit':
                return 'submit';
            case 'backStep':
                return this.backStep;
            case 'resetProvider':
                return this.resetProvider;
            case 'cmsPage':
                return this.getCmsPage(name, providerId);
            case 'callWonAmountToPayout':
                return this.getWonAmountToPayout(name, providerId);
            case 'callCustomerBankDetail':
                return this.getCustomerBankDetail(name);
            case 'callBillers':
                return this.getBillers(name);
            case 'removeCardConfirm':
                return this.removeCardConfirm;
        }
    }

    removeCardConfirm = () => {
        const card =  this.props.observedSelectorsValeus['card->cardType'].split('->');
        const [cardType, cardNumber] = card;
        this.addNotification(null, null, null, 
            <RemoveCardCardComponent cardType={cardType} cardNumber={cardNumber} onClickAccept={this.removeCard} onClickCancel={this.removeNotification}/>);
    }

    removeCard = async(cardType) => {
        const [card] = this.state.callData['callCreditCardList'].filter(key => (key.cardType == cardType));
        try {
            const data = await this.props.dispatch(customerCreditCardUpdate({activeCard: false, creditCardId: card.creditCardId}));
            if (_.isEmpty(data)) {
                this.resetProvider();
            }
        } catch (error) {
            throw this.showLogAndNotification('removeCard', error);
        }
    }

    activeProvider = () => {
        this.setState({ active: true });
    }

    backStep = async () => {
        await this.setState(this.getInitialState());
        this.setState(this.drawComponents());
    }

    showLogAndNotification = (functionName, error) => {
        console.log(`Error in function ${functionName}!!! Error: ${error.message}`);
        this.addNotification('danger', translation(`payments_${error.message}`), translation('payments_generalError'));
    }

    resetProvider = async () => {
        const { dispatch } = this.props;

        await dispatch(reset(this.props.form));
        await this.setState(this.getInitialState(reset));
        this.setState(this.drawComponents());
    }

    extractDataForSelect = (selectData, value, name) => {
        let out = [];

        selectData.map((item) => {
            out.push({
                value: item[value] + '->' + item[name],
                name: item[name]
            })
        })

        return out;
    }

    render() {
        const { handleSubmit, hasError, isPending, isLogged } = this.props;
        const controller = app.getCurrentController();

        if (hasError) {
            return <div>Error fallback component!</div>
        }

        if (isPending) {
            return <Loader />
        }

        if (!isLogged && controller != 'AdminDeposit' && !isPending) {
            return <NotLogged>{translation('payment_notLogged')}</NotLogged>
        }

        return (
            <S.ProviderContent method="post" autoComplete="off" className="providerForm" onSubmit={handleSubmit(this.sendData)}>
                <ReactNotification ref={this.notificationDOMRef} />
                {this.state.drawing.map((item, index) => <Item item={item} key={index}></Item>)}
            </S.ProviderContent>
        );
    }

    //////////////// API FUNCTION  ////////////////

    getCreditCardList = async (name, providerId) => {
        const { dispatch, callCreditCardList } = this.props;
        try {
            let list = await dispatch(creditCardList(providerId))
            this.setState(state => (state.callData[name] = list, state));
        } catch (error) {
            throw this.showLogAndNotification('getCreditCardList', error);
        }
    }

    getPaystackBankList = async (name, providerId) => {
        const { dispatch, callPaystackBankList } = this.props;
        try {
            if (!callPaystackBankList) {
                let list = await dispatch(paystackBankList(providerId))
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callPaystackBankList, state));
            }
        } catch (error) {
            console.log('getPaystackBankList: ' + error);
        }
    }

    getBankList = async (name) => {
        const { dispatch, callBankList } = this.props;
        try {
            if (!callBankList) {
                let list = await dispatch(bankList())
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callBankList, state));
            }
        } catch (error) {
            console.log('getBankList: ' + error);
        }
    }

    getAccountTypes = async (name) => {
        const { dispatch, callAccountTypes } = this.props;
        try {
            if (!callAccountTypes) {
                let list = await dispatch(accountTypes())
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callAccountTypes, state));
            }
        } catch (error) {
            console.log('getAccountTypes: ' + error);
        }
    }

    getCmsPage = async (name, providerId) => {
        const { dispatch } = this.props;
        try {
            const page = await dispatch(cmsPage('paymentsprovider-' + providerId));
            this.setState(state => (state.callData[name + providerId] = page, state));
        } catch (error) {
            console.log('getAccountTypes: ' + error);
        }
    }

    getWonAmountToPayout = async (name, providerId) => {
        const { dispatch, userData: { currencyCode, userId } } = this.props;
        try {
            const obj = await dispatch(wonAmountToPayout({ providerId, transactionCurrency: currencyCode, customerId: userId }));
            this.setState(state => (state.callData[name] = obj, state));
        } catch (error) {
            console.log('getAccountTypes: ' + error);
        }
    }

    getAccountTypes = async (name) => {
        const { dispatch, callAccountTypes } = this.props;
        try {
            if (!callAccountTypes) {
                let list = await dispatch(accountTypes())
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callAccountTypes, state));
            }
        } catch (error) {
            console.log('getAccountTypes: ' + error);
        }
    }

    getCustomerBankDetail = async (name) => {
        const { dispatch } = this.props;
        try {
            let data = await dispatch(customerBankDetail())
            this.setState(state => (state.callData[name] = data, state));
        } catch (error) {
            console.log('getCustomerBankDetail: ' + error);
        }
    }

    getBillers = async (name) => {
        const { dispatch, callBillers } = this.props;
        try {
            if (!callBillers) {
                let list = await dispatch(billers())
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callBillers, state));
            }
        } catch (error) {
            console.log('getBillers: ' + error);
        }
    }

    //////////////// ********* ////////////////
}

//////////////// VALIDATORS ////////////////

const validate = (values, constraints) => {
    const { providerId } = constraints.paymentData;
    return validation(values, validators['PROVIDER_' + providerId]);;
};

//////////////// ********* ////////////////

const mapStateToProps = (state, props) => {
    let { providerForm } = props;
    let callData = null;
    let callDataSize = null;
    let initialValues = {};

    if (providerForm && 'callData' in providerForm) {
        callData = providerForm.callData;
        Array.isArray(callData) ? callDataSize = callData.length : callDataSize = 1;
    }

    let { ProviderContent: { isPending, callCreditCardList, callPaystackBankList, callBankList, callAccountTypes, callWonAmountToPayout, callCustomerBankDetail, callBillers, error }, Cms: { callCmsPage } } = state;

    if (callData) {
        isPending = true;

        if (callCreditCardList && callDataSize == 1) {
            isPending = false;
        }

        if (callPaystackBankList && callDataSize == 1) {
            isPending = false;
        }

        if (callCmsPage && callDataSize == 1) {
            isPending = false;
        }

        if (callBillers && callDataSize == 1) {
            isPending = false;
        }

        if (callWonAmountToPayout && callDataSize == 1) {
            isPending = false;
        }

        if (callPaystackBankList && callCmsPage && callDataSize == 2) {
            isPending = false;
        }

        if (callBankList && callAccountTypes && callDataSize == 2) {
            isPending = false;
        }

        if (callCreditCardList && callCmsPage && callDataSize == 2) {
            isPending = false;
        }

        if (callCustomerBankDetail && callAccountTypes && callBankList && callCmsPage && callDataSize == 4) {

            const { accountNumber, accountType, bankId } = callCustomerBankDetail;
            initialValues["bank->accountNumber"] = accountNumber;
            callAccountTypes.map((item) => {
                if (item.accountType == accountType) {
                    initialValues["bank->accountType"] = accountType + '->' + item.accountTypeName;
                }
            })
            callBankList.map((item) => {
                if (item.bankId == bankId) {
                    initialValues["bank->bankId"] = bankId + '->' + item.bankName;
                }
            })

            isPending = false;
        }

        if (callCustomerBankDetail && callAccountTypes && callBankList && callCmsPage && wonAmountToPayout && callDataSize == 5) {

            const { accountNumber, accountType, bankId } = callCustomerBankDetail;
            initialValues["bank->accountNumber"] = accountNumber;
            callAccountTypes.map((item) => {
                if (item.accountType == accountType) {
                    initialValues["bank->accountType"] = accountType + '->' + item.accountTypeName;
                }
            })
            callBankList.map((item) => {
                if (item.bankId == bankId) {
                    initialValues["bank->bankId"] = bankId + '->' + item.bankName;
                }
            })

            isPending = false;
        }
    }


    if (!isPending) {
        return {
            isPending,
            error,
            callCreditCardList,
            callPaystackBankList,
            callBankList,
            callAccountTypes,
            callCmsPage,
            callWonAmountToPayout,
            initialValues
        }
    } else {
        return {
            isPending,
            error,
            callCreditCardList,
            callPaystackBankList,
            callBankList,
            callAccountTypes,
            callCmsPage,
            callWonAmountToPayout
        }
    }
};

ProviderContent = reduxForm({
    validate,
})(ProviderContent)


ProviderContent = connect(
    (state, props) => {
        if (props.providerForm.observedSelectors) {
            const {observedSelectors} = props.providerForm; 
            const selector = formValueSelector(props.form);
            const observedSelectorsValeus = {};
            observedSelectors.map( item => {
                observedSelectorsValeus[item] = selector(state, item);
            })
            
            return {
                observedSelectorsValeus
            }
        } else {
            return {};
        }
    }
)(ProviderContent);

export default connect(mapStateToProps)(ProviderContent);
