import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};
const imagePath = process.env.MOBILE_URL;

const duration = 300;

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyOswald = ({theme: {$textOswaldRegular}}) => $textOswaldRegular;

S.BetHistoryItemDetails = styled.div`
  
`;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.BetHistoryItemHeader = styled.div`
    display: flex;
    background: #fff;
    color: #061957;
    padding: 0;
    height: 2.5625rem;
    line-height: 2.5625rem;
    font-size: 0.875rem;
    font-family: "Oswald", sans-serif;
    font-weight: 500;

    .back {
        width: 2.625rem;
        text-align: center;
        background: url(${imagePath}images/svg/arrow-right-navy-blue.svg) no-repeat;
        background-size: 0.5rem 0.875rem;
        background-position: center;
        transform: rotate(180deg);
    }

    .header-title {
        font-size: 1rem;
        padding-left: .8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
    }
`;

S.BetHistoryBlockHeader = styled.div`
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterBackground}})=> $accountProfileBetHistoryTransactionDetailsFooterBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterColor}})=> $accountProfileBetHistoryTransactionDetailsFooterColor};
    height: 1.5rem;
    padding: 0 0.75rem;
    font-family: ${FontFamilyOswald};
`;

S.BetHistoryDetailsWrapper = styled.div`
    margin: 1rem 1rem 0 1rem;
    border 1px solid: ${({theme: {$accountProfileBetHistoryTransactionItemBorder}}) => $accountProfileBetHistoryTransactionItemBorder};
`;

S.BetHistoryDetailsHeader = styled.div`
    display: flex;
    height: 2rem;
    flex-direction: row;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    padding: 0 0.75rem;
    font-family: ${FontFamilyOswald};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionHeaderBackground}})=> $accountProfileBetHistoryTransactionHeaderBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionHeaderTxtColor}})=> $accountProfileBetHistoryTransactionHeaderTxtColor};
    justify-content: space-between;
`;

S.BetHistoryDetailsHeaderInfoId = styled.div`
`;

S.BetHistoryDetailsHeaderActions = styled.div`
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 1.4375rem;
`;

S.BetHistoryDetailsHeaderGoBack = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 1.33rem;
    background: url(/images/svg/ic_expand_close.svg) no-repeat;
    background-size: contain;
`;

S.BetHistoryDetailsHeaderTitle = styled.div`
    display: flex;
    padding-bottom: 2px;
    font-size: 1.38rem;
    font-weight: 600;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderTitle}})=> $accountProfileBetHistoryTransactionDetailsHeaderTitle};
`;

S.BetHistoryItemDetails = styled.div`
    border-left: 1px solid ${({theme: {$accountProfileBetHistoryTransactionDetailsBorder}}) => $accountProfileBetHistoryTransactionDetailsBorder};
    border-right: 1px solid ${({theme: {$accountProfileBetHistoryTransactionDetailsBorder}}) => $accountProfileBetHistoryTransactionDetailsBorder};
`;

S.BetHistoryDetailsBody = styled.div`
`;

S.BetHistorySingleItemHeaderInfo = styled.div`
    background-color: ${({theme:{$accountProfileBetHistoryTransactionItemBackground}})=> $accountProfileBetHistoryTransactionItemBackground};
    color: ${({theme: {$accountProfileBetHistoryTransactionItemDateColor}}) => $accountProfileBetHistoryTransactionItemDateColor};
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 1rem;
    .event-name-info,
    .event-time-info {
        display: flex;
        flex-direction: column;
    }
    .event-name-info {
        padding-right: 0.5rem;
        div {
            font-size: 0.85rem;
            font-weight: bold;
            margin-bottom: 0.25rem;
        }
    }
    .event-time-info {
        opacity: 0.5;
        width: 6rem;
        text-align: right;
    }
`;

S.BetHistorySingleItemBetInfo = styled.div`
    display: flex;
    color: ${({theme: {$accountProfileBetHistoryTransactionItemDateColor}}) => $accountProfileBetHistoryTransactionItemDateColor};
    padding: 1rem;
    .event-infos {
        display: flex;
        flex-direction: column;
        padding-right: 1rem;
        width: 100%;
        .info-row {
            display: flex;
            & > div:first-of-type {
                flex-basis: 25%;
            }
            & > div:last-of-type {
                flex: 1;
                font-weight: bold;
            }
        }
    }
    .event-status {
        display: flex;
        align-items: center;
    }
    
`;


S.BetHistoryDetailsHeaderRebet = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 1.88rem;
    margin-left: auto;
    padding: 0 0.88rem;
    line-height: 1;
    font-size: 0.88rem;
    font-weight: 600;
    border-radius: 3px;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsRebetBackground}})=> $accountProfileBetHistoryTransactionDetailsRebetBackground};
    cursor: pointer;
`;

S.BetHistoryItemsFooter = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1.25rem;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterBackground}})=> $accountProfileBetHistoryTransactionDetailsFooterBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterColor}})=> $accountProfileBetHistoryTransactionDetailsFooterColor};
`;

S.BetHistoryItemsFooterDetailsContainer = styled.div`
    display: flex;
`;

S.BetHistoryItemsFooterLabels = styled.div`
    width: 50%;
    div {
        opacity: 0.5;
        height: 26px;
        line-height: 26px;
        padding: 1px 0;
    }
`;

S.BetHistoryItemsFooterValues = styled.div`
    width: 50%;
    text-align: right;
    div {
        height: 26px;
        line-height: 26px;
        padding: 1px 0;
        font-family: ${FontFamilyOswald}
    }
`;

S.BetHistoryDetailsCashoutButton = styled.div`
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsCashoutBackground}})=> $accountProfileBetHistoryTransactionDetailsCashoutBackground};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsCashoutColor}})=> $accountProfileBetHistoryTransactionDetailsCashoutColor};
    border-radius: 30px;
    margin-top: 1rem;
    display: flex;
    height: 2.5rem;
    font-family: ${FontFamilyOswald};
    justify-content: center;
    align-items: center;
    .cashout-text {
        font-size: 0.9rem;
        padding-right: 0.5rem;
    }
    .cashout-value {
        font-size: 1rem;
        font-weight: bold;
        padding-left: 0.5rem;
    }
`;

S.BetHistoryDetailsSummary = styled.div`
    display: flex;
    height: 4rem;
    font-family: ${FontFamilyOswald};
    & > div {
        width: 50%;
    }
    .footer-logo {
        background-color: ${({theme: {$accountProfileBetHistoryTransactionDetailsFooterLogoBackground}}) => $accountProfileBetHistoryTransactionDetailsFooterLogoBackground};
        background-image: url(${imagePath}images/bet_details_footer_logo.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
`;

S.PossibleWinWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({theme: {$accountProfileBetHistoryTransactionDetailsFooterWonAmountBackground}}) => $accountProfileBetHistoryTransactionDetailsFooterWonAmountBackground};
    color: ${({theme: {$accountProfileBetHistoryTransactionDetailsFooterWonAmountColor}}) => $accountProfileBetHistoryTransactionDetailsFooterWonAmountColor};
    text-align: right;
    flex: 1;
    justify-content: center;
    padding-right: 1.5rem;
`;

S.PossibleWinLabel = styled.div`
    font-size: 0.9rem;
`;

S.PossibleWinValue = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

S.StatusIcon = styled.div`
    height: 14px;
    width: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    &.pending_acceptation {
        background-image: url(/images/icon_pending.png);
    }

    &.won {
        background-image: url(/images/icon_won.png);
    }

    &.cashout {
        background-image: url(/images/icon_cashout.png);
    }

    &.cancel {
        background-image: url(/images/icon_cancel.png);
    }

    &.cashback {
        background-image: url(/images/icon_cashback.png);
    }

    &.lost {
        background-image: url(/images/icon_lost.png);
    }

    &.placed {
        background-image: url(/images/icon_pending.png);
    }
`;

S.BetHistoryItemsFooterInfo = styled(S.BetHistoryItemsFooterLabels)`
    align-items: flex-end;
`;

S.FreebetIcon = styled.div`
 width:  25px;
     margin-left: auto;
         margin-top: 7px;
         margin-right: 15px;
         flex-shrink: 0;
`;

export default S;