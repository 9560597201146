import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';
const S = {};
const baseFont = `12px`;

S.AccountMenu = styled(List)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
S.AccountMenuItem = styled.li`
    display: inline-block;
    width: 30%;
    margin: 5px;
    background-color: ${({theme: {$accountMenuItemBg}}) => $accountMenuItemBg};
    border-radius: 2px;
`;

S.Image = styled.img`

    height: ${rem('18px', baseFont)};
    width: ${rem('18px', baseFont)};
    
    &.transactionImg {
        width: ${rem('36px', baseFont)};
    }

    &.cashbackImg {
        width: ${rem('24px', baseFont)};
    }

    &.transferImg {
        width: ${rem('18px', baseFont)};
    }

    &.responsibleGamingImg {
        width: ${rem('18px', baseFont)};
    }
`;

S.AccountMenuLabel = styled.div`
    margin-top: 10px;
    width: 100%;
    font-size: ${baseFont};
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
 
S.AccountMenuLink = styled.a`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    text-decoration: none;
    color: ${({theme: {$accountMenuColor}}) => $accountMenuColor};
`;

export default S;