import _size from 'lodash/size';
import limits from 'limitTypes.enum.js';
import {translation} from 'utilsHelper.js';
import {logOutUser} from 'authActions.js';
import {loadCustomerLimits} from 'customerActions.js';
export const CHANGE_DATE_BY_TYPE = 'CHANGE_RESPONSIBLE_GAME_DATE_BY_TYPE';
export const EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS = 'EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS';

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const excludeAccount = (type, disablePeriod) => {
    return async (dispatch, getState, {CustomerApi}) => {

        dispatch(excludeAccountPending(type));

        try {
            let disableAccountModel = {
                limitType: limits.BETTING_EXCLUSION_TIME_LIMIT,
                limitAmount: disablePeriod,
            };
            disableAccountModel = [].concat(disableAccountModel);
            const {code, data} = await CustomerApi.updateCustomerLimits(disableAccountModel);

            if (code == 200 && _size(data)) {
                const [excludeLimit] = data;
                const {exceptionCode, limitAmount} = excludeLimit;
                if(limitAmount>=1){
                    dispatch(logOutUser());
                        //spaw do usunięcia, jak już top będzie w reactcie...
                        if(CLIENT == 'etoto'){
                            app.component.UserSwipeMenu.logoutUser();
                        }
                    return;
                }

                await dispatch(loadCustomerLimits());
                dispatch(excludeAccountSuccess(type));
                return data;
            } else {
                const errorMsg = translation(`excludeAccount_error_${code}`);
                throw errorMsg;
            }
        } catch (error) {
            dispatch(excludeAccountFailure(type, error));
            throw error;
        }
    }
};

const excludeAccountPending = (type) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_PENDING`,
        payload: {type}
    }
};

const excludeAccountFailure = (type, error) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_FAILURE`,
        payload: {type, error}
    }
};

const excludeAccountSuccess = (type, data) => {
    return {
        type: `${EXCLUDE_ACCOUNT_UPDATE_CUSTOMER_LIMITS}_SUCCESS`,
        payload: {type , data}
    }
};

export {
    changeDateByType,
    excludeAccount
}