import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _head from 'lodash/head';
import _findIndex from 'lodash/findIndex';

import {
    CHANGE_DATE_BY_TYPE,
    CHANGE_ACTIVE_TAB,
    FETCH_TRANSACTIONS_LIST,
    FETCH_TRANSACTION_BY_ID,
    SET_CURRENT_PAGE,
    FETCH_CASHOUTS_LIST,
    SET_MAXIMUM_CASHOUT_AMOUNT,
    RESET_BET_HISTORY_LIST,
    CHANGE_ACTIVE_FILTER,
    TOGGLE_LIVE_CURTAIN_FOR_TX_ID,
    RESET_CASHOUT_AMOUNT_FOR_TX_ID,
    FETCH_EXTERNAL_BET_HISTORY,
    FILTERED_EXTERNAL_BET_HISTORY,
    VIRTUAL_TAB_CHANGE_DATE_BY_TYPE,
    VIRTUAL_TAB_CHANGE_ACTIVE_TAB,
    VIRTUAL_TAB_SET_CURRENT_PAGE
} from 'accountBetHistoryActions.js';

let initialState = {
    isPending: true,
    error: null,
    startDate: new Date(),
    endDate: new Date(),
    currentPage: 0,
    limit: 10,
    activeTab: 'all',
    activeFilter: 'all',
    transactionsList: [],
    transaction: [],
    cashouts: [],
    virtualBetHistory: [],
    filteredVirtualBetHistory: [],
    isPendingVirtualHistory: true,
    virtualStartDate: new Date(),
    virtualEndDate: new Date(),
    virtualTabCurrentPage: 0,
    virtualLimit: 1000,
    virtualActiveTab: 'all'
};

export default typeToReducer({
    [CHANGE_ACTIVE_FILTER]: (state, {payload: {activeFilter}}) => {
        return {...state, currentPage: 0, activeFilter}
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {activeTab}}) => {
        return {...state, currentPage: 0, activeTab}
    },
    [CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return {...state, currentPage: 0, [dateType]: date}
    },
    [SET_CURRENT_PAGE]: (state, {payload: {currentPage}}) => {
        return {...state, currentPage}
    },
    [FETCH_TRANSACTIONS_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPending: false, transactionsList: data};
        }
    },
    [FETCH_TRANSACTION_BY_ID]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPending: false, transactionsList: data};
        }
    },
    [FETCH_CASHOUTS_LIST]: {
        SUCCESS: (state, {payload: {cashouts}}) => {
            return {...state, cashouts};
        }
    },
    [SET_MAXIMUM_CASHOUT_AMOUNT]: (state, {payload: {maxAmount, transactionId}}) => {
        const indexOfItem = _findIndex(state.transactionsList, {transactionId});
        const transactionsBeforeIndex = state.transactionsList.slice(0, indexOfItem);
        const transactionAfterIndex = state.transactionsList.slice(indexOfItem + 1);
        const transaction = _head(state.transactionsList.slice(indexOfItem, indexOfItem + 1));
        return {
            ...state,
            transactionsList: [...transactionsBeforeIndex, {...transaction, maxAmount: {...transaction.maxAmount, ...maxAmount}}, ...transactionAfterIndex]
        };
    },

    [RESET_CASHOUT_AMOUNT_FOR_TX_ID]: (state, {payload: {transactionId}}) => {
        const cashouts = update(state.cashouts, {
            [transactionId]: {$merge: {currentCashoutAmount: null}}
        })
        return {...state, cashouts};
    },


    [TOGGLE_LIVE_CURTAIN_FOR_TX_ID]: (state, {payload: {transactionId, curtainActive}}) => {
        const indexOfItem = _findIndex(state.transactionsList, {transactionId});
        const transactionsList = update(state.transactionsList, {
            [indexOfItem]: {
                curtainActive: (prevCurtainState) => {
                    return update(prevCurtainState || false, {$set: curtainActive});
                }
            }
        });
        return {...state, transactionsList};
    },
    [RESET_BET_HISTORY_LIST]: (state, action) => {
        return initialState;
    },
    [VIRTUAL_TAB_CHANGE_ACTIVE_TAB]: (state, {payload: {virtualActiveTab}}) => {
        return {...state, currentPage: 0, virtualActiveTab}
    },
    [VIRTUAL_TAB_CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return {...state, virtualTabCurrentPage: 0, [dateType]: date}
    },
    [VIRTUAL_TAB_SET_CURRENT_PAGE]: (state, {payload: {virtualTabCurrentPage}}) => {
        return {...state, virtualTabCurrentPage}
    },
    [FETCH_EXTERNAL_BET_HISTORY]: {
        PENDING: (state, action) => {
            return {...state, isPendingVirtualHistory: true};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPendingVirtualHistory: false, virtualBetHistory: data};
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPendingVirtualHistory: false, error};
        },
    },
    [FILTERED_EXTERNAL_BET_HISTORY]: (state, {payload: {data}}) => {
        return {...state, filteredVirtualBetHistory: data}
    }
}, initialState);
