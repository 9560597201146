import _size from 'lodash/size';
import _get from 'lodash/get';
import _map from 'lodash/map';

export const UPDATE_FAVOURITE_LEAGUES = 'UPDATE_FAVOURITE_LEAGUES';
export const SET_FAVOURITE_LEAGUES = 'SET_FAVOURITE_LEAGUES';
export const UPDATE_FAVOURITE_BY_TYPE = 'UPDATE_FAVOURITE_BY_TYPE';
export const SET_FAVOURITE_BY_TYPE = 'SET_FAVOURITE_BY_TYPE';
export const REMOVE_FAVOURITE_BY_TYPE = 'REMOVE_FAVOURITE_BY_TYPE';

const removeFavouriteByType = (type)=>{
    return {
        type: REMOVE_FAVOURITE_BY_TYPE,
        payload: {type}
    }
};

const updateFavouriteByType = (id, name, status, type) => {
    return {
        type: UPDATE_FAVOURITE_BY_TYPE,
        payload: {id, name, status, type}
    }
};

const setFavouriteByType = (data, type) => {
    return {
        type: SET_FAVOURITE_BY_TYPE,
        payload: {data, type}
    }
};

const updateFavouriteLeagues = (leagueId, status) => {
    return {
        type: UPDATE_FAVOURITE_LEAGUES,
        payload: {leagueId, status}
    }
};

const setFavouriteLeagues = (favouritesLeaguesList) => {
    return {
        type: SET_FAVOURITE_LEAGUES,
        payload: {favouritesLeaguesList}
    }
};

const toggleFavouriteForLeague = (leagueId, status) => {
    return async(dispatch, getState, {FavouritesApi}) => {
        try {
            const {code, data} = await FavouritesApi.updateFavouriteLeagues(leagueId, status);
            if (code == 200) {
                dispatch(updateFavouriteLeagues(leagueId, status));
                app.util.Utils.syncFavouriteLeague(leagueId);
                return data;
            }
            throw {message: data};
        } catch ({message}) {
            console.log({message})
        }
    }
};

const checkIfFavourite = (id, type = null) => {
    return (dispatch, getState) => {
        const {Favourites: {favouriteLeagues, favouritesByType}} = getState();
        const favouritesList = type ? _map(_get(favouritesByType, [type], []), ({id})=>Number(id)) : favouriteLeagues;
        return favouritesList.indexOf(id) != -1;
    }
};

const getFavouriteLeagues = () => {
    return async(dispatch, getState, {FavouritesApi}) => {

        try {
            const {data} = await FavouritesApi.getFavouriteLeagues();
            if(!_size(data)){
                throw new Error('empty favourite leagues');
            }

            dispatch(setFavouriteLeagues(data));
            return data;

        } catch (error) {
            dispatch(setFavouriteLeagues(error));
        }
    }
};

const getFavouriteByType = (type) => {
    return async(dispatch, getState, {FavouritesApi}) => {

        try {
            const {data} = await FavouritesApi.getFavouritesByType(type);
            if(!_size(data)){
                throw new Error('empty favourites by type');
            }

            let typeKey = ``;
            if(type == 'participant'){
                typeKey = `participants`;
            }else if(type == 'league'){
                typeKey = `leagues`;
            }

            let favourites = _get(data, [typeKey], []);
            if(type == 'league'){
                favourites = _map(favourites, id=>{
                    return {id};
                });
            }
            dispatch(setFavouriteByType(favourites, type));

            return favourites;

        } catch (error) {
            throw error;
        }
    }
};


const toggleFavouriteByType = (id, name, status, type) => {
    return async(dispatch, getState, {FavouritesApi}) => {
        try {
            const {code, data} = await FavouritesApi.updateFavouritesByType(id, status, type);
            if (code == 200 && data) {
                dispatch(updateFavouriteByType(id, name, status, type));
                return data;
            }


            throw {code, data};
        } catch (error) {
            throw error;
        }
    }
};


export {toggleFavouriteForLeague, checkIfFavourite, setFavouriteLeagues, getFavouriteLeagues, setFavouriteByType, getFavouriteByType, toggleFavouriteByType, removeFavouriteByType}