const theme = {

    $color_1: `#E0E0E0`,
    $color_2: `#000000`,
    $color_3: `#FFFFFF`,
    $color_4: `#F05A22`,
    $color_5: `#D6D6D6`,
}

// event list tree in modal
theme['$eventListTreeModalBorderColor'] = theme['$color_1'];
theme['$eventListTreeModalColor'] = theme['$color_2'];
theme['$eventListTreeModalTabBackgroundColor'] = theme['$color_3'];
theme['$eventListTreeModalScrollbarThumbBackgroundColor'] = theme['$color_4'];
theme['$eventListTreeModalEventDetailsBackgroundColor'] = theme['$color_5'];
theme['$eventListTreeModalCategoryTabBackgroundColor'] = theme['$color_3'];
theme['$eventListTreeModalMarkActiveEventBackgroundColor'] = theme['$color_4'];
theme['$eventListTreeModalEventBorderBottomColor'] = theme['$color_3'];
theme['$eventListTreeModalSetResultColor'] = theme['$color_4'];



export default theme;