import React, {useState, useMemo}  from 'react';
import _get from 'lodash/get';
import S from 'StyledVirtualBetHistoryItem.js';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect, useHistory} from 'react-router-dom';
import {formatDateFromUnix} from 'helpers/datesHelper.js';
import {translation, redirect} from 'helpers/utilsHelper';
import virtualBetSlipTypes from 'virtualBetSlipTypes.enum.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";

const VirtualBetHistoryItem = ({
    transaction,
    index,
    history,
    activeTab
}) => {

    const {transactionId, regDate, stake, possibleReturn, confirmedReturn, bonus, statusCode, slipType, curtainActive = false, maxAmount = '', transactionDetails, slipCombinations} = transaction;
    
    const currencyCode = process.env.MAIN_CURRENCY_CODE;

    const sumOdds = (transaction) => {

      let sum = 1;
      if (transaction && transaction.transactionDetails) {
  
          for (var i = 0; i < transaction.transactionDetails.length; i++) {
              if (transaction.transactionDetails[i].outcomeId != -1) {
                  sum *= parseFloat(transaction.transactionDetails[i].odds);
              }
          }
  
      }
      return sum.toFixed(2);
    };

    const totalOdds = useMemo(() => sumOdds(transaction), [transaction]);

    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty, cashBackReturn) => {

        let resultText = '';

        switch (statusCode) {
          case 1:
              resultText = 'placed';
              break;
          case 2 :
              resultText = 'lost';
              break;
          case 3 :
              resultText = 'won';
              break;
          case 4 :
              resultText = 'cashout';
              break;
          case 5 :
              resultText = 'cancel';
              break;
          case 6:
              resultText = 'pending_acceptation';
              break;
          case 7:
              resultText = 'rejected';
              break;
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const parseSlipType = (slipType) => {
        return virtualBetSlipTypes.getByType(slipType).name;
    };

    const redirectToVirtualTransactionDetails = (e, transactionId) => {
        e.stopPropagation();

        if (transactionDetails) {
            history.push({
                pathname: '/virtuals-bet-history/'+ transactionId,
                activeTab: activeTab
            });
        }
    }

    const transactionStatusClass = getTransactionStatusClass(statusCode, stake);

    return (
        <S.VirtualBetHistoryItemWrapper className="bet-history-item-wrapper" onClick={(e) => redirectToVirtualTransactionDetails(e, transactionId)}>

            <S.VirtualBetHistoryItem className="bet-history-item">

                <div className="transaction-info">
                    <div className={`transaction-status ${transactionStatusClass}`}>
                        <S.StatusIcon className={transactionStatusClass}></S.StatusIcon>
                    </div>

                    <div className="transaction-type">
                        <span>{translation(parseSlipType(slipType))}</span>
                    </div>
                </div>

                <div className={`transaction-stake ${transactionStatusClass}`}>
                    {`${stake.toFixed(2)} ${currencyCode}`}
                </div>

                <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                    {`${confirmedReturn.toFixed(2)} ${currencyCode}`}
                </div>

                <div className="transaction-date">
                    <div className="date-container">
                        <div>{formatDateFromUnix(regDate, 'dd.MM')}</div>
                        <div>{formatDateFromUnix(regDate, 'HH:mm')}</div>
                    </div>
                    <i className="ion-ios-arrow-right"></i>
                </div>

            </S.VirtualBetHistoryItem>

        </S.VirtualBetHistoryItemWrapper>
    );
};



export default withRouter(VirtualBetHistoryItem);