import { translation } from 'utilsHelper.js';
import _each from 'lodash/each';
export const FETCH_REGISTRATION_OFFERS = 'FETCH_REGISTRATION_OFFERS';
export const CHANGE_DATE_BY_TYPE = 'CHANGE_BONUS_DATE_BY_TYPE';
export const FETCH_BONUS_LIST = 'FETCH_BONUS_LIST';
export const FETCH_CREDIT_BONUS_LIST = 'FETCH_CREDIT_BONUS_LIST';
export const SET_CURRENT_PAGE = 'SET_BONUS_CURRENT_PAGE';
export const CHANGE_ACTIVE_TAB = 'CHANGE_BONUS_ACTIVE_TAB';

const fetchRegistrationOffersSuccess = (data) => {
    return {
        type: `${FETCH_REGISTRATION_OFFERS}_SUCCESS`,
        payload: {data},
    }
};

const fetchRegistrationOffersFailure = (error) => {
    return {
        type: `${FETCH_REGISTRATION_OFFERS}_FAILURE`,
        payload: {error},
    }
};

const fetchRegistrationOffersPending = () => {
    return {
        type: `${FETCH_REGISTRATION_OFFERS}_PENDING`,
    }
};

const fetchRegistrationOffers = () => {
    return async (dispatch, getState, {AccountBonusApi}) => {
        dispatch(fetchRegistrationOffersPending());
        try {
            let { code, data } = await AccountBonusApi.getRegistrationOffers();
            if (code !== 200) throw new Error(translation(`account_registerForm_bonusError${code}`));

            dispatch(fetchRegistrationOffersSuccess(data));

            return data;
        } catch ({message}) {
            dispatch(fetchRegistrationOffersFailure(message));
            return {};
        }
    }
};

const setCurrentPage = (currentPage, status) => {
    status = status.toLowerCase();
    return {
        type: SET_CURRENT_PAGE,
        payload: {currentPage, status}
    }
};

const changeActiveTab = (activeTab) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: {
            activeTab
        }
    }
};

const manageBonus = (status, offerId) => {
    return async(dispatch, getState, {AccountBonusApi}) => {
        try {
            const {code, data, messageKey, description = "undefined_error"} = await AccountBonusApi.manageBonus(status, offerId);
            if (!data) {
                //obsługa błędów z 500 / na ten moment backend może zwrócić nam jedynie 500 + description/
                switch (description) {
                    case (description.match(new RegExp('You have active bonus already!')) || {}).input:
                        throw {message: 'error_You have active bonus already!'};
                    case (description.match(new RegExp('Cannot cancel bonus, player have bets in play.')) || {}).input:
                        throw {message: 'error_Cannot cancel bonus, player have bets in play'};
                    default:
                        throw {message: `error_${(messageKey ? messageKey : code)}`};
                }
            }

            const result = await dispatch(fetchAllBonus());
            return data;
        } catch (error) {
            throw `${('message' in error) ? error['message'] : `error_${error.code}`}`;
        }
    }
};

const fetchBonusListByFilter = (config = {}) => {
    return async(dispatch, getState, {AccountBonusApi}) => {

        try {
            const {code, data} = await AccountBonusApi.getBonusListByFilter(config);
            const {status = 'ACTIVE'} = config;
            dispatch(fetchBonusListSuccess(data, status));
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const fetchAllBonus = () => {
    return async(dispatch, getState) => {
        dispatch(fetchBonusListPending());

        try {
            const status = ['ACTIVE', 'HISTORY', 'QUEUE'];
            const {AccountBonus:{startDate, endDate, activeTab}} = getState();
            const result = await dispatch(fetchBonusListByFilter({status, startDate, endDate, activeTab}));

            dispatch(fetchBonusListFulfilled(result));

            return result;
        } catch (error) {
            dispatch(fetchBonusListFailure(error));
            throw error;
        }
    }
};

const fetchBonusListFulfilled = (result = []) => {
    return {
        type: `${FETCH_BONUS_LIST}_FULFILLED`,
        payload: {result}
    }
};

const fetchBonusListSuccess = (data, status) => {
    return (dispatch) => {
        if (Array.isArray(status)) {
            _each(status, (s) => {
                s = s.toLowerCase();
                const d = data[s];
                dispatch({type: `${FETCH_BONUS_LIST}_SUCCESS`, payload: {data: d, status: s}});
            });
        } else {
            status = status.toLowerCase();
            data = data[status];
            dispatch({type: `${FETCH_BONUS_LIST}_SUCCESS`, payload: {data, status}});
        }
    };
};

const fetchBonusListPending = () => {
    return {
        type: `${FETCH_BONUS_LIST}_PENDING`
    }
};

const fetchBonusListFailure = (error) => {
    return {
        type: `${FETCH_BONUS_LIST}_FAILURE`,
        payload: {error}
    }
};

const changeDateByType = (dateType, date) => {
    return {
        type: CHANGE_DATE_BY_TYPE,
        payload: {
            dateType,
            date
        }
    }
};

const fetchCreditBonusListByFilter = (config = {}) => {
    return async(dispatch, getState, {AccountBonusApi}) => {

        try {
            const {code, data} = await AccountBonusApi.getCreditBonusListByFilter(config);
            const {status = 'ACTIVE'} = config;
            dispatch(fetchCreditBonusListSuccess(data, status));
            return data;
        } catch (error) {
            throw error;
        }
    }
};

const fetchCreditBonusListFulfilled = (result = []) => {
    return {
        type: `${FETCH_CREDIT_BONUS_LIST}_FULFILLED`,
        payload: {result}
    }
};

const fetchCreditBonusListSuccess = (data, status) => {
    return (dispatch) => {
        if (Array.isArray(status)) {
            _each(status, (s) => {
                s = s.toLowerCase();
                const d = data[s];
                dispatch({type: `${FETCH_CREDIT_BONUS_LIST}_SUCCESS`, payload: {data: d, status: s}});
            });
        } else {
            status = status.toLowerCase();
            data = data[status];
            dispatch({type: `${FETCH_CREDIT_BONUS_LIST}_SUCCESS`, payload: {data, status}});
        }
    };
};

const fetchCreditBonusListPending = () => {
    return {
        type: `${FETCH_CREDIT_BONUS_LIST}_PENDING`
    }
};

const fetchCreditBonusListFailure = (error) => {
    return {
        type: `${FETCH_CREDIT_BONUS_LIST}_FAILURE`,
        payload: {error}
    }
};
const manageCreditBonus = (status, offerId) => {
    return async(dispatch, getState, {AccountBonusApi}) => {
        try {
            const {code, data, messageKey, description = "undefined_error"} = await AccountBonusApi.manageCreditBonus(status, offerId);
            if (!data) {
                throw {message: `error_${(messageKey ? messageKey : code)}`, description: description};
            }
            const result = await dispatch(fetchAllCreditBonus());
            return data;
        } catch (error) {
            throw `${('message' in error) ? error['message'] : `error_${error.code}`}`;
        }
    }
};

const fetchAllCreditBonus = () => {
    return async(dispatch, getState) => {
        dispatch(fetchCreditBonusListPending());

        try {
            const status = ['ACTIVE', 'HISTORY', 'QUEUE'];
            const {AccountBonus:{startDate, endDate, activeTab}} = getState();
            const result = await dispatch(fetchCreditBonusListByFilter({status, startDate, endDate, activeTab}));

            dispatch(fetchCreditBonusListFulfilled(result));

            return result;
        } catch (error) {
            dispatch(fetchCreditBonusListFailure(error));
            throw error;
        }
    }
};

export {
    fetchRegistrationOffers,
    changeDateByType,
    changeActiveTab,
    fetchBonusListByFilter,
    setCurrentPage,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure,
    manageBonus,
    fetchCreditBonusListByFilter,
    fetchCreditBonusListPending,
    fetchCreditBonusListFulfilled,
    fetchCreditBonusListFailure,
    manageCreditBonus
}