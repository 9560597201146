import React, {useState, useMemo}  from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import S from 'StyledBetHistoryTransactionItem.js';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect, useHistory} from 'react-router-dom';
import useToggle from 'UseToggle.js'
import ModalOpacity from 'ModalOpacity.js';
import {formatDate} from 'helpers/datesHelper.js';
import {translation, redirect, decimal} from 'helpers/utilsHelper';
import betSlipTypes from 'betSlipTypes.enum.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";
import _size from 'lodash/size';
import _map from 'lodash/map';
import FreebetIcon from 'freebet_icon.svg';
import {getPolishTransactionType} from 'helpers/transactionsHelper.js';

const BetHistoryTransactionItem = ({
    transaction,
    transactionsList,
    currencyCode,
    index,
    cashouts,
    historyFreebets,
    prepareCashout,
    processCashout,
    refreshBetHistoryList,
    headerTitle,
    toggleAccountModal,
    toggleLiveCurtainForTxId,
    history
}) => {

    const {transactionId, regDate, stake, possibleReturn, confirmedReturn, bonus, statusCode, slipType, curtainActive = false, maxAmount = '', transactionDetails, slipCombinations = null, cashBackId, cashBackReturn, isFreebet, totalOddsWithoutSalesTaxFactor} = transaction;
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');


    const getCombinationApplicableBonusAmount = useMemo(() => {
        let combinationApplicableBonus = 0;
        if (slipCombinations?.combinations) {
            for (let combination of slipCombinations.combinations) {
                if (combination.confirmedReturn) {
                    combinationApplicableBonus = combinationApplicableBonus + combination.bonus;
                }
            }
        }
        return combinationApplicableBonus
    },[]);

    const hasCancelledCombination = () => {
        const transactionDetails = transaction.transactionDetails;
        const isCancelledComb = transactionDetails?.every(detail => detail.statusCode === 4) ?? false;

        return isCancelledComb;
    };

    // poprawić
    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty, cashBackReturn, isFreebet) => {

        if (cashBackReturn) {
            return 'cashback';
        }

        const hasCancelledComb = hasCancelledCombination();
        var resultText = '';

        if (statusCode == 2) {

            if ([0, 100].indexOf(slipType) != -1) {
                if ((confirmedReturn + bonus) >= stake || (isFreebet && confirmedReturn > 0) || (isFreebet && hasCancelledComb)) {
                    resultText = 'won';
                } else {
                    resultText = 'lost';
                }
            }else{
                if (confirmedReturn > 0) {
                    resultText = 'won';
                } else {
                    resultText = 'lost';
                }
            }


        } else {

            switch (statusCode) {
                case 0:
                    resultText = 'pending_acceptation';
                    break;
                case 1:
                    resultText = 'placed';
                    break;
                case 2 :
                    resultText = 'won';
                    break;
                case 3 :
                    resultText = 'lost';
                    break;
                case 4 :
                    resultText = 'cancel';
                    break;
                case 5 :
                    resultText = 'cashout';
                    break;
                default:
                    resultText = 'default';
                    break;
            }
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const checkCashout = (slipId) => {
        return (cashouts && cashouts[slipId]?.currentCashoutAmount);
    };

    const checkFreebet = (slipId) => {
        const historyFreebetList = _reduce(historyFreebets, (initialArray, {betSlipId}) => {
            return [...initialArray, betSlipId];
        }, []);
        return (historyFreebetList && historyFreebetList.indexOf(slipId) !== -1);
    };

    const parseSlipType = (slipType) => {
        return betSlipTypes.getByType(slipType).name;
    };

    const getTransactionType = useMemo(() => {
      return getPolishTransactionType(transaction);
    }, []);

    const redirectToTransactionDetails = (e,transactionId) => {
        e.stopPropagation();
        if (transactionDetails) {
            history.push({
                pathname: '/'+transactionId,
                cashoutFormProps: cashoutFormProps
            });
        }
    };

    const redirectToCashout = async (e, transactionId, cashoutFormProps) => {
        e.stopPropagation();
        e.preventDefault();
        try {
            const maxAmount = await prepareCashout(transactionId);
            history.push({
                pathname: '/cashout',
                state: cashoutFormProps
            });
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    const closeCashoutErrorModal = (e) => {
        e.stopPropagation();
        toggleCashoutErrorModal();
    }

    const goBackToTransactionList = () => {
        history.push({pathname: '/'});
    };

    const transactionStatusClass = getTransactionStatusClass(statusCode, stake, confirmedReturn, false, cashBackReturn, isFreebet);
    const cashoutFormProps = {
        toggleLiveCurtainForTxId,
        refreshBetHistoryList,
        transactionId,
        stake,
        possibleReturn,
        prepareCashout,
        currencyCode,
        goBackToTransactionList,
        cachoutOnSubmit: processCashout,
        initialValues: {maximumAmount: cashouts && cashouts[transactionId]?.currentCashoutAmount},
        transactionOdds: decimal(totalOddsWithoutSalesTaxFactor)
    };

    const renderCashoutOptions = () => {
        // if cashout is available display green button, if cashout was cashouted display green icon, any other option display gray icon
        if(checkCashout(transactionId) && !isFreebet){
            return (
                <S.CashoutButton className={"availableCashout"} onClick={(e) => redirectToCashout(e, transactionId, cashoutFormProps)}>
                <div className="cashout-label">{translation('account_betHistoryTransactionItem_cashoutLabel')}</div>
                <span>{`${cashouts[transactionId].currentCashoutAmount.toFixed(2)} ${currencyCode}`}</span>
                </S.CashoutButton>
            )
        } else if(statusCode == 5) {
            return <S.CashoutButton className={"cashouted"}>{`${confirmedReturn.toFixed(2)} ${currencyCode}`}</S.CashoutButton>
        } else {
            return <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                {(isFreebet && hasCancelledCombination())
                    ? `${(0).toFixed(2)} ${currencyCode}`
                    : (getCombinationApplicableBonusAmount > 0
                        ? `${(confirmedReturn + getCombinationApplicableBonusAmount).toFixed(2)} ${currencyCode}`
                        : `${(confirmedReturn + bonus).toFixed(2)} ${currencyCode}`
                    )
                }
            </div>
        }
    }

    return (
        <S.BetHistoryItemWrapper className="bet-history-item-wrapper" onClick={(e) => redirectToTransactionDetails(e, transactionId)}>
            
            <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={(e) => closeCashoutErrorModal(e)} showHeaderIcon={false}
                          showHeaderTitle={false}
                          width={'75%'}>
                <S.ErrorModal>
                    <S.ErrorModalTitle data-translation="account_betHistoryTransactionItem_cashout_error_title">{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                    <S.ErrorModalMessage data-translation={cashoutErrorCode ? `account_betHistoryTransactionItem_cashout_error_${cashoutErrorCode}` : `account_betHistoryTransactionItem_cashout_error_default`}>{cashoutErrorCode ? translation(`account_betHistoryTransactionItem_cashout_error_${cashoutErrorCode}`) : translation(`account_betHistoryTransactionItem_cashout_error_default`)}</S.ErrorModalMessage>
                </S.ErrorModal>
            </ModalOpacity>
 
            <S.BetHistoryItem className="bet-history-item">
                <div className="transaction-info">
                    <div className={`transaction-status ${transactionStatusClass}`}>
                        <S.StatusIcon className={transactionStatusClass}></S.StatusIcon>
                    </div>
                    <div className="transaction-type"> {getTransactionType}</div>
                </div>

                <div className={`transaction-stake ${transactionStatusClass}`}>
                    {`${stake.toFixed(2)} ${currencyCode}`}
                    {(process.env.ENABLE_FREEBET && isFreebet) && <S.FreebetIcon dangerouslySetInnerHTML={{ __html: FreebetIcon }} />}
                </div>

                {process.env.CASHOUT && (<div className="transaction-cashout">
                    {renderCashoutOptions()}
                </div>)}

                <div className="transaction-date">
                    <div className="date-container">
                        <div>{formatDate(regDate, 'dd.MM')}</div>
                        <div>{formatDate(regDate, 'HH:mm')}</div>
                    </div>
                    <div>
                    <i className="ion-ios-arrow-right"></i>
                    </div>
                </div>
            </S.BetHistoryItem>

        </S.BetHistoryItemWrapper>
    );
};

export default withRouter(BetHistoryTransactionItem);
