import React from 'react';
import ProviderContent from 'ProviderContent.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import {connect} from 'react-redux';


const ProviderContentAddapter = ({paymentData, user, isLogged}) => {

  const {providerId} = paymentData;
  const {PROVIDER}  = paymentPrepareTransaction;

  return (
    <ProviderContent
      key={providerId}
      providerForm={PROVIDER[providerId]}
      paymentData={paymentData}
      limits={PROVIDER[providerId]}
      userData={user}
      isLogged={isLogged}
      form ={`providerContentForm_ ${providerId}`}
    />
  ) 
}

const mapStateToProps = (state, props) => {
  const {Auth:{user, isLogged}} = state;
  return {
      user,
      isLogged
  }
};

export default connect(mapStateToProps)(ProviderContentAddapter);
