import typeToReducer from "type-to-reducer";
import update from 'immutability-helper';


import {
    LOAD_LIVE_MAJOR_GAMES
} from 'liveEventActions';

let initialState = {
    isPendingLiveMajorGames: false,
    errorOnLiveMajorGames: null, 
    liveMajorGames: []
};

export default typeToReducer({

    [LOAD_LIVE_MAJOR_GAMES] : {
        PENDING: (state, action) => {
            return {...state, isPendingLiveMajorGames: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, error, isPendingLiveMajorGames: false};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state, isPending: false, liveMajorGames: data};
        }
    }

}, initialState); 

