import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';

const PrimaryBtn = ({ theme: { primaryBtn } }) => primaryBtn();
const BtnContainer = ({ theme: { btnContainer } }) => btnContainer();

const CalendarContainer = ({ theme: { calendarContainer } }) => calendarContainer()
const DateLabel = ({ theme: { dateLabel } }) => dateLabel()
const DatePicker = ({ theme: { datePicker } }) => datePicker()

const S = {};

S.AccountBonus = styled.div`width: 100%;`;

S.BonusFilters = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem 0.5rem
`;

S.BonusFiltersDates = styled.div`${CalendarContainer}
    padding-top: 22px;
`;

S.Datepicker = styled.div`${DatePicker}
    flex-wrap: wrap;
`;

S.DateLabel = styled.div`${DateLabel}`;

S.BonusFiltersBtns = styled.div` ${BtnContainer }`;

S.FilterBtn = styled(Button)` ${PrimaryBtn} padding: 0.85rem 0.5rem; margin: 0 0.2rem`;

export default S;