import styled from 'styled-components';
const S = {};

const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;

S.ModalBackground = styled.div`
  display: flex;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

S.ModalWrapper = styled.div`
  position: relative;
  z-index: 99;
  margin: 0 auto;
  background-color: #fff;
  width: ${({width})=> (width!=null) ? width : '90%' };
  border-radius: .42rem;
  overflow: visible;
  padding: ${({padding})=> (padding!=null) ? padding : '1rem 0' }}
`;

S.ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

S.ModalTitle = styled.h2``;

S.UserIconContainer = styled.div`
    width: 1.5rem;
    height: 1.5rem;
`;

S.CloseIconContainer = styled.div`
  position: absolute;
  right: -40px;
  top: -40px;
  padding: 0;
  width: 2.8rem;
  height: 2.8rem;
  transform: none;
  line-height: 1;
  margin-right: -3rem;
  margin-top: -3rem;
  border-radius: 50%;
  bottom: 0;
  margin: .83rem 0;
  background: #fff url(${imagePath}images/svg/close.svg) no-repeat 50% 50%;
  background-size: 1.47rem 1.47rem;
  cursor: pointer;
`;

S.ModalBody = styled.div`
  display: flex;
  justify-content: center;
`;

export default S;