import React, {Component} from 'react';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import AccountSessionTimer from 'AccountSessionTimer.js';
import AccountMenu from 'AccountMenu.js';
import S from 'StyledAccount.js';
import {translation, formatMoney, redirect} from 'utilsHelper.js';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import {fetchFreebetByStatus} from 'freebetActions.js';
import {loadVeryficationStatus} from 'accountActions.js';
import {logOutUser} from 'authActions.js';

class Account extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false, freebetCount: 0, freebetBalance: 0.0};

    componentDidMount = async() => {
        const {fetchFreebetByStatus, loadVeryficationStatus, accountTab} = this.props;

        if (!this.props.user) {
            redirect('/');
            return; 
        } else if (accountTab){
            this.props.history.push('/account/'+accountTab);
        }

        try {

            loadVeryficationStatus();
            const config = {
                status: 'ACTIVE'
            };
            const {active} = await fetchFreebetByStatus(config);
          
            let freebetBalance = 0;
            if (_size(active) > 0){
                freebetBalance = active[0].freebetStake;
            }
            this.setState((prevState) => {
                return {...prevState, freebetCount: _size(active), freebetBalance };
            })

        } catch (error) {
            console.log('fetchFreebetByStatus:' + error);
        }
    };

    getBalanceList = () => {
        
        const {user:{bonusBalanceList, currencyCode, balance}} = this.props;
        const options = [];
        const mainBalance = {
            value: 0,
            label: `${translation('account_balance')}: ${formatMoney(balance)} ${currencyCode}`
        };

        options.push(mainBalance);

        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${formatMoney(amount)} ${currency}`
            };
            options.push(bonusBalance);
        });

        return options;
    };

    getBonusBalanceSingleAmount = () => {
        const {user:{bonusBalanceList, currencyCode}} = this.props;

        let bonusAmount = `0.00 ${currencyCode}`;
        if (bonusBalanceList && bonusBalanceList.length > 0){
            const amount = formatMoney(bonusBalanceList[0].amount); 
            bonusAmount = `${amount} ${bonusBalanceList[0].currency}`;
        }

        return bonusAmount;
    };

    selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || this.props.balanceId;
        const selectedValue = _find(this.getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };

    onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        this.props.toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    onUserDataLinkClick = (event) => {
        event.preventDefault();
        redirect('/account/session-history');
    };
 
    render() {
        if (!this.props.user) {
            return null;
        }

        const {user: {balance, currencyCode, firstName, lastName}, accountData, logOutUser} = this.props;
        const {hasError} = this.state;
        
        return (
            <S.AccountWrapper className="account-wrapper">

                    <S.AccountTopPanel className="account-top-panel">

                        <S.UserDataContainer>

                            <S.UserAvatarContainer className="user-avatar-container">
                                <S.UserAvatar className="user-avatar"></S.UserAvatar>
                            </S.UserAvatarContainer>

                            <S.UserNameDataContainer className="user-name-data-container">
                                <S.UserName className="user-name">{firstName} {lastName}</S.UserName>
                                <S.UserDataButtonContainer  onClick={(e) => { this.onUserDataLinkClick(e); }}>
                                    <S.UserDataButton className="user-data-button">
                                            {translation('account_userPanel_userData')}
                                    </S.UserDataButton>
                                </S.UserDataButtonContainer>
                                
                            </S.UserNameDataContainer>

                        </S.UserDataContainer>

                        <S.Separator/>

                        <S.UserBalanceContainer className="user-balance-container">

                            <S.UserMainBalance>
                                <S.UserMainBalanceLabel>{translation('account_userPanel_balance')}</S.UserMainBalanceLabel>
                                <S.UserMainBalanceContainer className="user-main-balance-container">
                                    <S.UserMainBalanceValue>{ formatMoney(balance) }</S.UserMainBalanceValue>
                                    <S.UserMainBalanceCurrency>{currencyCode}</S.UserMainBalanceCurrency>
                                </S.UserMainBalanceContainer>
                            </S.UserMainBalance>

                            <S.UserBonusBalanceWrapper>
                                <S.UserBonusBalance>
                                    <S.UserBonusBalanceLabel>{translation('account_userPanel_bonus')}</S.UserBonusBalanceLabel>
                                    <S.UserBonusBalanceAmount className="user-bonus-balance-amount">{ this.getBonusBalanceSingleAmount() }</S.UserBonusBalanceAmount>
                                </S.UserBonusBalance>
                            </S.UserBonusBalanceWrapper>

                            <S.UserFreebets className="user-freebets">
                                <S.FreebetLabel>{translation('account_userPanel_freebetCount')}</S.FreebetLabel>
                                <S.FreebetsBalanceValue>{formatMoney(this.state.freebetBalance)}</S.FreebetsBalanceValue>
                            </S.UserFreebets>

                        </S.UserBalanceContainer>
                        

                    </S.AccountTopPanel>

                    <S.AccountMainMenuContainer className="account-main-menu-container">

                        <AccountMenu logOut={logOutUser}/>
                            
                    </S.AccountMainMenuContainer>

                    <S.UserDataSessionTimerContainer className="user-data-session-timer-container">
                        <S.AccountSessionTimerWrapper>
                            <S.AccountSessionLabel>{translation('account_userSessionTime')}</S.AccountSessionLabel>
                            <AccountSessionTimer/>
                        </S.AccountSessionTimerWrapper>
                        <S.UserActivityLink as={NavLink} to='/account/session-history' onClick={(e) => this.onUserDataLinkClick(e) } className="user-activity-link">{translation('account_userPanel_activity')}
                        </S.UserActivityLink>
                    </S.UserDataSessionTimerContainer>

            </S.AccountWrapper>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Account:{ accountData}, Auth: {user} } = state;
    return {
        accountData,
        user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFreebetByStatus: bindActionCreators(fetchFreebetByStatus, dispatch),
        loadVeryficationStatus: bindActionCreators(loadVeryficationStatus, dispatch),
        logOutUser: bindActionCreators(logOutUser, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
