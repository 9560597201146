import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _get from 'lodash/get';
import { translation, formatMoney } from 'utilsHelper.js';
import { formatDate, substractDays, compareAsc } from 'datesHelper.js';
import { changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage, resetToInitialState } from 'accountWalletActions.js';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import S from 'StyledAccountWallet.js';

class AccountWallet extends Component {
  state = {
    transactions: [],
    calendarStartDate: formatDate(new Date(), 'yyyy-MM-dd'),
    calendarEndDate: formatDate(new Date(), 'yyyy-MM-dd'),
  };

  componentDidMount = async () => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container');
    Array.from(datePickers).forEach((el) => el.childNodes[0].setAttribute('readOnly', true));

    const { fetchTransactionsByFilter, resetToInitialState } = this.props;
    try {
      resetToInitialState();
      fetchTransactionsByFilter();
    } catch (error) {
      console.log('fetchTransactionsByFilter:' + error);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isPending != this.props.isPending && !this.props.isPending && _size(this.props.data)) {
      this.prepeareStateFromTransations(this.props.data);
    }
  }
  handleRedirectToPaymentCancel = (id, amount, date) => {
    app.router.redirect('/payoutCancel/:transactionId/:amount/:date', {
      transactionId: id,
      amount,
      date,
    });
  };

  handleChangeStart = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const startDate = new Date(date);

    this.setState({ calendarStartDate: date });
    changeDateByType('startDate', startDate);
    fetchTransactionsByFilter({ startDate });
  };

  handleChangeEnd = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const endDate = new Date(date);

    this.setState({ calendarEndDate: date });
    changeDateByType('endDate', endDate);
    fetchTransactionsByFilter({ endDate });
  };

  onChangeFilter = (activeTab) => {
    const { changeActiveTab, fetchTransactionsByFilter } = this.props;

    changeActiveTab(activeTab);
    fetchTransactionsByFilter({ activeTab });
  };

  prepeareStateFromTransations = (data) => {
    const transactions = _map(data, ({ transactionId }) => {
      const item = {
        transactionId,
        isOpen: false,
      };
      return item;
    });

    this.setState((prevState) => {
      const nextState = {
        transactions,
      };
      return nextState;
    });
  };

  findItemByTransactionId = (searchId) => {
    return this.state.transactions.find(({ transactionId }) => transactionId == searchId);
  };

  updateTransactionItem = (indexOfItem, item) => {
    this.setState((prevState) => {
      const itemsBeforeIndex = prevState.transactions.slice(0, indexOfItem);
      const itemsAfterIndex = prevState.transactions.slice(indexOfItem + 1);
      const nextState = {
        ...prevState,
        transactions: [...itemsBeforeIndex, { ...item, isOpen: !item.isOpen }, ...itemsAfterIndex],
      };
      return nextState;
    });
  };

  getTransactionStatusClass = (statusCode) => {
    const status = statusCode.toUpperCase();
    switch (status) {
      case 'DEC':
      case 'FLD':
      case 'ERR':
        return 'red';
      case 'SCS':
        return 'green';
      case 'IPR':
      case 'INI':
        return 'gray';
      default:
        return '';
    }
  };

  parseStatus = (status) => {
    return 'transfer_statusList.' + status;
  };

  parseType = (type) => {
    return 'transferTypeList.' + type;
  };

  goPage = async (nextPage) => {
    const { fetchTransactionsByFilter, setCurrentPage } = this.props;

    try {
      await fetchTransactionsByFilter({ nextPage });
      setCurrentPage(nextPage);
    } catch (error) {
      console.log('goPage:' + error);
    }
  };

  setStartDate = (days) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const today = new Date();
    const startDate = substractDays(today, days);
    const endDate = days == 1 ? startDate : today;

    fetchTransactionsByFilter({ startDate, endDate });
    changeDateByType('startDate', startDate);
    changeDateByType('endDate', endDate);
  };

  compareDates = (dateLeft, dateRight) => {
    dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
    dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
    const result = compareAsc(dateLeft, dateRight);

    if (!result) {
      return true;
    }
    return false;
  };

  render() {
    const { isPending, activeTab, currentPage, limit, data, prevData, nextData } = this.props;

    return (
      <S.AccountWallet>
        <S.WalletFilters>
          <S.WalletFiltersTop>
            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'all')} isActive={activeTab === 'all'}>
              {translation('account_wallet_types_all')}
            </S.FilterBtn>

            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'deposit')} isActive={activeTab === 'deposit'}>
              {translation('account_wallet_types_deposits')}
            </S.FilterBtn>
            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'payout')} isActive={activeTab === 'payout'}>
              {translation('account_wallet_types_payouts')}
            </S.FilterBtn>
          </S.WalletFiltersTop>
          <S.WalletFiltersDates>
            <S.DateLabel>
              <div className="select-date"> {translation('account_wallet_selectDate')}</div>
              <div className="select-date"> {translation('account_wallet_selectDate')}</div>
            </S.DateLabel>

            <S.Datepicker>
              <input type="date" value={this.state.calendarStartDate} onChange={(event) => this.handleChangeStart(event.target.value)} />
            </S.Datepicker>
            <S.Datepicker>
              <input type="date" value={this.state.calendarEndDate} onChange={(event) => this.handleChangeEnd(event.target.value)} />
            </S.Datepicker>
          </S.WalletFiltersDates>
        </S.WalletFilters>
        <S.WalletTransactions className="wallet-transaction-list">
          {isPending ? (
            <Loader />
          ) : (
            <>
              <S.WalletTransactionsList className="transaction-list">
                <S.FakeTableBody>
                  {_map(data, ({ amount, regDate, type, statusCode, transactionId, currencyCode, referenceId }) => {
                    const translationItem = this.findItemByTransactionId(transactionId);
                    const isOpen = _get(translationItem, ['isOpen'], false);
                    let date = formatDate(regDate, 'dd-MM-yyyy HH:mm').split(' ');
                    let dateWithHours = [...date[0], translation('account_wallet_shortHour'), ...date[1]].join('');
                    let isTransactionCancelable = type == 156 && statusCode == 'INI';
                    return (
                      <S.WalletTransactionsListItemWrapper key={transactionId}>
                        <S.WalletTransactionsListItem className="transaction-list-item">
                          <div className="wallet-id">
                            <span>{transactionId}</span>
                          </div>
                          <div className="wallet-date">
                            <span>{dateWithHours}</span>
                          </div>
                        </S.WalletTransactionsListItem>

                        <S.WalletTransactionsListItemDetails isOpen={isOpen}>
                          <S.ItemDetailsBox>
                            <S.ItemDetailsHeader>{translation('account_wallet_transfers_status')}</S.ItemDetailsHeader>
                            <S.ItemDetailsBody className={`wallet-status ${this.getTransactionStatusClass(statusCode)}`}>
                              <div className="status-icon"></div>
                            </S.ItemDetailsBody>
                          </S.ItemDetailsBox>
                          <S.Divider />

                          <S.ItemDetailsBox>
                            <S.ItemDetailsHeader>{translation('account_wallet_transfers_type')}</S.ItemDetailsHeader>
                            <S.ItemDetailsBody>{translation(this.parseType(type))}</S.ItemDetailsBody>
                          </S.ItemDetailsBox>
                          <S.Divider />

                          <S.ItemDetailsBox>
                            <S.ItemDetailsHeader>{translation('account_wallet_transfers_description')}</S.ItemDetailsHeader>
                            <S.ItemDetailsBody className={this.getTransactionStatusClass(statusCode)}>
                              {translation(this.parseStatus(statusCode))}
                            </S.ItemDetailsBody>
                          </S.ItemDetailsBox>
                          <S.Divider />
                          <S.ItemDetailsBox>
                            <S.ItemDetailsHeader>{translation('account_wallet_transfers_amount')}</S.ItemDetailsHeader>
                            <S.ItemDetailsBody>
                              {formatMoney(amount)} {currencyCode === 'PLN' ? 'zł' : currencyCode}
                            </S.ItemDetailsBody>
                          </S.ItemDetailsBox>
                          <S.Divider />

                          <S.ItemDetailsBox>
                            <S.ItemDetailsHeader>{translation('account_wallet_transfers_transferNumber')}</S.ItemDetailsHeader>
                            <S.ItemDetailsBody> {transactionId}</S.ItemDetailsBody>
                          </S.ItemDetailsBox>
                          <S.ItemDetailsBox>
                            <S.PayoutCancelButton
                              onClick={() => this.handleRedirectToPaymentCancel(referenceId, amount, regDate)}
                              disabled={!isTransactionCancelable}
                              className={`${isTransactionCancelable ? 'available' : 'not-available'}`}
                            >
                              {isTransactionCancelable ? translation('Anuluj wypłatę') : translation('Niedostępne')}
                            </S.PayoutCancelButton>
                          </S.ItemDetailsBox>
                        </S.WalletTransactionsListItemDetails>
                      </S.WalletTransactionsListItemWrapper>
                    );
                  })}
                </S.FakeTableBody>

                {!_size(data) && <S.NoResult className="no-result">{translation('account_wallet_transfers_notFound')}</S.NoResult>}
              </S.WalletTransactionsList>

              {_size(data) > 0 ? <Pagination useNewPagination cb={this.goPage} {...{ currentPage, limit, data, prevData, nextData }} /> : null}
            </>
          )}
        </S.WalletTransactions>
      </S.AccountWallet>
    );
  }
}

const mapStateToProps = ({ AccountWallet: { isPending, error, startDate, endDate, activeTab, data, prevData, nextData, currentPage, limit } }, props) => {
  return {
    error,
    isPending,
    startDate,
    endDate,
    activeTab,
    data,
    currentPage,
    limit,
    prevData,
    nextData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
    changeDateByType: bindActionCreators(changeDateByType, dispatch),
    fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
    setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
    resetToInitialState: bindActionCreators(resetToInitialState, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWallet);
