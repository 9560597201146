import React, {useState} from 'react';
import S from 'StyledAccountShareBetIcon.js';
import {withRouter} from 'react-router-dom';
import { translation } from 'utilsHelper.js';




const AccountShareBetIcon = ({type, history, match, transaction: { transactionId }, showCopyInfo}) => {

    const [ shareLink, setShareLink ] = useState(`${window.location.origin}/kupon/${transactionId}${translation('deepLinkShare')}`);

    const shareTypeHandler = () => {

        switch(type) {
            case "facebook":
                shareFacebookHandler();
                break;
            case "twitter": 
                shareTwitterHandler();
                break;
            case "link": 
                shareLinkHandler();
                break;
            default:
                console.log("miss matched type");
        }
    };

    const shareFacebookHandler = () => {
        let fbLink = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`;
  
        window.open(fbLink, 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    }

    const shareTwitterHandler = () => {

        let twLink = `http://www.twitter.com/share?url=${shareLink}`;
        window.open(twLink, 'twShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
    }

    const shareLinkHandler = () => {
        navigator.clipboard.writeText(shareLink);
        showCopyInfo();
        if(window.isWebView){
          window.ReactNativeWebView.postMessage(shareLink);
        }
    }

    return (
        <S.AccountShareBetIconWrapper className={type} onClick={shareTypeHandler}>
        </S.AccountShareBetIconWrapper>
    )


};

export default withRouter(AccountShareBetIcon);
