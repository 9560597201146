import typeToReducer from 'type-to-reducer';
import {LOAD_ACTIVE_PROVIDERS} from 'paymentActions.js';

let initialState = {
    providersCount: 0,
    isPending: true,
    error: null,
    hasError: false
};

export default typeToReducer({
    [LOAD_ACTIVE_PROVIDERS]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {paymentProviders}}) => {
            return {...state, isPending: false, paymentProviders};
        }
    },
}, initialState);
