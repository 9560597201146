import React, {useState, useEffect, useMemo} from 'react';
import useToggle from 'UseToggle.js';
import {translation, getUrlPreparedString, redirect, findCategoryItemByIdLevel, removeDiacritics} from 'utilsHelper.js';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import {connect} from 'react-redux'
import S from 'StyledFavourites.js';
import {toggleFavouriteByType} from 'favouritesActions.js';
import ShowSelectedIcon from 'star_selected.svg';
import FavouriteIcon from 'star_unselected.svg';

const Favourites = (props) => {

    const {filteredCategories, favouritesByType, toggleFavouriteByType, isLogged} = props;
    const [showFavourites, toggleFavourites] = useToggle(false);
    const [favourites, setFavourites] = useState(() => ({ leagues: [], participants: []}));
    const {participants, leagues} = favourites;

    useEffect(() => {

        const participants = _get(favouritesByType, ['participant'], []);
        const leagues = _get(favouritesByType, ['league'], []);

        setFavourites({participants, leagues});

    }, [favouritesByType]);

    const allFavouriteLeaguesSorted = useMemo(() => {

        const leagues = _map(favourites.leagues, ({id}) => Number(id));
        const favouriteLeagues = _filter(filteredCategories, ({categoryId}) => leagues.indexOf(categoryId) != -1);
        const favouritesWithOrders = _map(favouriteLeagues, (o) => {
            const {sortOrder, parentCategory, sportId} = o;
            const calegoryLvl1 = _find(favouriteLeagues, {categoryId: sportId});
            const calegoryLvl2 = _find(favouriteLeagues, {categoryId: parentCategory});
            const calegoryLvl1Order = _get(calegoryLvl1, 'sortOrder', 0);
            const calegoryLvl2Order = _get(calegoryLvl2, 'sortOrder', 0);
            const order = `${calegoryLvl1Order}_${calegoryLvl2Order}_${sortOrder}`;
            return {...o, order}
        });

        return _sortBy(favouritesWithOrders, ['order']);

    }, [favourites.leagues]);

    const eventsCount = useMemo(() => {

        const leaguesEventsCount = _reduce(allFavouriteLeaguesSorted, (initial, league) => {
            const eventsCount = _get(league, ['eventsCount'], 0);
            return initial + eventsCount;
        }, 0);

        return leaguesEventsCount;

    }, [allFavouriteLeaguesSorted]);

    const redirectToLeagueEventList = (leagueId, sportId) => {

        // dla live
        if(!sportId){
            return false;
        }

        const normalizeURL = (name) => getUrlPreparedString(name).trim();
        const findCategoryNameByLevel = (categoryId, level) => {
            const item = findCategoryItemByIdLevel(categoryId, level, filteredCategories);
            return _get(item, ['categoryName']);
        };

        let sportName =  findCategoryNameByLevel(leagueId, 1);
        let selectedCountries = _map([leagueId], (id) => findCategoryNameByLevel(id, 2));
        let selectedLeagues = _map([leagueId], (id) => findCategoryNameByLevel(id, 3));
        selectedCountries = _map([...new Set(selectedCountries)], normalizeURL);
        selectedLeagues = _map([...new Set(selectedLeagues)], normalizeURL);
        sportName = removeDiacritics(sportName).split(' ').join('-');

        const pathParams = {
            categories: leagueId,
            selectedSports: sportName,
            filterSportId: sportId,
            selectedCountries,
            selectedLeagues
        };
        redirect('/zaklady-bukmacherskie/:selectedSports/:selectedCountries/:selectedLeagues/:categories/:filterSportId', pathParams);
    };

    const redirectToParticipantEventList = (id, name) => {
        const encodedPhrase = getUrlPreparedString(name).trim();
        redirect(`/quick-search-results/${encodedPhrase}/${id}`);
    };

    const removeFavouriteByType = async(id, type, e) => {
        e.stopPropagation();
        try{
            await toggleFavouriteByType(id, '', false, type);
            app.util.Utils.syncFavouriteByType(id, type);
        }catch(error){
            console.log('removeFavouriteByType:', error);
        }
    };

    const hideOtherCategories = ()=>{
        const otherCategoriesItems = document.querySelectorAll(`.tree-category`);
        _each([...otherCategoriesItems], (element)=>element.style.display='none');

    };

    const setContentHeaderTitle = ()=>{
        const contentHeaderTitleSelector = document.getElementById(`contentHeaderTitle`);
        if(contentHeaderTitleSelector){
            contentHeaderTitleSelector.innerText = translation('my_favourite_leagues');
            contentHeaderTitleSelector.previousElementSibling.style.display = 'block';
        }
    };

    const toggleFavouritesList = (e) => {
        e.stopPropagation();
        hideOtherCategories();
        setContentHeaderTitle();
        toggleFavourites();
    };

    if(!isLogged){
        return null;
    }

    return (
        <S.FavouritesWrapper>

            <S.FavouritesHeader active={showFavourites} onClick={toggleFavouritesList}>
                <S.HeaderFavouriteIcon dangerouslySetInnerHTML={{__html: FavouriteIcon}}/>
                <S.Title>{translation('my_favourite_leagues')}</S.Title>
                <S.EventsCount>{eventsCount}</S.EventsCount>
                <S.Arrow>
                    <S.ArrowIcon/>
                </S.Arrow>
            </S.FavouritesHeader>

            {showFavourites && (
                <S.FavouritesBody>
                    <S.FavouritesList>

                        {_map(allFavouriteLeaguesSorted, ({categoryName, categoryId, eventsCount, sportId}) => {
                            return (
                                <S.FavouritesItem
                                    key={categoryId}
                                    onClick={redirectToLeagueEventList.bind(null, categoryId, sportId)}>

                                    <S.FavouriteIcon
                                        dangerouslySetInnerHTML={{__html: ShowSelectedIcon}}
                                        onClick={removeFavouriteByType.bind(null, categoryId, 'league')}>
                                    </S.FavouriteIcon>

                                    <S.LeagueName>{categoryName}</S.LeagueName>

                                    <S.EventsCount>{eventsCount}</S.EventsCount>

                                </S.FavouritesItem>
                            )
                        })}

                        {_map(participants, ({id, name}) => {
                            return (
                                <S.FavouritesItem key={id}
                                                  onClick={redirectToParticipantEventList.bind(null, id, name)}>

                                    <S.FavouriteIcon
                                        dangerouslySetInnerHTML={{__html: ShowSelectedIcon}}
                                        onClick={removeFavouriteByType.bind(null, id, 'participant')}>
                                    </S.FavouriteIcon>

                                    <S.ParticipaneName>
                                        {name}
                                    </S.ParticipaneName>

                                </S.FavouritesItem>
                            )
                        })}
                    </S.FavouritesList>
                </S.FavouritesBody>
            )}

        </S.FavouritesWrapper>
    );
};

const mapStateToProps = ({Favourites: {favouritesByType}, Auth: {isLogged}}) => {
    return {
        isLogged,
        favouritesByType
    }
};

const mapDispatchToProps = {
    toggleFavouriteByType
};

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);