import React from 'react';
import {Route, Switch, Redirect, useParams, MemoryRouter, withRouter} from 'react-router-dom';
import AccountBetHistoryListView from 'AccountBetHistoryListView.js';
import AccountBetHistoryItemDetails from 'BetHistoryItemDetails.js';
import VirtualBetHistoryItemDetails from 'VirtualBetHistoryItemDetails.js';
import CashoutForm from 'CashoutForm.js';

const AccountBetHistoryRoute = (props) => {
    return (
        <Switch>
            <Route path="/cashout" render={(props) => <CashoutForm {...props}/>}/> 
            <Route path="/virtuals-bet-history/:transactionId" render={(props) => <VirtualBetHistoryItemDetails {...props}/>}/> 
            <Route path="/:transactionId" render={(props) => <AccountBetHistoryItemDetails {...props}/>}/> 
            <Route path="/" render={(props) => <AccountBetHistoryListView {...props}/>}/>
        </Switch>
    )
};

export default AccountBetHistoryRoute;