import styled from 'styled-components';
import {Card} from 'global/styles/styles.js';
const S = {};

S.BetHistoryItemWrapper = styled.div`
    margin: 0.2rem 0;
    padding: 0 0.5rem;
    filter: drop-shadow(0px 4px 10px rgba(68, 85, 106, 0.08));
`;

S.BetHistoryItem = styled(Card)`
    width: 100%;
    font-family: Open Sans;
    font-size: 0.6rem;
    font-weight: 600;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    padding: 0.5rem 0;

    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #2D375E80;
        font-size: 12px;
        letter-spacing: -0.01em;
        font-weight: 600;

        &.transaction-info {
            flex-wrap: wrap;
            text-overflow: ellipsis;
            text-align: left;
            padding-left: 0.5rem;

            & > .transaction-status {
                padding-left: 0.25rem;
            }
            & > .transaction-type {
                padding-left: 0.25rem;
            }
        }
        &.transaction-confirmed-win {
            color: #02B253;
            flex-wrap: wrap;
            justify-content: center;
            opacity: 0.7;
        }
        &.transaction-cashout {
            align-items: center;
        }

        &.transaction-date {
            justify-content: space-between;

            & .date-container {
                padding-left: 10px;
            }

                & div > .ion-ios-arrow-right {
                    padding-right: 0.8rem;
            }
        }
    }    
    `;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.StatusIcon = styled.div`
    margin: 0;
    height: 12px;
    width: 12px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    &.pending_acceptation {
        background-image: url(/images/icon_pending.png);
    }

    &.won {
        background-image: url(/images/icon_won.png);
    }

    &.cashout {
        background-image: url(/images/icon_cashout.png);
    }

    &.cancel {
        background-image: url(/images/icon_cancel.png);
    }

    &.cashback {
        background-image: url(/images/icon_cashback.png);
    }

    &.lost {
        background-image: url(/images/icon_lost.png);
    }

    &.placed {
        background-image: url(/images/icon_pending.png);
    }
`;

S.CashoutButton = styled.div`
    &.availableCashout {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 74px;
        height: 35px;
        font-size: 0.5rem;
        color: ${({theme:{$accountProfileBetHistoryCashoutBttnColor}})=> $accountProfileBetHistoryCashoutBttnColor};
        background-color: ${({theme:{$accountProfileBetHistoryCashoutBttnBackground}})=> $accountProfileBetHistoryCashoutBttnBackground};
        line-height: 1;
        cursor: pointer;
        border-radius: 4px;
    }
    & .cashout-label {
        padding-left: 7px;
        display: flex;
        align-items: center;
        font-family:Open Sans;
        letter-spacing: -0.01rem;
        font-style: normal;
        font-weight: bold;
        font-size: 7px;
        opacity: 0.8;
    }
    & span {
        padding-left: 7px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: -0.01em;
    }
    &.cashouted,
    &.uncashouted{
        display:flex;
        align-items:center;
        height: 1.88rem;
    }
    &.cashouted {
        background-image: url(/images/cashout-green.png);
    }
    &.uncashouted {
        background-image: url(/images/cashout-grey.png);
    }
`;

S.FreebetIcon = styled.div`
 width:  20px;
     flex-shrink: 0;
     margin-top: 5px;
`;


export default S;