import styled from 'styled-components';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwMedium = ({theme:{$fwMedium}}) => `${$fwMedium}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme:{$fwBold}}) => `${$fwBold}`;
const FontFamilyOswald = ({theme: {$textOswaldRegular}}) => `${$textOswaldRegular}`;
const TextMuliBold = ({theme:{$textMuliBold}}) => `${$textMuliBold}`;
const TextMuliSemibold = ({theme:{$textMuliSemibold}}) => `${$textMuliSemibold}`;

const baseFont = `16px`;

const S = {};

S.LimitsTable = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0;
`;

S.SingleLimitInfoText = styled.p`
    display: flex;
    padding: ${rem('10px', baseFont)} ${rem('10px', baseFont)} ${rem('13px', baseFont)} ${rem('15px', baseFont)};
    flex-basis: 100%;
    justify-content: flex-start;
    margin: 0;
    font-size: ${rem('13px', baseFont)};
    color: ${({theme:{$accountResponsibleGameSingleLimitInfo}})=> $accountResponsibleGameSingleLimitInfo}; 
    overflow-wrap: anywhere;
`;

S.LimitsInfoText = styled.div`
    padding: ${rem('15px', baseFont)} ${rem('15px', baseFont)};
    border-radius: 7px;
    font-family: ${FontFamily};
    font-size: ${rem('13px', baseFont)};
    font-weight: ${FwRegular};
    color: ${({theme:{$accountResponsibleGameLimitsInfo}})=> $accountResponsibleGameLimitsInfo};
    background: ${({theme:{$accountResponsibleGameTabBgColor}})=> $accountResponsibleGameTabBgColor}; 
    box-shadow: 0 4px 13px 0 ${({theme:{$accountResponsibleGameLimitTabShadowColor}})=> $accountResponsibleGameLimitTabShadowColor};
`;

S.LimitsHeaderText = styled.h2`
    margin: ${rem('26px', baseFont)} ${rem('15px', baseFont)} ${rem('15px', baseFont)} ${rem('15px', baseFont)};
    font-size: 17px;
    color: ${({theme:{$accountResponsibleGameTitleColor}})=> $accountResponsibleGameTitleColor};
    font-weight: ${FwBold}; 
`;

S.FlexTable = styled.div`
    font-family: ${TextMuliSemibold};
    display: flex;
    flex-flow: row wrap;
    font-size: ${rem('12px', baseFont)};
    color: ${({theme:{$accountResponsibleGameTableColor}})=> $accountResponsibleGameTableColor};
  
    &.header {
        font-family: ${TextMuliBold};
    }
    & .row {
        flex-direction: column;
        font-family: ${TextMuliSemibold};
    }
    .first {
        display: flex;
        flex-basis: 30%;
        align-items: center;
    }
`;

S.FlexRow = styled.div`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: 0 ${rem('15px', baseFont)} ${rem('15px', baseFont)} ${rem('15px', baseFont)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

S.MainHeader = styled.div`
    display: flex;
    padding: ${rem('19px', baseFont)} ${rem('15px', baseFont)};;
    flex-basis: 100%;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${FontFamily};  
    font-size: ${rem('15px', baseFont)};
    font-weight: ${FwSemibold}; 
    color: ${({theme:{$accountResponsibleGameLimitTabTxt}})=> $accountResponsibleGameLimitTabTxt};
`;

S.Header = styled.div`
    display: flex;
    padding: 0;
    flex-basis: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${FontFamily};  
    font-size: ${rem('12px', baseFont)};
    font-weight: ${FwRegular}; 
    color: #2D375EB3;
`;

S.LimitTypeWrapper = styled.div`
    margin: 3.5px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 4px 13px 0 ${({theme:{$accountResponsibleGameLimitTabShadowColor}})=> $accountResponsibleGameLimitTabShadowColor};
    background-color: ${({theme:{$accountResponsibleGameLimitContentBackground}})=> $accountResponsibleGameLimitContentBackground};
`;

S.Cell = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    flex-basis: 100%;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${FontFamily};  
    font-weight: ${FwSemibold}; 
    font-size: ${rem('12px', baseFont)};
    color: ${({theme:{$accountResponsibleGameLimitTabTxt}})=> $accountResponsibleGameLimitTabTxt};
    line-height: 1;
    border-bottom: 1px solid ${({theme:{$accountResponsibleGameTableBorderColor}})=> $accountResponsibleGameTableBorderColor};
    .text {
            text-overflow: ellipsis;
            overflow: hidden;
    }
    &:last-child {
        border-bottom: none;
    }
`;

S.CellLabels = styled.div`
    display: flex;
    justify-content: flex-start;
    min-height: ${rem('14px', baseFont)};
    padding: 0;
    flex-basis: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${rem('12px', baseFont)};
    line-height: 1;
`;

S.CellDoubleData = styled.div`
    display: flex;
    justify-content: flex-end;
    min-height:${rem('14px', baseFont)};
    padding: 0;
    flex-basis: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${rem('12px', baseFont)};
    line-height: 1;
    & div:last-child {
        padding-left: ${rem('7px', baseFont)};
    }
`;

S.CellDoubleDataBold = styled(S.CellDoubleData)`
    font-weight: bold;
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('14px', baseFont)};
    height: ${rem('14px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameTableHelpBgColor}})=> $accountResponsibleGameTableHelpBgColor};
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin: 0 ${rem('5px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.ArrowSwitchIcon = styled.div`
    display: flex;
    align-items: center;

    & svg {
        display: flex;
        width: ${rem('14px', baseFont)};
        height: ${rem('8px', baseFont)};
    }
    &.open {
        & svg {
            transform: rotate(180deg);
            & path {
                opacity: 1;
                stroke: ${({theme:{$accountResponsibleGameLimitTabArrowOpenColor}})=> $accountResponsibleGameLimitTabArrowOpenColor};
            }
        }
    }
`;

export default S;