import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';

let initialState = {
    toggleMap: [
        {
            key: 'TOGGLE_OPEN_RESET_PASSWORD_MODAL',
            isOpen: false
        },
        {
            key: 'TOGGLE_OPEN_REGISTER_MODAL',
            isOpen: false
        },
        {
            key: 'TOGGLE_OPEN_LOGIN_MODAL',
            isOpen: false
        },
        {
            key: 'TOGGLE_OPEN_ACTIVATE_ACCOUNT_MODAL',
            isOpen: false
        },
        {
            key: 'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL',
            isOpen: false
        }
    ]
};

export default typeToReducer({
    ['TOGGLE_BY_KEY']: (state, {payload: {key, isOpen}}) => {
        let newState = state;
        const index = _findIndex(state.toggleMap, {key});
        if (index != -1) {
            newState = update(newState, {toggleMap: {[index]: {isOpen: {$set: isOpen}}}});
        }
        return {...newState};
    }
}, initialState);
