const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL

const theme = {
    // Smartphones Mobiles (Portrait)
    mqMobile: `@media (min-width: 320px) and (max-width: 480px)`,
    // Smartphones Mobiles (Landscape)
    mqMobileHorz: `@media (min-width: 481px) and (max-width: 767px)`,
    // Tablets, Ipads (Portrait)
    mqTablets: `@media all (min-width: 768px) and (max-width: 1024px)`,
    // Tablets, Ipads (Landscape)
    mqTabletsHorz: `@media all (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
    // Desktops
    mqSmallDesktops: `@media (min-width: 1025px)`,
    mqMediumDesktops: `@media (min-width: 1281px)`,
    mqLargeDesktops: `@media (min-width: 1600px)`,
    mqXLargeDesktops: `@media (min-width: 1920px)`,

    lineClamp: (lineNumber)=>{
        return `
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${lineNumber};
            overflow: hidden;
        `;
    },
    
    visuallyHidden: ()=>{
        return `
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          padding:0 !important;
          border:0 !important;
          height: 1px !important;
          width: 1px !important;
          overflow: hidden;
        `;
    },

    // Main colors
    $color_1: `#09256D`,
    $color_2: `#F4F7FC`,
    $color_3: `#FFF`,
    $color_4: '#001a5b',
    $color_5: '#EFF2F3',
    $color_6: '#061957',
    $color_7: '#001A5B',
    $color_8: '#0E62C4',
    $color_9: '#A1A1A1',
    $color_10: '#E4202C',
    $color_11: '#9a9a9a',
    $color_12: '#112469',
    $color_13: '#B8C1D2',
    $color_14: '#8F9AB7',
    $color_15: '#CFE0F3',
    $color_16: '#E4202C',
    $color_17: '#BAC1D0',
    $color_18: '#009100',
    $color_19: '#0064CB',
    $color_20: '#02153F',
    $color_21: '#f3f6fa',
    $color_22: '#cfdbf0',
    $color_23: '#e87980',
    $color_24: '#111A5B',
    $color_25: '#C6D9F1',
    $color_26: '#F5F7FC',
    $color_27: '#2D375E80', // 50% opacity
    $color_28: '#2D375E',
    $color_29: '#ECF0F4',
    $color_30: '#44556A14',
    $color_31: '#E6EBF1',
    $color_32: '#EBEFF3',
    $color_33: '#EC3839',
    $color_34: '#EC383966', // 40% opacity
    $color_35: '#2D375ECC',// 80% opacity
    $color_36: '#969BAF',
    $color_37: '#eff3ff',
    $primaryColor: '#141C4E',
    $fontColor1: '#2D375E',
    $linkColor: ' #0E62C4',
    $secondColor: '#2D375E80',
    $white: '#fff',
    $black: '#000',

    primaryBtn: () => {
        return `
        width: 100%;
        height: 100%;
        font-size: 0.60rem;
        letter-spacing: 0.02rem;
        background-color: ${theme['$color_3']};
        color: ${theme['$primaryColor']};
        font-family: 'Open Sans';
        font-weight: ${theme['$fwMedium']};
        padding: 0;
        text-overflow: ellipsis;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        text-align: center;

        &.active { 
            background-color: ${theme['$secondColor']};
            color: ${theme['$color_3']};
        }
        `;
    },

    btnContainer: () => {
        return `
        display: flex;
        flex: 1;
        justify-content:space-between;

        & > a {
            &:hover,
            &:link,
            &:visited,
            &:active {
                text-decoration: none;
            }
        }
        `;
    },

    header: () => {
        return `
        color: ${theme['$fontColor1']};
        font-family: 'Open Sans';
        font-weight: ${theme['$fwBold']};
        margin: 1rem 0 0.7rem;
        `;
    },

    cardBody: () => {
        return `
        box-shadow: 0px 4px 13px rgba(68, 85, 106, 0.08);
        border-radius: 10px;
        `;
    },

    cardWithTab: () => {
        return `
        flex-wrap: wrap;
        justify-content:space-between;
        font-size: 0.75rem;
        border-radius: 0 0 10px 10px;
        padding: 0.5rem 0 0.5rem;
        `;
    },

    cardTab: () => {
        return `
        background-color: ${theme['$primaryColor']};
        font-family: 'Open Sans';
        height: 100%;
        width: 100%;
        min-height: 90px;
        border-radius: 10px 10px 0 0;
        margin: auto;
        `;
    },

    cardLabel: () => {
        return `
        color: ${theme['$fontColor1']};
        font-weight: ${theme['$fwRegular']};
        font-size: 0.6rem;
        font-family: 'Open Sans';
        `;
    },

    divider: () => {
        return `
        border-bottom: 1px solid #E6EBF1;
        padding: 0 48%;
        margin: auto;
        opacity: 0.7;
        `;
    },

    link: () => {
        return `
        color: ${theme['$linkColor']};
        font-family: 'Open Sans';
        font-weight: ${theme['$fwBold']};
        font-size: 0.6rem;
        text-decoration: none;
        `;
    },

    //Calendar
    calendarContainer: () => {
        return `
        display: flex;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;

        & .select-date {
          color: ${theme['$secondColor']};
          padding: 1rem 0 0.4rem;
          font-size: 0.75rem;
          font-weight: 600;
        };
        `;
    },

    dateLabel: () => {
        return `
        display: flex;
        width:100%;
        justify-content: space-between;
        font-weight: 600;
        color: ${theme['$secondColor']};
        font-size: 0.9rem;
        letter-spacing: -0.01em;
        & > .select-date {
        width: 48.5%;
        };
        `;
    },

    datePicker: () => {
        return `
        display: flex;
        width: 48.5%;
        input[type="date"] {
        width: 100%;
        display:flex;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #2D375E;
        font-size: 0.75rem;
        font-weight: 700;
        border: none;
        background-color: #E6EAF0;
        font-family: open sans;
        border-radius: 7px;
        padding: 11px 10px;
            &::-webkit-calendar-picker-indicator {
                background-image: url(${imagePath}images/svg/calendar_arrow.svg);
        };
            };
        `;
    },

    $fontFamily: `'Open Sans', sans-serif`,
    $textOswaldRegular: `"Open Sans", sans-serif`,
    $fwRegular: 400,
    $fwMedium: 500,
    $fwSemibold: 600,
    $fwBold: 700
};

// fonts
theme['$fontFamily'] = theme['$fontFamily'];
theme['$textOswaldRegular'] = theme['$textOswaldRegular'];
theme['$fwRegular'] = theme['$fwRegular'];
theme['$fwMedium'] = theme['$fwMedium'];
theme['$fwSemibold'] = theme['$fwSemibold'];
theme['$fwBold'] = theme['$fwBold'];

// login form
theme['$loginFormSubmitBtnColor'] = theme['$color_3'];
theme['$loginFormSubmitBtnBgColor'] = theme['$color_16'];

//account responsible game
theme['$accountResponsibleGameTabBgColor'] = theme['$color_3']; 
theme['$accountResponsibleGameTabActiveBgColor'] = theme['$color_27']; 
theme['$accountResponsibleGameTabTextColor'] = theme['$color_28']; 
theme['$accountResponsibleGameTabActiveTextColor'] = theme['$color_3']; 
theme['$accountResponsibleGameTabShadowColor'] = theme['$color_29']; 
theme['$accountResponsibleGameInfoColor'] = theme['$color_4']; 
theme['$accountResponsibleGameTableBgColor'] = theme['$color_2']; 
theme['$accountResponsibleGameTableColor'] = theme['$color_4']; 
theme['$accountResponsibleGameTableBorderColor'] = theme['$color_31']; 
theme['$accountResponsibleGameTableHelpBgColor'] = theme['$color_4']; 
theme['$accountResponsibleGameTooltipContentColor'] = theme['$color_4']; 
theme['$accountResponsibleGameTitleColor'] = theme['$color_28']; 
theme['$accountResponsibleGameSelfExclusionDescColor'] = theme['$color_28'];
theme['$accountResponsibleGameSelfExclusionHeaderColor'] = theme['$color_28'];
theme['$accountResponsibleGameSelfExclusionBoxBg'] = theme['$color_3'];
theme['$accountResponsibleGameSelfExclusionBtnBgColor'] = theme['$color_37'];
theme['$accountResponsibleGameSelfExclusionBtnColor'] = theme['$color_28'];
theme['$accountResponsibleGameSelfExclusionBtnOkColor'] = theme['$black'];
theme['$accountResponsibleGameSelfExclusionBtnCancelColor'] = theme['$color_3'];
theme['$accountResponsibleGameSelfExclusionBtnCancelBgColor'] = theme['$color_8'];
theme['$accountResponsibleGameSelfExclusionBtnDisabled'] = theme['$color_34'];
theme['$accountResponsibleGameSelfExclusionBtnTxtDisabled'] = theme['$color_27'];
theme['$accountResponsibleGameSelfExclusionDialogText'] = theme['$black'];
theme['$accountResponsibleGameFormSubmitBtn'] = theme['$color_33'];
theme['$accountResponsibleGameFormClearBtn'] = theme['$color_4']; 
theme['$accountResponsibleGameLimitsInfo'] = theme['$color_28']; 
theme['$accountResponsibleGameSingleLimitInfo'] = theme['$color_28']; 
theme['$accountResponsibleGameLimitTabActiveBackground'] = theme['$color_2']; 
theme['$accountResponsibleGameLimitTabTxt'] = theme['$color_28']; 
theme['$accountResponsibleGameLimitTabArrowOpenColor'] = theme['$color_28'];  
theme['$accountResponsibleGameLimitTabShadowColor'] = theme['$color_30'];  
theme['$accountResponsibleGameLimitContentBackground'] = theme['$color_3'];
theme['$accountResponsibleGameFormLabel'] = theme['$color_28'];
theme['$accountResponsibleGameFormInputText'] = theme['$color_28']; 
theme['$accountResponsibleGameFormShadowColor'] = theme['$color_29']; 
theme['$accountResponsibleGameFormBorderColor'] = theme['$color_31']; 
theme['$accountResponsibleGameFormInputFocusedBorder'] = theme['$color_8'];
theme['$accountBetHistoryItemDetailsBlockCombinationsCaption'] = theme['$color_36'];
theme['$accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor'] = theme['$color_3'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemBgColor'] = theme['$color_3'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemColor'] = theme['$color_24'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemBorderColor'] = theme['$color_17'];

// session
theme['$sessionTimerColor'] = theme['$color_28'];

// wallet
theme['$walletTransactionsListItemBg'] = theme['$color_7'];
theme['$walletTransactionsListItemDetailsBorderColor'] = theme['$color_1'];
theme['$walletTransactionsListItemDetailsBgColor'] = theme['$color_2'];
theme['$walletFiltersBg'] = theme['$color_3'];
theme['$datepickerWrapperBorderColor'] = theme['$color_4'];
theme['$walletFiltersBtnsBgColor'] = theme['$color_5'];
theme['$itemDetailsHeaderColor'] = theme['$color_6'];
theme['$walletFilterBtnColor'] = theme['$color_3'];
theme['$dateIconWrapperBgColor'] = theme['$color_7'];

// bonus
theme['$accountProfileBonusTabActiveBonusProgressBarBgColor'] = theme['$color_15'];
theme['$accountProfileBonusTabActiveBonusProgressBarPercColor'] = theme['$color_1'];
theme['$accountProfileBonusTabActiveBonusProgressBarMainColor'] = theme['$color_10']; 
theme['$accountProfileBonusTabActiveBonusCancelBg'] = theme['$color_1'];
theme['$accountProfileBonusModalsCancelBttnBg'] = theme['$color_10'];
theme['$accountProfileBonusModalsAcceptBorderBttnBg'] = theme['$color_1'];
theme['$accountProfileBonusModalsAcceptBttnColor'] = theme['$color_3'];
theme['$accountProfileBonusModalsTextColor'] = theme['$black'];
theme['$accountProfileBonusRegulationLinkColor'] = theme['$color_17'];
theme['$accountProfileBonusFilterBtnColor'] = theme['$color_3'];
theme['$accountProfileBonusFilterBtnBg'] = theme['$color_7'];
theme['$accountProfileBonusFilterBtnActiveBg'] = theme['$color_8'];
theme['$accountProfileBonusModalsSuccessBttnBg'] = theme['$color_10'];

// account bet history 
theme['$accountProfileBetHistoryDatesBackground'] = theme['$color_3']; 
theme['$accountProfileBetHistoryDatepickerBorder'] = theme['$color_6']; 
theme['$accountProfileBetHistoryDatepickerLabelBg'] = theme['$color_6']; 
theme['$accountProfileBetHistoryDatepickerLabelColor'] = theme['$color_6'];
theme['$accountProfileBetHistoryDatepickerIconColor'] = theme['$color_3']; 
theme['$accountProfileBetHistoryFilterBttnTxt'] = theme['$color_3']; 
theme['$accountProfileBetHistoryStatusFiltersBackground'] = theme['$color_3'];
theme['$accountProfileBetHistoryFilterBttnBackground'] = theme['$color_6'];
theme['$accountProfileBetHistoryFilterBttnActiveBackground'] = theme['$color_8']; 
theme['$accountProfileBetHistoryTransactionHeaderTxtColor'] = theme['$color_1']; 
theme['$accountProfileBetHistoryTransactionHeaderBackground'] = theme['$color_22']; 
theme['$accountProfileBetHistoryTransactionItemWrapperBackground'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionItemBackground'] = theme['$color_21']; 
theme['$accountProfileBetHistoryTransactionItemBorder'] = theme['$color_13']; 
theme['$accountProfileBetHistoryTransactionItemTypeColor'] = theme['$color_7']; 
theme['$accountProfileBetHistoryTransactionItemStakeColor'] = theme['$color_1']; 
theme['$accountProfileBetHistoryTransactionItemWonAmountColor'] = theme['$color_18']; 
theme['$accountProfileBetHistoryTransactionItemDateColor'] = theme['$color_1']; 
theme['$accountProfileBetHistoryTransactionArrowColor'] = theme['$color_6'];
theme['$accountProfileBetHistoryTransactionLabelBg'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionLostLabelColor'] = theme['$color_10']; 
theme['$accountProfileBetHistoryTransactionWonLabelColor'] = theme['$color_13']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBgActions'] = theme['$color_10']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBgInfo'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderTitle'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionDetailsBorder'] = theme['$color_21']; 
theme['$accountProfileBetHistoryTransactionDetailsMainColor'] = theme['$color_11']; 
theme['$accountProfileBetHistoryTransactionDetailsBg'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockBg'] = theme['$color_10']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockTxt'] = theme['$color_3']; 
theme['$accountProfileBetHistoryTransactionDetailsFooterBackground'] = theme['$color_1'];  
theme['$accountProfileBetHistoryTransactionDetailsFooterColor'] = theme['$color_3'];
theme['$accountProfileBetHistoryTransactionDetailsFooterLogoBackground'] = theme['$color_20'];
theme['$accountProfileBetHistoryTransactionDetailsFooterWonAmountBackground'] = theme['$color_4'];
theme['$accountProfileBetHistoryTransactionDetailsFooterWonAmountColor'] = theme['$color_3'];
theme['$accountProfileBetHistoryTransactionDetailsCashoutBackground'] = theme['$color_19'];  
theme['$accountProfileBetHistoryTransactionDetailsCashoutColor'] = theme['$color_3'];  
theme['$accountProfileBetHistoryTransactionDetailsRebetBackground'] = theme['$color_3']; 
theme['$accountProfileBetHistoryCashoutBttnBackground'] = theme['$color_8']; 
theme['$accountProfileBetHistoryCashoutBttnColor'] = theme['$color_3'];  
theme['$accountProfileBetHistoryTabBackgroundColor'] = theme['$color_2'];
theme['$accountProfileBetHistoryTabColor'] = theme['$color_4'];
theme['$accountProfileBetHistoryTabBackgroundColorActive'] = theme['$color_4'];
theme['$accountProfileBetHistoryTabColorActive'] = theme['$color_2'];

// freebets
theme['$accountProfileFreebetFilterBtnColor'] = theme['$color_3'];
theme['$accountProfileFreebetFilterBtnActiveBg'] = theme['$color_8'];
theme['$accountProfileFreebetHeaderColor'] = theme['$color_7'];
theme['$accountProfileFreebetBodyBgColor'] = theme['$color_2'];
theme['$accountProfileFreebetRegulationLinkColor'] = theme['$color_17'];

// general
theme['$noResultColor'] = theme['$color_7'];

export default theme;