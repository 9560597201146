import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.FavouritesWrapper = styled.div`
`;

S.FavouritesHeader = styled.div``;

S.FavouritesBody = styled.div``;

S.FavouritesList = styled(List)``;

S.FavouritesItem = styled.li``;

S.Title = styled.div``;

S.ParticipaneName = styled.div``;

S.EventsCount = styled.div``;

S.LeagueName = styled.div``;

S.FavouriteIcon = styled.div`
    flex-shrink: 0;
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    margin-right: ${rem('10px', baseFont)};

    & > svg {
        height: 100%;
    }
`;

export default S;
