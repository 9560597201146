import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountQueueBonus.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ModalOpacity from 'ModalOpacity.js';
import {
    setCurrentPage,
    fetchCreditBonusListByFilter,
    fetchCreditBonusListPending,
    fetchCreditBonusListFulfilled,
    fetchCreditBonusListFailure,
    manageCreditBonus
} from 'accountBonusActions.js';


class AccountQueueBonus extends Component {

    state = {
        selectedOfferId: null,
        showConfirmationModal: false,
        showInfoModal: false,
        modalTitle: '',
        modalDescription: '',
        infoModalTitle: '',
        infoModalDescription: '',
        status: ''
    };

    setSelectedOfferId = (selectedOfferId) => {
        this.setState((prevState) => {
            return {...prevState, selectedOfferId}
        });
    };

    setStatus = (status) => {
        this.setState((prevState) => {
            return {...prevState, status}
        });
    };

    setInfoModalTextElements = (infoModalTitle, infoModalDescription) => {
        this.setState((prevState) => {
            return {...prevState, infoModalTitle, infoModalDescription};
        });
    };

    setConfirmModalTextElements = (action) => {
        let modalTitle = '';
        let modalDescription = '';
        switch (action) {
            case 'activate':
                modalTitle = translation('account_bonus_activationTitle');
                modalDescription = translation('account_bonus_confirmActivate');
                break;
            case 'cancel':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmCancel');
                break;
            case 'reject':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmReject');
                break;
        }

        this.setState((prevState) => {
            return {...prevState, modalTitle, modalDescription};
        });
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    renderBonusActionConfirmation = (offerId, status) => {
        this.setStatus(status);
        this.setConfirmModalTextElements(status);
        this.setSelectedOfferId(offerId);
        this.toggleModal('showConfirmationModal');
    };

    componentDidMount = async() => {
        const {fetchCreditBonusListPending, fetchCreditBonusListByFilter, fetchCreditBonusListFailure, fetchCreditBonusListFulfilled, startDate, endDate} = this.props;

        fetchCreditBonusListPending();

        try {
            const data = await fetchCreditBonusListByFilter({status: 'QUEUE', endDate, startDate});
            fetchCreditBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountQueueBonus:', error);
            fetchCreditBonusListFailure(error)
        }

    };

    goPage = async(status, currentPage) => {
        const {fetchCreditBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            status,
            currentPage: offset
        };

        try {
            await fetchCreditBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    bonusActionAccept = async(offerId, action) => {
        const {manageCreditBonus} = this.props;

        try {
            const result = await manageCreditBonus(action, offerId);
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('success', '');
            // unfortunately topMenu has been made in spike instead of React
            app.component.TopMenu.updateBalance();
        } catch (error) {
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('error', translation(error));
        }
    };

    render() {
        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const {showInfoModal, showConfirmationModal, modalTitle, modalDescription, infoModalTitle, infoModalDescription, status, selectedOfferId} = this.state;

        const queueBonus = _get(bonus, 'queue');
        const queueBonusData = _get(queueBonus, 'data');

        return (
            <S.AccountQueueBonus className="account-queue-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <ModalOpacity isOpen={showConfirmationModal} showHeaderIcon={false} showHeaderTitle={false}
                                      padding={'1rem'} showCloseIcon={false} toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}>
                            <S.ActivateModal>
                                <S.Title>{modalTitle}</S.Title>
                                <S.Description>{modalDescription}</S.Description>
                                <S.BtnsWrapper>
                                    <S.ButtonCancel
                                        onClick={this.toggleModal.bind(this, 'showConfirmationModal')}>{translation('common_cancel')}</S.ButtonCancel>
                                    <S.ButtonAccept
                                        onClick={this.bonusActionAccept.bind(this, selectedOfferId, status)}>{translation('common_accept')}</S.ButtonAccept>
                                </S.BtnsWrapper>
                            </S.ActivateModal>
                        </ModalOpacity>

                        <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false}
                                      padding={'1rem'} toggleOpen={this.toggleModal.bind(this, 'showInfoModal')}>

                            <S.ModalInfo>
                                <S.InfoTitle>{infoModalTitle}</S.InfoTitle>
                                {infoModalDescription && <S.InfoDescription>{infoModalDescription}</S.InfoDescription>}
                            </S.ModalInfo>

                        </ModalOpacity>

                        <S.BonusList>
                           

                                    {_size(queueBonusData) ?
                                        (
                                            <>

                                            {_map(queueBonusData, ({offerName, offerId, offerPlayerInfo, validTo, bonusValue, offerQueueId, nameKey }) => {
                                                offerPlayerInfo = offerPlayerInfo ?? {};
                                                const {imageUrl = null, rulesPageUrl = null} = offerPlayerInfo;
                                                return (
                                                    <S.QueueBonusRow key={offerQueueId}>
                                                        <S.BonusHeader data-translation={nameKey} className="bonusName">{translation(nameKey)}</S.BonusHeader>
                                                        <S.BonusBody>
                                                            <S.BonusImageWrapper ratio={2}>
                                                                {imageUrl && <S.BonusOffrImg src={imageUrl}></S.BonusOffrImg>}
                                                            </S.BonusImageWrapper>
                                                            <S.BonusContent>
                                                                <S.BonusBox>
                                                                    <S.BonusTitle>{translation('account_bonus_validTo')}</S.BonusTitle>
                                                                    <S.BonusValue>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</S.BonusValue>
                                                                </S.BonusBox>
                                                                <S.Divider />
                                                                <S.BonusBox>
                                                                    <S.BonusTitle>{translation('account_bonus_amount')}</S.BonusTitle>
                                                                    <S.BonusValue>
                                                                        <span className="amount">{formatMoney(bonusValue)}</span>&nbsp;
                                                                    <span className="currency">{currencyCode}</span></S.BonusValue>
                                                                </S.BonusBox>
                                                                <S.Button className="accept"
                                                                    onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'activate')}>{translation('account_bonus_use')}</S.Button>
                                                            </S.BonusContent>

                                                        </S.BonusBody>
                                                        
                                                        {rulesPageUrl &&
                                                            <S.BonusFooter>
                                                                <S.BonusRegulationLink href={rulesPageUrl}>
                                                                    {translation('account_bonus_regulationLink')}
                                                                </S.BonusRegulationLink>
                                                            </S.BonusFooter>
                                                        }
                                                      
                                                    </S.QueueBonusRow>
                                                )
                                            })}

                                            {_size(queueBonusData) && (
                                                        <Pagination
                                                            currentPage={_get(queueBonus, 'currentPage')} limit={limit}
                                                            data={queueBonusData} cb={this.goPage.bind(this, 'queue')}/>
                                                    )}
                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <div className="noResults">{translation('account_bonus_notFound')}</div>
                                        </S.NoResult>
                                    }
                              
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountQueueBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        fetchCreditBonusListPending: bindActionCreators(fetchCreditBonusListPending, dispatch),
        fetchCreditBonusListFulfilled: bindActionCreators(fetchCreditBonusListFulfilled, dispatch),
        fetchCreditBonusListFailure: bindActionCreators(fetchCreditBonusListFailure, dispatch),
        manageCreditBonus: bindActionCreators(manageCreditBonus, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountQueueBonus);